import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { getAllSubjectModules } from '@app/services/module';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { getStudentById, getStudentWorkHistoryWithPagination } from '@app/services/student';
import { useSelector } from 'react-redux';

const StudentWorkHistory = () => {

    let { id } = useParams();
    let obj: any = {}
    const [student, setStudent] = useState(obj);
    const [history, setHistory] = useState([]);
    const [modules, setModules] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({fromDate: '', toDate: '', moduleId: ''});
    const [toDateError, setToDateError] = useState('');
    const [fromDateError, setFromDateError] = useState('');
    const canAccess = useSelector((state: any) => state.auth.permissions.student);

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };
      
    useEffect(() => {
        
        if(search.fromDate != '' && search.toDate == ''){
            setToDateError('Please select To date')
        }else{
            setToDateError('')
        }

        if(search.fromDate == '' && search.toDate != ''){
            setFromDateError('Please select From date')
        }else{
            setFromDateError('')
        }

        let fromDate = new Date(search.fromDate).getTime();
        let toDate = new Date(search.toDate).getTime();

        if((search.fromDate == '' && search.toDate == '') || (search.fromDate != '' && search.toDate != '')){
            if (fromDate > toDate) {
                setFromDateError('From date must be less than To date')
            } else {
                setFromDateError('')
                getStudentWorkHistoryData(1, perPage, sortField, sortDirection);
            }
        }
    },[search]);

    useEffect(() => {
        getStudDetailsById(id)
        getAllModuleData();
    },[]);

    const getStudDetailsById = async (id) => {
        try {
            setLoading(true)
            const resp = await getStudentById(id);
            if (resp.success === true) {
                setLoading(false)
                setStudent(resp?.collections)
            } else {
                setLoading(false)
                toast.error(resp?.errors[0]?.msg)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.response?.message || 'Internal server error');
        }
    };

    const getStudentWorkHistoryData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const resp = await getStudentWorkHistoryWithPagination(id, page, perPage, sortField, sortDirection, search);
            if (resp?.success === true) {
                setLoading(false);
                setHistory(resp?.collections?.data);
                setTotalRows(resp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllModuleData = async () => {
        try {
            const modulesResp = await getAllSubjectModules();
            if (modulesResp.success === true) {
                setModules(modulesResp.collections);
            } else {
                toast.error(modulesResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const columns = [
        {
            name: 'Assignment Date',
            selector: (row: { assignment_date: string; }) => row.assignment_date,
            cell: (row: { assignment_date: string; }) => (
                row.assignment_date ? moment(row.assignment_date).format('DD/MM/YYYY, dddd') : '---'
            ),
            sortable: true,
            sortField: 'assignment_date',
        },
        {
            name: 'Module',
            selector: (row: { name: string, worksheet_number:number }) => row.name +'-'+ row.worksheet_number ,//`${row.name} - ${row.worksheet_number}`, 
            sortable: true,
            sortField: 'module_name',
        }
    ];

    const handlePageChange = page => {
        getStudentWorkHistoryData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getStudentWorkHistoryData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getStudentWorkHistoryData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        setSearch({fromDate: '', toDate: '', moduleId: ''});
    };
    
    return (
        <>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Student Work History</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active">Student Work History</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div style={{ marginBottom: 10 }} className="row">
                                <div className="col-lg-12">
                                    
                                </div>
                            </div>
                            <div className="external-event" style={{backgroundColor: "#ffd316", position: 'relative', zIndex: 'auto', left: '0px', top: '0px', width: '15%', fontWeight: 400, cursor:'default'}}>Student Details</div>
                            <div className="card">
                                <div className="card-body" style={{backgroundColor: '#f9f1cc'}}>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="row">
                                                <dt className="col-sm-4">Student Name:</dt>
                                                <dd className="col-sm-8">{ student?.first_name + ' ' + student?.last_name} </dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="row">
                                                <dt className="col-sm-4">Year Group:</dt>
                                                <dd className="col-sm-8">{ student?.grade_name ?? '---' } </dd>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Student Work History</h3>
                                    <div className='d-flex justify-content-end'>
                                        { canAccess?.['work-summary'] && (
                                            <Link className="btn btn-default mr-2" to={`/student/work-summary/${id}`} role="button">Current Work Summary </Link>
                                        )}
                                        <Link className="btn btn-default mr-2" to='/students'>Close</Link>
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <div className="row mb-2">
                                        <div className="col-sm-2">
                                            <label>From Date</label>
                                            <Form.Control 
                                                type="date" 
                                                name="fromDate" 
                                                id="fromDate" 
                                                placeholder="Enter from date" 
                                                value={moment(search.fromDate).format('YYYY-MM-DD')}
                                                onChange={(e) => setSearch({ ...search, fromDate: e.target.value })}
                                            />
                                            <div className="text-danger">
                                                { fromDateError }
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <label>To Date</label>
                                            <Form.Control 
                                                type="date" 
                                                name="toDate" 
                                                id="toDate" 
                                                placeholder="Enter to date" 
                                                value={moment(search.toDate).format('YYYY-MM-DD')}
                                                onChange={(e) => setSearch({ ...search, toDate: e.target.value })}
                                            />
                                            <div className="text-danger">
                                                { toDateError }
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <label>Module</label>
                                            <select
                                                className="form-control"
                                                name="moduleId"
                                                onChange={(e) => setSearch({ ...search, moduleId: e.target.value })}
                                                value={search.moduleId}
                                            >
                                                <option value="">Search By Module</option>
                                                {modules.map((obj) => (
                                                    <option key={obj.id} value={obj.id}>
                                                        {obj.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-sm-2' style={{marginTop: '32px'}}>
                                            <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                        </div>
                                    </div>
                                    
                                    <DataTable
                                        columns={columns}
                                        data={history}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={currentPage}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                        progressPending={isLoading}
                                        sortServer
                                        onSort={handleSort}
                                        customStyles={customStyle}
                                    />
                                </div>
                                {/* /.card-body */}
                            </div>
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
            {/* /.content */}
        </>
    )
};

export default StudentWorkHistory;