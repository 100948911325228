import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import Select from "react-select";
import { Button } from '@app/components';
import { getRoleGroups, getRolesByGroupName } from '@app/services/role';
import { getUserById, updateUser } from '@app/services/user';
import { toast } from 'react-toastify';

const EditUser = () => {
    const userStatus = [{id:'active', name:'Active'}, {id:'inactive', name:'Inactive'}]

    let obj: any = {}
    const [roleGroups, setroleGroups] = useState([]);
    const [FMSroles, setFMSroles] = useState([]);
    const [FCroles, setFCroles] = useState([]);
    const [fmsRoleFlag, setFmsRoleFlag] = useState(false);
    const [fcRoleFlag, setFcRoleFlag] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [userData, setUserData] = useState(obj);
    const navigate = useNavigate();
    let { id } = useParams();

    useEffect(() => {
        getRoleGroupsData();
        getUserDetailsById(id);
    }, []);

    useEffect(() => {
        if(fmsRoleFlag){
            getRolesByGroupNameData({roleGroup: 'FMS-A'});
        }else{
            setFieldValue("fms_a_role", '');
        }
    }, [fmsRoleFlag]);

    useEffect(() => {
        if(fcRoleFlag){
            getRolesByGroupNameData({roleGroup: 'FC-A'});
        }else{
            setFieldValue("fc_a_role", '');
        }
    }, [fcRoleFlag]);

    const getUserDetailsById = async (id) => {
        try {
            const resp = await getUserById(id);
            if(resp.success === true){
                setUserData(resp.collections);
                if(resp?.collections?.roles?.length > 0){
                    const isFMSfound = resp.collections.roles.some(function(el) {
                        return el['group'] === "FMS-A";
                    })
                    setFmsRoleFlag(isFMSfound);
            
                    const isFCfound = resp.collections.roles.some(function(el) {
                        return el['group'] === "FC-A";
                    })
                    setFcRoleFlag(isFCfound);
            
                    resp.collections.roles.map((option) => {
                        if(option.group === "FMS-A"){
                            setFieldValue("fms_a_role", option.id);
                        }
                        if(option.group === "FC-A"){
                            setFieldValue("fc_a_role", option.id);
                        }
                    })
                    
                    setFieldValue(
                    "role_group",
                    [].slice.call(resp.collections.roles).map((option) => option.group)
                    );
                }
            }else{
                toast.error(resp?.errors[0]?.msg);
                navigate('/users');
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getRoleGroupsData = async () => {
       try {
            const roleGroupsResp = await getRoleGroups();
            if (roleGroupsResp.success === true) {
                setroleGroups(roleGroupsResp.collections);
            } else {
                toast.error(roleGroupsResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getRolesByGroupNameData = async (payload) => {
        const rolesResp = await getRolesByGroupName(payload);
        if(payload.roleGroup === 'FMS-A'){
            setFMSroles(rolesResp.collections);
        }
        if(payload.roleGroup === 'FC-A'){
            setFCroles(rolesResp.collections);
        }
    };

    const putUser = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateUser(id, payload);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                navigate('/users');
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            first_name: userData?.first_name || '',
            last_name: userData?.last_name || '',
            email: userData?.email || '',
            alt_email: userData?.alt_email || '',
            phone: userData?.phone || '',
            alt_phone: userData?.alt_phone || '',
            address: userData?.address || '',
            role_group: [],
            status: userData?.status || '',
            fms_a_role: '',
            fc_a_role: '',
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required('Please enter first name'),
            last_name: Yup.string().required('Please enter last name'),
            email: Yup.string()
                .required("Please enter email address")
                .email("Enter valid email"),
            alt_email: Yup.string()
                .email("Enter valid email"),
            phone: Yup.string()//.required('Please enter mobile number')
            .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                "Please enter valid number"
            ),
            alt_phone: Yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
            "Please enter valid number"
            ),
            // address: Yup.string().required('Please enter address'),
            role_group: Yup.array()
            .of(Yup.string().trim())
            .min(1, "Please select at least 1 role group")
            .required("Please select role group"),
            status: Yup.string().required('Please select status'),
            fms_a_role: Yup.string().when("role_group", {
                is: (val: string | string[]) => val.includes('FMS-A'),
                then: Yup.string().required('Please select role'),
            }),
            fc_a_role: Yup.string().when("role_group", {
                is: (val: string | string[]) => val.includes('FC-A'),
                then: Yup.string().required('Please select role'),
            })
        }),
        onSubmit: (values) => {
            values.first_name = (values.first_name).trim();
            values.last_name = (values.last_name).trim();
            putUser(values);
        },
        enableReinitialize: true,
    });

    const roleGroupChange = (options: readonly typeof Option[], setFieldValue) => {
        const isFMSfound = options.some(function(el) {
            return el['name'] === "FMS-A";
        })
        setFmsRoleFlag(isFMSfound);

        const isFCfound = options.some(function(el) {
            return el['name'] === "FC-A";
        })
        setFcRoleFlag(isFCfound);

        setFieldValue(
          "role_group",
          [].slice.call(options).map((option) => option.name)
        );
    };

  return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Update User</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">First Name <span className="required">*</span></label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter first name" 
                                                    name="first_name" 
                                                    onChange={handleChange} 
                                                    value={values.first_name}
                                                />
                                                {(touched.first_name && errors.first_name) && (
                                                    <div className="text-danger">
                                                        {errors.first_name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">Last Name <span className="required">*</span></label>
                                                <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Enter last name" 
                                                    name="last_name" 
                                                    onChange={handleChange} 
                                                    value={values.last_name}
                                                />
                                                {(touched.last_name && errors.last_name) && (
                                                    <div className="text-danger">
                                                        {errors.last_name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Email <span className="required">*</span></label>
                                                <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Enter email" 
                                                    name="email" 
                                                    onChange={handleChange}
                                                    value={values.email}
                                                />
                                                {(touched.email && errors.email) && (
                                                    <div className="text-danger">
                                                        {errors.email}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Alternate Email </label>
                                                <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Enter alternate email" 
                                                    name="alt_email" 
                                                    onChange={handleChange}
                                                    value={values.alt_email}
                                                />
                                                {(touched.alt_email && errors.alt_email) && (
                                                    <div className="text-danger">
                                                        {errors.alt_email}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">Mobile {/*<span className="required">*</span> */}</label>
                                                <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Enter mobile number" 
                                                    name="phone" 
                                                    onChange={handleChange} 
                                                    value={values.phone}
                                                />
                                                {(touched.phone && errors.phone) && (
                                                    <div className="text-danger">
                                                        {errors.phone}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Alternate Number</label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter alternate number" 
                                                    name="alt_phone" 
                                                    onChange={handleChange} 
                                                    value={values.alt_phone}
                                                />
                                                {(touched.alt_phone && errors.alt_phone) && (
                                                    <div className="text-danger">
                                                        {errors.alt_phone}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Address {/*<span className="required">*</span> */}</label>
                                                <textarea 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter address" 
                                                    name="address" 
                                                    onChange={handleChange} 
                                                    value={values.address}
                                                ></textarea>
                                                {(touched.address && errors.address) && (
                                                    <div className="text-danger">
                                                        {errors.address}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Role Group <span className="required">*</span></label>
                                                <Select
                                                    placeholder={<div>-- Please select --</div>}
                                                    isMulti
                                                    options={roleGroups}
                                                    closeMenuOnSelect={false}
                                                    getOptionLabel={(option)=>option.name}
                                                    getOptionValue={(option)=>option.name}
                                                    name="role_group"
                                                    onChange={ (e) => {roleGroupChange(e, setFieldValue)}}
                                                    value={
                                                        roleGroups?.length
                                                        ? roleGroups.filter((obj) =>
                                                            values.role_group.includes(obj.name)
                                                            )
                                                        : null
                                                    } // set selected values
                                                />
                                                {(touched.role_group && errors.role_group) && (
                                                    <div className="text-danger">
                                                        {errors.role_group}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Status <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={handleChange} 
                                                    value={values.status}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {userStatus.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.status && errors.status) && (
                                                    <div className="text-danger">
                                                        {errors.status}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        { fmsRoleFlag && (
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">FMS-A Role <span className="required">*</span></label>
                                                    <select 
                                                        className="form-control"
                                                        name="fms_a_role"
                                                        onChange={handleChange} 
                                                        value={values.fms_a_role}
                                                    >
                                                        <option value="">--Please select--</option>
                                                        {FMSroles.map((obj) => (
                                                            <option key={obj.id} value={obj.id}>
                                                                {obj.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {(touched.fms_a_role && errors.fms_a_role) && (
                                                        <div className="text-danger">
                                                            {errors.fms_a_role}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        { fcRoleFlag && (
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">FC-A Role <span className="required">*</span></label>
                                                    <select 
                                                        className="form-control"
                                                        name="fc_a_role"
                                                        onChange={handleChange} 
                                                        value={values.fc_a_role}
                                                    >
                                                        <option value="">--Please select--</option>
                                                        {FCroles.map((obj) => (
                                                            <option key={obj.id} value={obj.id}>
                                                                {obj.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {(touched.fc_a_role && errors.fc_a_role) && (
                                                        <div className="text-danger">
                                                            {errors.fc_a_role}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className='col-md-12'>
                                        <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                                        <Link className="btn btn-default" to='/users'>Close</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
  )
}

export default EditUser;