import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import {Form} from 'react-bootstrap';
import {toast} from 'react-toastify';
import { Button } from '@app/components';
import { createPostcode, getAllCountries } from '@app/services/postcode';
import { getAllRegions } from '@app/services/region';
import { getAllAreas } from '@app/services/area';

const AddPostcode = () => {

    const postcodeStatus = [ 
        // {id:'active', name:'Active'},
        {id:'available', name:'Available'},
        {id:'offered', name:'Offered'},
        {id:'reserved', name:'Reserved'},
    ]
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [areas, setAreas] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [commentShow, setCommentShow] = useState(false);
    const [isAreaRegion, setAreaRegionShow] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getCountriesData();
    },[]);

    const getCountriesData = async () => {
        try {
            const resp = await getAllCountries();
            if (resp.success === true) {
                setCountries(resp.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllRegionsData = async (slug) => {
        if(slug === 'united-kingdom'){
            try {
                const resp = await getAllRegions(slug)
                if (resp?.success === true) {
                    setRegions(resp?.collections);
                } else {
                    toast.error(resp?.errors[0]?.msg);
                }
            } catch (error: any) {
                toast.error(error?.response?.message || 'Failed');
            }
        }else{
            setRegions([]);
        }
    };

    const getAllAreasData = async (slug: string) => {
        if(slug){
            try {
                const resp = await getAllAreas(slug)
                if (resp?.success === true) {
                    setAreas(resp?.collections);
                } else {
                    toast.error(resp?.errors[0]?.msg);
                }
            } catch (error: any) {
                toast.error(error?.response?.message || 'Failed');
            }
        }else{
            setAreas([]);
        }
    };

    const addPostcode = async (payload) => {
        try {
            setLoading(true);
            const resp = await createPostcode(payload);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                navigate('/postcodes');
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue} = useFormik({
        initialValues: {
            country: '',
            region: '',
            area: '',
            name: '',
            description: '',
            comment: '',
            status: ''
        },
        validationSchema: Yup.object({
            country: Yup.string().required('Please select country'),
            region: Yup.string().when("country", {
                is: (val: string) => (val === 'united-kingdom'),
                then: Yup.string().required('Please select region'),
            }),
            area: Yup.string().when("country", {
                is: (val: string) => (val === 'united-kingdom'),
                then: Yup.string().required('Please select area'),
            }),
            name: Yup.string().required('Please enter postcode'),
            comment: Yup.string().when("status", {
                is: (val: string) => (val === 'reserved' || val === 'offered'),
                then: Yup.string().required('Please enter comment'),
            }),
            status: Yup.string().required('Please select status'),
        }),
        onSubmit: (values) => {
            addPostcode(values);
        }
    });

    const statusChange = (e, setFieldValue) => {
        if(e.target.value === 'reserved' || e.target.value === 'offered'){
            setCommentShow(true);
        }else{
            setCommentShow(false);
        }
        setFieldValue("status", e.target.value);
    };

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Add Postcode</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Country <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="country"
                                                    onChange={ (e) => {
                                                        handleChange(e);
                                                        getAllRegionsData(e.target.value);
                                                        setAreaRegionShow(e.target.value === 'united-kingdom')
                                                    }} 
                                                    value={values.country}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {countries.map((obj) => (
                                                        <option key={obj.slug} value={obj.slug}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.country && errors.country) && (
                                                    <div className="text-danger">
                                                        {errors.country}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Postcode <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter postcode"
                                                    name="name" 
                                                    onChange={handleChange} 
                                                    value={values.name}
                                                />
                                                {(touched.name && errors.name) && (
                                                    <div className="text-danger">
                                                        {errors.name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        { isAreaRegion && (
                                            <>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Region <span className="required">*</span></label>
                                                    <select
                                                        className="form-control"
                                                        name="region"
                                                        onChange={ (e) => {
                                                            handleChange(e);
                                                            getAllAreasData(e.target.value);
                                                        }} 
                                                        value={values.region}
                                                    >
                                                        <option value="">--Please select--</option>
                                                        {regions.map((obj) => (
                                                            <option key={obj.slug} value={obj.slug}>
                                                                {obj.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {(touched.region && errors.region) && (
                                                        <div className="text-danger">
                                                            {errors.region}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Area <span className="required">*</span></label>
                                                    <select
                                                        className="form-control"
                                                        name="area"
                                                        onChange={handleChange} 
                                                        value={values.area}
                                                    >
                                                        <option value="">--Please select--</option>
                                                        {areas.map((obj) => (
                                                            <option key={obj.slug} value={obj.slug}>
                                                                {obj.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {(touched.area && errors.area) && (
                                                        <div className="text-danger">
                                                            {errors.area}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            </>
                                        )}
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Status <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={ e => statusChange(e, setFieldValue) } 
                                                    value={values.status}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {postcodeStatus.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.status && errors.status) && (
                                                    <div className="text-danger">
                                                        {errors.status}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Description </label>
                                                <textarea 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter postcode description" 
                                                    name="description" 
                                                    onChange={handleChange} 
                                                    value={values.description}
                                                ></textarea>
                                                {(touched.description && errors.description) && (
                                                    <div className="text-danger">
                                                        {errors.description}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        { commentShow && (
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Comment <span className="required">*</span></label>
                                                    <textarea 
                                                        className="form-control" 
                                                        id="exampleInputEmail1" 
                                                        placeholder="Enter comment" 
                                                        name="comment" 
                                                        onChange={handleChange} 
                                                        value={values.comment}
                                                    ></textarea>
                                                    {(touched.comment && errors.comment) && (
                                                        <div className="text-danger">
                                                            {errors.comment}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Add </Button>
                                    <Link className="btn btn-default" to='/postcodes'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default AddPostcode
