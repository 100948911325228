import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import { deletePermissionById, getAllModules, getPermissionsWithPagination } from '@app/services/permission';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import Select from "react-select";

const ListPermission = () => {

    const selectRef = useRef(null);
    const [permissions, setPermissions] = useState([]);
    const [modules, setModules] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingModules, setLoadingModules] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({name: '', module: ''});

    useEffect(() => {
        getAllPermissionsData(1, perPage, sortField, sortDirection);
    },[search]);

    useEffect(() => {
        getModulesData();
    },[]);

    const getAllPermissionsData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const resp = await getPermissionsWithPagination(page, perPage, sortField, sortDirection, search);
            if (resp.success === true) {
                setLoading(false);
                setPermissions(resp.collections?.data);
                setTotalRows(resp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getModulesData = async () => {
        try {
            setLoadingModules(true);
            const resp = await getAllModules();
            if (resp.success === true) {
                setLoadingModules(false);
                setModules(resp?.collections);
            } else {
                setLoadingModules(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoadingModules(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const columns = [
        {
            name: 'Module',
            selector: (row: { module: string; }) => row.module ? row.module.charAt(0).toUpperCase() + row.module.slice(1) : '',
            sortable: true,
        },
        {
            name: 'Permission',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
        },
        // {
        //     name: 'Status',
        //     selector: (row: { status: string; }) => row.status,
        //     cell: (row: { status: string; }) => (
        //         row.status === 'Active' ? <span className="badge bg-success">{ row.status }</span> : <span className="badge bg-danger">{ row.status }</span>
        //     )
        // },
        {  
            name: 'Action',
            cell: (row: { id: number; }) => (
                <>
                    <Link
                        to={`/permissions/edit/${row.id}`}
                        role="button"
                        state={{ id: row.id }}
                    >
                        <i className="fas fa-pen mr-2" />
                    </Link>
                    <a href='#' onClick={(e) => { deletePermissionConfirm(e, row.id) }}>
                        <i className="fas fa-trash mr-2" />
                    </a>
                </>
            ),
        },
    ];
    
    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    const deletePermissionConfirm = (e, id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to delete this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deletePermission(id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const deletePermission = async (id) => {
        try {
            setLoading(true);
            const resp = await deletePermissionById(id);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                getAllPermissionsData(currentPage, perPage, sortField, sortDirection);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const handlePageChange = page => {
        getAllPermissionsData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllPermissionsData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllPermissionsData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        selectRef.current.setValue('');
        setSearch({name: '', module: ''});
    };

    const onChangeModule = (option) => {
        setSearch({ ...search, module: option.slug })
    };

    return (
        <div>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Manage Permissions</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active">Manage Permissions</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div style={{ marginBottom: 10 }} className="row">
                                
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Manage Permissions</h3>
                                    <div className="col-lg-12 text-right">
                                        <Link className="btn btn-primary" to="/permissions/add" > + Add New Permission </Link>
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <div className="row mb-2">
                                        <div className="col-sm-4">
                                            <Select
                                                    ref={selectRef}
                                                    name="module"
                                                    placeholder="Search By Module"
                                                    options={modules}
                                                    getOptionValue={(option)=>`${option['slug']}`}
                                                    getOptionLabel={(option)=>`${option['name']}`}
                                                    onChange={ (option) => {onChangeModule(option)}}
                                                    // styles={customStylesSelect}
                                                    isLoading={isLoadingModules}
                                                />
                                        </div>
                                        <div className="col-sm-4">
                                            <input type="text" name='name' className="form-control" value={search.name} onChange={(e) => setSearch({ ...search, name: e.target.value })} placeholder="Search By Permission" />
                                        </div>
                                        <div className='col-sm-4'>
                                            <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                        </div>
                                    </div>

                                    <DataTable
                                        columns={columns}
                                        data={permissions}
                                        pagination
                                        progressPending={ isLoading }
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={currentPage}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                        sortServer
                                        onSort={handleSort}
                                        customStyles={customStyle}
                                    />
                                </div>
                                {/* /.card-body */}
                            </div>
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>
    )
};

export default ListPermission;