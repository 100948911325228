import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getRoleById, getRolePermissionsByModule, updateRolePermissions } from '@app/services/role';
import { Button } from '@app/components';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setInitialData, setModulePermission, setPermission } from '@app/store/reducers/modulePermissions';

const EditRolePermission = () => {

    let obj: any = {}
    const roleModulesPermission = useSelector((state: any) => state.modulePermissions.data);
    const [isLoading, setLoading] = useState(false);
    const [roleData, setRoleData] = useState(obj);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { id } = useParams();

    useEffect(() => {
        getRoleDetailsById(id);
        getRolePermissions(id);
    },[]);

    const getRoleDetailsById = async (id) => {
        try{
            setLoading(true);
            const resp = await getRoleById(id);
            if(resp.success === true){
                setLoading(false);
                setRoleData(resp.collections);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getRolePermissions = async (id) => {
        try {
            const resp = await getRolePermissionsByModule(id);
            if (resp.success === true) {
                dispatch(setInitialData(resp.collections))
            } else {
                dispatch(setInitialData([]))
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };
    
    const putRolePermissions = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateRolePermissions(payload);
            if (resp.success === true) {
                toast.success(resp?.message);
                setLoading(false);
                navigate('/roles');
            } else {
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        let permissions = [];
        roleModulesPermission.map( (module) => {
            module?.permissions.map( (permission) => {
                if(permission.active === true){
                    permissions.push(permission.id)
                }
            })
        })

        if(permissions.length == 0){
            toast.error('Please select at least one permission for the role.');
            setLoading(false);
            return;
        }

        putRolePermissions({role_id: id, permissions: permissions});
    };

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Update Role Permission - { roleData.name }</h3>
                            </div>
                            <form>
                                <div className="card-body">
                                    {roleModulesPermission && roleModulesPermission.map((modules, index) => (
                                        <div key={index}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-check">
                                                        <label>
                                                            <input 
                                                                type="checkbox" 
                                                                className="form-check-input" 
                                                                name={`${modules.slug}-module`} 
                                                                onChange={ (e) => dispatch(setModulePermission({active: e.target.checked, slug: modules.slug})) }
                                                            />
                                                            { modules.name }
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                {modules.permissions.map((permission, index2) => (
                                                    <div className="col-sm-2" key={index2}>
                                                        <div className="form-check">
                                                            <label className="form-check-label">
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="form-check-input" 
                                                                    value={permission.id} 
                                                                    checked={permission.active} 
                                                                    name={`${modules.slug}.perm`} 
                                                                    onChange={ (e) =>  dispatch(setPermission({active: e.target.checked, slug: modules.slug, id: permission.id}))  }/>
                                                                { permission.name }
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <hr/>
                                        </div>
                                    ))}
                                </div>
                                <div className="card-footer">
                                    <div className='col-md-12'>
                                        <Button type="button" isLoading={isLoading} className="btn btn-primary mr-2" onClick={handleSubmit} >Update </Button>
                                        <Link className="btn btn-default" to='/roles'>Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default EditRolePermission