import { addToFranchiseGroupURL, createFranchiseURL, createShippingAddressURL, deleteFranchiseURL, getAllActiveFranchiseURL, getAllUnlinkFranchiseURL, getFranchiseByGroupIdURL, getFranchiseByIdURL, getFranchiseShippingAddressWithPaginationURL, getFranchisesWithPaginationURL, getPostcodeCountryByFranchiseURL, getSubjectsByFranchiseURL, makeDefaultAddressURL, makeDefaultFranchiseForGroupURL, removeFranchiseFromGroupURL, shippingAddressCommonURL, updateFranchiseURL } from "@app/constants/ApiEndPoints";
import axios from "axios";

export const getFranchisesWithPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(getFranchisesWithPaginationURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}
															&name=${search.name}&type=${search.type}&phone=${search.phone}&address=${search.address}&franchisee=${search.franchisee}&status=${search.status}&kashflowLicenseAccount=${search.kashflowLicenseAccount}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllActiveFranchise = async () => {
	try {
		const response = await axios.get(getAllActiveFranchiseURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const createFranchise = async (payload) => {
	try {
		const response = await axios.post(createFranchiseURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getFranchiseById = async (id: number) => {
	try {
		const response = await axios.get(getFranchiseByIdURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateFranchise = async (id, payload) => {
	try {
		const response = await axios.put(updateFranchiseURL + id, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const deleteFranchiseById = async (id: number) => {
	try {
		const response = await axios.delete(deleteFranchiseURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllUnlinkFranchise = async () => {
	try {
		const response = await axios.get(getAllUnlinkFranchiseURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getFranchiseByGroupId = async (id: number) => {
	try {
		const response = await axios.get(getFranchiseByGroupIdURL + id + '/fetch');
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const addToFranchiseGroup = async (payload) => {
	try {
		const response = await axios.post(addToFranchiseGroupURL, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const removeFranchiseFromGroup = async (payload) => {
	try {
		const response = await axios.delete(removeFranchiseFromGroupURL, {data: payload});
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};

export const makeDefaultFranchiseForGroup = async (payload) => {
	try {
		const response = await axios.post(makeDefaultFranchiseForGroupURL, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getFranchiseShippingAddressWithPagination = async (franchise_id, page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(getFranchiseShippingAddressWithPaginationURL + franchise_id + `/list?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}
															&address=${search.address}&phone=${search.phone}&isDefault=${search.isDefault}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const createShippingAddress = async (payload) => {
	try {
		const response = await axios.post(createShippingAddressURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getShippingAddressById = async (id: number) => {
	try {
		const response = await axios.get(shippingAddressCommonURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateShippingAddressById = async (id, payload) => {
	try {
		const response = await axios.put(shippingAddressCommonURL + id, payload);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const deleteShippingAddressById = async (id: number) => {
	try {
		const response = await axios.delete(shippingAddressCommonURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};

export const makeDefaultAddress = async (payload) => {
	try {
		const response = await axios.post(makeDefaultAddressURL, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllShippingAddressByFranchise = async (franchise_id: number) => {
	try {
		const response = await axios.get(shippingAddressCommonURL + franchise_id + '/list/all' );
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getSubjectsByFranchise = async (franchise_id: number) => {
	try {
		const response = await axios.get(getSubjectsByFranchiseURL + franchise_id );
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getSubjectHistory = async (franchise_id: number, subject_id: number) => {
	try {
		const response = await axios.get(getSubjectsByFranchiseURL + franchise_id + '/history/' + subject_id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const subjectActivateInactivate = async (status, payload) => {
	try {
		const response = await axios.post(getSubjectsByFranchiseURL + status, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getSubjectRateHistory = async (franchise_id: number, subject_id: number) => {
	try {
		const response = await axios.get(getSubjectsByFranchiseURL + 'rate/' + franchise_id + '/history/' + subject_id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateSubjectRate = async (payload) => {
	try {
		const response = await axios.post(getSubjectsByFranchiseURL + 'rate/update', payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getPostcodeCountryByFranchiseId = async (franchise_id: number) => {
	try {
		const response = await axios.get(getPostcodeCountryByFranchiseURL + franchise_id );
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};