import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import {Form} from 'react-bootstrap';
import { useFormik } from 'formik';
import { getRoleById, getRoleGroups, updateRole } from '@app/services/role';
import { Button } from '@app/components';
import { toast } from 'react-toastify';

const EditRole = () => {

    let obj: any = {}
    // const roleGroups = [{id:'1', name:'FMS-A'}, {id:'2', name:'FC-A'}, {id:'3', name:'EU'}]
    // const roleStatus = [{id:'1', name:'Active'}, {id:'0', name:'In Active'}]
    const [roleGroups, setroleGroups] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [roleData, setRoleData] = useState(obj);
    let { id } = useParams();

    useEffect(() => {
        getRoleGroupsData();
        getRoleDetailsById(id);
    },[]);

    const getRoleGroupsData = async () => {
        try {
            const roleGroupsResp = await getRoleGroups();
            if (roleGroupsResp.success === true) {
                setroleGroups(roleGroupsResp.collections);
            } else {
                toast.error(roleGroupsResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getRoleDetailsById = async (id) => {
        try {
            const resp = await getRoleById(id);
            if (resp.success === true) {
                setRoleData(resp.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };
    
    const putRole = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateRole(id, payload);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                navigate('/roles');
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const { handleChange, values, handleSubmit, touched, errors } = useFormik({
        initialValues: {
            name: roleData?.name || '',
            group: roleData?.group || '',
            // status: roleData?.status || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter role name')
            .min(5, 'Role name must be 5 characters or more')
            .max(30, 'Role name must be 30 characters or less'),
            group: Yup.string().required('Please select role group'),
            // status: Yup.string().required('Please select status'),
        }),
        onSubmit: (values) => {
            putRole(values);
        },
        enableReinitialize: true,
    });

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Update Role</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Role Name <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter role name"
                                                    name="name" 
                                                    onChange={handleChange} 
                                                    value={values.name}
                                                />
                                                {(touched.name && errors.name) && (
                                                    <div className="text-danger">
                                                        {errors.name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Role Group <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="group"
                                                    onChange={handleChange} 
                                                    value={values.group}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {roleGroups.map((obj) => (
                                                        <option key={obj.id} value={obj.name}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.group && errors.group) && (
                                                    <div className="text-danger">
                                                        {errors.group}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Status</label>
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={handleChange} 
                                                    value={values.status}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {roleStatus.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.status && errors.status) && (
                                                    <div className="text-danger">
                                                        {errors.status}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="card-footer">
                                    <div className='col-md-12'>
                                        <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                                        <Link className="btn btn-default" to='/roles'>Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default EditRole
