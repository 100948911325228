import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik, ErrorMessage, Field, FieldArray, FormikProvider } from 'formik';
import { Button } from '@app/components';
import { toast } from 'react-toastify';
import { getPricingPlanById, getSubjectsWisePricesByPlanId, updateSubjectAndTierWisePlanPrices } from '@app/services/pricingPlans';
import { getAllActiveSubjects } from "@app/services/subject";
import moment from 'moment';
import { Form } from 'react-bootstrap';

const AttachSubjectToPlan = () => {

    let obj: any = {}
    let { id } = useParams();
    const [planDetails, setPlanDetails] = useState(obj);
    const [subjects, setSubjects] = useState([]);
    const [tiers, setPlanTiers] = useState([]);
    const [tierArr, setTierArr] = useState([]);
    const [subjectWisePrices, setSubjectWisePrices] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getPricingPlanDetailsById(id);
        getSubjectsWisePricesByPlanIdData(id);
    },[]);

    const getPricingPlanDetailsById = async (id) => {
        try {
            setLoading(true);
            const resp = await getPricingPlanById(id);
            if(resp.success === true){
                setLoading(false);
                setPlanDetails(resp?.collections);
                setPlanTiers(resp?.collections?.tiers);
                (resp?.collections?.tiers).map((tier) => {
                    tierArr.push({...tier, price:''})
                })
                getAllActiveSubjectsData();
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
                navigate('/pricing-plans');
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllActiveSubjectsData = async () => {
        try {
			setLoading(true);
            const resp = await getAllActiveSubjects();
            if (resp.success === true) {
				setLoading(false);
                const sub = [];
                (resp?.collections).map((subject) => {
                    sub.push({id:id, subject_id:subject?.id, name:subject?.name, tiers:tierArr})
                })
                setSubjects(sub);
            } else {
				setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
			setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getSubjectsWisePricesByPlanIdData = async (id) => {
        try {
            setLoading(true);
            const resp = await getSubjectsWisePricesByPlanId(id);
            if (resp?.success === true) {
                setLoading(false);
                setSubjectWisePrices(resp?.collections);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const updateSubjectWisePlanPricesAPI = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateSubjectAndTierWisePlanPrices(payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                getSubjectsWisePricesByPlanIdData(id);
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    // const { handleChange, values, handleSubmit, touched, errors, setFieldValue, resetForm } = useFormik({
    const formik = useFormik({
        initialValues: {
            subjectTierPrices: subjects,
        },
        validationSchema: Yup.object({
            subjectTierPrices: Yup.array().of(
                Yup.object({
                    tiers: Yup.array().of(Yup.object({
                        price: Yup.number().required('Please enter amount'),
                    }))
                })
            )
        }),
        onSubmit: (values) => {
            // console.log(values.subjectTierPrices);return
            updateSubjectWisePlanPricesAPI(values.subjectTierPrices);
            formik.resetForm();
        },
        enableReinitialize: true,
    });

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Update Plan Prices</h3>
                            </div>
                            <div className="card-body">
                                <div className="row" style={{borderBottom: '1px solid #d1d1d1'}}>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Plan Name </label>
                                            <Form.Control plaintext readOnly value={planDetails?.name} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Start Date </label>
                                            <Form.Control plaintext readOnly value={moment(planDetails?.start_date).format('DD-MM-YYYY')} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">End Date </label>
                                            <Form.Control plaintext readOnly value={moment(planDetails?.end_date).format('DD-MM-YYYY')} />
                                        </div>
                                    </div>
                                </div>
                                <FormikProvider value={formik}>
                                <form onSubmit={formik.handleSubmit} style={{borderBottom: '1px solid #d1d1d1'}}>
                                    <FieldArray name="subjectTierPrices">
                                        {() => (
                                            <>
                                                {(formik.values.subjectTierPrices).map((subject, index) => (
                                                    <div key={index}>
                                                        <div className='row'>
                                                            <div className='col-md-12 my-3'>
                                                                <b>{ subject?.name } </b>
                                                            </div>
                                                        </div>
                                                        <div className='row' key={`tiers.${index}`}>
                                                            <FieldArray name={`subjectTierPrices.${index}.tiers`}>
                                                            {() => (
                                                                <>
                                                                    {(subject?.tiers).map((tier, index2) => (
                                                                        <div className="col-sm-3" key={index2}>
                                                                            <div className="form-group">
                                                                                <label htmlFor="exampleInputEmail1">{ tier?.name } (Range { tier?.from_count } - { tier?.to_count }) <span className="required">*</span></label>
                                                                                <Field type="number" id="exampleInputEmail1" className="form-control" placeholder="Enter amount" name={`subjectTierPrices.${index}.tiers.${index2}.price`}/>
                                                                                <div className="text-danger">
                                                                                    <ErrorMessage name={`subjectTierPrices.${index}.tiers.${index2}.price`} />
                                                                                </div>
                                                                            </div>
                                                                            {/* <pre>{ JSON.stringify(formik.errors) }</pre> */}
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            )}
                                                            </FieldArray>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </FieldArray>
                                    <div className="row">
                                        <div className='col-sm-4'>
                                            <div className='form-group'>
                                                <label htmlFor="franchise_id"></label>
                                                <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                                                <Link className="btn btn-default" to='/pricing-plans'>Close</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                </FormikProvider>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        {subjectWisePrices?.length > 0 && (
                                            <table className="table table-bordered" id="client_id_table">
                                                <tbody>
                                                    <tr className='text-center'>
                                                        <th>Subject</th>
                                                        { tiers.map((tier) => (
                                                            <th key={tier?.id}>{ tier?.name } (Range { tier?.from_count } - { tier?.to_count })</th>
                                                        ))}
                                                    </tr>
                                                    {subjectWisePrices?.length > 0  && subjectWisePrices.map((subject) => (
                                                        <tr key={subject.id}>
                                                            <td>{ subject.name }</td>
                                                            {(subject?.tiers).length > 0 && (subject?.tiers).map((tier) => (
                                                                <td key={tier.id}>£ { tier.price }</td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                        {subjectWisePrices?.length == 0 && (
                                            <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default AttachSubjectToPlan;