import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getStudentEnrollmentDetails } from '@app/services/summerCamp';
import CurrencyFormat from 'react-currency-format';

const ViewEnrollDetailsModal = (props) => {

    const [enrollDetails, setEnrollDetails] = useState([]);
    
    useEffect(() => {
        console.log(props?.campId, props?.studentId)
        if(props?.campId > 0 && props?.studentId > 0 && props?.show == true){
            getEnrollmentDetails({summer_camp_id: props?.campId, student_id: props?.studentId});
        }
    },[props?.show]);

    const getEnrollmentDetails = async (payload) => {
        try {
            const resp = await getStudentEnrollmentDetails(payload);
            if (resp.success === true) {
                setEnrollDetails(resp?.collections)
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error');
        }
    };

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{props.subjectName} View Enrollment Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            { enrollDetails.length > 0 && (
                <>
                <div className="list_module_wrapper row">
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail12">Camp Name </label>
                            <dd>{enrollDetails[0]?.summer_camp_name ?? '---'}</dd>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail13">Student Name </label>
                            <dd>{enrollDetails[0]?.student_name ?? '---'}</dd>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail13">Franchise Name </label>
                            <dd>{enrollDetails[0]?.franchise_name ?? '---'}</dd>
                        </div>
                    </div>
                </div>
                <hr/>
                </>
            )}
            { enrollDetails.length > 0 && (enrollDetails).map((enroll) => (
                <div className='content_inner_wrap'>
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="form-group">
                                <dt>Enroll Type </dt>
                                <dd>{enroll?.type ? enroll?.type.charAt(0).toUpperCase() + enroll?.type.slice(1) : '---'}</dd>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="form-group">
                                <dt>Total Amount </dt>
                                <dd><CurrencyFormat value={ enroll?.total_amount } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={props?.currency + ' '} /></dd>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="form-group">
                                <dt>Enrolled At </dt>
                                <dd>{enroll?.created_at ? moment(enroll?.created_at).format('DD-MM-YYYY hh:mm A') : '---'}</dd>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-8 mt-2">
                            <table className="table table-bordered" id="client_id_table">
                                <tbody>
                                    <tr className='text-center'>
                                        <th>Batch Name</th>
                                        <th>Batch Time</th>
                                        {enroll?.type == 'day' && ( <th>Camp Date(s)</th> )}
                                        {/* <th>Price</th> */}
                                    </tr>
                                    { enroll?.details && (enroll?.details).map((obj) => (
                                        <tr key={obj?.id} className='text-center'>
                                            <td>{ obj?.batch_name }</td>
                                            <td>{ obj?.batch_timing }</td>
                                            {enroll?.type == 'day' && ( <td>{ obj?.date ? moment(obj?.date).format('DD-MM-YYYY') : '---' }</td> )}
                                            {/* <td><CurrencyFormat value={ obj?.price } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={props?.currency + ' '} /></td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ))}
            { enrollDetails.length == 0 && (
                <div className="row">
                    <div className="col-md-12">
                        <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                    </div>
                </div>
            )}
            </Modal.Body>
            {/* <Modal.Footer>
                <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    </div>
  )
}

export default ViewEnrollDetailsModal;