import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';

export interface SmallBoxProps {
	type: 'info' | 'success' | 'warning' | 'danger';
	icon?: string;
	count: number;
	title: string;
	navigateTo: string;
	isLoading?: boolean;
}

const SmallBox = ({
	type = 'info',
	icon = 'ion-bag',
	count,
	title,
	navigateTo,
	isLoading,
}: SmallBoxProps) => {
	const [t] = useTranslation();
	let spinnerTemplate;

	if (isLoading) {
		spinnerTemplate = (
			<Spinner
				className="ml-2"
				as="span"
				animation="border"
				size="sm"
				role="status"
				aria-hidden="true"
			/>
		);
	}

	return (
		<div className={`small-box bg-${type}`}>
			<div className="inner">
				{spinnerTemplate}
				<h3>{count}</h3>
				<p>{title}</p>
			</div>
			<div className="icon">
				<i className={`fas ${icon || 'ion-bag'}`} />
			</div>
			<Link to={navigateTo} className="small-box-footer">
				<span className="mr-2">{t('main.label.moreInfo')}</span>
				<i className="fa fa-arrow-circle-right" />
			</Link>
		</div>
	);
};

export default SmallBox;
