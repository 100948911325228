import { SmallBox } from '@app/components';
import { getDashboard } from '@app/services/auth';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const Dashboard = () => {
	
	const [data, setDashboardData] = useState([]);
	const [isLoading, setLoading] = useState(false);
    const canAccess = useSelector((state: any) => state.auth.permissions.dashboard);
    const user = useSelector((state: any) => state.auth.currentUser);
    const role = useSelector((state: any) => state.auth.selectedRole);

	useEffect(() => {
        getDashboardData();
    },[]);

    const getDashboardData = async () => {
        try {
			setLoading(true);
            const resp = await getDashboard();
            if (resp.success === true) {
				setLoading(false);
				setDashboardData(resp.collections);
            } else {
				setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
			setLoading(false);
            toast.error(error.message);
        }
    };

	return (
		<div className="container-fluid">
			{/* Small Box (Stat card) */}
			{/* <h5 className="mb-2 mt-4">Small Box</h5> */}
			{ canAccess?.view && (
				<div className="row">
					<div className="col-lg-3 col-6">
						<SmallBox
							type="success"
							icon="fa-user-tag"
							count={ data['postcode'] }
							title="Active Postcodes"
							navigateTo="/postcodes"
							isLoading={isLoading}
						/>
					</div>
					<div className="col-lg-3 col-6">
						<SmallBox
							type="warning"
							icon="fa-briefcase"
							count={ data['franchise'] }
							title="Active Franchises"
							navigateTo="/franchise"
							isLoading={isLoading}
						/>
					</div>
					<div className="col-lg-3 col-6">
						<SmallBox
							type="info"
							icon="fa-briefcase"
							count={ data['centre']}
							title="Active Centres"
							navigateTo="/centers"
							isLoading={isLoading}
						/>
					</div>
				</div>
			)}
			{ !canAccess?.view && (
				<div className="row">
					<div className="col-md-12">
						<div className="card card-default">
							<div className="card-header">
								<h3 className="card-title">
									{/* <i className="fas fa-info" /> */}
									Welcome { user.first_name}
								</h3>
							</div>
							<div className="card-body">
								<div className="callout callout-info">
									<h5>Welcome { user.first_name +' '+ user.last_name}!</h5>
									<dt>Your Email:</dt>
									<dd>{ user.email }</dd>
									<dt>Your Mobile:</dt>
									<dd>{ user.phone }</dd>
									<p>You are logged in as a <b>{ role.name }</b> </p>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Dashboard;