import React, { useEffect, useState } from 'react'
import Table from '@app/components/datatable/Table';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getAllSetting } from '@app/services/setting';
import UpdateSettingModal from './UpdateSettingModal';

const ListSetting = () => {

    const [settings, setSettings] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [settingPayload, setUpdateSettingPayload] = useState({id: '', name: '', description: '', value: ''});
    const permission = useSelector((state: any) => state.auth?.permissions?.setting);

    useEffect(() => {
        getAllSettingssData();
    },[]);

    const getAllSettingssData = async () => {
        try {
            setLoading(true);
            const resp = await getAllSetting();
            if (resp.success === true) {
                setLoading(false);
                setSettings(resp.collections);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const columns = [
        {
            name: 'Setting Name',
            selector: (row: { description: string; }) => row.description,
            sortable: true,
        },
        {
            name: 'Setting Value',
            selector: (row: { value: string; }) => row.value,
            sortable: true,
        },
        {  
            name: 'Action',
            cell: (row: { id: number, name: string, description: string, value: string }) => (
              <>
                { permission?.update &&
                    <a href='#' onClick={ (e) => { handleUpdateSetting(row.id, row.name, row.description, row.value) }} data-toggle="tooltip" title="Edit Setting">
                        <i className="fas fa-pen mr-2"/>
                    </a>
                }
              </>
            ),
        },
    ];

    const handleUpdateSetting= async(id, name, description, value) => {
        handleShow();
        setUpdateSettingPayload({id: id, name: name, description: description, value: value})
    }
    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manage Settings</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active">Manage Settings</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Manage Settings</h3>
                                        <div className="col-lg-12 text-right">
                                            
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <Table
                                            columns={columns}
                                            data={settings}
                                            pagination
                                            // selectableRows
                                            progressPending={ isLoading }
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                    <UpdateSettingModal
                        show={show}
                        handleClose={handleClose}
                        getAllSettingssData={getAllSettingssData}
                        payload={settingPayload}
                    />
                </section>
                {/* /.content */}
            </div>

        </div>
    )
};

export default ListSetting;
