import { createSlice } from '@reduxjs/toolkit';

export interface SettingPatternState {
	days: any;
}

const initialState: SettingPatternState = {
	days: [],
};

export const SettingPatternSlice = createSlice({
	name: 'settingPattern',
	initialState,
	reducers: {
		loadDays: (state, { payload }) => {
			state.days = [];
			for (let i=1; i <= (7 - payload.count); i++) { 
				state.days.push({day: i, module1:'', module2:'', module3:'', module4: ''})
			}
		},
		loadEditDays: (state, { payload }) => {
			state.days = payload;
			// for (let i=1; i <= (7 - payload.count); i++) { 
			// 	state.days.push({day: i, module1:'', module2:'', module3:'', module4: ''})
			// }
		},
		clearDays: (state) => {
			state.days = [];
		},
	}
});

export const { loadDays, clearDays, loadEditDays} = SettingPatternSlice.actions;

export default SettingPatternSlice.reducer;