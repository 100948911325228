import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import {Form} from 'react-bootstrap';
import { useFormik } from 'formik';
import { Button } from '@app/components';
import { toast } from 'react-toastify';
import { getSubjectById, updateSubject } from '@app/services/subject';

const EditSubject = () => {

    let obj: any = {}
    const subjectStatus = [{value:'active', label:'Active'}, {value:'inactive', label:'Inactive'}]
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [subjectData, setSubjectData] = useState(obj);
    let { id } = useParams();

    useEffect(() => {
        getSubjectDetailsById(id);
    },[]);

    const getSubjectDetailsById = async (id) => {
        try {
            const resp = await getSubjectById(id);
            if (resp.success === true) {
                setSubjectData(resp.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };
    
    const putSubject = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateSubject(id, payload);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                navigate('/subjects');
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const { handleChange, values, handleSubmit, touched, errors } = useFormik({
        initialValues: {
            name: subjectData?.name || '',
            prefix: subjectData?.prefix || '',
            status: subjectData?.status || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter subject name')
            .min(5, 'Subject name must be 5 characters or more')
            .max(30, 'Subject name must be 30 characters or less'),
            prefix: Yup.string().required('Please enter subject prefix'),
            status: Yup.string().required('Please select status'),
        }),
        onSubmit: (values) => {
            putSubject(values);
        },
        enableReinitialize: true,
    });

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Update Subject</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Subject Name <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter subject name"
                                                    name="name" 
                                                    onChange={handleChange} 
                                                    value={values.name}
                                                />
                                                {(touched.name && errors.name) && (
                                                    <div className="text-danger">
                                                        {errors.name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Subject Prefix <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter subject prefix"
                                                    name="prefix" 
                                                    onChange={handleChange} 
                                                    value={values.prefix}
                                                />
                                                {(touched.prefix && errors.prefix) && (
                                                    <div className="text-danger">
                                                        {errors.prefix}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Status <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={handleChange} 
                                                    value={values.status}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {subjectStatus.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.status && errors.status) && (
                                                    <div className="text-danger">
                                                        {errors.status}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className='col-md-12'>
                                        <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                                        <Link className="btn btn-default" to='/subjects'>Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default EditSubject
