import "../../pages/order-form/OrderForm.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OrderFormStep1 from "./OrderFormStep1";
import OrderFormStep2 from "./OrderFormStep2";
import OrderFormStep3 from "./OrderFormStep3";
import Invoice from "./Invoice";
import { useEffect } from "react";
import { setStep } from "@app/store/reducers/order";
import StripeContainer from "./../stripe/StripeContainer";

const OrderForm = () => {

    const orderStore = useSelector((state: any) => state.order);
    const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		return () => {
			dispatch(setStep(1));
		};
	}, []);

	return (
		<>
		<section className="modul_sec_wrapper">
			<div className="col-md-12">
				<div className="card card-primary mb-0">
					<div className="card-header">
						<h3 className="card-title">{ orderStore.step === 1 ? 'Choose Franchise' : (orderStore.step === 2 ? 'Order Form' : (orderStore.step === 3 ? 'Order Summary' : (orderStore.step === 4 ? 'Order Invoice' : 'Make Payment')))}</h3>
						{ orderStore.step !== 1 && (
							<div className="card-tools">
								<h3 className="card-title">You are ordering for franchise : <u>{orderStore.franchise.name}</u></h3>
							</div>
						)}
					</div>
				</div>
			</div>
			{ orderStore.step == 1 && (
				<OrderFormStep1/>
			)}
			{ orderStore.step == 2 && (
				<OrderFormStep2/>
			)}
			{ orderStore.step == 3 && (
				<OrderFormStep3/>
			)}
			{ orderStore.step == 4 && (
				<Invoice/>
			)}
			{ orderStore.step == 5 && (
				<StripeContainer/>
			)}
		</section>
		</>
	);
};

export default OrderForm;