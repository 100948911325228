import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link,useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { getParentById } from '@app/services/guardian';
import { useSelector } from 'react-redux';

const ViewGuardian = () => {
    let obj: any = {}
    const [isLoading, setLoading] = useState(false);
    const [parent, setParentData] = useState(obj);
    const canAccess = useSelector((state: any) => state.auth.permissions.parent);
    const navigate = useNavigate();
    let { id } = useParams();

    useEffect(() => {
        getParentDetailsById(id);
    }, []);

    const getParentDetailsById = async (id) => {
        try {
            setLoading(true);
            const resp = await getParentById(id);
            if(resp.success === true){
                setLoading(false);
                setParentData(resp.collections);
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
                navigate('/parents');
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

  return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">View Parent Details</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Parent Name </label>
                                            <dd>{parent.first_name+' '+parent.last_name} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Email </label>
                                            <dd>{parent.email ? parent.email : '---'}</dd>   
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Gender </label>
                                            <dd>{parent.gender ? parent.gender.charAt(0).toUpperCase()+ parent.gender.slice(1) : '---'}</dd>   
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Franchise </label>
                                            <dd>{parent.franchise_name ? parent.franchise_name : '---'}</dd>   
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Contact No. </label>
                                            <dd>{parent.phone ? parent.phone : '---'}</dd>   
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Alternate Contact No. </label>
                                            <dd>{parent.alt_phone ? parent.alt_phone : '---'}</dd>   
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Status </label>
                                            <dd>{parent.status ? parent.status.charAt(0).toUpperCase()+ parent.status.slice(1) : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Address Line 1 </label>
                                            <dd>{parent?.address}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Address Line 2 </label>
                                            <dd>{parent?.address_line_2 ?? '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">City </label>
                                            <dd>{parent?.city ?? '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Postcode </label>
                                            <dd>{parent?.postcode ?? '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Country </label>
                                            <dd>{parent?.country ? parent?.country.toUpperCase() : "---"}</dd>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <Link className="btn btn-default" to='/parents'>Close</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default ViewGuardian;