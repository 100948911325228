import { attachFranchiseToPlanURL, createPricingPlanURL, deletePricingPlanURL, detachFranchiseFromPlanURL, getAllActivePricingPlansURL, getAllUnlinkPlanFranchiseURL, getFranchiseByPlanIdURL, getPricingPlanByIdURL, getPricingPlanHistoryByPlanIdURL, getPricingPlansWithPaginationURL, getSubjectwisePricesByPlanIdURL, makeDefaultPricingPlanURL, updatePricingPlanURL, updateSubjectAndTierWisePlanPricesURL, updateSubjectWisePlanPricesURL } from "@app/constants/ApiEndPoints";
import axios from "axios";

export const getPricingPlansWithPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(getPricingPlansWithPaginationURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&name=${search.name}&status=${search.status}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllActivePricingPlans = async () => {
	try {
		const response = await axios.get(getAllActivePricingPlansURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const createPricingPlan = async (payload) => {
	try {
		const response = await axios.post(createPricingPlanURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getPricingPlanById = async (id: number) => {
	try {
		const response = await axios.get(getPricingPlanByIdURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updatePricingPlan = async (id, payload) => {
	try {
		const response = await axios.put(updatePricingPlanURL + id, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const deletePricingPlanById = async (id: number) => {
	try {
		const response = await axios.delete(deletePricingPlanURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};

export const makeDefaultPricingPlan = async (payload) => {
	try {
		const response = await axios.post(makeDefaultPricingPlanURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllUnlinkPlanFranchise = async () => {
	try {
		const response = await axios.get(getAllUnlinkPlanFranchiseURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getFranchiseByPlanId = async (id: number) => {
	try {
		const response = await axios.get(getFranchiseByPlanIdURL + id + '/plan');
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const attachFranchiseToPlan = async (payload) => {
	try {
		const response = await axios.post(attachFranchiseToPlanURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const detachFranchiseFromPlan = async (payload) => {
	try {
		const response = await axios.post(detachFranchiseFromPlanURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getSubjectsWisePricesByPlanId = async (id: number) => {
	try {
		const response = await axios.get(getSubjectwisePricesByPlanIdURL + id + '/subject');
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateSubjectAndTierWisePlanPrices = async (payload) => {
	try {
		const response = await axios.post(updateSubjectAndTierWisePlanPricesURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateSubjectWisePlanPrices = async (payload) => {
	try {
		const response = await axios.post(updateSubjectWisePlanPricesURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getPricingPlanHistoryByPlanIdWithPagination = async (id, page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(getPricingPlanHistoryByPlanIdURL + id + `/history?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&type=${search.type}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};