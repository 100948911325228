import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import {Form} from 'react-bootstrap';
import { useFormik } from 'formik';
import { Button } from '@app/components';
import { toast } from 'react-toastify';
import { getItemById, updateItem } from '@app/services/otherItem';

const EditItem = () => {

    let obj: any = {}
    const itemStatus = [{value:'active', label:'Active'}, {value:'inactive', label:'Inactive'}]
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [itemData, setItemData] = useState(obj);
    let { id } = useParams();

    useEffect(() => {
        getItemDetailsById(id);
    },[]);

    const getItemDetailsById = async (id) => {
        try {
            const resp = await getItemById(id);
            if (resp.success === true) {
                setItemData(resp.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };
    
    const putItem = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateItem(id, payload);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                navigate('/other-items');
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
          }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            name: itemData?.name || '',
            price: itemData?.price || '',
            status: itemData?.status || '',
            taxable: itemData?.taxable || '',
            in_stock : itemData?.in_stock || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter item name')
            .min(5, 'Item name must be 5 characters or more')
            .max(30, 'Item name must be 30 characters or less'),
            price: Yup.string().required('Please enter price')
            .matches(/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/,
				"Please enter valid price"
			),
            status: Yup.string().required('Please select status'),
        }),
        onSubmit: (values) => {
            putItem(values);
        },
        enableReinitialize: true,
    });

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Update Item</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Item Name <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter item name"
                                                    name="name" 
                                                    onChange={handleChange} 
                                                    value={values.name}
                                                />
                                                {(touched.name && errors.name) && (
                                                    <div className="text-danger">
                                                        {errors.name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Item Price <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter item price"
                                                    name="price" 
                                                    onChange={handleChange}
                                                    value={values.price}
                                                    onWheel={ (e) => e.currentTarget.blur() }
                                                />
                                                {(touched.price && errors.price) && (
                                                    <div className="text-danger">
                                                        {errors.price}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="franchise_id"><b> Taxable Item? </b></label>
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox"
                                                        id="taxable"  
                                                        name="taxable" 
                                                        className="form-check-input" 
                                                        onChange={(e) => { 
                                                            handleChange 
                                                            setFieldValue("taxable", e.target.checked ? 1 : 0)
                                                        }}
                                                        checked={values.taxable}
                                                    />
                                                    <label htmlFor="taxable" className="form-check-label"></label>
                                                </div>
                                                {(touched.taxable && errors.taxable) && (
                                                    <div className="text-danger">
                                                        {errors.taxable}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Status <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={handleChange} 
                                                    value={values.status}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {itemStatus.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.status && errors.status) && (
                                                    <div className="text-danger">
                                                        {errors.status}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="franchise_id"><b> Out Of Stock </b></label>
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox"
                                                        id="in_stock"  
                                                        name="in_stock" 
                                                        className="form-check-input" 
                                                        onChange={(e) => { 
                                                            handleChange 
                                                            setFieldValue("in_stock", e.target.checked ? 1 : 0)
                                                        }}
                                                        checked={values.in_stock}
                                                    />
                                                    <label htmlFor="in_stock" className="form-check-label"></label>
                                                </div>
                                                {(touched.in_stock && errors.in_stock) && (
                                                    <div className="text-danger">
                                                        {errors.in_stock}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className='col-md-12'>
                                        <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                                        <Link className="btn btn-default" to='/other-items'>Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default EditItem
