import React, { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { getDemographicDeatailsByPostcode } from '@app/services/postcode';
import Spinner from '@app/modules/main/Spinner';
import { Button } from '@app/components';
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import fileDownload from 'js-file-download';

const ViewDemographic = () => {
    const [isLoading, setLoading] = useState(false);
    const [demographicData, setDemographicData] = useState([]);
    const ageGroups = [{ name: "Age 0-4"}, { name: "Age 5-7"}, { name: "Age 8-9"}, { name: "Age 10-14"}, { name: "Age 15"}, { name: "Age 16-17"}, { name: "Age 18-19"}, { name: "Age 20-24"}, { name: "Age 25-29"}, { name: "Age 30-44"}, { name: "Age 45-59"}, { name: "Age 60-64"}, { name: "Age 65-74"}, { name: "Age 75-84"}, { name: "Age 85-89"}, { name: "Age 90+"}]
    const ethinicities =  [{name: "White"},{name: "Mixed Ethnicity"},{name: "Indian"},{name: "Pakistani"},{name: "Bangladeshi"},{name: "Chinese"},{name: "Other Asian"},{name: "Black African"},{name: "Black Caribbean"},{name: "Other Black/African/Caribbean"},{name: "Other"}]  
    const socials = [{name: "AB"},{name: "C1"},{name: "C2"},{name: "DE"},]
    
    const getDemographicDeatailsByPostcodeData = async (postcode, index) => {
        try {
            setLoading(true);
            const resp = await getDemographicDeatailsByPostcode(postcode);
            if (resp?.success === true) {
                setLoading(false);
                let demographicArr = [...demographicData]
                demographicArr.splice(index, 1, resp?.collections)
                setDemographicData(demographicArr);
                // setDemographicData([...demographicData.slice(0, index), resp?.collections, ...demographicData.slice(index, demographicData.length)])
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const exportExcel = () => {
        if(demographicData.length === 0){
            toast.error('Please enter a postocode.')
            return;
        }

        let excelJSWorkbook = new ExcelJS.Workbook();
        let worksheet = excelJSWorkbook.addWorksheet("Demographics");
        let ageIndex = 12;
        let ethinicityIndex = 34;
        let socialIndex = 57;
        let columns = ['E', 'F', 'G', 'H', 'I', 'J'];
        let style = {bold: true};

        worksheet.getCell("B7").value = 'Postcode Demographics & Pricing';
        worksheet.getCell('B7').font = {bold: true, underline: true};
        worksheet.getCell("B8").value = 'Postcode Description';
        worksheet.getCell("B9").value = 'Initial Fee Pricing (excluding VAT)';
        worksheet.getCell('B9').font = style;
        worksheet.getCell("B11").value = 'Ages';
        worksheet.getCell('B11').font = style;
        worksheet.getCell("B12").value = '(Population)';

        ageGroups.map((age) => {
            worksheet.getCell(`C${ageIndex}`).value = age.name
            ageIndex++;
        })

        worksheet.getCell("C28").value = 'Total';
        worksheet.getCell('C28').font = style;
        worksheet.getCell("B30").value = 'Subtotal 5-17 in 2011';
        worksheet.getCell('B30').font = style;
        worksheet.getCell("B31").value = 'Subtotal 0-14 in 2011 (proxy for school age today)';
        worksheet.getCell('B31').font = style;
        worksheet.getCell("B33").value = 'Ethnicity';
        worksheet.getCell('B33').font = style;
        worksheet.getCell("B34").value = '(Population)';
        
        ethinicities.map((ethinicity) => {
            worksheet.getCell(`C${ethinicityIndex}`).value = ethinicity.name
            ethinicityIndex++;
        })

        worksheet.getCell("C45").value = 'Total';
        worksheet.getCell('C45').font = style;
        worksheet.getCell("B48").value = 'Subtotal Asian Ethnic';
        worksheet.getCell('B48').font = style;
        worksheet.getCell("B49").value = '% Asian  Ethnic';
        worksheet.getCell("B51").value = 'Subtotal Black African Ethnic';
        worksheet.getCell('B51').font = style;
        worksheet.getCell("B52").value = '% Black African Ethnic';
        worksheet.getCell("B54").value = 'Subtotal Asian + Black Ethnic';
        worksheet.getCell('B54').font = style;
        worksheet.getCell("B55").value = '% Asian + Black Ethnic';
        worksheet.getCell("B57").value = 'Social Grades';
        worksheet.getCell('B57').font = style;
        worksheet.getCell("B58").value = '(No. Households)';

        socials.map((social) => {
            worksheet.getCell(`C${socialIndex}`).value = social.name
            socialIndex++;
        })

        worksheet.getCell("C61").value = 'Total';
        worksheet.getCell('C61').font = style;
        worksheet.getCell("B67").value = 'Subtotal 0-14 in 2011 (proxy for school age today)';
        worksheet.getCell('B67').font = style;
        worksheet.getCell("C68").value = '% Asian Ethnic';
        worksheet.getCell("C70").value = 'Total child x % asian';
        worksheet.getCell("C71").value = 'Total child x 10%';
        worksheet.getCell("C72").value = 'Sum of above';
        worksheet.getCell("C75").value = 'Initial Fee';
        worksheet.getCell('C75').font = style;
        

        demographicData.forEach((obj, index) => {
            worksheet.getCell(`${columns[index]}6`).value = obj?.status ? obj.status.charAt(0).toUpperCase() + obj?.status.slice(1) : '';
            worksheet.getCell(`${columns[index]}6`).font = {color: { argb: 'ffff6347' }}
            worksheet.getCell(`${columns[index]}7`).value = obj.postcode_name;
            worksheet.getCell(`${columns[index]}7`).border = { top: {style:'thick'}, left: {style:'thick'}, bottom: {style:'thick'}, right: {style:'thick'}};
            worksheet.getCell(`${columns[index]}7`).fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'ffffff99'}}
            worksheet.getCell(`${columns[index]}8`).value = obj.description;
            worksheet.getCell(`${columns[index]}9`).value = parseInt(obj.price);
            worksheet.getCell(`${columns[index]}9`).border = { top: {style:'thick'}, bottom: {style:'double'}};
            worksheet.getCell(`${columns[index]}9`).fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'ffbdd6ee'}};

            let ageIndex = 12;
            obj?.age_group && obj?.age_group.map((age) => {
                worksheet.getCell(`${columns[index]}${ageIndex}`).value = parseInt(age.value) 
                ageIndex++;
            })

            worksheet.getCell(`${columns[index]}28`).value = parseInt(obj.total_ages);
            worksheet.getCell(`${columns[index]}28`).border = { top: {style:'thick'}, bottom: {style:'thick'}};
            worksheet.getCell(`${columns[index]}30`).value = parseInt(obj.subtotal_5_17);
            worksheet.getCell(`${columns[index]}31`).value = parseInt(obj.subtotal_0_14);

            let ethinicityIndex = 34;
            obj?.ethinicity && obj?.ethinicity.map((ethni) => {
                worksheet.getCell(`${columns[index]}${ethinicityIndex}`).value = parseInt(ethni.value) 
                ethinicityIndex++;
            })

            worksheet.getCell(`${columns[index]}45`).value = parseInt(obj.total_ethnicity);
            worksheet.getCell(`${columns[index]}45`).border = { top: {style:'thick'}, bottom: {style:'thick'}};
            worksheet.getCell(`${columns[index]}48`).value = parseInt(obj.subtotal_asian);
            worksheet.getCell(`${columns[index]}48`).font = style;
            worksheet.getCell(`${columns[index]}49`).value = parseFloat(obj.subtotal_asian_percent) + '%';
            worksheet.getCell(`${columns[index]}51`).value = parseInt(obj.subtotal_black_african);
            worksheet.getCell(`${columns[index]}51`).font = style;
            worksheet.getCell(`${columns[index]}52`).value = parseFloat(obj.subtotal_black_african_percent) + '%';
            worksheet.getCell(`${columns[index]}54`).value = parseInt(obj.subtotal_asian_black_african);
            worksheet.getCell(`${columns[index]}54`).font = style;
            worksheet.getCell(`${columns[index]}55`).value = parseFloat(obj.subtotal_asian_black_african_percent) + '%';

            let socialIndex = 57;
            obj?.social && obj?.social.map((soc) => {
                worksheet.getCell(`${columns[index]}${socialIndex}`).value = parseInt(soc.value) 
                socialIndex++;
            })

            worksheet.getCell(`${columns[index]}61`).value = parseInt(obj.total_social);
            worksheet.getCell(`${columns[index]}61`).border = { top: {style:'thick'}, bottom: {style:'thick'}};
            worksheet.getCell(`${columns[index]}67`).value = parseInt(obj.subtotal_0_14_1);
            worksheet.getCell(`${columns[index]}68`).value = parseFloat(obj.subtotal_asian_percent_1) + '%';
            worksheet.getCell(`${columns[index]}70`).value = parseInt(obj.total_child_asian);
            worksheet.getCell(`${columns[index]}71`).value = parseInt(obj.total_child);
            worksheet.getCell(`${columns[index]}72`).value = parseInt(obj.sum_total_child);
            worksheet.getCell(`${columns[index]}72`).font = style;
            worksheet.getCell(`${columns[index]}72`).border = { top: {style:'thick'}, bottom: {style:'thick'}};
            worksheet.getCell(`${columns[index]}75`).value = parseInt(obj.initial_fee);
            worksheet.getCell(`${columns[index]}75`).border = { top: {style:'thick'}, bottom: {style:'double'}};
        })

        excelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
            fileDownload(buffer, 'Demographics.xlsx');
        });
    
    };

  return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Demographics</h3>
                                <div className="col-lg-12 text-right">
                                    <Link className="btn btn-default bg-gradient-secondary mr-2" to='/postcodes'><i className="fas fa-caret-left"></i> Back</Link>
                                    <Button type="submit" isLoading={isLoading} onClick={exportExcel} className="btn btn-success mr-2"><i className="far fa-file-excel"></i> Export Excel </Button>
                                </div>
                                {/* <Link className="float-right btn btn-default bg-gradient-secondary" to='/postcodes'>Back</Link> */}
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12">
                                        <table className="table table-bordered" id="client_id_table">
                                            <tbody>
                                                <tr>
                                                    <th>Postcode</th>
                                                    <td>
                                                        <input type="text" name="name" className="form-control" placeholder="Postcode" onBlur={ (e) => e.target.value ? getDemographicDeatailsByPostcodeData(e.target.value, 0) : ''} style={{textTransform: 'uppercase'}}/>
                                                    </td>
                                                    <td>
                                                        <input type="text" name="name" className="form-control" placeholder="Postcode" onBlur={ (e) => e.target.value ? getDemographicDeatailsByPostcodeData(e.target.value, 1) : ''} style={{textTransform: 'uppercase'}}/>
                                                    </td>
                                                    <td>
                                                        <input type="text" name="name" className="form-control" placeholder="Postcode" onBlur={ (e) => e.target.value ? getDemographicDeatailsByPostcodeData(e.target.value, 2) : ''} style={{textTransform: 'uppercase'}}/>
                                                    </td>
                                                    <td>
                                                        <input type="text" name="name" className="form-control" placeholder="Postcode" onBlur={ (e) => e.target.value ? getDemographicDeatailsByPostcodeData(e.target.value, 3) : ''} style={{textTransform: 'uppercase'}}/>
                                                    </td>
                                                    <td>
                                                        <input type="text" name="name" className="form-control" placeholder="Postcode" onBlur={ (e) => e.target.value ? getDemographicDeatailsByPostcodeData(e.target.value, 4) : ''} style={{textTransform: 'uppercase'}}/>
                                                    </td>
                                                    <td>
                                                        <input type="text" name="name" className="form-control" placeholder="Postcode" onBlur={ (e) => e.target.value ? getDemographicDeatailsByPostcodeData(e.target.value, 5) : ''} style={{textTransform: 'uppercase'}}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Description</th>
                                                    <td>{ demographicData[0]?.description }</td>
                                                    <td>{ demographicData[1]?.description }</td>
                                                    <td>{ demographicData[2]?.description }</td>
                                                    <td>{ demographicData[3]?.description }</td>
                                                    <td>{ demographicData[4]?.description }</td>
                                                    <td>{ demographicData[5]?.description }</td>
                                                </tr>
                                                <tr>
                                                    <th>Status</th>
                                                    <td>{ demographicData[0]?.status ? demographicData[0]?.status.charAt(0).toUpperCase() + demographicData[0]?.status.slice(1) : '' }</td>
                                                    <td>{ demographicData[1]?.status ? demographicData[1]?.status.charAt(0).toUpperCase() + demographicData[1]?.status.slice(1) : '' }</td>
                                                    <td>{ demographicData[2]?.status ? demographicData[2]?.status.charAt(0).toUpperCase() + demographicData[2]?.status.slice(1) : '' }</td>
                                                    <td>{ demographicData[3]?.status ? demographicData[3]?.status.charAt(0).toUpperCase() + demographicData[3]?.status.slice(1) : '' }</td>
                                                    <td>{ demographicData[4]?.status ? demographicData[4]?.status.charAt(0).toUpperCase() + demographicData[4]?.status.slice(1) : '' }</td>
                                                    <td>{ demographicData[5]?.status ? demographicData[5]?.status.charAt(0).toUpperCase() + demographicData[5]?.status.slice(1) : '' }</td>
                                                </tr>
                                                <tr>
                                                    <th>Initial Fee Pricing (excluding VAT)</th>
                                                    <td>{ demographicData[0]?.price ? '£' + demographicData[0]?.price : '' }</td>
                                                    <td>{ demographicData[1]?.price ? '£' + demographicData[1]?.price : '' }</td>
                                                    <td>{ demographicData[2]?.price ? '£' + demographicData[2]?.price : '' }</td>
                                                    <td>{ demographicData[3]?.price ? '£' + demographicData[3]?.price : '' }</td>
                                                    <td>{ demographicData[4]?.price ? '£' + demographicData[4]?.price : '' }</td>
                                                    <td>{ demographicData[5]?.price ? '£' + demographicData[5]?.price : '' }</td>
                                                </tr>
                                                <tr>
                                                    <th>Ages (Population)</th>
                                                </tr>
                                                { ageGroups && ageGroups.map((obj, index) => (
                                                    <tr key={index}>
                                                        <td>{ obj.name }</td>
                                                        <td>{ demographicData[0]?.age_group[index]?.value }</td>
                                                        <td>{ demographicData[1]?.age_group[index]?.value }</td>
                                                        <td>{ demographicData[2]?.age_group[index]?.value }</td>
                                                        <td>{ demographicData[3]?.age_group[index]?.value }</td>
                                                        <td>{ demographicData[4]?.age_group[index]?.value }</td>
                                                        <td>{ demographicData[5]?.age_group[index]?.value }</td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <th>Ages Total</th>
                                                    <td>{ demographicData[0]?.total_ages }</td>
                                                    <td>{ demographicData[1]?.total_ages }</td>
                                                    <td>{ demographicData[2]?.total_ages }</td>
                                                    <td>{ demographicData[3]?.total_ages }</td>
                                                    <td>{ demographicData[4]?.total_ages }</td>
                                                    <td>{ demographicData[5]?.total_ages }</td>
                                                </tr>
                                                <tr><td></td></tr>
                                                <tr>
                                                    <th>Subtotal 5-17 in 2011</th>
                                                    <td>{ demographicData[0]?.subtotal_5_17 }</td>
                                                    <td>{ demographicData[1]?.subtotal_5_17 }</td>
                                                    <td>{ demographicData[2]?.subtotal_5_17 }</td>
                                                    <td>{ demographicData[3]?.subtotal_5_17 }</td>
                                                    <td>{ demographicData[4]?.subtotal_5_17 }</td>
                                                    <td>{ demographicData[5]?.subtotal_5_17 }</td>
                                                </tr>
                                                <tr>
                                                    <th>Subtotal 0-14 in 2011 (proxy for school age today)</th>
                                                    <td>{ demographicData[0]?.subtotal_0_14 }</td>
                                                    <td>{ demographicData[1]?.subtotal_0_14 }</td>
                                                    <td>{ demographicData[2]?.subtotal_0_14 }</td>
                                                    <td>{ demographicData[3]?.subtotal_0_14 }</td>
                                                    <td>{ demographicData[4]?.subtotal_0_14 }</td>
                                                    <td>{ demographicData[5]?.subtotal_0_14 }</td>
                                                </tr>
                                                <tr>
                                                    <th>Ethnicity (Population)</th>
                                                </tr>
                                                { ethinicities && ethinicities.map((obj, index) => (
                                                    <tr key={index}>
                                                        <td>{ obj.name }</td>
                                                        <td>{ demographicData[0]?.ethinicity[index]?.value }</td>
                                                        <td>{ demographicData[1]?.ethinicity[index]?.value }</td>
                                                        <td>{ demographicData[2]?.ethinicity[index]?.value }</td>
                                                        <td>{ demographicData[3]?.ethinicity[index]?.value }</td>
                                                        <td>{ demographicData[4]?.ethinicity[index]?.value }</td>
                                                        <td>{ demographicData[5]?.ethinicity[index]?.value }</td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <th>Ethnicity Total</th>
                                                    <td>{ demographicData[0]?.total_ethnicity }</td>
                                                    <td>{ demographicData[1]?.total_ethnicity }</td>
                                                    <td>{ demographicData[2]?.total_ethnicity }</td>
                                                    <td>{ demographicData[3]?.total_ethnicity }</td>
                                                    <td>{ demographicData[4]?.total_ethnicity }</td>
                                                    <td>{ demographicData[5]?.total_ethnicity }</td>
                                                </tr>
                                                <tr><td></td></tr>
                                                <tr>
                                                    <th>Subtotal Asian Ethnic</th>
                                                    <td>{ demographicData[0]?.subtotal_asian }</td>
                                                    <td>{ demographicData[1]?.subtotal_asian }</td>
                                                    <td>{ demographicData[2]?.subtotal_asian }</td>
                                                    <td>{ demographicData[3]?.subtotal_asian }</td>
                                                    <td>{ demographicData[4]?.subtotal_asian }</td>
                                                    <td>{ demographicData[5]?.subtotal_asian }</td>
                                                </tr>
                                                <tr>
                                                    <th>% Asian Ethnic</th>
                                                    <td>{ demographicData[0]?.subtotal_asian_percent ? demographicData[0]?.subtotal_asian_percent + '%' : ''}</td>
                                                    <td>{ demographicData[1]?.subtotal_asian_percent ? demographicData[1]?.subtotal_asian_percent + '%' : ''}</td>
                                                    <td>{ demographicData[2]?.subtotal_asian_percent ? demographicData[2]?.subtotal_asian_percent + '%' : ''}</td>
                                                    <td>{ demographicData[3]?.subtotal_asian_percent ? demographicData[3]?.subtotal_asian_percent + '%' : ''}</td>
                                                    <td>{ demographicData[4]?.subtotal_asian_percent ? demographicData[4]?.subtotal_asian_percent + '%' : ''}</td>
                                                    <td>{ demographicData[5]?.subtotal_asian_percent ? demographicData[5]?.subtotal_asian_percent + '%' : ''}</td>
                                                </tr>
                                                <tr><td></td></tr>
                                                <tr>
                                                    <th>Subtotal Black African Ethnic</th>
                                                    <td>{ demographicData[0]?.subtotal_black_african }</td>
                                                    <td>{ demographicData[1]?.subtotal_black_african }</td>
                                                    <td>{ demographicData[2]?.subtotal_black_african }</td>
                                                    <td>{ demographicData[3]?.subtotal_black_african }</td>
                                                    <td>{ demographicData[4]?.subtotal_black_african }</td>
                                                    <td>{ demographicData[5]?.subtotal_black_african }</td>
                                                </tr>
                                                <tr>
                                                    <th>% Black African Ethnic</th>
                                                    <td>{ demographicData[0]?.subtotal_black_african_percent ? demographicData[0]?.subtotal_black_african_percent + '%' : '' }</td>
                                                    <td>{ demographicData[1]?.subtotal_black_african_percent ? demographicData[1]?.subtotal_black_african_percent + '%' : '' }</td>
                                                    <td>{ demographicData[2]?.subtotal_black_african_percent ? demographicData[2]?.subtotal_black_african_percent + '%' : '' }</td>
                                                    <td>{ demographicData[3]?.subtotal_black_african_percent ? demographicData[3]?.subtotal_black_african_percent + '%' : '' }</td>
                                                    <td>{ demographicData[4]?.subtotal_black_african_percent ? demographicData[4]?.subtotal_black_african_percent + '%' : '' }</td>
                                                    <td>{ demographicData[5]?.subtotal_black_african_percent ? demographicData[5]?.subtotal_black_african_percent + '%' : '' }</td>
                                                </tr>
                                                <tr><td></td></tr>
                                                <tr>
                                                    <th>Subtotal Asian + Black Ethnic</th>
                                                    <td>{ demographicData[0]?.subtotal_asian_black_african }</td>
                                                    <td>{ demographicData[1]?.subtotal_asian_black_african }</td>
                                                    <td>{ demographicData[2]?.subtotal_asian_black_african }</td>
                                                    <td>{ demographicData[3]?.subtotal_asian_black_african }</td>
                                                    <td>{ demographicData[4]?.subtotal_asian_black_african }</td>
                                                    <td>{ demographicData[5]?.subtotal_asian_black_african }</td>
                                                </tr>
                                                <tr>
                                                    <th>% Asian + Black Ethnic</th>
                                                    <td>{ demographicData[0]?.subtotal_asian_black_african_percent ? demographicData[0]?.subtotal_asian_black_african_percent + '%' : ''}</td>
                                                    <td>{ demographicData[1]?.subtotal_asian_black_african_percent ? demographicData[1]?.subtotal_asian_black_african_percent + '%' : ''}</td>
                                                    <td>{ demographicData[2]?.subtotal_asian_black_african_percent ? demographicData[2]?.subtotal_asian_black_african_percent + '%' : ''}</td>
                                                    <td>{ demographicData[3]?.subtotal_asian_black_african_percent ? demographicData[3]?.subtotal_asian_black_african_percent + '%' : ''}</td>
                                                    <td>{ demographicData[4]?.subtotal_asian_black_african_percent ? demographicData[4]?.subtotal_asian_black_african_percent + '%' : ''}</td>
                                                    <td>{ demographicData[5]?.subtotal_asian_black_african_percent ? demographicData[5]?.subtotal_asian_black_african_percent + '%' : ''}</td>
                                                </tr>
                                                <tr>
                                                    <th>Social Grades (No. Households)</th>
                                                </tr>
                                                { socials && socials.map((obj, index) => (
                                                    <tr key={index}>
                                                        <td>{ obj.name }</td>
                                                        <td>{ demographicData[0]?.social[index]?.value }</td>
                                                        <td>{ demographicData[1]?.social[index]?.value }</td>
                                                        <td>{ demographicData[2]?.social[index]?.value }</td>
                                                        <td>{ demographicData[3]?.social[index]?.value }</td>
                                                        <td>{ demographicData[4]?.social[index]?.value }</td>
                                                        <td>{ demographicData[5]?.social[index]?.value }</td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <th>Social Total</th>
                                                    <td>{ demographicData[0]?.total_social }</td>
                                                    <td>{ demographicData[1]?.total_social }</td>
                                                    <td>{ demographicData[2]?.total_social }</td>
                                                    <td>{ demographicData[3]?.total_social }</td>
                                                    <td>{ demographicData[4]?.total_social }</td>
                                                    <td>{ demographicData[5]?.total_social }</td>
                                                </tr>
                                                <tr><td></td></tr>
                                                <tr>
                                                    <th>Subtotal 0-14 in 2011 (proxy for school age today)</th>
                                                    <td>{ demographicData[0]?.subtotal_0_14_1 }</td>
                                                    <td>{ demographicData[1]?.subtotal_0_14_1 }</td>
                                                    <td>{ demographicData[2]?.subtotal_0_14_1 }</td>
                                                    <td>{ demographicData[3]?.subtotal_0_14_1 }</td>
                                                    <td>{ demographicData[4]?.subtotal_0_14_1 }</td>
                                                    <td>{ demographicData[5]?.subtotal_0_14_1 }</td>
                                                </tr>
                                                <tr>
                                                    <th>% Asian Ethnic</th>
                                                    <td>{ demographicData[0]?.subtotal_asian_percent_1 ? demographicData[0]?.subtotal_asian_percent_1 + '%' : '' }</td>
                                                    <td>{ demographicData[1]?.subtotal_asian_percent_1 ? demographicData[1]?.subtotal_asian_percent_1 + '%' : '' }</td>
                                                    <td>{ demographicData[2]?.subtotal_asian_percent_1 ? demographicData[2]?.subtotal_asian_percent_1 + '%' : '' }</td>
                                                    <td>{ demographicData[3]?.subtotal_asian_percent_1 ? demographicData[3]?.subtotal_asian_percent_1 + '%' : '' }</td>
                                                    <td>{ demographicData[4]?.subtotal_asian_percent_1 ? demographicData[4]?.subtotal_asian_percent_1 + '%' : '' }</td>
                                                    <td>{ demographicData[5]?.subtotal_asian_percent_1 ? demographicData[5]?.subtotal_asian_percent_1 + '%' : '' }</td>
                                                </tr>
                                                <tr><td></td></tr>
                                                <tr>
                                                    <th>Total child x % asian</th>
                                                    <td>{ demographicData[0]?.total_child_asian }</td>
                                                    <td>{ demographicData[1]?.total_child_asian }</td>
                                                    <td>{ demographicData[2]?.total_child_asian }</td>
                                                    <td>{ demographicData[3]?.total_child_asian }</td>
                                                    <td>{ demographicData[4]?.total_child_asian }</td>
                                                    <td>{ demographicData[5]?.total_child_asian }</td>
                                                </tr>
                                                <tr>
                                                    <th>Total child x 10%</th>
                                                    <td>{ demographicData[0]?.total_child }</td>
                                                    <td>{ demographicData[1]?.total_child }</td>
                                                    <td>{ demographicData[2]?.total_child }</td>
                                                    <td>{ demographicData[3]?.total_child }</td>
                                                    <td>{ demographicData[4]?.total_child }</td>
                                                    <td>{ demographicData[5]?.total_child }</td>
                                                </tr>
                                                <tr>
                                                    <th>Sum of above</th>
                                                    <td>{ demographicData[0]?.sum_total_child }</td>
                                                    <td>{ demographicData[1]?.sum_total_child }</td>
                                                    <td>{ demographicData[2]?.sum_total_child }</td>
                                                    <td>{ demographicData[3]?.sum_total_child }</td>
                                                    <td>{ demographicData[4]?.sum_total_child }</td>
                                                    <td>{ demographicData[5]?.sum_total_child }</td>
                                                </tr>
                                                <tr><td></td></tr>
                                                <tr>
                                                    <th>Initial Fee </th>
                                                    <td>{ demographicData[0]?.initial_fee ? '£' + demographicData[0]?.initial_fee : '' }</td>
                                                    <td>{ demographicData[1]?.initial_fee ? '£' + demographicData[1]?.initial_fee : '' }</td>
                                                    <td>{ demographicData[2]?.initial_fee ? '£' + demographicData[2]?.initial_fee : '' }</td>
                                                    <td>{ demographicData[3]?.initial_fee ? '£' + demographicData[3]?.initial_fee : '' }</td>
                                                    <td>{ demographicData[4]?.initial_fee ? '£' + demographicData[4]?.initial_fee : '' }</td>
                                                    <td>{ demographicData[5]?.initial_fee ? '£' + demographicData[5]?.initial_fee : '' }</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                {/* <Link className="btn btn-primary mr-2" to={`/franchise/edit/${id}`}>Edit</Link> */}
                                <Link className="btn btn-default" to='/postcodes'>Close</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Spinner isLoading={isLoading}/>
        </section>
    </div>
  )
}

export default ViewDemographic;