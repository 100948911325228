import axios from "axios";
import { opsmanualURL } from "@app/constants/ApiEndPoints";

export const getListS3BucketItems = async (payload) => {
	try {
		const response = await axios.post(opsmanualURL + 'list', payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const downloadfile = async (payload) => {
	try {
		const response = await axios.post(opsmanualURL + 'download', payload, { responseType: 'blob' });
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getSignedUrl = async (payload) => {
	try {
		const response = await axios.post(opsmanualURL + 'signedurl', payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getExtension = (path) => {
	let baseName = path.split(/[\\/]/).pop(), // extracts file name from full path
	  // (supports separators `\\` and `/`)
	  pos = baseName.lastIndexOf("."); // gets the last position of `.`
	if (baseName === "" || pos < 1) // if the file name is empty or ...
	  return ""; // the dot not found (-1) or comes first (0)
	return baseName.slice(pos + 1); // extracts extension ignoring "."
};