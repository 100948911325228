import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { getSettingPatternById } from '@app/services/settingPattern';
import { toast } from 'react-toastify';

const ViewPatternDetailsModal = (props) => {

    let obj: any = {}
    const [pattern, setPattern] = useState(obj);
    useEffect(() => {
        if(props?.id > 0){
            getPatternDetailsById(props?.id);
        }
    },[props?.id]);

    const getPatternDetailsById = async (id) => {
        try {
            const resp = await getSettingPatternById(id);
            if (resp.success === true) {
                setPattern(resp?.collections)
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Internal server error');
        }
    };

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{props.subjectName} View Setting Pattern Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="row">
            <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail12">Pattern Id </label>
                        <dd>#{pattern?.id}</dd>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail12">Pattern Name </label>
                        <dd>{pattern?.name}</dd>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail13">Module Per Week </label>
                        <dd>{pattern?.modules_per_week}</dd>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail14">No Work Set </label>
                        <dd>{ pattern?.no_work_days ? ( Array.prototype.map.call( pattern?.no_work_days, function(day) { return day; }).join(", ")) : '---'}</dd>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor="franchise_id"><b> Is default? </b></label>
                        <dd>{pattern?.is_default ? 'Yes' : 'No'}</dd>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor="franchise_id"><b> Status </b></label>
                        <dd>{pattern.status === 'active' ? <span className="badge bg-success">Active</span> : <span className="capitalize badge bg-danger">{pattern.status}</span>}</dd>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <dt>Created At </dt>
                        <dd>{moment(pattern?.created_at).format('DD-MM-YYYY hh:mm A')}</dd>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <dt>Last Updated At </dt>
                        <dd>{pattern?.updated_at ? moment(pattern?.updated_at).format('DD-MM-YYYY hh:mm A') : '---'}</dd>
                    </div>
                </div>
            </div>
            <div className='row mt-3'>
                {/* <div className='col-md-12 mt-3'>
                    <dt>Days</dt>
                </div> */}
                <div className="col-md-12">
                    <table className="table table-bordered" id="client_id_table">
                        <tbody>
                            <tr className='text-center'>
                                <th>Day</th>
                                {pattern?.modules_per_week > 0 && (<th>M1</th>)}
                                {pattern?.modules_per_week > 1 && (<th>M2</th>)}
                                {pattern?.modules_per_week > 2 && (<th>M3</th>)}
                                {pattern?.modules_per_week > 3 && (<th>M4</th>)}
                            </tr>
                            { pattern?.days && (pattern?.days).map((day) => (
                                <tr key={day?.day} className='text-center'>
                                    <td><b><i>Day {day?.day}</i></b></td>
                                    {pattern?.modules_per_week > 0 && (<td>{ day?.module1 }</td>)}
                                    {pattern?.modules_per_week > 1 && (<td>{ day?.module2 }</td>)}
                                    {pattern?.modules_per_week > 2 && (<td>{ day?.module3 }</td>)}
                                    {pattern?.modules_per_week > 3 && (<td>{ day?.module4 }</td>)}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {(pattern?.days)?.length == 0 && (
                        <span style={{ color: "red", textAlign: "center" }}>There are no days to display</span>
                    )}
                </div>
            </div>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    </div>
  )
}

export default ViewPatternDetailsModal;