import { getAllSchoolsURL,getAllGradesURL,createSchoolURL } from "@app/constants/ApiEndPoints";
import axios from "axios";


export const getAllSchools = async () => {
	try {
		const response = await axios.get(getAllSchoolsURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllGrades = async () => {
	try {
		const response = await axios.get(getAllGradesURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const createSchoolService = async (payload) => {
	try {
		const response = await axios.post(createSchoolURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};