import { Form, Modal } from 'react-bootstrap';
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import { Document, Page, pdfjs } from 'react-pdf';
// import { Blob } from 'buffer';
import "../student-details/set-work/printWorksheetsPDF.css";
import { useDispatch, useSelector } from 'react-redux';
import { setPageNum } from '@app/store/reducers/studentWork';
import { getWorksheetsPDFByModuleId } from '@app/services/learningPathway';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const PreviewWorksheetsPDF = (props) => {

    const [pdfLoading, setPDFLoading] = useState(false);
    // const [file, setFile] = useState<Blob>(); 
    const [fileURL, setFileURL] = useState(null); 
    const [numPages, setNumPages] = useState(null); 
    const [pageNumber, setPageNumber] = useState(1); 
    const dispatch = useDispatch();
    const studentWorkStore = useSelector((state: any) => state.studentWork);


    useEffect(() => {
        if(props.show == true){

            downloadWorksheets(props.moduleId);
            return () => {
                // Anything in here is fired on component unmount.
                setPageNumber(1)
                setNumPages(null)
                setFileURL(null)
                // setFile(null)
                dispatch(setPageNum(1))
            }
        }
    },[props.show]);

    const downloadWorksheets = async(moduleId) => {
        try{
            setPDFLoading(true);
            const resp = await getWorksheetsPDFByModuleId(moduleId);
            
            //Create a Blob from the PDF Stream
            // const file = new Blob( [resp], {type: 'application/pdf'});
            // setFile(resp)
            
            //Build a URL from the file
            const pdfFileURL = URL.createObjectURL(resp);
            setFileURL(pdfFileURL);   
            
            setPDFLoading(false);
        } catch (error: any) {
            setPDFLoading(false);
            toast.error(error?.message || 'Internal server error');
        }
    }

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    }

    const prevPage = () => {
        if(pageNumber > 1){
            setPageNumber(pageNumber - 1)
            dispatch(setPageNum(pageNumber - 1))
        }
    }

    const nextPage = () => {
        if(pageNumber < numPages){
            setPageNumber(pageNumber + 1)
            dispatch(setPageNum(pageNumber + 1))
        }
    }

    const setPage = (pageNum) => {
        let page = parseInt(pageNum)
        if(page >= 1 && page <= numPages){
            dispatch(setPageNum(page))
            setPageNumber(page)
        }else{
            dispatch(setPageNum(pageNumber))
            setPageNumber(pageNumber)
        }
    }

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose} size='lg'>
            <div className={`overlay ${ pdfLoading ? '' : ' d-none'}`} style={{flexDirection: 'column'}}>
                <i className="fas fa-3x fa-sync-alt fa-spin" />
                <div className="text-bold pt-2">Worksheet(s) Loading...</div>
            </div>
            <Modal.Header closeButton>
                <Modal.Title>{props.subjectName} Preview Worksheet(s)</Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">

                            { numPages != null && (
                                <div className='d-flex justify-content-center'>
                                    <Button className='btn btn-default mr-2' onClick={prevPage} disabled={ pageNumber === 1 }>Prev Page</Button>
                                    <Button className='btn btn-default' onClick={nextPage} disabled={ pageNumber === numPages }>Next Page</Button>
                                </div>
                            )}

                            <Document 
                                file={fileURL}
                                onLoadSuccess={onDocumentLoadSuccess}
                                onContextMenu={(e) => e.preventDefault() }
                                className='pdf-container'
                                loading="Loading Worksheet(s) PDF…."
                            >
                                <Page 
                                    pageNumber={pageNumber}
                                    loading="Loading Page…."
                                />
                            </Document>

                            { numPages != null && (
                                <div className="row d-flex justify-content-center">
                                    <label className='col-md-1'> Page </label>
                                    <Form.Control
                                        className='col-md-1'
                                        type="number"
                                        min="1"
                                        value={ studentWorkStore.pageNumber }
                                        // onWheel={ (e) => OnWheelPreventChange(e) } 
                                        onChange={(e) =>  dispatch(setPageNum(e.target.value))}
                                        onBlur={ (e) =>  setPage(e.target.value)}
                                    />
                                    <label className='col-md-1'> of {numPages} </label>
                                </div>
                            
                                // <p className='text-center'>
                                //     Page {pageNumber} of {numPages}
                                // </p>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
                </Modal.Footer>
        </Modal>
    </div>
  )
}

export default PreviewWorksheetsPDF;