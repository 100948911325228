import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import { Modal } from 'react-bootstrap';
import { updateTransactionStatus } from '@app/services/studentCount';
import CurrencyFormat from 'react-currency-format';

const UpdateTransactionStatusModal = (props) => {

    const [isLoading, setLoading] = useState(false);

    const updateTransactionStatusAPI = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateTransactionStatus(payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                props.handleClose();
                props.getTransactionsData();
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const { handleChange, values, handleSubmit, touched, errors, resetForm } = useFormik({
        initialValues: {
            status: '',
        },
        validationSchema: Yup.object({
            status: Yup.string().required('Please select status'),
        }),
        onSubmit: (values) => {
            updateTransactionStatusAPI({order_id: props?.payload?.transaction_id, status: values.status});
            resetForm();
        },
        enableReinitialize: true,
    });

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update Transaction Status</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className='row'>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <dt>Transaction Id</dt>
                                <dd>#{ props?.payload?.transaction_id } </dd>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <dt>Franchise</dt>
                                <dd>{ props?.payload?.franchise_name }</dd>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <dt>Total Amount</dt>
                                <dd><CurrencyFormat value={ props?.payload?.total_price } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></dd>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Status <span className="required">*</span></label>
                                <select 
                                    className="form-control"
                                    name="status"
                                    onChange={handleChange} 
                                    value={values.status}
                                >
                                    <option value="">--Please select--</option>
                                    <option value='success'> Success </option>
                                </select>
                                {(touched.status && errors.status) && (
                                    <div className="text-danger">
                                        {errors.status}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                    <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    </div>
  )
}

export default UpdateTransactionStatusModal;