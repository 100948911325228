import { Modal } from 'react-bootstrap';
import React, { useState, useEffect } from 'react'
import { ErrorMessage, Field, FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { loadDays, loadEditDays } from '@app/store/reducers/settingPattern';
import { createSettingPattern, getAllActiveSettingPatterns, getSettingPatternById, getSettingPatternByStudentId, updateSettingPattern } from '@app/services/settingPattern';
import { resetSelectedModules, setModule, setPattern, setStep } from '@app/store/reducers/studentWork';

const SetPatternModal = (props) => {

    const dispatch = useDispatch();
    const patternStore = useSelector((state: any) => state.settingPattern);
    const studentWorkStore = useSelector((state: any) => state.studentWork);
    const weekDays = [
        { name: "Mon", value: "Mon" },
        { name: "Tue", value: "Tue" },
        { name: "Wed", value: "Wed" },
        { name: "Thu", value: "Thu" },
        { name: "Fri", value: "Fri" },
        { name: "Sat", value: "Sat" },
        { name: "Sun", value: "Sun" }
    ]

    const [selectedPatternId, setSelectedPatternId] = useState('');
    const [modPerWk, setModPerWk] = useState('');
    const [noWorkDays, setNoWorkDays] = useState([]);
    const [settingPatterns, setSettingPatterns] = useState([]);
    const [patternId, setPatternId] = useState('');
    const [isLoading, setLoading] = useState(false);
    const modulesPerWeek = []
    for (let i=1; i <= 4; i++) {
        modulesPerWeek.push({value: i, name: i})
    }

    useEffect(() => {
        if(Object.keys(props?.pattern).length > 0){
            // setModPerWk(props?.pattern?.modules_per_week || '')
            setNoWorkDays(props?.pattern?.no_work_days || [])
            setPatternId(props?.pattern?.id)
            dispatch(loadEditDays(props?.pattern?.days))
        }else{
            dispatch(loadDays({count: 0}))
        }
        setModPerWk(studentWorkStore?.modulePerWeek)
        // setModPerWk(props?.pattern?.modules_per_week)
    },[props?.pattern]);

    useEffect(() => {
        if(props.show == true){
            setModPerWk(studentWorkStore?.modulePerWeek)
            getActiveSettingPatterns()
        }
        
        return () => {
            setSettingPatterns([])
        };
    },[props.show]);

    const getActiveSettingPatterns = async () => {
        try {
            const resp = await getAllActiveSettingPatterns();
            if (resp.success === true) {
                setSettingPatterns(resp?.collections.filter(function(pattern){ return pattern.modules_per_week == studentWorkStore?.modulePerWeek}))
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Internal server error');
        }
    };

    const addPattern = async (payload) => {
        try {
            setLoading(true);
            const resp = await createSettingPattern(payload);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                props.handleClose()
                setPatternId(resp?.collections?.id)
                dispatch(setPattern(resp?.collections))
                dispatch(setStep(3))
                // dispatch(resetSelectedModules())
                // dispatch(setModule({moduleNumber: 'all', module: []}))
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Internal server error');
          }
    };

    const updatePattern = async (patternId, payload) => {
        try {
            setLoading(true);
            const resp = await updateSettingPattern(patternId, payload);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                props.handleClose()
                dispatch(setPattern(payload))
                dispatch(setStep(3))
                // dispatch(resetSelectedModules())
                // dispatch(setModule({moduleNumber: 'all', module: []}))
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Internal server error');
          }
    };

    const getPatternDetailsById = async (id) => {
        try {
            const resp = await getSettingPatternById(id);
            if (resp.success === true) {
                if(Object.keys(resp?.collections).length > 0){
                    if(modPerWk == resp?.collections?.modules_per_week){
                        // setModPerWk(resp?.collections?.modules_per_week)
                        setNoWorkDays(resp?.collections?.no_work_days)
                        dispatch(loadEditDays(resp?.collections?.days))
                    }else{
                        toast.error(`Selected pattern stream per week doesn't match with preset streams per week`);
                    }
                }else{
                    dispatch(loadDays({count: 0}))
                }
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Internal server error');
        }
    };

    const formik = useFormik({
        initialValues: {
            student_id: props?.studId,
            pattern_id: selectedPatternId,
            modules_per_week: modPerWk,
            no_work_days: noWorkDays,
            days: patternStore.days
        },
        validationSchema: Yup.object({
            // name: Yup.string().required('Please enter pattern name'),
            modules_per_week: Yup.number().required('Please select streams per week'),
            no_work_days: Yup.array().of(Yup.string().trim()),
            // status: Yup.string().required('Please select status'),
            days: Yup.array().of(Yup.object({
                day: Yup.string(),
                module1: Yup.number().when("modules_per_week", {
                    is: () => (modPerWk >= '1'),
                    then: Yup.number().integer('Worksheet count must be an integer').required('Enter worksheet count')
                    .min(0, "Worksheet count must be greater than or equal to 0"),
                }),
                module2: Yup.number().when("modules_per_week", {
                    is: () => (modPerWk >= '2'),
                    then: Yup.number().integer('Worksheet count must be an integer').required('Enter worksheet count')
                    .min(0, "Worksheet count must be greater than or equal to 0"),
                }),
                module3: Yup.number().when("modules_per_week", {
                    is: () => (modPerWk >= '3'),
                    then: Yup.number().integer('Worksheet count must be an integer').required('Enter worksheet count')
                    .min(0, "Worksheet count must be greater than or equal to 0"),
                }),
                module4: Yup.number().when("modules_per_week", {
                    is: () => (modPerWk >= '4'),
                    then: Yup.number().integer('Worksheet count must be an integer').required('Enter worksheet count')
                    .min(0, "Worksheet count must be greater than or equal to 0"),
                }),
            })),
        }),
        onSubmit: (values) => {
            patternId == '' ? addPattern({...values, subject_id: studentWorkStore?.subject?.id}) : updatePattern(patternId, {...values, subject_id: studentWorkStore?.subject?.id})
        },
        enableReinitialize: true,
    });

    useEffect(() => {
		formik.setFieldValue('pattern_id', selectedPatternId)
		formik.setFieldValue('modules_per_week', modPerWk)
        formik.setFieldValue("no_work_days", noWorkDays)
    },[selectedPatternId, modPerWk, noWorkDays]);

    const onPatternChange = (e) => {
        if(e.target.value != ''){
            getPatternDetailsById(e.target.value)
        }
        //else{
        //     setModPerWk('')
        //     setNoWorkDays([])
        //     dispatch(loadDays({count: 0}))
        // }
    }

    const OnWheelPreventChange = (e) => {
		e.currentTarget.blur();
	} 

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>{props.subjectName} Set Pattern Details</Modal.Title>
            </Modal.Header>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail12">Setting Pattern  </label>
                                            <select
                                                className="form-control"
                                                name="pattern_id"
                                                onChange={ (e) => {
                                                    formik.setFieldValue('pattern_id', e.target.value)
                                                    setSelectedPatternId(e.target.value)
                                                    onPatternChange(e);
                                                }}
                                                value={formik.values.pattern_id}
                                            >
                                                <option value="">Select Setting Pattern</option>
                                                {settingPatterns.map((obj) => (
                                                    <option key={obj.id} value={obj.id}>
                                                        {obj.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {/* {(formik.touched.pattern_id && formik.errors.pattern_id) && (
                                                <div className="text-danger">
                                                    {formik.errors.pattern_id}
                                                </div>
                                            )} */}
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail13">Stream Per Week <span className="required">*</span></label>
                                            <select
                                                className="form-control"
                                                name="modules_per_week"
                                                onChange={ (e) => {
                                                    formik.setFieldValue('modules_per_week', e.target.value)
                                                    setModPerWk(e.target.value)
                                                }}
                                                value={formik.values.modules_per_week}
                                                disabled
                                            >
                                                <option value="">Please select streams per week</option>
                                                {modulesPerWeek.map((obj) => (
                                                    <option key={obj.value} value={obj.value}>
                                                        {obj.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {(formik.touched.modules_per_week && formik.errors.modules_per_week) && (
                                                <div className="text-danger">
                                                    {formik.errors.modules_per_week}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail14">No Work Set </label>
                                            <Select
                                                placeholder={<div>Please select </div>}
                                                isMulti
                                                options={weekDays}
                                                closeMenuOnSelect={false}
                                                getOptionLabel={(option)=> option.name}
                                                getOptionValue={(option)=>option.value}
                                                name="no_work_days"
                                                onChange={ (e) => {
                                                    dispatch(loadDays({count: e?.length}))
                                                    setNoWorkDays([].slice.call(e).map((option) => option.value))
                                                }}
                                                isOptionDisabled={() => (formik.values.no_work_days).length >= 6}
                                                value={
                                                    weekDays?.length && formik.values.no_work_days
                                                    ? weekDays.filter((obj) =>
                                                        formik.values.no_work_days.includes(obj.value)
                                                        )
                                                    : formik.values.no_work_days
                                                } // set selected values
                                            />
                                            <div style={{ marginBottom: 10 }} className="row">
                                                <div className="col-sm-12">
                                                    {(formik.touched.no_work_days && formik.errors.no_work_days) && (
                                                        <div className="text-danger">
                                                            {formik.errors.no_work_days}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <FieldArray name="days">
                                    {({ push, remove }) => (
                                        <>
                                        <div className='row'>
                                            <div className="col-sm-12">
                                                <h6 className='mt-2 grey_wrapper_title'>Stream Worksheet Count</h6>
                                            </div>
                                        </div>
                                        {formik.values.days && formik.values.days.map((day, index) => (
                                            <div className="row" key={index}>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        { (index + 1) == 1 && (
                                                            <label htmlFor="exampleInputEmail15">Day </label>
                                                        )}
                                                        <dd> <b><i>Day {index + 1}</i></b></dd>
                                                        <div className="text-danger">
                                                            <ErrorMessage name={`days.${index}.day`} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        { (index + 1) == 1 && (
                                                            <label htmlFor="exampleInputEmail1">{ studentWorkStore?.selectedModules?.pathway1Name ?? 'S1'} <span className="required">*</span></label>
                                                        )}
                                                        <Field type="number" id="exampleInputEmail1" className="form-control"  name={`days.${index}.module1`} disabled={ (formik.values.modules_per_week < '1')} onWheel={ (e) => OnWheelPreventChange(e) }/>
                                                        <div className="text-danger">
                                                            <ErrorMessage name={`days.${index}.module1`} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        { (index + 1) == 1 && (
                                                            <label htmlFor="exampleInputEmail2">{ studentWorkStore?.selectedModules?.pathway2Name ?? 'S2'} <span className="required">*</span></label>
                                                        )}
                                                        <Field type="number" id="exampleInputEmail2" className="form-control"  name={`days.${index}.module2`} disabled={ (formik.values.modules_per_week < '2')} onWheel={ (e) => OnWheelPreventChange(e) }/>
                                                        <div className="text-danger">
                                                            <ErrorMessage name={`days.${index}.module2`} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        { (index + 1) == 1 && (
                                                            <label htmlFor="exampleInputEmail3">{ studentWorkStore?.selectedModules?.pathway3Name ?? 'S3'} <span className="required">*</span></label>
                                                        )}
                                                        <Field type="number" id="exampleInputEmail3" className="form-control"  name={`days.${index}.module3`} disabled={ (formik.values.modules_per_week < '3')} onWheel={ (e) => OnWheelPreventChange(e) }/>
                                                        <div className="text-danger">
                                                            <ErrorMessage name={`days.${index}.module3`} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        { (index + 1) == 1 && (
                                                            <label htmlFor="exampleInputEmail4">{ studentWorkStore?.selectedModules?.pathway4Name ?? 'S4'} <span className="required">*</span></label>
                                                        )}
                                                        <Field type="number" id="exampleInputEmail4" className="form-control"  name={`days.${index}.module4`} disabled={ (formik.values.modules_per_week < '4')} onWheel={ (e) => OnWheelPreventChange(e) }/>
                                                        <div className="text-danger">
                                                            <ErrorMessage name={`days.${index}.module4`} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Save </Button>
                        <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
                    </Modal.Footer>
                </form>
            </FormikProvider>
        </Modal>
    </div>
  )
}

export default SetPatternModal;