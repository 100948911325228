import { useFormik } from 'formik';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import {Form} from 'react-bootstrap';
import {toast} from 'react-toastify';
import { Button } from '@app/components';
import { createSubject } from '@app/services/subject';

const AddSubject = () => {

    const subjectStatus = [{value:'active', label:'Active'}, {value:'inactive', label:'Inactive'}]
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    const addSubject = async (payload) => {
        try {
            setLoading(true);
            const resp = await createSubject(payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                navigate('/subjects');
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const { handleChange, values, handleSubmit, touched, errors } = useFormik({
        initialValues: {
            name: '',
            prefix: '',
            subject_rate: '',
            status: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter subject name')
            .min(5, 'Subject name must be 5 characters or more')
            .max(30, 'Subject name must be 30 characters or less'),
            prefix: Yup.string().required('Please enter subject prefix'),
            subject_rate: Yup.number().required('Please enter subject rate').positive('Subject rate must be a positive number'),
            // .max(10000, 'Rate must be less than or equal to 10,000'),
            status: Yup.string().required('Please select status'),
        }),
        onSubmit: (values) => {
            addSubject(values);
        }
    });

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Add Subject</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Subject Name <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter subject name"
                                                    name="name" 
                                                    onChange={handleChange} 
                                                    value={values.name}
                                                />
                                                {(touched.name && errors.name) && (
                                                    <div className="text-danger">
                                                        {errors.name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Subject Prefix <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter subject prefix"
                                                    name="prefix" 
                                                    onChange={handleChange} 
                                                    value={values.prefix}
                                                />
                                                {(touched.prefix && errors.prefix) && (
                                                    <div className="text-danger">
                                                        {errors.prefix}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Rate Per Student<span className="required">*</span></label>
                                                <Form.Control 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter subject rate per student"
                                                    name="subject_rate" 
                                                    onChange={handleChange} 
                                                    value={values.subject_rate}
                                                    onWheel={ (e) => e.currentTarget.blur() }
                                                />
                                                {(touched.subject_rate && errors.subject_rate) && (
                                                    <div className="text-danger">
                                                        {errors.subject_rate}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Status <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={handleChange} 
                                                    value={values.status}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {subjectStatus.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.status && errors.status) && (
                                                    <div className="text-danger">
                                                        {errors.status}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Add </Button>
                                    <Link className="btn btn-default" to='/subjects'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default AddSubject
