import React, { useState, useEffect } from 'react'
import { ErrorMessage, Field, FieldArray, FormikProvider, useFormik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { loadDays, loadEditDays } from '@app/store/reducers/settingPattern';
import { getSettingPatternById, updateSettingPattern } from '@app/services/settingPattern';

const EditPattern = () => {

    let { id } = useParams();
    const dispatch = useDispatch();
    const patternStore = useSelector((state: any) => state.settingPattern);
    const statuses = [{id:'active', name:'Active'}, {id:'inactive', name:'Inactive'}];
    const weekDays = [
        { name: "Mon", value: "Mon" },
        { name: "Tue", value: "Tue" },
        { name: "Wed", value: "Wed" },
        { name: "Thu", value: "Thu" },
        { name: "Fri", value: "Fri" },
        { name: "Sat", value: "Sat" },
        { name: "Sun", value: "Sun" }
    ]
    const [name, setName] = useState('');
    const [modPerWk, setModPerWk] = useState('');
    const [noWorkDays, setNoWorkDays] = useState([]);
    const [isDefault, setIsDefault] = useState(false);
    const [status, setStatus] = useState('');
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const modulesPerWeek = []
    for (let i=1; i <= 4; i++) {
        modulesPerWeek.push({value: i, name: i})
    }

    useEffect(() => {
        getPatternDetailsById(id);
    },[]);

    const getPatternDetailsById = async (id) => {
        try {
            const resp = await getSettingPatternById(id);
            if (resp.success === true) {
                setName(resp?.collections?.name)
                setModPerWk(resp?.collections?.modules_per_week)
                setNoWorkDays(resp?.collections?.no_work_days)
                setIsDefault(resp?.collections?.is_default)
                setStatus(resp?.collections?.status)
                dispatch(loadEditDays(resp?.collections?.days))
            } else {
                navigate('/setting-patterns');
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Internal server error');
        }
    };

    const updatePattern = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateSettingPattern(id, payload);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                navigate('/setting-patterns');
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Internal server error');
          }
    };

    const formik = useFormik({
        initialValues: {
            name: name,
            modules_per_week: modPerWk,
            no_work_days: noWorkDays,
            is_default: isDefault,
            status: status,
            days: patternStore.days
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter pattern name'),
            modules_per_week: Yup.number().required('Please select streams per week'),
            no_work_days: Yup.array().of(Yup.string().trim()),
            status: Yup.string().required('Please select status'),
            days: Yup.array().of(Yup.object({
                day: Yup.string(),
                module1: Yup.number().when("modules_per_week", {
                    is: () => (modPerWk >= '1'),
                    then: Yup.number().integer('Worksheet count must be an integer').required('Enter worksheet count')
                    .min(0, "Worksheet count must be greater than or equal to 0"),
                }),
                module2: Yup.number().when("modules_per_week", {
                    is: () => (modPerWk >= '2'),
                    then: Yup.number().integer('Worksheet count must be an integer').required('Enter worksheet count')
                    .min(0, "Worksheet count must be greater than or equal to 0"),
                }),
                module3: Yup.number().when("modules_per_week", {
                    is: () => (modPerWk >= '3'),
                    then: Yup.number().integer('Worksheet count must be an integer').required('Enter worksheet count')
                    .min(0, "Worksheet count must be greater than or equal to 0"),
                }),
                module4: Yup.number().when("modules_per_week", {
                    is: () => (modPerWk >= '4'),
                    then: Yup.number().integer('Worksheet count must be an integer').required('Enter worksheet count')
                    .min(0, "Worksheet count must be greater than or equal to 0"),
                }),
            })),
        }),
        onSubmit: (values) => {
            updatePattern(values);
        },
        enableReinitialize: true,
    });

    useEffect(() => {
		formik.setFieldValue('name', name)
		formik.setFieldValue('modules_per_week', modPerWk)
        formik.setFieldValue("no_work_days", noWorkDays)
        formik.setFieldValue("is_default", isDefault)
        formik.setFieldValue("status", status)
    },[name, modPerWk, noWorkDays, isDefault, status]);

    const OnWheelPreventChange = (e) => {
		e.currentTarget.blur();
	} 
    
    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Update Setting Pattern</h3>
                            </div>
                            <FormikProvider value={formik}>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail12">Pattern Name <span className="required">*</span></label>
                                                <input type="text" className="form-control" id="exampleInputEmail12" placeholder="Enter pattern name" 
                                                    name="name" 
                                                    onChange={ (e) => {
                                                        setName(e.target.value)
                                                    }}
                                                    value={formik.values.name}
                                                />
                                                {(formik.touched.name && formik.errors.name) && (
                                                    <div className="text-danger">
                                                        {formik.errors.name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail13">Stream Per Week <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="modules_per_week"
                                                    onChange={ (e) => {
                                                        formik.setFieldValue('modules_per_week', e.target.value)
                                                        setModPerWk(e.target.value)
                                                    }}
                                                    value={formik.values.modules_per_week}
                                                >
                                                    <option value="">Please select streams per week</option>
                                                    {modulesPerWeek.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(formik.touched.modules_per_week && formik.errors.modules_per_week) && (
                                                    <div className="text-danger">
                                                        {formik.errors.modules_per_week}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail14">No Work Set </label>
                                                <Select
                                                    placeholder={<div>Please select </div>}
                                                    isMulti
                                                    options={weekDays}
                                                    closeMenuOnSelect={false}
                                                    getOptionLabel={(option)=> option.name}
                                                    getOptionValue={(option)=>option.value}
                                                    name="no_work_days"
                                                    onChange={ (e) => {
                                                        dispatch(loadDays({count: e?.length}))
                                                        setNoWorkDays([].slice.call(e).map((option) => option.value))
                                                    }}
                                                    value={
                                                        weekDays?.length
                                                        ? weekDays.filter((obj) =>
                                                            formik.values.no_work_days.includes(obj.value)
                                                            )
                                                        : formik.values.no_work_days
                                                    } // set selected values
                                                />
                                                <div style={{ marginBottom: 10 }} className="row">
                                                    <div className="col-sm-12">
                                                        {(formik.touched.no_work_days && formik.errors.no_work_days) && (
                                                            <div className="text-danger">
                                                                {formik.errors.no_work_days}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Status <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={ (e) => {
                                                        setStatus(e.target.value)
                                                    }}
                                                    value={formik.values.status}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {statuses.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(formik.touched.status && formik.errors.status) && (
                                                    <div className="text-danger">
                                                        {formik.errors.status}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <label htmlFor="franchise_id"><b> Is default? </b></label>
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox"
                                                        id="is_default"  
                                                        name="is_default" 
                                                        className="form-check-input" 
                                                        checked={formik.values.is_default}
                                                        onChange={(e) => { 
                                                            setIsDefault(e.target.checked)
                                                        }}
                                                    />
                                                    <label htmlFor="is_default" className="form-check-label"></label>
                                                </div>
                                                {(formik.touched.is_default && formik.errors.is_default) && (
                                                    <div className="text-danger">
                                                        {formik.errors.is_default}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <FieldArray name="days">
                                        {({ push, remove }) => (
                                            <>
                                            <div className='row'>
                                                <div className="col-sm-12">
                                                    <h6 className='mt-2 grey_wrapper_title'>Module Worksheet Count</h6>
                                                </div>
                                            </div>
                                            {formik.values.days.map((day, index) => (
                                                <div className="row" key={index}>
                                                    <div className="col-sm-2">
                                                        <div className="form-group">
                                                            { (index + 1) == 1 && (
                                                                <label htmlFor="exampleInputEmail15">Day </label>
                                                            )}
                                                            <dd> <b><i>Day {index + 1}</i></b></dd>
                                                            <div className="text-danger">
                                                                <ErrorMessage name={`days.${index}.day`} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-group">
                                                            { (index + 1) == 1 && (
                                                                <label htmlFor="exampleInputEmail1">S1 <span className="required">*</span></label>
                                                            )}
                                                            <Field type="number" id="exampleInputEmail1" className="form-control"  name={`days.${index}.module1`} value={(formik.values.modules_per_week < '1') ? '' : day.module1} disabled={ (formik.values.modules_per_week < '1')} onWheel={ (e) => OnWheelPreventChange(e) }/>
                                                            <div className="text-danger">
                                                                <ErrorMessage name={`days.${index}.module1`} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-group">
                                                            { (index + 1) == 1 && (
                                                                <label htmlFor="exampleInputEmail2">S2 <span className="required">*</span></label>
                                                            )}
                                                            <Field type="number" id="exampleInputEmail2" className="form-control"  name={`days.${index}.module2`} value={(formik.values.modules_per_week < '2') ? '' : day.module2} disabled={ (formik.values.modules_per_week < '2')} onWheel={ (e) => OnWheelPreventChange(e) }/>
                                                            <div className="text-danger">
                                                                <ErrorMessage name={`days.${index}.module2`} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-group">
                                                            { (index + 1) == 1 && (
                                                                <label htmlFor="exampleInputEmail3">S3 <span className="required">*</span></label>
                                                            )}
                                                            <Field type="number" id="exampleInputEmail3" className="form-control"  name={`days.${index}.module3`} value={(formik.values.modules_per_week < '3') ? '' : day.module3} disabled={ (formik.values.modules_per_week < '3')} onWheel={ (e) => OnWheelPreventChange(e) }/>
                                                            <div className="text-danger">
                                                                <ErrorMessage name={`days.${index}.module3`} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-group">
                                                            { (index + 1) == 1 && (
                                                                <label htmlFor="exampleInputEmail4">S4 <span className="required">*</span></label>
                                                            )}
                                                            <Field type="number" id="exampleInputEmail4" className="form-control"  name={`days.${index}.module4`} value={(formik.values.modules_per_week < '4') ? '' : day.module4} disabled={ (formik.values.modules_per_week < '4')} onWheel={ (e) => OnWheelPreventChange(e) }/>
                                                            <div className="text-danger">
                                                                <ErrorMessage name={`days.${index}.module4`} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-sm-2 d-flex align-items-center form_wrapper">
                                                        <i className="fas fa-times-circle" onClick={() => remove(index)} style={{cursor:'pointer'}}></i>
                                                    </div> */}
                                                    {/* <pre>{ JSON.stringify(formik.errors) }</pre> */}
                                                </div>
                                            ))}
                                                {/* <i className="cursor-pointer fas fa-plus-circle" onClick={() => push(emptyDays)} style={{cursor:'pointer'}}></i> */}
                                            </>
                                        )}
                                    </FieldArray>
                                </div>
                                <div className="card-footer">
                                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                                    <Link className="btn btn-default" to='/setting-patterns'>Close</Link>
                                </div>
                            </form>
                            </FormikProvider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default EditPattern;