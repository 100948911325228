import React, { useEffect, useRef, useState } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { getAllActiveFranchise } from '@app/services/franchise';
import Select from "react-select";
import AddStudentCountsModal from './AddStudentCountsModal';
import { deleteStudentCount, getAllByPagination, getDefaulters } from '@app/services/studentCount';
import { confirmAlert } from 'react-confirm-alert';
import UpdateStudentCountsModal from './UpdateStudentCountsModal';
import moment from 'moment';
import fileDownload from 'js-file-download';
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import { Link } from 'react-router-dom';

const ListStudentCounts = () => {

    const selectRef = useRef(null);
    const [studentCounts, setStudentCounts] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({franchise_order_id:'', franchise: '', month: '', year: (new Date().getFullYear()) + ''});
    const [franchises, setFranchises] = useState([]);
    const canAccess = useSelector((state: any) => state.auth.permissions?.['student-count']);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showUpdate, setShowUpdate] = useState(false);
    const handleCloseUpdate = () => setShowUpdate(false);
    const handleShowUpdate = () => setShowUpdate(true);
    const [updateStudCountPayload, setUpdateStudCount] = useState({franchise_id: '', month: '', year: '', maths: '', english: '', science: ''});

    const months = [{id: 1, name:'January'}, {id: 2, name:'February'}, {id: 3, name:'March'}, 
                    {id: 4, name:'April'}, {id: 5, name:'May'}, {id: 6, name:'June'},
                    {id: 7, name:'July'},{id: 8, name:'August'},{id: 9, name:'September'},
                    {id: 10, name:'October'}, {id: 11, name:'November'}, {id: 12, name:'December'}];
    const years = Array.from({ length: 20 }, (_, index) => new Date().getFullYear() - index );
    
    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    const customStylesSelect = {
        dropdownIndicator: () => ({
            display: 'none',
        }),
    }

    useEffect(() => {
        getAllByPaginationData(1, perPage, sortField, sortDirection);
    },[search]);

    useEffect(() => {
        getAllActiveFranchiseData();
    },[]);

    const getAllByPaginationData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const usersResp = await getAllByPagination(page, perPage, sortField, sortDirection, search);
            if (usersResp.success === true) {
                setLoading(false);
                setStudentCounts(usersResp.collections.data);
                setTotalRows(usersResp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(usersResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllActiveFranchiseData = async () => {
        try {
            const resp = await getAllActiveFranchise();
            if (resp?.success === true) {
                setFranchises(resp?.collections.filter((franchise) => franchise.enabled_student_count == true));
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const columns = [
        {
            name: '#Txn Id',
            selector: (row: { franchise_order_id: string; }) => `#${row.franchise_order_id}`,
            sortable: true,
            sortField: 'franchise_order_id',
            // width: '8%',
            wrap: true
        },
        {
            name: 'Franchise',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            sortField: 'franchise_id',
            wrap: true,
        },
        {
            name: 'Postcodes',
            selector: (row: { postcodes: string; }) => (row.postcodes).length > 0 ? ( Array.prototype.map.call(row.postcodes, function(postcode) { return postcode.name; }).join(", ") ) : '',
            wrap: true,
        },
        {
            name: 'Month',
            selector: (row: { month: any; }) => { return (months.find(obj => +row.month === obj.id))?.name ?? '---' },
            sortable: true,
            sortField: 'month',
        },
        {
            name: 'Year',
            selector: (row: { year: string; }) => row.year,
            sortable: true,
            sortField: 'year',
        },
        {
            name: 'Mathematics',
            selector: (row: { maths: string; }) => row.maths ?? '---',
            // sortable: true,
            // sortField: 'maths',
            wrap: true,
        },
        {
            name: 'English',
            selector: (row: { english: string; }) => row.english ?? '---',
            // sortable: true,
            // sortField: 'english',
            wrap: true,
        },
        {
            name: 'Science',
            selector: (row: { science: string; }) => row.science ?? '---',
            // sortable: true,
            // sortField: 'science',
            wrap: true,
        },
        {
            name: 'Total',
            selector: (row: { total: string; }) => row.total ?? '---',
            // sortable: true,
            // sortField: 'total',
            wrap: true,
        },
        {
            name: 'Submission Date',
            selector: (row: { created_at: string; }) => row.created_at,
            cell: (row: { created_at: string; }) => (
                row.created_at ? moment(row.created_at).format('DD/MM/YYYY') : '---'
            ),
            sortable: true,
            sortField: 'created_at',
            // wrap: true
        },
        {  
            name: 'Action',
            cell: (row) => (
              <>
                {/* { canAccess?.['edit-student-count'] &&
                    <a href='#' onClick={ (e) => { handleUpdateStudCount({postcodes: row.postcodes, franchise_id: row.franchise_id, month: row.month, year: row.year, maths: row.maths, english: row.english, science: row.science, total: row.total}) }} data-toggle="tooltip" title="Edit Student Count">
                        <i className="fas fa-pen mr-2"/>
                    </a>
                }
                { canAccess?.['delete-student-count'] &&
                    <a href='#' onClick={ (e) => { deleteConfirm(e, {franchise_id: row.franchise_id, month: row.month, year: row.year})} }>
                        <i className="fas fa-trash mr-2"/>
                    </a>
                } */}
                { canAccess?.['view-transaction'] && 
                    ( row.franchise_order_id > 0 &&
                        <Link
                            to={`/student-counts/view/${row.franchise_order_id}`}
                            role="button"
                            state={{ id: row.franchise_order_id }}
                            data-toggle="tooltip" title="View Transaction Details"
                        ><i className="fas fa-eye mr-2"/></Link>
                    )
                }
              </>
            ),
            omit: !(canAccess?.['view-transaction'])
        },
    ];

    const deleteConfirm = (e, payload) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to delete this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteStudentCountAPI(payload)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const deleteStudentCountAPI = async (id) => {
        try {
            setLoading(true);
            const resp = await deleteStudentCount(id);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                getAllByPaginationData(currentPage, perPage, sortField, sortDirection);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const handleUpdateStudCount= async(payload) => {
        handleShowUpdate();
        payload.postcodes = (payload.postcodes).length > 0 ? ( Array.prototype.map.call(payload.postcodes, function(postcode) { return postcode.name; }).join(", ") ) : ''
        setUpdateStudCount(payload)
    }

    const handlePageChange = page => {
        getAllByPaginationData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllByPaginationData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllByPaginationData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        selectRef.current.setValue('');
        setSearch({franchise_order_id:'', franchise: '', month: '', year: ''});
    };

    const onChangeFranchise = (option) => {
        setSearch({ ...search, franchise: option?.id ? option?.id : '' });
    };

    const handleFetchAgain = () => {
        getAllByPaginationData(currentPage, perPage, sortField, sortDirection)
    }

    const exportCSV = async() => {
        let records = await getAllByPagination(1, totalRows, sortField, sortDirection, search)

        if(records?.collections?.data.length === 0){
            toast.error('Records not found.')
            return;
        }

        const workbook = new ExcelJS.Workbook();
        workbook.addWorksheet("Student Counts");
        const worksheet = workbook.getWorksheet('Student Counts');
        worksheet.columns = [
            { header: "Franchise", key: "name" },
            { header: "Kashflow Order Account", key: "kashflow_order_account" },
            { header: "Month", key: "month" },
            { header: "Year", key: "year" },
            { header: "Mathematics", key: "maths" },
            { header: "English", key: "english" },
            { header: "Science", key: "science" },
            { header: "Total", key: "total" },
            { header: "Submission Date", key: "created_at" }
        ];

        (records?.collections?.data).map( (row) => {
            row.month = (months.find(obj => +row.month === obj.id))?.name ?? '---';
            row.created_at = row.created_at ? moment(row.created_at).format('DD/MM/YYYY') : '---';
        })
        worksheet.addRows(records?.collections?.data);
        let filename = 'Student-Counts-' + moment().format('DD-MMM-YYYY') + '.csv';
        workbook.csv.writeBuffer().then(function(buffer) {
            fileDownload(buffer, filename);
        });
    };

    const exportDefaulterCSV = async() => {
        let records = await getDefaulters(search);

        if(records?.collections.length === 0){
            toast.error('Records not found.');
            return;
        }

        if(search?.year == '2023'){
            let filterArr = records?.collections.filter((obj) => {
                return obj.month == 'November' || obj.month == 'December'
            })
            
            if(filterArr.length === 0){
                toast.error('Records not found.');
                return;
            }
        }

        const workbook = new ExcelJS.Workbook();
        workbook.addWorksheet("Sheet1");
        const worksheet = workbook.getWorksheet('Sheet1');
        worksheet.columns = [
            { header: "Franchise", key: "name" },
            { header: "Month", key: "month" },
            { header: "Year", key: "year" }
        ];

        // (records?.collections?.data).map( (row) => {
        //     row.month = (months.find(obj => +row.month === obj.id))?.name ?? '---'
        // })
        worksheet.addRows(records?.collections);
        let filename = 'Defaulters-' + moment().format('DD-MMM-YYYY') + '.csv';
        workbook.csv.writeBuffer().then(function(buffer) {
            fileDownload(buffer, filename);
        });
    }

    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Submit Student Subject Count</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active">Submit Student Subject Count</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Submit Student Subject Count</h3>
                                        <div className="col-lg-12 text-right">
                                            { canAccess?.['export-student-count'] &&
                                                <Button className="btn btn-success mr-2" isLoading={isLoading} onClick={exportCSV}><i className="fas fa-file-csv"></i> Export Student Count </Button>
                                            }
                                            { canAccess?.['create-student-count'] &&
                                                // <a className="btn btn-primary" onClick={handleShow} > + Add New </a>
                                                <Link className="btn btn-primary" to="/submit-student-counts" > Submit Student Count </Link>
                                            }
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row mb-3">
                                            <div className="col-sm-2">
                                                <input type="text" name='franchise_order_id' className="form-control" value={search.franchise_order_id} onChange={(e) => setSearch({ ...search, franchise_order_id: e.target.value })} placeholder="Search By Txn Id" />
                                            </div>
                                            <div className="col-sm-3">
                                                <Select
                                                    ref={selectRef}
                                                    name="franchiseId"
                                                    placeholder="Search By Franchise"
                                                    options={franchises}
                                                    getOptionValue={(option)=>`${option['id']}`}
                                                    getOptionLabel={(option)=> option['name'] }
                                                    onChange={ (option) => {onChangeFranchise(option)}}
                                                    isClearable={true}
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    // onInputChange={ (option) => {onChangeFranchise(option)}}
                                                    // value={search.franchiseId}
                                                    // styles={customStylesSelect}
                                                />
                                            </div>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="month"
                                                    onChange={(e) => setSearch({ ...search, month: e.target.value })} 
                                                    value={search.month}
                                                >
                                                    <option value="">All Months</option>
                                                    {months.map((obj) => (
                                                        <option key={obj.name} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="year"
                                                    onChange={(e) => setSearch({ ...search, year: e.target.value })} 
                                                    value={search.year}
                                                >
                                                    <option value="">All Years</option>
                                                    {years.map((year) => (
                                                        <option key={year} value={year}>
                                                            {year}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-sm-3'>
                                                <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning mr-2">Reset Filter</Button>
                                                { canAccess?.['export-defaulters'] &&
                                                    <Button type="submit" isLoading={isLoading} onClick={exportDefaulterCSV} className="btn btn-success my-1">Export Defaulters</Button>
                                                }
                                            </div>
                                        </div>

                                        <DataTable
                                            columns={columns}
                                            data={studentCounts}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationDefaultPage={currentPage}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            paginationPerPage={perPage}
                                            paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                            progressPending={isLoading}
                                            sortServer
                                            onSort={handleSort}
                                            customStyles={customStyle}
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                <AddStudentCountsModal
                    show={show}
                    handleClose={handleClose}
                    franchises={franchises}
                    handleFetchAgain={handleFetchAgain}
                />
                <UpdateStudentCountsModal
                    show={showUpdate}
                    handleClose={handleCloseUpdate}
                    franchises={franchises}
                    handleFetchAgain={handleFetchAgain}
                    payload={updateStudCountPayload}
                />
                {/* /.content */}
            </div>

        </div>
    )
};

export default ListStudentCounts;
