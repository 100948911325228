import { Form, Modal } from 'react-bootstrap';
import React, { useState, useEffect } from 'react'
import { Field, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import { useDispatch, useSelector } from "react-redux";
import { getLearningPathwayById, getStudSubjectLearningPathways } from '@app/services/learningPathway';
import { setModule, setModulesPerWk, setSelModuleDetails, setSelectedModules, setStep } from '@app/store/reducers/studentWork';

const SelectModuleModal = (props) => {

    let obj: any = {}
    const dispatch = useDispatch();
    const studentWorkStore = useSelector((state: any) => state.studentWork);
    const [pathways, setPathways] = useState([]);
    const [finishedModules, setFinishedModules] = useState([]);
    const [module1Selected, setModule1Selected] = useState(obj);
    const [module2Selected, setModule2Selected] = useState(obj);
    const [module3Selected, setModule3Selected] = useState(obj);
    const [module4Selected, setModule4Selected] = useState(obj);
    const [isLoading, setLoading] = useState(false);
    const [isStreamLoading, setStreamLoading] = useState(true);
    const [streamPerWk, setStreamPerWk] = useState(0);

    let modulesPerWeek = []
    for (let i=1; i <= 4; i++) {
        modulesPerWeek.push({value: i, name: i})
    }

    // useEffect(() => {
    //     getActiveLearningPathways()
    //     dispatch(setModule({moduleNumber: 'all', module: []}))
    //     dispatch(setSelModuleDetails({moduleNumber: 'all', module: null}))
    // },[]);

    useEffect(() => {
        if(props.show == true){
            setStreamLoading(true)

            setStreamPerWk(studentWorkStore?.modulePerWeek)
            getActiveLearningPathways()
            // dispatch(setModule({moduleNumber: 'all', module: []}))
            dispatch(setSelModuleDetails({moduleNumber: 'all', module: null}))

            const timeoutId = setTimeout(() => {
                setStreamLoading(false)
                if(studentWorkStore?.selectedModules?.module1 != ''){
                    let foundModule = studentWorkStore?.module1.find(obj => {
                        return obj.module_id === studentWorkStore?.selectedModules?.module1;
                    });
                    if(foundModule != undefined){
                        setModule1Selected(foundModule)
                        dispatch(setSelModuleDetails({moduleNumber: 1, module: foundModule}))
                    }
                }
                if(studentWorkStore?.selectedModules?.module2 != ''){
                    let foundModule = studentWorkStore?.module2.find(obj => {
                        return obj.module_id === studentWorkStore?.selectedModules?.module2;
                    });
                    if(foundModule != undefined){
                        setModule2Selected(foundModule)
                        dispatch(setSelModuleDetails({moduleNumber: 2, module: foundModule}))
                    }
                }
                if(studentWorkStore?.selectedModules?.module3 != ''){
                    let foundModule = studentWorkStore?.module3.find(obj => {
                        return obj.module_id === studentWorkStore?.selectedModules?.module3;
                    });
                    if(foundModule != undefined){
                        setModule3Selected(foundModule)
                        dispatch(setSelModuleDetails({moduleNumber: 3, module: foundModule}))
                    }
                }
                if(studentWorkStore?.selectedModules?.module4 != ''){
                    let foundModule = studentWorkStore?.module4.find(obj => {
                        return obj.module_id === studentWorkStore?.selectedModules?.module4;
                    });
                    if(foundModule != undefined){
                        setModule4Selected(foundModule)
                        dispatch(setSelModuleDetails({moduleNumber: 4, module: foundModule}))
                    }
                }
                
                let finishedStreams = [];
                (props.lastStreams).map((obj) => {
                    if(obj.latest_worksheet_number == null){
                        switch(obj.module){
                            case 'module1' : finishedStreams.push('<b>Stream 1:</b> ' + obj.module_name)
                                    break;
                            case 'module2' : finishedStreams.push('<b>Stream 2:</b> ' + obj.module_name)
                                    break;
                            case 'module3' : finishedStreams.push('<b>Stream 3:</b> ' + obj.module_name)
                                    break;
                            case 'module4' : finishedStreams.push('<b>Stream 4:</b> ' + obj.module_name)
                                    break;
                        }
                    }
                })
                setFinishedModules(finishedStreams)
            }, 1000);

            return () => {
                // Anything in here is fired on component unmount.
                clearTimeout(timeoutId)
            }
        }

        return () => {
            setPathways([])
        }
    },[props.show]);

    const getActiveLearningPathways = async () => {
        try {
            setLoading(true);
            const resp = await getStudSubjectLearningPathways({student_id: props?.studId, subject_id: studentWorkStore.subject.id ?? 0});
            if (resp.success === true) {
                setLoading(false);
                setPathways(resp?.collections)
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Internal server error');
        }
    };

    const getLearningPathwayByIdAPI = async (id, module_num) => {
        try {
            setLoading(true);
            const resp = await getLearningPathwayById(id);
            if (resp?.success === true) {
                setLoading(false);
                dispatch(setModule({moduleNumber: module_num, module: resp?.collections}))
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Internal server error');
        }
    };

    const formik = useFormik({
        initialValues: {
            modules_per_week: streamPerWk,
            module1LearnigPath: studentWorkStore?.selectedModules?.module1LearnigPath || '',
            module1: studentWorkStore?.selectedModules?.module1 || '',
            module1WSFrom: studentWorkStore?.selectedModules?.module1WSFrom || '',
            module1WSTo: studentWorkStore?.selectedModules?.module1WSTo || '',
            module2LearnigPath: studentWorkStore?.selectedModules?.module2LearnigPath || '',
            module2: studentWorkStore?.selectedModules?.module2 || '',
            module2WSFrom: studentWorkStore?.selectedModules?.module2WSFrom || '',
            module2WSTo: studentWorkStore?.selectedModules?.module2WSTo || '',
            module3LearnigPath: studentWorkStore?.selectedModules?.module3LearnigPath || '',
            module3: studentWorkStore?.selectedModules?.module3 || '',
            module3WSFrom: studentWorkStore?.selectedModules?.module3WSFrom || '',
            module3WSTo: studentWorkStore?.selectedModules?.module3WSTo || '',
            module4LearnigPath: studentWorkStore?.selectedModules?.module4LearnigPath || '',
            module4: studentWorkStore?.selectedModules?.module4 || '',
            module4WSFrom: studentWorkStore?.selectedModules?.module4WSFrom || '',
            module4WSTo: studentWorkStore?.selectedModules?.module4WSTo || '',
        },
        validationSchema: Yup.object({
            module1LearnigPath: Yup.number().when([], {
                is: () => streamPerWk >= 1,
                then: Yup.number().integer().required('Please select learning pathway'),
                otherwise: Yup.number(),
            }),
            module1: Yup.string().when([], {
                is: () => streamPerWk >= 1,
                then: Yup.string().required("Please select module 1"),
                otherwise: Yup.string(),
            }),
            module1WSFrom: Yup.number().when([], {
                is: () => streamPerWk >= 1,
                then: () =>  Yup.number().positive().required('Please enter worksheet From')
                        .lessThan(formik.values.module1WSTo, 'Worksheet FROM must be less than worksheet TO')
                        .min(module1Selected?.worksheet_from, `Worksheet FROM must be greater than or equal to ${module1Selected?.worksheet_from}`),
                otherwise: Yup.number(),
            }),
            module1WSTo: Yup.number().when([], {
                is: () => streamPerWk >= 1,
                then: () => Yup.number().positive().required('Please enter worksheet To')
                        .moreThan(formik.values.module1WSFrom, 'Worksheet TO must be greater than worksheet FROM')
                        .max(module1Selected?.worksheet_to, `Worksheet TO must be less than or equal to ${module1Selected?.worksheet_to}`),
                otherwise: Yup.number(),
            }),

            module2LearnigPath: Yup.number().when([], {
                is: () => streamPerWk >= 2,
                then: Yup.number().integer().required('Please select learning pathway'),
                otherwise: Yup.number(),
            }),
            module2: Yup.string().when([], {
                is: () => streamPerWk >= 2,
                then: Yup.string().required("Please select module 2"),
                otherwise: Yup.string(),
            }),
            module2WSFrom: Yup.number().when([], {
                is: () => streamPerWk >= 2,
                then:() => Yup.number().positive().required('Please enter worksheet From')
                        .lessThan(formik.values.module2WSTo, 'Worksheet FROM must be less than worksheet TO')
                        .min(module2Selected?.worksheet_from, `Worksheet FROM must be greater than or equal to ${module2Selected?.worksheet_from}`),
                otherwise: Yup.number(),
            }),
            module2WSTo: Yup.number().when([], {
                is: () => streamPerWk >= 2,
                then: () => Yup.number().positive().required('Please enter worksheet To')
                        .moreThan(formik.values.module2WSFrom, 'Worksheet To must be greater than worksheet FROM')
                        .max(module2Selected?.worksheet_to, `Worksheet TO must be less than or equal to ${module2Selected?.worksheet_to}`),
                otherwise: Yup.number(),
            }),

            module3LearnigPath: Yup.number().when([], {
                is: () => streamPerWk >= 3,
                then: Yup.number().integer().required('Please select learning pathway'),
                otherwise: Yup.number(),
            }),
            module3: Yup.string().when([], {
                is: () => streamPerWk >= 3,
                then: Yup.string().required("Please select module 3"),
                otherwise: Yup.string(),
            }),
            module3WSFrom: Yup.number().when([], {
                is: () => streamPerWk >= 3,
                then:() => Yup.number().positive().required('Please enter worksheet From')
                        .lessThan(formik.values.module3WSTo, 'Worksheet FROM must be less than worksheet TO')
                        .min(module3Selected?.worksheet_from, `Worksheet FROM must be greater than or equal to ${module3Selected?.worksheet_from}`),
                otherwise: Yup.number(),
            }),
            module3WSTo: Yup.number().when([], {
                is: () => streamPerWk >= 3,
                then: () => Yup.number().positive().required('Please enter worksheet To')
                        .moreThan(formik.values.module3WSFrom, 'Worksheet To must be greater than worksheet FROM')
                        .max(module3Selected?.worksheet_to, `Worksheet TO must be less than or equal to ${module3Selected?.worksheet_to}`),
                otherwise: Yup.number(),
            }),

            module4LearnigPath: Yup.number().when([], {
                is: () => streamPerWk >= 4,
                then: Yup.number().integer().required('Please select learning pathway'),
                otherwise: Yup.number(),
            }),
            module4: Yup.string().when([], {
                is: () => streamPerWk >= 4,
                then: Yup.string().required("Please select module 4"),
                otherwise: Yup.string(),
            }),
            module4WSFrom: Yup.number().when([], {
                is: () => streamPerWk >= 4,
                then:() => Yup.number().positive().required('Please enter worksheet From')
                        .lessThan(formik.values.module4WSTo, 'Worksheet FROM must be less than worksheet TO')
                        .min(module4Selected?.worksheet_from, `Worksheet FROM must be greater than or equal to ${module4Selected?.worksheet_from}`),
                otherwise: Yup.number(),
            }),
            module4WSTo: Yup.number().when([], {
                is: () => streamPerWk >= 4,
                then: () => Yup.number().positive().required('Please enter worksheet To')
                        .moreThan(formik.values.module4WSFrom, 'Worksheet To must be greater than worksheet FROM')
                        .max(module4Selected?.worksheet_to, `Worksheet TO must be less than or equal to ${module4Selected?.worksheet_to}`),
                otherwise: Yup.number(),
            }),
        }),
        onSubmit: (values) => {

            let moduleAndPathwayNames = {
                module1Name: module1Selected?.module_name,
                module2Name: module2Selected?.module_name,
                module3Name: module3Selected?.module_name,
                module4Name: module4Selected?.module_name,
                pathway1Name: module1Selected?.pathway_name,
                pathway2Name: module2Selected?.pathway_name,
                pathway3Name: module3Selected?.pathway_name,
                pathway4Name: module4Selected?.pathway_name,
            } 
            dispatch(setSelectedModules({...values, ...moduleAndPathwayNames}))
            dispatch(setStep(2))
            dispatch(setModulesPerWk(streamPerWk))
            props.handleClose()
        },
        enableReinitialize: true,
    });

    const onPathwayChanges = (e, module_number) => {
        if(e.target.value != ''){
            getLearningPathwayByIdAPI(e.target.value, module_number)
        }else{
            dispatch(setModule({moduleNumber: module_number, module: []}))
        }
        switch(module_number){
            case 1 : setModule1Selected(obj)
                    formik.setFieldValue('module1WSFrom', '') 
                    formik.setFieldValue('module1WSTo', '') 
                    formik.setFieldValue('module1', '') 
                    break;
            case 2 : setModule2Selected(obj)
                    formik.setFieldValue('module2WSFrom', '') 
                    formik.setFieldValue('module2WSTo', '') 
                    formik.setFieldValue('module2', '') 
                    break;
            case 3 : setModule3Selected(obj)
                    formik.setFieldValue('module3WSFrom', '') 
                    formik.setFieldValue('module3WSTo', '') 
                    formik.setFieldValue('module3', '') 
                    break;
            case 4 : setModule4Selected(obj)
                    formik.setFieldValue('module4WSFrom', '') 
                    formik.setFieldValue('module4WSTo', '') 
                    formik.setFieldValue('module4', '') 
                    break;
        }
    }

    const handleOnRadioChange = (moduleNum, module) => {
        switch(moduleNum){
            case 1 : setModule1Selected(module)
                    formik.setFieldValue('module1WSFrom',module?.worksheet_from) 
                    formik.setFieldValue('module1WSTo',module?.worksheet_to) 
                    break;
            case 2 : setModule2Selected(module)
                    formik.setFieldValue('module2WSFrom',module?.worksheet_from) 
                    formik.setFieldValue('module2WSTo',module?.worksheet_to) 
                    break;
            case 3 : setModule3Selected(module)
                    formik.setFieldValue('module3WSFrom',module?.worksheet_from) 
                    formik.setFieldValue('module3WSTo',module?.worksheet_to) 
                    break;
            case 4 : setModule4Selected(module)
                    formik.setFieldValue('module4WSFrom',module?.worksheet_from) 
                    formik.setFieldValue('module4WSTo',module?.worksheet_to) 
                    break;
        }
        dispatch(setSelModuleDetails({moduleNumber: moduleNum, module: module}))
    }

    const OnWheelPreventChange = (e) => {
		e.currentTarget.blur();
	} 

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose} size='xl'>
            <div className={`overlay ${ isStreamLoading ? '' : ' d-none'}`}>
                <i className="fas fa-2x fa-sync fa-spin"></i>
            </div>
            <Modal.Header closeButton>
                <Modal.Title>{props.subjectName} Verify Streams</Modal.Title>
            </Modal.Header>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail13">Stream Per Week <span className="required">*</span></label>
                                    <select
                                        className="form-control"
                                        name="modules_per_week"
                                        onChange={ (e) => {
                                            formik.setFieldValue('modules_per_week', e.target.value)
                                            setStreamPerWk(parseInt(e.target.value))
                                        }}
                                        value={formik.values.modules_per_week}
                                    >
                                        <option value="">Please select streams per week</option>
                                        {modulesPerWeek.map((obj) => (
                                            <option key={obj.value} value={obj.value}>
                                                {obj.name}
                                            </option>
                                        ))}
                                    </select>
                                    {(formik.touched.modules_per_week && formik.errors.modules_per_week) && (
                                        <div className="text-danger">
                                            {formik.errors.modules_per_week}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {finishedModules.length > 0 && (
                                <div className="col-sm-8 text-info">
                                    <dd>The following modules have been finished previously. Please select the next modules to proceed further -</dd>
                                    <div dangerouslySetInnerHTML={{ __html: finishedModules.join(", ")}} />
                                    {/* { ( Array.prototype.map.call(finishedModules, function(module) { return 'Stream ' + module.stream +': '+module.name; }).join(", ") ) } */}
                                </div>
                            )}
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h6 className='mt-2 grey_wrapper_title'>Set Stream(s) <span className="required">*</span></h6>
                                    </div>
                                    {streamPerWk >= 1 && (
                                        <div className={`col-md-${12/streamPerWk}`}>
                                            <h5 className='text-center'>Stream 1</h5>
                                            <div className='m-3'>
                                                <select 
                                                    className="form-control" 
                                                    name="module1LearnigPath" 
                                                    onChange={ (e) => {
                                                        formik.setFieldValue('module1LearnigPath', e.target.value)
                                                        onPathwayChanges(e, 1)
                                                    }}
                                                    value={formik.values.module1LearnigPath}
                                                >
                                                    <option value="">Select Pathway</option>
                                                    {pathways.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(formik.touched.module1LearnigPath && formik.errors.module1LearnigPath) && (
                                                    <div className="text-danger">
                                                        {formik.errors.module1LearnigPath}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group m-3">
                                                <Form.Control 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Worksheet from"
                                                    name="module1WSFrom" 
                                                    onChange={formik.handleChange} 
                                                    value={formik.values.module1WSFrom}
                                                    disabled={formik.values.module1 == ''}
                                                    onWheel={ (e) => OnWheelPreventChange(e) } 
                                                />
                                                {(formik.touched.module1WSFrom && formik.errors.module1WSFrom) && (
                                                    <div className="text-danger">
                                                        {formik.errors.module1WSFrom}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group m-3">
                                                <Form.Control 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Worksheet to"
                                                    name="module1WSTo" 
                                                    onChange={formik.handleChange} 
                                                    value={formik.values.module1WSTo}
                                                    disabled={formik.values.module1 == ''}
                                                    onWheel={ (e) => OnWheelPreventChange(e) } 
                                                />
                                                {(formik.touched.module1WSTo && formik.errors.module1WSTo) && (
                                                    <div className="text-danger">
                                                        {formik.errors.module1WSTo}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group m-3">
                                                Module Picked: &nbsp;
                                                <label className='col-form-label'>
                                                    { Object.keys(module1Selected).length > 0 && formik.values.module1 != '' ? module1Selected?.module_name + ' (' + module1Selected?.worksheet_from +' - '+ module1Selected?.worksheet_to + ")" : '---' }
                                                </label>
                                            </div>
                                            {(formik.touched.module1 && formik.errors.module1) && (
                                                <div className="text-danger">
                                                    {formik.errors.module1}
                                                </div>
                                            )}
                                            <div className="form-group m-3">
                                                {studentWorkStore?.module1 && (studentWorkStore?.module1).map((module, i) =>(
                                                    <div className="custom-control custom-radio mb-2" key={`module1-${i}`}>
                                                        <div className='row'>
                                                            <Field className="col-sm-2 custom-control-input" 
                                                                type="radio" name="module1"
                                                                id={`module1-${module?.module_id}`}  
                                                                value={module.module_id}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue('module1', module?.module_id)
                                                                    handleOnRadioChange(1, module)
                                                                }}
                                                                checked={module.module_id == formik.values.module1}
                                                            />
                                                            <label className="col-sm-6 custom-control-label" htmlFor={`module1-${module?.module_id}`}>{ module?.module_name }</label>
                                                            <span className="col-sm-4">({ module?.worksheet_from +' - '+ module?.worksheet_to })</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {streamPerWk >= 2 && (
                                        <div className={`col-md-${12/streamPerWk} border-left border-secondary pl-3`}>
                                            <h5 className='text-center'>Stream 2</h5>
                                            <div className='m-3'>
                                                <select 
                                                    className="form-control" 
                                                    name="module2LearnigPath" 
                                                    onChange={ (e) => {
                                                        formik.setFieldValue('module2LearnigPath', e.target.value)
                                                        onPathwayChanges(e, 2)
                                                    }}
                                                    value={formik.values.module2LearnigPath}
                                                >
                                                    <option value="">Select Pathway</option>
                                                    {pathways.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(formik.touched.module2LearnigPath && formik.errors.module2LearnigPath) && (
                                                    <div className="text-danger">
                                                        {formik.errors.module2LearnigPath}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group m-3">
                                                <Form.Control 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Worksheet from"
                                                    name="module2WSFrom" 
                                                    onChange={formik.handleChange} 
                                                    value={formik.values.module2WSFrom}
                                                    disabled={formik.values.module2 == ''}
                                                    onWheel={ (e) => OnWheelPreventChange(e) } 
                                                />
                                                {(formik.touched.module2WSFrom && formik.errors.module2WSFrom) && (
                                                    <div className="text-danger">
                                                        {formik.errors.module2WSFrom}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group m-3">
                                                <Form.Control 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Worksheet to"
                                                    name="module2WSTo" 
                                                    onChange={formik.handleChange} 
                                                    value={formik.values.module2WSTo}
                                                    disabled={formik.values.module2 == ''}
                                                    onWheel={ (e) => OnWheelPreventChange(e) } 
                                                />
                                                {(formik.touched.module2WSTo && formik.errors.module2WSTo) && (
                                                    <div className="text-danger">
                                                        {formik.errors.module2WSTo}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group m-3">
                                                Module Picked: &nbsp;
                                                <label className='col-form-label'>
                                                    { Object.keys(module2Selected).length > 0 && formik.values.module2 != '' ? module2Selected?.module_name + ' (' + module2Selected?.worksheet_from +' - '+ module2Selected?.worksheet_to + ")" : '---' }
                                                </label>
                                            </div>
                                            {(formik.touched.module2 && formik.errors.module2) && (
                                                <div className="text-danger">
                                                    {formik.errors.module2}
                                                </div>
                                            )}
                                            <div className="form-group">
                                                {studentWorkStore?.module2 && (studentWorkStore?.module2).map((module, i) =>(
                                                    <div className="custom-control custom-radio mb-2" key={`module2-${i}`}>
                                                        <div className='row'>
                                                            <Field className="col-sm-2 custom-control-input" 
                                                                type="radio" name="module2"
                                                                id={`module2-${module?.module_id}`}  
                                                                value={module.module_id}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue('module2', module?.module_id)
                                                                    handleOnRadioChange(2, module)
                                                                }}
                                                                checked={module.module_id == formik.values.module2}
                                                            />
                                                            <label className="col-sm-6 custom-control-label" htmlFor={`module2-${module?.module_id}`}>{ module?.module_name }</label>
                                                            <span className="col-sm-4">({ module?.worksheet_from +' - '+ module?.worksheet_to })</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {streamPerWk >= 3 && (
                                        <div className={`col-md-${12/streamPerWk} border-left border-secondary pl-3`}>
                                            <h5 className='text-center'>Stream 3</h5>
                                            <div className='m-3'>
                                                <select 
                                                    className="form-control" 
                                                    name="module3LearnigPath" 
                                                    onChange={ (e) => {
                                                        formik.setFieldValue('module3LearnigPath', e.target.value)
                                                        onPathwayChanges(e, 3)
                                                    }}
                                                    value={formik.values.module3LearnigPath}
                                                >
                                                    <option value="">Select Pathway</option>
                                                    {pathways.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(formik.touched.module3LearnigPath && formik.errors.module3LearnigPath) && (
                                                    <div className="text-danger">
                                                        {formik.errors.module3LearnigPath}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group m-3">
                                                <Form.Control 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Worksheet from"
                                                    name="module3WSFrom" 
                                                    onChange={formik.handleChange} 
                                                    value={formik.values.module3WSFrom}
                                                    disabled={formik.values.module3 == ''}
                                                    onWheel={ (e) => OnWheelPreventChange(e) } 
                                                />
                                                {(formik.touched.module3WSFrom && formik.errors.module3WSFrom) && (
                                                    <div className="text-danger">
                                                        {formik.errors.module3WSFrom}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group m-3">
                                                <Form.Control 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Worksheet to"
                                                    name="module3WSTo" 
                                                    onChange={formik.handleChange} 
                                                    value={formik.values.module3WSTo}
                                                    disabled={formik.values.module3 == ''}
                                                    onWheel={ (e) => OnWheelPreventChange(e) } 
                                                />
                                                {(formik.touched.module3WSTo && formik.errors.module3WSTo) && (
                                                    <div className="text-danger">
                                                        {formik.errors.module3WSTo}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group m-3">
                                                Module Picked: &nbsp;
                                                <label className='col-form-label'>
                                                    { Object.keys(module3Selected).length > 0 && formik.values.module3 != '' ? module3Selected?.module_name + ' (' + module3Selected?.worksheet_from +' - '+ module3Selected?.worksheet_to + ")" : '---' }
                                                </label>
                                            </div>
                                            {(formik.touched.module3 && formik.errors.module3) && (
                                                <div className="text-danger">
                                                    {formik.errors.module3}
                                                </div>
                                            )}
                                            <div className="form-group">
                                                {studentWorkStore?.module3 && (studentWorkStore?.module3).map((module, i) =>(
                                                    <div className="custom-control custom-radio mb-2" key={`module3-${i}`}>
                                                        <div className='row'>
                                                            <Field className="col-sm-2 custom-control-input" 
                                                                type="radio" name="module3"
                                                                id={`module3-${module?.module_id}`}  
                                                                value={module.module_id}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue('module3', module?.module_id)
                                                                    handleOnRadioChange(3, module)
                                                                }}
                                                                checked={module.module_id == formik.values.module3}
                                                            />
                                                            <label className="col-sm-6 custom-control-label" htmlFor={`module3-${module?.module_id}`}>{ module?.module_name }</label>
                                                            <span className="col-sm-4">({ module?.worksheet_from +' - '+ module?.worksheet_to })</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {streamPerWk >= 4 && (
                                        <div className={`col-md-${12/streamPerWk} border-left border-secondary pl-3`}>
                                            <h5 className='text-center'>Stream 4</h5>
                                            <div className='m-3'>
                                                <select 
                                                    className="form-control" 
                                                    name="module4LearnigPath" 
                                                    onChange={ (e) => {
                                                        formik.setFieldValue('module4LearnigPath', e.target.value)
                                                        onPathwayChanges(e, 4)
                                                    }}
                                                    value={formik.values.module4LearnigPath}
                                                >
                                                    <option value="">Select Pathway</option>
                                                    {pathways.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(formik.touched.module4LearnigPath && formik.errors.module4LearnigPath) && (
                                                    <div className="text-danger">
                                                        {formik.errors.module4LearnigPath}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group m-3">
                                                <Form.Control 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Worksheet from"
                                                    name="module4WSFrom" 
                                                    onChange={formik.handleChange} 
                                                    value={formik.values.module4WSFrom}
                                                    disabled={formik.values.module4 == ''}
                                                    onWheel={ (e) => OnWheelPreventChange(e) } 
                                                />
                                                {(formik.touched.module4WSFrom && formik.errors.module4WSFrom) && (
                                                    <div className="text-danger">
                                                        {formik.errors.module4WSFrom}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group m-3">
                                                <Form.Control 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Worksheet to"
                                                    name="module4WSTo" 
                                                    onChange={formik.handleChange} 
                                                    value={formik.values.module4WSTo}
                                                    disabled={formik.values.module4 == ''}
                                                    onWheel={ (e) => OnWheelPreventChange(e) } 
                                                />
                                                {(formik.touched.module4WSTo && formik.errors.module4WSTo) && (
                                                    <div className="text-danger">
                                                        {formik.errors.module4WSTo}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group m-3">
                                                Module Picked: &nbsp;
                                                <label className='col-form-label'>
                                                    { Object.keys(module4Selected).length > 0 && formik.values.module4 != '' ? module4Selected?.module_name + ' (' + module4Selected?.worksheet_from +' - '+ module4Selected?.worksheet_to + ")" : '---' }
                                                </label>
                                            </div>
                                            {(formik.touched.module4 && formik.errors.module4) && (
                                                <div className="text-danger">
                                                    {formik.errors.module4}
                                                </div>
                                            )}
                                            <div className="form-group">
                                                {studentWorkStore?.module4 && (studentWorkStore?.module4).map((module, i) =>(
                                                    <div className="custom-control custom-radio mb-2" key={`module3-${i}`}>
                                                        <div className='row'>
                                                            <Field className="col-sm-2 custom-control-input" 
                                                                type="radio" name="module4"
                                                                id={`module4-${module?.module_id}`}  
                                                                value={module.module_id}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue('module4', module?.module_id)
                                                                    handleOnRadioChange(4, module)
                                                                }}
                                                                checked={module.module_id == formik.values.module4}
                                                            />
                                                            <label className="col-sm-6 custom-control-label" htmlFor={`module4-${module?.module_id}`}>{ module?.module_name }</label>
                                                            <span className="col-sm-4">({ module?.worksheet_from +' - '+ module?.worksheet_to })</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    { !streamPerWk && (
                                        <p className='text-danger'> Please select stream(s) per week. </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        { streamPerWk > 0 && (
                            <Button type="submit" isLoading={isStreamLoading} className="btn btn-primary mr-2">Save </Button>
                        )}
                        <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
                    </Modal.Footer>
                </form>
            </FormikProvider>
            {/* <div style={{display: isStreamLoading ? 'block' : 'none' ,zIndex: 100, background: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100%', position: 'absolute'}}>
                <div className="loader" style={{top: 'calc(50% - 40px)', left: 'calc(50% - 40px)', position: 'fixed'}} />
            </div> */}
        </Modal>
    </div>
  )
}

export default SelectModuleModal;