import React, { useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { deleteSetById, getSetsWithPagination } from '@app/services/set';
import { getAllSubjects } from '@app/services/subject';
import { getAllSubjectModules } from '@app/services/module';
import { getAllBlocks } from '@app/services/block';

const ListSets = () => {

    const [sets, setSets] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [modules, setModules] = useState([]);
    const [blocks, setBlocks] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const canAccess = useSelector((state: any) => state.auth.permissions.sets);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({subjectId: '', moduleId:'', blockId:'', name: '', worksheetFrom: '', worksheetTo: '', status: ''});
    const setStatus = [{value:'active', label:'Active'}, {value:'inactive', label:'Inactive'}]

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    useEffect(() => {
        getAllSetsData(1, perPage, sortField, sortDirection);
    },[search]);

    useEffect(() => {
        getAllSubjectsData();
        getAllModuleData();
        getAllBlocksData();
    },[]);

    const getAllSubjectsData = async () => {
        try {
            const subjectsResp = await getAllSubjects();
            if (subjectsResp.success === true) {
                setSubjects(subjectsResp.collections);
            } else {
                toast.error(subjectsResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllModuleData = async () => {
        try {
            const modulesResp = await getAllSubjectModules();
            if (modulesResp.success === true) {
                setModules(modulesResp.collections);
            } else {
                toast.error(modulesResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllBlocksData = async () => {
        try {
            const resp = await getAllBlocks();
            if (resp.success === true) {
                setBlocks(resp.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllSetsData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const resp = await getSetsWithPagination(page, perPage, sortField, sortDirection, search);
            if (resp.success === true) {
                setLoading(false);
                setSets(resp?.collections?.data);
                setTotalRows(resp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const columns = [
        {
            name: 'Subject',
            selector: (row: { subject_name: string; }) => row.subject_name,
            sortable: true,
            sortField: 'subject_name',
        },
        {
            name: 'Module',
            selector: (row: { module_name: string; }) => row.module_name,
            sortable: true,
            sortField: 'module_name',
        },
        {
            name: 'Set Name',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            sortField: 'name',
            wrap: true
        },
        // {
        //     name: 'Block',
        //     selector: (row: { block_name: string; }) => row.block_name,
        //     sortable: true,
        //     sortField: 'block_name',
        // },
        {
            name: 'WorkSheet From',
            selector: (row: { worksheet_from: string; }) => row.worksheet_from,
            sortable: true,
            sortField: 'worksheet_from',
        },
        {
            name: 'WorkSheet To',
            selector: (row: { worksheet_to: string; }) => row.worksheet_to,
            sortable: true,
            sortField: 'worksheet_to',
        },
        {
            name: 'Status',
            selector: (row: { status: string; }) => row.status,
            cell: (row: { status: string; }) => (
                row.status === 'active' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>
            ),
            sortable: true,
            sortField: 'status',
        },
        // {  
        //     name: 'Action',
        //     cell: (row: { id: number; }) => (
        //       <>
        //         { canAccess?.edit &&
        //             <Link
        //             to={`/sets/edit/${row.id}`}
        //             role="button"
        //             state={{ id: row.id }}
        //             >
        //             <i className="fas fa-pen mr-2"/>
        //             </Link>
        //         }
        //         { canAccess?.delete &&
        //             <a href='#' onClick={ (e) => { deleteItemConfirm(e, row.id)} }>
        //                 <i className="fas fa-trash mr-2"/>
        //             </a>
        //         }
        //       </>
        //     ),
        // },
    ];

    const ExpandedComponent = ({ data }) => <div className='border border-secondary rounded'>
            <div className="row m-2" style={{fontSize: '14px'}}>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Block </label>
                        <dd>{data?.block_name ? data?.block_name : '---'}</dd>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Description </label>
                        <dd>{data?.description ? data?.description : '---'}</dd>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Type </label>
                        <dd>{data?.type ? data?.type : '---'}</dd>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Sequence </label>
                        <dd>{data?.sequence ? data?.sequence : '---'}</dd>
                    </div>
                </div>
            </div>
        </div>;

    const deleteItemConfirm = (e, id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to delete this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteItem(id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const deleteItem = async (id) => {
        try {
            setLoading(true);
            const resp = await deleteSetById(id);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                getAllSetsData(currentPage, perPage, sortField, sortDirection);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const handlePageChange = page => {
        getAllSetsData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllSetsData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllSetsData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        setSearch({subjectId: '', moduleId:'', blockId:'', name: '', worksheetFrom: '', worksheetTo: '', status: ''});
    };

    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manage Sets</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active">Manage Sets</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    <div className="col-lg-12 text-right">
                                    {/* { canAccess?.create &&
                                        <Link className="btn btn-primary" to="/sets/add" > + Add New Set </Link>
                                    } */}
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Manage Sets</h3>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="subjectId"
                                                    onChange={(e) => setSearch({ ...search, subjectId: e.target.value })}
                                                    value={search.subjectId}
                                                >
                                                    <option value="">Search By Subject</option>
                                                    {subjects.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="moduleId"
                                                    onChange={(e) => setSearch({ ...search, moduleId: e.target.value })}
                                                    value={search.moduleId}
                                                >
                                                    <option value="">Search By Module</option>
                                                    {modules.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='name' className="form-control" value={search.name} onChange={(e) => setSearch({ ...search, name: e.target.value })} placeholder="Search By Set Name" />
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='worksheetFrom' className="form-control" value={search.worksheetFrom} onChange={(e) => setSearch({ ...search, worksheetFrom: e.target.value })} placeholder="Worksheet From" />
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='worksheetTo' className="form-control" value={search.worksheetTo} onChange={(e) => setSearch({ ...search, worksheetTo: e.target.value })} placeholder="Worksheet To" />
                                            </div>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={(e) => setSearch({ ...search, status: e.target.value })}
                                                    value={search.status}
                                                >
                                                    <option value="">Search By Status</option>
                                                    {setStatus.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="blockId"
                                                    onChange={(e) => setSearch({ ...search, blockId: e.target.value })}
                                                    value={search.blockId}
                                                >
                                                    <option value="">Search By Block</option>
                                                    {blocks.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-sm-2'>
                                                <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                            </div>
                                        </div>

                                        <DataTable
                                            columns={columns}
                                            data={sets}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationDefaultPage={currentPage}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            paginationPerPage={perPage}
                                            paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                            progressPending={isLoading}
                                            sortServer
                                            onSort={handleSort}
                                            // paginationComponent
                                            // selectableRows
                                            expandableRows
                                            expandableRowsComponent={ExpandedComponent}
                                            // expandableRowExpanded={rowPreExpanded}
                                            customStyles={customStyle}
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>

        </div>
    )
};

export default ListSets;