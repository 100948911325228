import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import { Modal } from 'react-bootstrap';
import Select from "react-select";
import { checkStudentCountExist, createStudentCount, updateStudentCount } from '@app/services/studentCount';
import { getSubjectsByFranchise } from '@app/services/franchise';
import { useSelector } from 'react-redux';

const AddStudentCountsModal = (props) => {

    const [isLoading, setLoading] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [postcodes, setPostcodes] = useState('');
    const [btnText, setBtnText] = useState('Add');
    const [payload, setPayload] = useState({franchise_id: '', month: new Date().getMonth() + 1, year: new Date().getFullYear(), maths: 0, english: 0, science: 0});
    const months = [{id: 1, name:'January'}, {id: 2, name:'February'}, {id: 3, name:'March'}, 
                    {id: 4, name:'April'}, {id: 5, name:'May'}, {id: 6, name:'June'},
                    {id: 7, name:'July'},{id: 8, name:'August'},{id: 9, name:'September'},
                    {id: 10, name:'October'}, {id: 11, name:'November'}, {id: 12, name:'December'}];
    const years = Array.from( { length: 20 }, (_, index) => new Date().getFullYear() - index );
    const sub = {maths: false, english: false, science: false};
    const [subjects, setSubjects] = useState(sub);
    const canAccess = useSelector((state: any) => state.auth.permissions?.['student-count']);

    const getAllActiveSubjectsData = async (franchise_id) => {
        try {
            setLoading(true);
            const resp = await getSubjectsByFranchise(franchise_id);
            if (resp.success === true) {
                setLoading(false);
                if(resp.collections){
                    (resp.collections).map((subject)=> {
                        switch(subject?.name){
                            case 'Mathematics': sub.maths = subject.status == 'active' ? false : true;
                                                // if(subject.status === 'inactive'){
                                                //     setPayload({ ...payload, maths: 0});
                                                // }
                                                break;
                            case 'English': sub.english = subject.status == 'active' ? false : true;
                                            // if(subject.status === 'inactive'){
                                            //     setPayload({ ...payload, english: 0});
                                            // }
                                            break; 
                            case 'Science': sub.science = subject.status == 'active' ? false : true;
                                            // if(subject.status === 'inactive'){
                                            //     setPayload({ ...payload, science: 0});
                                            // }
                                            break; 
                            default:  break; 
                        }
                    })
                    setSubjects(sub);
                }
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const addStudentCountAPI = async (payload) => {
        try {
            setLoading(true);
            const resp = await createStudentCount(payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                props.handleClose();
                props.handleFetchAgain();
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const checkStudentCountExistAPI = async (payload) => {
        try {
            setLoading(true);
            const resp = await checkStudentCountExist(payload);
            if(resp.success === true){
                setLoading(false);
                if(resp?.collections?.maths){
                    // toast.success(resp?.message);
                    setPayload({ ...payload, maths: resp?.collections?.maths, english: resp?.collections?.english, science: resp?.collections?.science});
                    if(canAccess?.['edit-student-count']){
                        setErrorMsg('The count has been already added for this month. You can update it from this screen')
                        setBtnText('Update');
                    }else{
                        setErrorMsg('The count has been already added for this month. Please contact Admin to update.')
                        setBtnDisable(true)
                    }
                }else{
                    setErrorMsg('')
                    setPayload({ ...payload, maths: '', english: '', science: ''});
                    setBtnText('Add');
                    setBtnDisable(false)
                }
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
            if(values.franchise_id != ""){
                getAllActiveSubjectsData(values.franchise_id);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const updateStudentCountAPI = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateStudentCount(payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                props.handleClose();
                props.handleFetchAgain();
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue, resetForm } = useFormik({
        initialValues: {
            franchise_id: payload?.franchise_id,
            month: payload?.month,
            year: payload?.year,
            maths: payload?.maths,
            english: payload?.english,
            science: payload?.science,
            total: 0,
        },
        validationSchema: Yup.object({
            franchise_id: Yup.string().required('Please select franchise'),
            month: Yup.string().required('Please select month'),
            year: Yup.string().required('Please select year'),
            // maths: Yup.string().required('Please enter maths count'),
            // english: Yup.string().required('Please enter english count'),
            // science: Yup.string().required('Please enter science count'),
            // total: Yup.string().required('Please enter total count'),
            maths: Yup.string().when([], {
                is: () => !subjects?.maths,
                then: Yup.string().required('Please enter maths count'),
                otherwise: Yup.string(),
            }),
            english: Yup.string().when([], {
                is: () => !subjects?.english,
                then: Yup.string().required('Please enter english count'),
                otherwise: Yup.string(),
            }),
            science: Yup.string().when([], {
                is: () => !subjects?.science,
                then: Yup.string().required('Please enter science count'),
                otherwise: Yup.string(),
            }),
        }),
        onSubmit: (values) => {
            values.total = values.maths + values.english + values.science;
            if(btnText === 'Add'){
                addStudentCountAPI(values);
            }else{
                updateStudentCountAPI(values);
            }
            setErrorMsg('');
            setPayload({ ...payload, maths: 0, english: 0, science: 0});
            resetForm();
        },
        enableReinitialize: true,
    });

    const handleStudentCountExist = (input, e) => {
        if(input === 'franchise_id'){
            setFieldValue('franchise_id', e.id)
            values[input] = e.id;
            setPostcodes((e.postcodes).length > 0 ? ( Array.prototype.map.call(e.postcodes, function(postcode) { return postcode.name; }).join(", ") ) : '')
        }else{
            setFieldValue(input, e.target.value)
            values[input] = e.target.value;
        }
        checkStudentCountExistAPI(values);
    };

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{ btnText } Students Count</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className='row'>
                        <span className='text-danger mb-2'>{ errorMsg }</span>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Franchise <span className="required">*</span></label>
                                <Select
                                    // ref={selectRef}
                                    name="franchiseId"
                                    placeholder="Search By Franchise"
                                    options={props.franchises}
                                    getOptionValue={(option)=>`${option['id']}`}
                                    getOptionLabel={(option)=> option['name'] }
                                    onChange={ (option) => { handleStudentCountExist('franchise_id', option)}}
                                    // onInputChange={ (option) => {onChangeFranchise(option)}}
                                    // value={search.franchiseId}
                                    // styles={customStylesSelect}
                                />
                                <span className='text-muted'> { postcodes }</span>
                                {(touched.franchise_id && errors.franchise_id) && (
                                    <div className="text-danger">
                                        {errors.franchise_id}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Month <span className="required">*</span></label>
                                <select
                                    className="form-control"
                                    name="month"
                                    onChange={(e) => {handleStudentCountExist('month', e)}} 
                                    value={values.month}
                                >
                                    <option value="">--Please select--</option>
                                    {months.map((obj) => (
                                        <option key={obj.name} value={obj.id}>
                                            {obj.name}
                                        </option>
                                    ))}
                                </select>
                                {(touched.month && errors.month) && (
                                    <div className="text-danger">
                                        {errors.month}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Year <span className="required">*</span></label>
                                <select
                                    className="form-control"
                                    name="year"
                                    onChange={(e) => {handleStudentCountExist('year', e)}} 
                                    value={values.year}
                                >
                                    <option value="">--Please select--</option>
                                    {years.map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </select>
                                {(touched.year && errors.year) && (
                                    <div className="text-danger">
                                        {errors.year}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="maths">Mathematics { !subjects?.maths && <span className="required">*</span> }</label>
                                <input type="number" className="form-control" id="maths" placeholder="Enter mathematics count" 
                                    name="maths" 
                                    onChange={handleChange} 
                                    value={values.maths}
                                    disabled={subjects?.maths}
                                />
                                {(touched.maths && errors.maths) && (
                                    <div className="text-danger">
                                        {errors.maths}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="english">English { !subjects?.english && <span className="required">*</span> }</label>
                                <input type="number" className="form-control" id="english" placeholder="Enter english count" 
                                    name="english" 
                                    onChange={handleChange} 
                                    value={values.english}
                                    disabled={subjects?.english}
                                />
                                {(touched.english && errors.english) && (
                                    <div className="text-danger">
                                        {errors.english}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="science">Science { !subjects?.science && <span className="required">*</span> }</label>
                                <input type="number" className="form-control" id="science" placeholder="Enter science count" 
                                    name="science" 
                                    onChange={handleChange} 
                                    value={values.science}
                                    disabled={subjects?.science}
                                />
                                {(touched.science && errors.science) && (
                                    <div className="text-danger">
                                        {errors.science}
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Total <span className="required">*</span></label>
                                <input type="number" className="form-control" id="exampleInputEmail1" placeholder="Enter total count" 
                                    name="total" 
                                    onChange={handleChange} 
                                    value={values.total}
                                />
                                {(touched.total && errors.total) && (
                                    <div className="text-danger">
                                        {errors.total}
                                    </div>
                                )}
                            </div>
                        </div> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2" disabled={btnDisable}>{ btnText }</Button>
                    <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    </div>
  )
}

export default AddStudentCountsModal;