import axios from "axios";
import { attachFranchiseURL, createPostcodeURL, deletePostcodeURL, detachFranchiseURL, getAllActivePostcodesURL, getAllCountriesURL, getAllPostcodesURL, getDemographicDeatailsByPostcodeURL, getPostcodeByIdURL, getPostcodesByFranchiseURL, getPostcodesHistoryURL, renewPostcodeFranchiseURL, updateFavouritePostcodeURL, updatePostcodeURL } from "@app/constants/ApiEndPoints";

export const getAllPostcodes = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(getAllPostcodesURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}
															&name=${search.name}&status=${search.status}&country=${search.country}&region=${search.region}&area=${search.area}&franchiseId=${search.franchiseId}&favourite=${search.favourite}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllCountries = async () => {
	try {
		const response = await axios.get(getAllCountriesURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const createPostcode = async (payload) => {
	try {
		const response = await axios.post(createPostcodeURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getPostcodeById = async (id: number) => {
	try {
		const response = await axios.get(getPostcodeByIdURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updatePostcode = async (id, payload) => {
	try {
		const response = await axios.put(updatePostcodeURL + id, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const deletePostcodeById = async (id: number) => {
	try {
		const response = await axios.delete(deletePostcodeURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};

export const attachFranchise = async (payload) => {
	try {
		const response = await axios.post(attachFranchiseURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const detachFranchise = async (payload) => {
	try {
		const response = await axios.post(detachFranchiseURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const renewPostcodeFranchise = async (payload) => {
	try {
		const response = await axios.post(renewPostcodeFranchiseURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getPostcodesByFranchiseId = async (id) => {
	try {
		const response = await axios.get(getPostcodesByFranchiseURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getPostcodeHistoryById = async (id, page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(getPostcodesHistoryURL + `${id}?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}
															&name=${search.name}&country=${search.country}&region=${search.region}&area=${search.area}&franchiseId=${search.franchiseId}&status=${search.status}&action=${search.action}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getDemographicDeatailsByPostcode = async (postcode) => {
	try {
		const response = await axios.get(getDemographicDeatailsByPostcodeURL + postcode);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateFavouritePostcode = async (payload) => {
	try {
		const response = await axios.post(updateFavouritePostcodeURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllActivePostcodes = async () => {
	try {
		const response = await axios.get(getAllActivePostcodesURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};