import { Button } from '@components';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import React, { useState, useEffect } from 'react'
import { getUserById, updateUserProfile } from '@app/services/user';
import { toast } from 'react-toastify';

const ActivityTab = ({ isActive }: { isActive: boolean }) => {
	let obj: any = {}
	const id = useSelector((state: any) => state.auth.currentUser.id);
	const [isLoading, setLoading] = useState(false);
	const [user, setUserData] = useState(obj);

	useEffect(() => {
        getUserDetailsById(id);
    }, []);

	const getUserDetailsById = async (id) => {
        try {
            const resp = await getUserById(id);
            if(resp.success === true){
                setUserData(resp.collections);
            }else{
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

	const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            first_name: user?.first_name || '',
            last_name: user?.last_name || '',
            email: user?.email || '',
            alt_email: user?.alt_email || '',
            phone: user?.phone || '',
            alt_phone: user?.alt_phone || '',
            address: user?.address || '',
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required('Please enter first name'),
            last_name: Yup.string().required('Please enter last name'),
            email: Yup.string()
                .required("Please enter email address")
                .email("Enter valid email"),
            alt_email: Yup.string()
                .email("Enter valid email"),
            phone: Yup.string().required('Please enter mobile number')
            .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                "Please enter valid number"
            ),
            alt_phone: Yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
            "Please enter valid number"
            ),
            address: Yup.string().required('Please enter address'),
        }),
        onSubmit: (values) => {
            updateProfile(values);
        },
        enableReinitialize: true,
    });

	const updateProfile = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateUserProfile(payload);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };
	
	return (
		<div className={`tab-pane ${isActive ? 'active' : ''}`}>
			<form className="form-horizontal"  onSubmit={handleSubmit}>
				<div className="form-group row">
					<label htmlFor="inputName" className="col-sm-2 col-form-label">
						First Name <span className="required">*</span>
					</label>
					<div className="col-sm-10">
						<input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter first name" 
							name="first_name" 
							onChange={handleChange} 
							value={values.first_name}
						/>
						{(touched.first_name && errors.first_name) && (
							<div className="text-danger">
								{errors.first_name}
							</div>
						)}
					</div>
				</div>
				<div className="form-group row">
					<label htmlFor="inputName" className="col-sm-2 col-form-label">
						Last Name <span className="required">*</span>
					</label>
					<div className="col-sm-10">
						<input type="text" className="form-control" id="exampleInputPassword1" placeholder="Enter last name" 
							name="last_name" 
							onChange={handleChange} 
							value={values.last_name}
						/>
						{(touched.last_name && errors.last_name) && (
							<div className="text-danger">
								{errors.last_name}
							</div>
						)}
					</div>
				</div>
				<div className="form-group row">
					<label htmlFor="inputEmail" className="col-sm-2 col-form-label">
						Email <span className="required">*</span>
					</label>
					<div className="col-sm-10">
						<input type="email" className="form-control" id="exampleInputEmail1" placeholder="Enter email" 
							name="email" 
							onChange={handleChange}
							value={values.email}
							disabled
							readOnly
						/>
						{(touched.email && errors.email) && (
							<div className="text-danger">
								{errors.email}
							</div>
						)}
					</div>
				</div>
				<div className="form-group row">
					<label htmlFor="exampleInputEmail1" className="col-sm-2 col-form-label">Alternate Email </label>
					<div className="col-sm-10">
						<input type="email" className="form-control" id="exampleInputEmail1" placeholder="Enter alternate email" 
							name="alt_email" 
							onChange={handleChange}
							value={values.alt_email}
						/>
						{(touched.alt_email && errors.alt_email) && (
							<div className="text-danger">
								{errors.alt_email}
							</div>
						)}
					</div>
				</div>
				<div className="form-group row">
					<label htmlFor="exampleInputPassword1" className="col-sm-2 col-form-label">Mobile <span className="required">*</span></label>
					<div className="col-sm-10">
						<input type="text" className="form-control" id="exampleInputPassword1" placeholder="Enter mobile number" 
							name="phone" 
							onChange={handleChange} 
							value={values.phone}
						/>
						{(touched.phone && errors.phone) && (
							<div className="text-danger">
								{errors.phone}
							</div>
						)}
					</div>
				</div>
				<div className="form-group row">
					<label htmlFor="inputName2" className="col-sm-2 col-form-label">
						Alternate Mobile 
					</label>
					<div className="col-sm-10">
						<input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter alternate number" 
							name="alt_phone" 
							onChange={handleChange} 
							value={values.alt_phone}
						/>
						{(touched.alt_phone && errors.alt_phone) && (
							<div className="text-danger">
								{errors.alt_phone}
							</div>
						)}
					</div>
				</div>
				<div className="form-group row">
					<label htmlFor="inputExperience" className="col-sm-2 col-form-label">
						Address <span className="required">*</span>
					</label>
					<div className="col-sm-10">
						<textarea 
							className="form-control" 
							id="exampleInputEmail1" 
							placeholder="Enter address" 
							name="address" 
							onChange={handleChange} 
							value={values.address}
						></textarea>
						{(touched.address && errors.address) && (
							<div className="text-danger">
								{errors.address}
							</div>
						)}
					</div>
				</div>
				<div className="form-group row">
					<div className="offset-sm-2 col-sm-10">
						<Button type="submit" theme="primary" isLoading={isLoading}>
							Submit
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default ActivityTab;
