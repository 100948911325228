import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import {Form} from 'react-bootstrap';
import {toast} from 'react-toastify';
import { Button } from '@app/components';
import { detachFranchise, getPostcodeById, renewPostcodeFranchise } from '@app/services/postcode';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import moment from 'moment';

const DetachFranchise = () => {

    let obj: any = {}
    let { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [postcodeDetails, setPostcodeDetails] = useState(obj);
    const [terminateDateShow, setTerminateDateShow] = useState(false);
    const [renewDateShow, setRenewDateShow] = useState(false);
    const [action, setAction] = useState('');
    const postcodeACtion = [ 
        {label:'Renew', value:'renew'},
        {label:'Terminate', value:'terminate'},
    ]

    useEffect(() => {
        getPostcodeDetailsById(id);
    },[]);

    const getPostcodeDetailsById = async (id) => {
        try {
            setLoading(true);
            const resp = await getPostcodeById(id);
            if(resp?.success === true){
                setLoading(false);
                setPostcodeDetails(resp?.collections);
                if(resp?.collections?.end_date !== null && resp?.collections?.renew_date !== null){
                    setTerminateDateShow(false);
                    setRenewDateShow(true);
                    setAction('renew');
                }else if(resp?.collections?.end_date !== null && resp?.collections?.renew_date === null){
                    setTerminateDateShow(true);
                    setRenewDateShow(false);
                    setAction('terminate');
                }
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
          }
    };

    const detachFranchiseFromPostcode = async (payload) => {
        try {
            setLoading(true);
            const resp = await detachFranchise(payload);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                navigate('/postcodes');
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const renewPostcodeFranchiseAPI = async (payload) => {
        try {
            setLoading(true);
            const resp = await renewPostcodeFranchise(payload);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                navigate('/postcodes');
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue} = useFormik({
        initialValues: {
            action: action,
            end_date: postcodeDetails?.end_date || '',
            renew_date: postcodeDetails?.renew_date || '',
        },
        validationSchema: Yup.object({
            action: Yup.string().required('Please select action'),
            renew_date: Yup.date().when("action", {
                is: (val: string) => (val === 'renew'),
                then:  Yup.date().required('Please enter renew date')
                .min(new Date(moment(postcodeDetails?.start_date !== null ? postcodeDetails?.start_date : new Date()).format('YYYY-MM-DD')), 'Date should be greater than start date'),
            }),
            end_date: Yup.date().when("action", {
                is: (val: string) => (val === 'terminate'),
                then:  Yup.date().required('Please enter terminate date')
                .min(new Date(moment(postcodeDetails?.start_date !== null ? postcodeDetails?.start_date : new Date()).format('YYYY-MM-DD')), 'Date should be greater than start date'),
            }),
        }),
        onSubmit: (values) => {
            if(values.action === 'renew'){
                renewPostcodeFranchiseAPI({
                    postcode_id: id,
                    franchise_id: postcodeDetails.franchise_id,
                    renew_date: moment(values.renew_date).format('YYYY-MM-DD'),
                })
            }

            if(values.action === 'terminate'){
                detachFranchiseFromPostcode({
                    postcode_id: id,
                    franchise_id: postcodeDetails.franchise_id,
                    end_date: moment(values.end_date).format('YYYY-MM-DD'),
                })
            }
        },
        enableReinitialize: true,
    });

    const actionChange = (e, setFieldValue) => {
        if(e.target.value === 'renew'){
            setRenewDateShow(true);
            setTerminateDateShow(false);
        }else if(e.target.value === 'terminate'){
            setTerminateDateShow(true);
            setRenewDateShow(false);
        }else{
            setTerminateDateShow(false);
            setRenewDateShow(false);
        }
        setFieldValue("action", e.target.value);
    };

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Renew / Terminate Franchise</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row" style={{borderBottom: '1px solid #d1d1d1'}}>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Postcode </label>
                                                <Form.Control plaintext readOnly value={postcodeDetails.name} />
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Franchise </label>
                                                <Form.Control plaintext readOnly value={postcodeDetails.franchise_name} />
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Start Date </label>
                                                <Form.Control plaintext readOnly value={moment(postcodeDetails.start_date).format('DD/MM/YYYY')} />
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Status </label>
                                                <Form.Control plaintext readOnly value={ postcodeDetails.status } />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Action <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="action"
                                                    onChange={ e => actionChange(e, setFieldValue) } 
                                                    value={values.action}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {postcodeACtion.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.action && errors.action) && (
                                                    <div className="text-danger">
                                                        {errors.action}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        { terminateDateShow && (
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Terminate Date <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="date" 
                                                    name="end_date" 
                                                    id="end_date" 
                                                    placeholder="Enter cease date" 
                                                    value={moment(values.end_date).format('YYYY-MM-DD')}
                                                    onChange={handleChange}
                                                />
                                                {(touched.end_date && errors.end_date) && (
                                                    <div className="text-danger">
                                                        {errors.end_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        )}

                                        { renewDateShow && (
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Renew Date <span className="required">*</span></label>
                                                    <Form.Control 
                                                        type="date" 
                                                        name="renew_date" 
                                                        id="renew_date" 
                                                        placeholder="Enter cease date" 
                                                        value={moment(values.renew_date).format('YYYY-MM-DD')}
                                                        onChange={handleChange}
                                                    />
                                                    {(touched.renew_date && errors.renew_date) && (
                                                        <div className="text-danger">
                                                            {errors.renew_date}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Save </Button>
                                    <Link className="btn btn-default" to='/postcodes'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default DetachFranchise
