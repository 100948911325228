import "../../pages/order-form/OrderForm.css";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import { clearCart, setCartAmt, setStep } from "@app/store/reducers/order";
import { getCartInvoiceByFranchise, placeOrder } from "@app/services/order";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CurrencyFormat from 'react-currency-format';
import { useNavigate } from "react-router-dom";
import { Button } from "@app/components";

const Invoice = () => {
	const [isInvoiceLoading, setInvoiceLoading] = useState(false);
	const [cartInvoice, setCartInvoice] = useState([]);
	const [payableAmt, setPayableAmt] = useState(0);
	const [netAmt, setNetAmt] = useState(0);
	const [vatAmt, setVatAmt] = useState(0);
	const franchise = useSelector((state: any) => state.order.franchise);
	const dispatch = useDispatch();
    const navigate = useNavigate();

	useEffect(() => {
		getCartInvoiceDataByFranchise(franchise?.id);
	}, [])

	const getCartInvoiceDataByFranchise = async (franchiseId) => {
        try {
            setInvoiceLoading(true);
            const resp = await getCartInvoiceByFranchise(franchiseId);
            if (resp?.success === true) {
				setInvoiceLoading(false);
				setCartInvoice(resp?.collections);
				setPayableAmt(resp?.collections?.total?.payable)
				dispatch(setCartAmt(resp?.collections?.total?.payable))
				setNetAmt(resp?.collections?.total?.net)
				setVatAmt(resp?.collections?.total?.vat)
				// dispatch(setStep(3));
            } else {
				setInvoiceLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setInvoiceLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

	const placeOrderData = async () => {
        try {
			setInvoiceLoading(true);
			const resp = await placeOrder(franchise?.id, {contact:franchise?.phone, address:franchise?.address, default_order:franchise?.default_order, handbook_binding:franchise?.handbook_binding});
			if(resp?.success === true){
				setInvoiceLoading(false);
				toast.success(resp?.message);
				dispatch(setStep(1));
				dispatch(clearCart());
				navigate('/in-progress-orders');
			}else{
				toast.error(resp?.message);
				setInvoiceLoading(false);
				dispatch(setStep(3));
			}
		} catch (error: any) {
			setInvoiceLoading(false);
			toast.error(error?.response?.message || 'Failed');
        }
    };

	const handleBack = () => {
		dispatch(setStep(2));
	}

	const handleSubmit = () => {
		dispatch(setStep(5));
	}

	return (
		<div className="col-md-12">
			<section className="content">
				<div className="container-fluid table_container">
					<Table responsive className="invoice_wrapper">
						<thead>
							<tr>
								<th>
									<b>INVOICE</b>
								</th>
								<th>
									<b>Order Reference</b>
								</th>
								<th></th>
								<th colSpan={3}>
									<b>Date as reference</b>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colSpan={6} style={{ height: "20px", padding: "0" }}>
									&nbsp;
								</td>
							</tr>
							<tr>
								<td
									colSpan={2}
									style={{
										border: "1px solid #000000",
										borderBottom: "0px",
									}}
								>
									<b>Please send your internet banking payment to:</b>
								</td>
								<td>&nbsp;</td>
								<td
									style={{
										borderTop: "1px solid #000000",
										borderLeft: "1px solid #000000",
										borderRight: "1px solid #000000",
									}}
								><b>{ franchise?.name }</b></td>
								<td rowSpan={6} >
									<img
										src="../img/FCL-logo.png"
										alt="Logo"
										style={{ maxWidth: "150px" }}
									/>
								</td>
							</tr>
							<tr>
								<td
									style={{
										borderLeft: "1px solid #000000",
									}}
								>
									<b>Bank:</b>
								</td>
								<td
									style={{
										borderRight: "1px solid #000000",
									}}
								>
									<b>HSBC</b>
								</td>
								<td>&nbsp;</td>
								<td rowSpan={2}
									style={{
										borderLeft: "1px solid #000000",
										borderRight: "1px solid #000000",
									}}
								>
									{ franchise?.address } <br />
									{/* <span>VAT Reg No. 103791431</span> */}
								</td>
							</tr>
							<tr>
								<td
									style={{
										borderLeft: "1px solid #000000",
									}}
								>
									<b>Account Name:</b>
								</td>
								<td
									style={{
										borderRight: "1px solid #000000",
									}}
								>
									<b>First Class Learning Orders</b>
								</td>
								<td>&nbsp;</td>
								{/* <td rowspan="4">VAT Reg No. 103791431</td> */}
							</tr>
							<tr>
								<td
									style={{
										borderLeft: "1px solid #000000",
									}}
								>
									<b>Sort Code:</b>
								</td>
								<td
									style={{
										borderRight: "1px solid #000000",
									}}
								>
									<b>40-33-01</b>
								</td>
								<td>&nbsp;</td>
								<td
									style={{
										borderBottom: "1px solid #000000",
										borderLeft: "1px solid #000000",
										borderRight: "1px solid #000000",
									}}
								><b>Kashflow Order Account:</b> { cartInvoice['franchise']?.kashflow_order_account ?? '---'}</td>
							</tr>
							<tr>
								<td
									style={{
										borderLeft: "1px solid #000000",
										borderBottom: "1px solid #000000",
									}}
								>
									<b>Account No:</b>
								</td>
								<td
									style={{
										borderRight: "1px solid #000000",
										borderBottom: "1px solid #000000",
									}}
								>
									<b>32884852</b>
								</td>
								<td>&nbsp;</td>
							</tr>
							{/* <tr>
								<td
									style={{
										borderLeft: "1px solid #000000",
										borderBottom: "1px solid #000000",
									}}
								>
									<b>Reference:</b>
								</td>
								<td
									style={{
										borderRight: "1px solid #000000",
										borderBottom: "1px solid #000000",
									}}
								>
									<b>Please quote your order reference</b>
								</td>
								<td>&nbsp;</td>
							</tr> */}
							<tr>
								<td colSpan={5} style={{ padding: "30px 10px", color: "red" }}>
									<b>
										Free processing on orders of £100 and over (excluding VAT) in your first year.
									</b>
								</td>
							</tr>
							<tr>
								<td colSpan={6} style={{ padding: "0" }}>
									<Table className="invoice_inner_wrapper">
										<thead>
											<tr>
												<th>
													<b>Qty</b>
												</th>
												<th>
													<b>Description</b>
												</th>
												<th>
													<b>Unit Cost</b>
												</th>
												<th>
													<b>VAT%</b>
												</th>
												<th>
													<b>Net</b>
												</th>
												<th>
													<b>VAT</b>
												</th>
											</tr>
										</thead>
										<tbody>
											{ cartInvoice['sets'] && (
												<>
													{(cartInvoice['sets']).map((set,i) => (
													<tr key={i}>
														<td>{ set?.qty }</td>
														<td>{ set?.subject } - { set?.description }</td>
														<td><CurrencyFormat value={ (set?.unit_cost).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></td>
														<td>{ (set?.vat_percent).toFixed(2) }%</td>
														<td><CurrencyFormat value={ (set?.net).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></td>
														<td><CurrencyFormat value={ (set?.vat_amount).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></td>
													</tr>
													))}
												</>
											)}
											{ cartInvoice['handbooks'] && (
												<tr>
													<td>{ cartInvoice['handbooks']['qty'] }</td>
													<td>{ cartInvoice['handbooks']['description'] }</td>
													<td><CurrencyFormat value={ (cartInvoice['handbooks']['unit_cost']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></td>
													<td>{ (cartInvoice['handbooks']['vat_percent']).toFixed(2) }%</td>
													<td><CurrencyFormat value={ (cartInvoice['handbooks']['net']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></td>
													<td><CurrencyFormat value={ (cartInvoice['handbooks']['vat_amount']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></td>
												</tr>
											)}
											{ cartInvoice['handbook_suppliments'] && (
												<tr>
													<td>{ cartInvoice['handbook_suppliments']['qty'] }</td>
													<td>{ cartInvoice['handbook_suppliments']['description'] }</td>
													<td><CurrencyFormat value={ (cartInvoice['handbook_suppliments']['unit_cost']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></td>
													<td>{ (cartInvoice['handbook_suppliments']['vat_percent']).toFixed(2) }%</td>
													<td><CurrencyFormat value={ (cartInvoice['handbook_suppliments']['net']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></td>
													<td><CurrencyFormat value={ (cartInvoice['handbook_suppliments']['vat_amount']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></td>
												</tr>
											)}
											{ (cartInvoice['otherItems']) && (
												<>
												{(cartInvoice['otherItems']).map((item,i) => (
													<tr key={i}>
														<td>{ item?.qty }</td>
														<td>{ item?.description }</td>
														<td><CurrencyFormat value={ (item?.unit_cost).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></td>
														<td>{ (item?.vat_percent).toFixed(2) }%</td>
														<td><CurrencyFormat value={ (item?.net).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></td>
														<td><CurrencyFormat value={ (item?.vat_amount).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></td>
													</tr>
												))}
												</>
											)}
											{ cartInvoice['processing_charge_standard'] && (
												<tr>
													<td>{ cartInvoice['processing_charge_standard']['qty'] }</td>
													<td>{ cartInvoice['processing_charge_standard']['description'] }</td>
													<td><CurrencyFormat value={ (cartInvoice['processing_charge_standard']['unit_cost']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></td>
													<td>{ (cartInvoice['processing_charge_standard']['vat_percent']).toFixed(2) }%</td>
													<td><CurrencyFormat value={ (cartInvoice['processing_charge_standard']['net']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></td>
													<td><CurrencyFormat value={ (cartInvoice['processing_charge_standard']['vat_amount']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></td>
												</tr>
											)}
											{ cartInvoice['processing_charge_first_year'] && (
												<tr>
													<td>{ cartInvoice['processing_charge_first_year']['qty'] }</td>
													<td>{ cartInvoice['processing_charge_first_year']['description'] }</td>
													<td><CurrencyFormat value={ (cartInvoice['processing_charge_first_year']['unit_cost']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></td>
													<td>{ (cartInvoice['processing_charge_first_year']['vat_percent']).toFixed(2) }%</td>
													<td><CurrencyFormat value={ (cartInvoice['processing_charge_first_year']['net']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></td>
													<td><CurrencyFormat value={ (cartInvoice['processing_charge_first_year']['vat_amount']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></td>
												</tr>
											)}
										</tbody>
									</Table>
								</td>
							</tr>
							<tr>
								<td colSpan={6} style={{ padding: "0" }}>
									<Table className="invoice_inner_wrapper total_table mb-0">
										<thead>
											<tr>
												<th colSpan={4} style={{ color: "red", textAlign: "left" }}>
													<b>Free processing on orders of £250 and over (excluding VAT)</b>
												</th>
												<th>
													<b>Net:</b>
												</th>
												<th>
													<CurrencyFormat value={ netAmt.toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} />
												</th>
											</tr>
											<tr>
												<th colSpan={4} style={{ color: "red" }}>

												</th>
												<th>
													<b>VAT:</b>
												</th>
												<th>
													<CurrencyFormat value={ vatAmt.toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} />
												</th>
											</tr>
										</thead>
									</Table>
								</td>
							</tr>
							<tr>
								<td colSpan={6} style={{ padding: "0" }}>
									<Table className="invoice_inner_wrapper payment_table">
										<thead>
											<tr>
												<th colSpan={4} style={{ textAlign: "left" }}>
													<b>Please note that your order cannot be processed until payment is received.</b> <br />
													{/* <b>Please quote the order reference on this invoice when making payment.</b> */}
												</th>
												<th style={{ verticalAlign: "middle" }}>
													<b>TOTAL PAYABLE:</b>
												</th>
												<th style={{ verticalAlign: "middle" }}>
													<b><CurrencyFormat value={ payableAmt.toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /></b>
												</th>
											</tr>
										</thead>
									</Table>
								</td>
							</tr>
							<tr>
								<td colSpan={6}>
									<Table responsive className="invoice_wrapper">
										<tbody>
											<tr>
												<td><b>First Class Learning Ltd</b></td>
												<td>&nbsp;</td>
											</tr>
											<tr>
												<td rowSpan={4}>4th Floor The Blade, Abbey Street, Reading, RG1 3BE <br />
														<span>VAT Reg No. 103791431</span>
												</td>
											</tr>
										</tbody>
									</Table>
								</td>
							</tr>
						</tbody>
					</Table>
					<div className="mt-4 d-flex justify-content-end align-items-center">
						{/* <span>
							<b>Total Sets:240</b>
						</span> */}
						<Button type="button" isLoading={isInvoiceLoading} onClick={ handleBack } className="btn btn-default mr-2"> Return To Basket </Button>
						<Button type="button" isLoading={isInvoiceLoading} onClick={ handleSubmit } className="btn btn-success float-right"> Place Order </Button>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Invoice;