import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getCampsWithPagination } from '@app/services/summerCamp';

const ListCamps = () => {

    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({name: '', status: ''});
    const userStatus = [{label:'Active', value:'active'}, {label:'Inactive', value:'inactive'}]
    const canAccess = useSelector((state: any) => state.auth.permissions['camps']);
    const [camps, setCamps] = useState([]);

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
                // borderBottomWidth: '0px',
                // borderBottomColor: theme.divider.default,
                // borderBottomStyle: 'solid',
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };
      
    useEffect(() => {
        getAllCampsData(1, perPage, sortField, sortDirection);
    },[search]);
    
    const getAllCampsData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const resp = await getCampsWithPagination(page, perPage, sortField, sortDirection, search);
            if (resp?.success === true) {
                setLoading(false);
                setCamps(resp?.collections?.data);
                setTotalRows(resp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal Server Error');
        }
    };

    const columns = [
        {
            name: 'Camp Name',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            sortField: 'name',
            wrap: true,
        },
        {
            name: 'Franchise Name',
            selector: (row: { franchise_name: string; }) => row.franchise_name ?? '---',
            // sortable: true,
            // sortField: 'franchise_name',
            wrap: true,
        },
        {
            name: 'Start Date',
            selector: (row: { start_date: string; }) => row.start_date,
            cell: (row: { start_date: string; }) => (
                row.start_date ? moment(row.start_date).format('DD/MM/YYYY') : '---'
            ),
            sortable: true,
            sortField: 'start_date',
        },
        {
            name: 'End Date',
            selector: (row: { end_date: string; }) => row.end_date,
            cell: (row: { end_date: string; }) => (
                row.end_date ? moment(row.end_date).format('DD/MM/YYYY') : '---'
            ),
            sortable: true,
            sortField: 'end_date',
        },
        {
            name: 'Location',
            selector: (row: { location: string; }) => row.location,
            sortable: true,
            sortField: 'location',
            wrap: true,
        },
        {
            name: 'Status',
            selector: (row: { status: string; }) => row.status,
            cell: (row: { status: string; }) => (
                row.status === 'active' ? <span className="badge bg-success">Active</span> : <span className="capitalize badge bg-danger">{row.status}</span>
            ),
            sortable: true,
            sortField: 'status',
        },
        {  
            name: 'Action',
            cell: (row: { id: number, is_default: string }) => (
              <>
                {/* { canAccess?.edit && 
                    <Link
                    to={`/pricing-plans/edit/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="Edit Pricing Plans"
                    >
                    <i className="fas fa-pen mr-2"/>
                    </Link>
                } */}
                { canAccess?.view && 
                    <Link
                    to={`/camps/view/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="View Camp Details"
                    >
                    <i className="fas fa-eye mr-2"/>
                    </Link>
                }
                { canAccess['enrolled-students'] && 
                    <Link
                    to={`/camps/enrolled-students/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="View Enrolled Students"
                    >
                    <i className="fas fa-users mr-2"/>
                    </Link>
                }
              </>
            ),
        },
    ];

    const handlePageChange = page => {
        getAllCampsData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllCampsData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllCampsData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        setSearch({name: '', status: ''});
    };
    
    return (
        <div>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Manage Camps</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active">Manage Camps</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div style={{ marginBottom: 10 }} className="row">
                                
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Manage Camps</h3>
                                    <div className="col-lg-12 text-right">
                                        {/* { canAccess?.create && <Link className="btn btn-primary" to="/pricing-plans/add" > + Add New Camp </Link> } */}
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <div className="row mb-2">
                                        <div className="col-sm-4">
                                            <input type="text" name='name' className="form-control" value={search.name} onChange={(e) => setSearch({ ...search, name: e.target.value })} placeholder="Search By Camp Name" />
                                        </div>
                                        <div className="col-sm-4">
                                            <select
                                                className="form-control"
                                                name="status"
                                                onChange={(e) => setSearch({ ...search, status: e.target.value })}
                                                value={search.status}
                                            >
                                                <option value="">Search By Status</option>
                                                {userStatus.map((obj) => (
                                                    <option key={obj.value} value={obj.value}>
                                                        {obj.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-sm-4'>
                                            <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                        </div>
                                    </div>
                                    
                                    <DataTable
                                        columns={columns}
                                        data={camps}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={currentPage}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                        progressPending={isLoading}
                                        sortServer
                                        onSort={handleSort}
                                        customStyles={customStyle}
                                    />
                                </div>
                                {/* /.card-body */}
                            </div>
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>
    )
};

export default ListCamps;