import { createSlice } from '@reduxjs/toolkit';

export interface OrderState {
	queue: [];
}

const initialState: OrderState = {
	queue: [],
};

export const PrintQueueSlice = createSlice({
	name: 'printQueue',
	initialState,
	reducers: {
		loadQueue: (state, { payload }) => {
			state.queue = payload;
		},
		clearQueue: (state) => {
			state.queue = [];
		},
		deleteQueueItem: (state, { payload }) => {
			const filterQueue = <any> state.queue.filter(
				(data) => data['queue_id'] !== payload.queue_id
			);
			state.queue = filterQueue;
		},
	}
});

export const { loadQueue, clearQueue, deleteQueueItem } = PrintQueueSlice.actions;

export default PrintQueueSlice.reducer;