import React, { useEffect, useRef, useState } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import Select from "react-select";
import { useNavigate, useParams } from 'react-router-dom';
import { getPrintReportDetailsByIdWithPagination } from '@app/services/printReport';
import moment from 'moment';
import { getStudentsByFranchiseId } from '@app/services/student';
import { getAllSubjects } from '@app/services/subject';
import { getAllActiveModulesBySubject } from '@app/services/module';
import { useDispatch, useSelector } from 'react-redux';
import { clearFranchisePrintSummary } from '@app/store/reducers/printSummary';

const PrintHistoryDetails = () => {

    let { id } = useParams();
    const studRef = useRef(null);
    const moduleRef = useRef(null);
    const printSummaryStore = useSelector((state: any) => state?.printSummary?.franchise);
    const [isLoading, setLoading] = useState(false);
    const [isModulesLoading, setModulesLoading] = useState(false);
    const [isStudentLoading, setStudentLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({studentId: '', subjectId:'', moduleId:'', month: printSummaryStore?.month, year: printSummaryStore?.year});
    const [totalWS, setTotalWS] = useState(0);
    const [printHistory, setPrintHistory] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [students, setStudents] = useState([]);
    const [modules, setModules] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const months = [{id: '01', name:'January'}, {id: '02', name:'February'}, {id: '03', name:'March'}, 
                    {id: '04', name:'April'}, {id: '05', name:'May'}, {id: '06', name:'June'},
                    {id: '07', name:'July'},{id: '08', name:'August'},{id: '09', name:'September'},
                    {id: '10', name:'October'}, {id: '11', name:'November'}, {id: '12', name:'December'}];
    
    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    useEffect(() => {
        getAllByPaginationData(1, perPage, sortField, sortDirection);
    },[search]);

    useEffect(() => {
        getAllFranchiseStudents(id)
        getAllSubjectsData()
    },[]);

    const getAllByPaginationData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const resp = await getPrintReportDetailsByIdWithPagination(id, page, perPage, sortField, sortDirection, search);
            if (resp.success === true) {
                setLoading(false);
                setPrintHistory(resp.collections.data);
                setTotalRows(resp?.collections?.pagination?.total);
                // let totalCnt = resp.collections.data.reduce((sum, { worksheets }) => sum + parseInt(worksheets), 0)
                // setTotalWS(totalCnt)
                setTotalWS(resp?.collections?.pagination?.totalWorksheets)
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal server error');
        }
    };

    const getAllFranchiseStudents = async (id) => {
        try {
            const resp = await getStudentsByFranchiseId(id);
            if (resp?.success === true) {
                setStudents(resp?.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error');
        }
    };

    const getAllSubjectsData = async () => {
        try {
            setStudentLoading(true)
            const resp = await getAllSubjects()
            if (resp.success === true) {
                setStudentLoading(false)
                setSubjects(resp.collections)
            } else {
                setStudentLoading(false)
                toast.error(resp?.errors[0]?.msg)
            }
        } catch (error: any) {
            setStudentLoading(false)
            toast.error(error?.message || 'Internal server error')
        }
    };

    const getAllActiveModulesBySubjectData = async (subjectId) => {
		try {
			setModulesLoading(true);
            const resp = await getAllActiveModulesBySubject(subjectId);
            if (resp.success === true) {
				setModulesLoading(false);
                setModules(resp.collections);
            } else {
				setModulesLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
			setModulesLoading(false);
            toast.error(error?.message || 'Internal server error');
        }
	}

    const columns = [
        {
            name: 'Student',
            selector: (row: { student_name: string; }) => row.student_name,
            sortable: true,
            sortField: 'student_name',
            wrap: true,
        },
        {
            name: 'Subject',
            selector: (row: { subject_name: string; }) => row.subject_name ?? '---',
            sortable: true,
            sortField: 'subject_name',
            wrap: true
        },
        {
            name: 'Module',
            selector: (row: { module_name: string; }) => row.module_name ?? '---',
            sortable: true,
            sortField: 'module_name',
            wrap: true
        },
        {
            name: 'Worksheet(s)',
            selector: (row: { worksheets: string; }) => row.worksheets ?? '---',
        },
        {
            name: 'Print Date',
            selector: (row: { date: string; }) => row.date,
            format: (row: { date: string; }) => row.date ? moment(row.date).format('DD MMM YYYY') : '---',
            sortable: true,
            sortField: 'date',
            wrap: true,
        },
    ];

    const handlePageChange = page => {
        getAllByPaginationData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllByPaginationData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllByPaginationData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        studRef.current.setValue('');
        moduleRef.current.setValue('');
        setModules([])
        setSearch({studentId: '', subjectId:'', moduleId:'', month: printSummaryStore?.month, year: printSummaryStore?.year});
    };

    const onStudentChange = (option) => {
        setSearch({ ...search, studentId: option?.id ? option?.id : '' });
    };

    const onSubjectChange = (subjectId) => {
        setSearch({ ...search, subjectId: subjectId })
        if(subjectId)
            getAllActiveModulesBySubjectData(subjectId)
        else
            setModules([])
    };

    const onModuleChange = (option) => {
        setSearch({ ...search, moduleId: option?.id ? option?.id : '' });
    };

    const backToPrintSummary = (e) => {
        e.preventDefault();
        dispatch(clearFranchisePrintSummary())
        navigate(`/print-summary`);
    }

    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Print Summary Details</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active"><a href="/print-summary">Print Summary</a></li>
                                    <li className="breadcrumb-item">Print Summary Details</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Print Summary Details</h3>
                                        <div className="col-lg-12 text-right">
                                            <a href='#' className="btn btn-default mr-2" onClick={ (e) => backToPrintSummary(e)}> Back </a>
                                        </div>
                                    </div>
                                    <div className='card-header'>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="row">
                                                    <dt className="col-sm-4">Franchise:</dt>
                                                    <dd className="col-sm-8">{ printSummaryStore?.name }</dd>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="row">
                                                    <dt className="col-sm-4">Month:</dt>
                                                    <dd className="col-sm-8">{ (months.find(obj => printSummaryStore?.month === obj.id))?.name ?? '---'} { printSummaryStore?.year } </dd>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="row">
                                                    <dt className="col-sm-5">Total Worksheets:</dt>
                                                    <dd className="col-sm-7">{ totalWS } </dd>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row mb-3">
                                            <div className="col-sm-3">
                                                <Select
                                                    ref={studRef}
                                                    name="studentId"
                                                    placeholder="Search By Student"
                                                    options={students}
                                                    getOptionValue={(option)=>`${option['id']}`}
                                                    getOptionLabel={(option)=> option['student_name'] }
                                                    onChange={ (option) => { onStudentChange(option) }}
                                                    isLoading={isStudentLoading}
                                                    isClearable={true}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <select
                                                    className="form-control"
                                                    name="subjectId"
                                                    onChange={(e) => onSubjectChange(e.target.value)}
                                                    value={search.subjectId}
                                                >
                                                    <option value="">Search By Subject</option>
                                                    {subjects.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-3">
                                                <Select
                                                    ref={moduleRef}
                                                    name="moduleId"
                                                    placeholder="Search By Module"
                                                    options={modules}
                                                    getOptionValue={(option)=>`${option['id']}`}
                                                    getOptionLabel={(option)=>`${option['name']}`}
                                                    onChange={ (option) => onModuleChange(option) }
                                                    isLoading={isModulesLoading}
                                                    isClearable={true}
                                                />
                                            </div>
                                            <div className='col-sm-3'>
                                                <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning mr-2">Reset Filter</Button>
                                            </div>
                                        </div>

                                        <DataTable
                                            columns={columns}
                                            data={printHistory}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationDefaultPage={currentPage}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            paginationPerPage={perPage}
                                            paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                            progressPending={isLoading}
                                            sortServer
                                            onSort={handleSort}
                                            customStyles={customStyle}
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>

        </div>
    )
};

export default PrintHistoryDetails;
