import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { viewUserById } from '@app/services/user';

const ViewUser = () => {
    const userStatus = [{value:'active', label:'Active'}, {value:'inactive', label:'Inactive'}];
    let obj: any = {}
    const [isLoading, setLoading] = useState(false);
    const [user, setUserData] = useState(obj);
    const [roles, setRoles] = useState('');
    const [franchise, setFranchise] = useState('');
    const [centres, setCentres] = useState('');
    const navigate = useNavigate();
    let { id } = useParams();
    
    useEffect(() => {
        getUserDetailsById(id);
    }, []);

    const getUserDetailsById = async (id) => {
        try {
            setLoading(true);
            const resp = await viewUserById(id);
            if(resp?.success === true){
                setLoading(false);
                setUserData(resp.collections);
                if(resp?.collections?.roles !== null){
                    setRoles(resp?.collections?.roles.map.call(resp?.collections?.roles, function(role) { return role.name; }).join(", "))
                }
                if(resp?.collections?.franchise !== null){
                    setFranchise(resp?.collections?.franchise.map.call(resp?.collections?.franchise, function(record) { return record.name; }).join(", "))
                }
                if(resp?.collections?.centre !== null){
                    setCentres(resp?.collections?.centre.map.call(resp?.collections?.centre, function(record) { return record.name; }).join(", "))
                }
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
                navigate('/users');
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

  return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">View User</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">First Name </label>
                                            <dd>{user?.first_name ? user?.first_name : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Last Name </label>
                                            <dd>{user?.last_name ? user?.last_name : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Email </label>
                                            <dd>{user?.email ? user?.email : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Alternate Email </label>
                                            <dd>{user?.alt_email ? user?.alt_email : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Mobile </label>
                                            <dd>{user?.phone ? user?.phone : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Alternate Mobile </label>
                                            <dd>{user?.alt_phone ? user?.alt_phone : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Address </label>
                                            <dd>{user?.address ? user?.address : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Roles </label>
                                            <dd>{roles ? roles : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Franchise Attached </label>
                                            <dd>{franchise ? franchise : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Centre Attached </label>
                                            <dd>{centres ? centres : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Status </label>
                                            <dd>{user?.status ? (userStatus.find(obj => { return obj.value === user?.status}))?.label : '---'}</dd>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <Link className="btn btn-primary mr-2" to={`/users/edit/${id}`}>Edit</Link>
                                <Link className="btn btn-default" to='/users'>Close</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default ViewUser;