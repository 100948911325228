import React, { useState } from 'react'
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import Select from "react-select";
import { useEffect } from 'react';
import { getFranchiseAdmins } from '@app/services/user';
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import { useSelector, useDispatch } from 'react-redux';
import AddUserModal from '../user/AddUserModal';
import { createFranchise } from '@app/services/franchise';
import { Form } from 'react-bootstrap';
import { getAllActiveSubjects } from '@app/services/subject';
import { setActiveSubjects, setDefaultCheck, setSubjectCheck, setSubjectRate, setSubjectRateError } from '@app/store/reducers/subject';

const AddFranchise = () => {

    const franchiseStatus = [{id:'active', name:'Active'}, {id:'inactive', name:'Inactive'}, {id:'on_hold', name:'On Hold'}];
    const franchiseTypes = [{value:'company', label:'Limited Company'}, {value:'partnership', label:'Partnership'}, {value:'sole_trader', label:'Sole Trader'}];
    const [franchisees, setFranchisees] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const canAccess = useSelector((state: any) => state.auth.permissions.franchise);
    const subjects = useSelector((state: any) => state.subject.data);
    const [show, setShow] = useState(false);
    const [reasonShow, setReasonShow] = useState(false);
    const [userFlag, setUserFlag] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();    

    useEffect(() => {
        getFranchiseAdminData();
        getAllActiveSubjectsData();
    }, [userFlag]);

    const getFranchiseAdminData = async () => {
        try {
            const franchiseeResp = await getFranchiseAdmins();
            if (franchiseeResp.success === true) {
                setFranchisees(franchiseeResp.collections);
            } else {
                toast.error(franchiseeResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const addFranchise = async (payload) => {
        try {
            setLoading(true);
            const resp = await createFranchise(payload);
            if(resp.success === true){
                toast.success(resp?.message);
                dispatch(setActiveSubjects([]));
                setLoading(false);
                navigate('/franchise');
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
          }
    };

    const getAllActiveSubjectsData = async () => {
        try {
            const resp = await getAllActiveSubjects();
            if (resp.success === true) {
                (resp.collections).map((subject) => {
                    delete subject.prefix
                    delete subject.set_price
                    delete subject.status
                    delete subject.created_at
                    delete subject.updated_at
                    delete subject.deleted_at
                    subject.active = false;
                    subject.is_default = false;
                    subject.rate = '';
                    subject.error = '';
                    subject.default_rate = subject.subject_rate;
                    delete subject.subject_rate;
                })
                dispatch(setActiveSubjects(resp.collections))
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            name: '',
            type: '',
            address: '',
            phone:'',
            status: '',
            users: [],
            kashflow_order_account: '',
            kashflow_license_account: '',
            start_date: '',
            end_date: '',
            vat: '',
            reason:'',
            effective_start_date:'',
            student_count_tax: true,
            enabled_student_count: false,
            student_local_printing: false,
            enabled_holiday_camp: false,
            enabled_adhoc_printing: false,
            enabled_manage_students: false,
            subjects: []
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter franchise name'),
            type: Yup.string().required('Please select franchise type'),
            address: Yup.string(),
            phone: Yup.string()
            .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
				"Please enter valid number"
			),
            status: Yup.string().required('Please select status'),
            users: Yup.array()
            .of(Yup.string().trim())
            .min(1, "Please select at least 1 admin")
            .required("Please select admin"),
            kashflow_license_account: Yup.number().positive('Kashflow licence account must be a positive number').required('Please enter kashflow licence account'),
            kashflow_order_account: Yup.number().positive('Kashflow order account must be a positive number').required('Please enter kashflow order account'),
            start_date: Yup.string().required('Please enter start date'),
            reason: Yup.string().when("status", {
                is: (val: string) => (val === 'on_hold'),
                then: Yup.string().required('Please enter reason'),
            }),
            effective_start_date: Yup.string().required('Please enter effective start date'),
        }),
        onSubmit: (values) => {
            let franchiseSubjects = [];
            let flag = true;
            subjects.map( (subject) => {
                if(subject.active === true){
                    if(subject.error != ''){
                        flag = false;
                    }
                    franchiseSubjects.push({id:subject.id, rate: Math.abs(subject.rate), is_default: subject.is_default})
                }
            })

            if(franchiseSubjects.length == 0){
                toast.error('Please select at least one subject.');
                return;
            }
            if(flag){
                values.subjects = franchiseSubjects;
                addFranchise(values);
            }
        }
    });

    const handleAdminsChange = (options: readonly typeof Option[], setFieldValue) => {
        setFieldValue(
          "users",
          [].slice.call(options).map((option) => option.id)
        );
    };

    const statusChange = (e, setFieldValue) => {
        if(e.target.value === 'on_hold'){
            setReasonShow(true);
        }else{
            setReasonShow(false);
        }
        setFieldValue("status", e.target.value);
    };

    const handleDefaultRateChange = (e, subject) => {
        if(e.target.checked){
            dispatch(setSubjectRate({rate: subject.default_rate, slug: subject.slug, id: subject.id}))
            dispatch(setDefaultCheck({is_default: e.target.checked, slug: subject.slug, id: subject.id}))
            dispatch(setSubjectRateError({slug: subject.slug, error: ''}))
        }else{
            dispatch(setSubjectRate({rate: 0, slug: subject.slug, id: subject.id}))
            dispatch(setDefaultCheck({is_default: e.target.checked, slug: subject.slug, id: subject.id}))
        }
    }

    const handleSubjectRate = (e, subject) => {
        if(e.target.value == '') {
            dispatch(setSubjectRateError({slug: subject.slug, error: 'Please enter rate'}))
        }else if(isNaN(Math.abs(e.target.value))){
            dispatch(setSubjectRateError({slug: subject.slug, error: 'Please enter valid rate'}))
        }else if(e.target.value < 0){
            dispatch(setSubjectRateError({slug: subject.slug, error: 'Rate must be greater than or equal to 0'}))
        }
        // else if(e.target.value > 10000){
        //     dispatch(setSubjectRateError({slug: subject.slug, error: 'Rate  must be less than or equal to 10,000'}))
        // }
        else{
            dispatch(setSubjectRateError({slug: subject.slug, error: ''}))
        }
        dispatch(setSubjectRate({rate: e.target.value, slug: subject.slug, id: subject.id}));
    }

    const handleSubjectCheck = (e, subject) => {
        dispatch(setSubjectCheck({active: e.target.checked, slug: subject.slug, id: subject.id}))
        if(e.target.checked){
            dispatch(setSubjectRateError({slug: subject.slug, error: 'Please enter rate'}))
        }
    }

    const OnWheelPreventChange = (e) => {
		e.currentTarget.blur();
	} 

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Add Franchise</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Franchise Name <span className="required">*</span></label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter franchise name" 
                                                    name="name" 
                                                    onChange={handleChange} 
                                                    value={values.name}
                                                />
                                                {(touched.name && errors.name) && (
                                                    <div className="text-danger">
                                                        {errors.name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Franchise Type <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="type"
                                                    onChange={handleChange} 
                                                    value={values.type}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {franchiseTypes.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.type && errors.type) && (
                                                    <div className="text-danger">
                                                        {errors.type}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Address </label>
                                                <textarea 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter address" 
                                                    name="address" 
                                                    onChange={handleChange} 
                                                    value={values.address}
                                                ></textarea>
                                                {(touched.address && errors.address) && (
                                                    <div className="text-danger">
                                                        {errors.address}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Assign Franchisee(s) <span className="required">*</span></label>
                                                <Select
                                                    placeholder={<div>-- Please select --</div>}
                                                    isMulti
                                                    options={franchisees}
                                                    closeMenuOnSelect={false}
                                                    getOptionLabel={(option)=> option.first_name +' '+ option.last_name}
                                                    getOptionValue={(option)=>option.id}
                                                    name="users"
                                                    onChange={ (e) => {handleAdminsChange(e, setFieldValue)}}
                                                    value={
                                                        franchisees?.length
                                                        ? franchisees.filter((obj) =>
                                                            values.users.includes(obj.id)
                                                            )
                                                        : values.users
                                                    } // set selected values
                                                />
                                                <div style={{ marginBottom: 10 }} className="row">
                                                    <div className="col-sm-12">
                                                        {(touched.users && errors.users) && (
                                                            <div className="text-danger">
                                                                {errors.users}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-sm-12 text-right">
                                                        { canAccess['create-user'] && <a href='#' onClick={handleShow}> + Add New Franchisee </a> }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Kashflow Licence Account <span className="required">*</span></label>
                                                <input type="number" className="form-control" id="exampleInputEmail1" placeholder="Enter kashflow license account" 
                                                    name="kashflow_license_account" 
                                                    onChange={handleChange} 
                                                    value={values.kashflow_license_account}
                                                   onWheel={ (e) => OnWheelPreventChange(e) }
                                                />
                                                {(touched.kashflow_license_account && errors.kashflow_license_account) && (
                                                    <div className="text-danger">
                                                        {errors.kashflow_license_account}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Kashflow Order Account <span className="required">*</span></label>
                                                <input type="number" className="form-control" id="exampleInputEmail1" placeholder="Enter kashflow order account" 
                                                    name="kashflow_order_account" 
                                                    onChange={handleChange} 
                                                    value={values.kashflow_order_account}
                                                   onWheel={ (e) => OnWheelPreventChange(e) }
                                                />
                                                {(touched.kashflow_order_account && errors.kashflow_order_account) && (
                                                    <div className="text-danger">
                                                        {errors.kashflow_order_account}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Start Date <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="date" 
                                                    name="start_date" 
                                                    id="start_date" 
                                                    placeholder="Enter start date" 
                                                    value={values.start_date}
                                                    onChange={handleChange}
                                                />
                                                {(touched.start_date && errors.start_date) && (
                                                    <div className="text-danger">
                                                        {errors.start_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">End Date </label>
                                                <Form.Control 
                                                    type="date" 
                                                    name="end_date" 
                                                    id="end_date" 
                                                    placeholder="Enter end date" 
                                                    value={values.end_date}
                                                    onChange={handleChange}
                                                />
                                                {(touched.end_date && errors.end_date) && (
                                                    <div className="text-danger">
                                                        {errors.end_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Effective Start Date <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="date" 
                                                    name="effective_start_date" 
                                                    id="effective_start_date" 
                                                    placeholder="Enter effective start date" 
                                                    value={values.effective_start_date}
                                                    onChange={handleChange}
                                                />
                                                {(touched.effective_start_date && errors.effective_start_date) && (
                                                    <div className="text-danger">
                                                        {errors.effective_start_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">VAT No. </label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter vat" 
                                                    name="vat" 
                                                    onChange={handleChange} 
                                                    value={values.vat}
                                                />
                                                {(touched.vat && errors.vat) && (
                                                    <div className="text-danger">
                                                        {errors.vat}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Phone </label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter franchise phone" 
                                                    name="phone" 
                                                    onChange={handleChange} 
                                                    value={values.phone}
                                                />
                                                {(touched.phone && errors.phone) && (
                                                    <div className="text-danger">
                                                        {errors.phone}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Status <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={ e => statusChange(e, setFieldValue) }
                                                    value={values.status}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {franchiseStatus.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.status && errors.status) && (
                                                    <div className="text-danger">
                                                        {errors.status}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        { reasonShow && (
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Reason <span className="required">*</span></label>
                                                    <textarea 
                                                        className="form-control" 
                                                        id="exampleInputEmail1" 
                                                        placeholder="Enter reason" 
                                                        name="reason" 
                                                        onChange={handleChange} 
                                                        value={values.reason}
                                                    ></textarea>
                                                    {(touched.reason && errors.reason) && (
                                                        <div className="text-danger">
                                                            {errors.reason}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='row content_inner_wrap'>
                                        <div className="col-sm-12">
                                            <label>Settings</label>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                {/* <label htmlFor="student_count_tax"><b> Taxable Item? </b></label> */}
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox"
                                                        id="student_count_tax"  
                                                        name="student_count_tax" 
                                                        className="form-check-input"
                                                        checked={values.student_count_tax} 
                                                        onChange={(e) => { 
                                                            handleChange 
                                                            setFieldValue("student_count_tax", e.target.checked ? true : false)
                                                        }}
                                                    />
                                                    <label htmlFor="student_count_tax" className="form-check-label">VAT Applicable?</label>
                                                </div>
                                                {(touched.student_count_tax && errors.student_count_tax) && (
                                                    <div className="text-danger">
                                                        {errors.student_count_tax}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                {/* <label htmlFor="enabled_student_count"><b> Taxable Item? </b></label> */}
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox"
                                                        id="enabled_student_count"  
                                                        name="enabled_student_count" 
                                                        className="form-check-input"
                                                        checked={values.enabled_student_count} 
                                                        onChange={(e) => { 
                                                            handleChange 
                                                            setFieldValue("enabled_student_count", e.target.checked ? true : false)
                                                        }}
                                                    />
                                                    <label htmlFor="enabled_student_count" className="form-check-label">Is Student Count Mandatory?</label>
                                                </div>
                                                {(touched.enabled_student_count && errors.enabled_student_count) && (
                                                    <div className="text-danger">
                                                        {errors.enabled_student_count}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                {/* <label htmlFor="student_local_printing"><b> Taxable Item? </b></label> */}
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox"
                                                        id="student_local_printing"  
                                                        name="student_local_printing" 
                                                        className="form-check-input"
                                                        checked={values.student_local_printing} 
                                                        onChange={(e) => { 
                                                            handleChange 
                                                            setFieldValue("student_local_printing", e.target.checked ? true : false)
                                                        }}
                                                    />
                                                    <label htmlFor="student_local_printing" className="form-check-label">Enable Local Printing?</label>
                                                </div>
                                                {(touched.student_local_printing && errors.student_local_printing) && (
                                                    <div className="text-danger">
                                                        {errors.student_local_printing}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                {/* <label htmlFor="enabled_holiday_camp"><b> Taxable Item? </b></label> */}
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox"
                                                        id="enabled_holiday_camp"  
                                                        name="enabled_holiday_camp" 
                                                        className="form-check-input"
                                                        checked={values.enabled_holiday_camp} 
                                                        onChange={(e) => { 
                                                            handleChange 
                                                            setFieldValue("enabled_holiday_camp", e.target.checked ? true : false)
                                                        }}
                                                    />
                                                    <label htmlFor="enabled_holiday_camp" className="form-check-label">Enable Holiday Camp?</label>
                                                </div>
                                                {(touched.enabled_holiday_camp && errors.enabled_holiday_camp) && (
                                                    <div className="text-danger">
                                                        {errors.enabled_holiday_camp}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                {/* <label htmlFor="enabled_adhoc_printing"><b> Taxable Item? </b></label> */}
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox"
                                                        id="enabled_adhoc_printing"  
                                                        name="enabled_adhoc_printing" 
                                                        className="form-check-input"
                                                        checked={values.enabled_adhoc_printing} 
                                                        onChange={(e) => { 
                                                            handleChange 
                                                            setFieldValue("enabled_adhoc_printing", e.target.checked ? true : false)
                                                        }}
                                                    />
                                                    <label htmlFor="enabled_adhoc_printing" className="form-check-label">Enable Adhoc Printing?</label>
                                                </div>
                                                {(touched.enabled_adhoc_printing && errors.enabled_adhoc_printing) && (
                                                    <div className="text-danger">
                                                        {errors.enabled_adhoc_printing}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                {/* <label htmlFor="enabled_manage_students"><b> Taxable Item? </b></label> */}
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox"
                                                        id="enabled_manage_students"  
                                                        name="enabled_manage_students" 
                                                        className="form-check-input"
                                                        checked={values.enabled_manage_students} 
                                                        onChange={(e) => { 
                                                            handleChange 
                                                            setFieldValue("enabled_manage_students", e.target.checked ? true : false)
                                                        }}
                                                    />
                                                    <label htmlFor="enabled_manage_students" className="form-check-label">Enable Manage Students?</label>
                                                </div>
                                                {(touched.enabled_manage_students && errors.enabled_manage_students) && (
                                                    <div className="text-danger">
                                                        {errors.enabled_manage_students}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label> Assign Subjects <span className="required">*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    {subjects.map((subject, index) => (
                                        <div className="row" key={index}>
                                            <div className="col-sm-2">
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox" 
                                                        className="form-check-input" 
                                                        value={subject.id} 
                                                        checked={subject.active} 
                                                        id={`${subject.slug}.subject`} 
                                                        name={`${subject.slug}.subject`} 
                                                        onChange={ (e) => handleSubjectCheck(e, subject)}
                                                    />
                                                    <label htmlFor={`${subject.slug}.subject`} className="form-check-label"> <b>{ subject.name }</b> </label>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Rate Per Student </label>
                                                    <div className='input-group'>
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">£</span>
                                                        </div>
                                                        <input type="number" className="form-control" id="exampleInputEmail1" placeholder="Enter subject rate" 
                                                            name="rate" 
                                                            value={subject.rate}
                                                            disabled={!subject.active || (subject.is_default && subject.active)}
                                                            onChange={ (e) => handleSubjectRate(e, subject) }
                                                            onWheel={ (e) => OnWheelPreventChange(e) }
                                                        />
                                                    </div>
                                                    <div className="text-danger"> {subject.error} </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="icheck-primary mt-5" style={{marginTop: '38px !important'}}>
                                                    <input 
                                                        type="checkbox" 
                                                        className="form-check-input" 
                                                        value={subject.id} 
                                                        checked={subject.is_default} 
                                                        id={`${subject.slug}.is_default`} 
                                                        name={`${subject.slug}.is_default`} 
                                                        onChange={ (e) => handleDefaultRateChange(e, subject)}
                                                        disabled={!subject.active}
                                                    />
                                                    <label htmlFor={`${subject.slug}.is_default`} className="form-check-label"> Set Default Rate </label>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="card-footer">
                                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Add </Button>
                                    <Link className="btn btn-default" to='/franchise'>Close</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <AddUserModal 
                show={show}
                handleClose={handleClose}
                setUserFlag={setUserFlag}
                // handleAdminsChange={handleAdminsChange}
            />
        </section>
    </div>
  )
}

export default AddFranchise;