import { deleteSetURL, getSetsWithPaginationURL } from "@app/constants/ApiEndPoints";
import axios from "axios";

export const getSetsWithPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(getSetsWithPaginationURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}
															&name=${search.name}&subjectId=${search.subjectId}&moduleId=${search.moduleId}&blockId=${search.blockId}&worksheetFrom=${search.worksheetFrom}&worksheetTo=${search.worksheetTo}&status=${search.status}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const deleteSetById = async (id: number) => {
	try {
		const response = await axios.delete(deleteSetURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};