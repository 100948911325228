import { Button } from '@app/components';
import { Modal } from 'react-bootstrap';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import React, { useState, useEffect } from 'react'

const PreviewModal = (props) => {

    // const docs = [
    //     { 
    //         uri: props.url,
    //         fileName: props.filename
    //     }, // Remote file
    //     // { uri: require("./example-files/pdf.pdf") }, // Local File
    // ];

    const [docs, setDocs] = useState([]);
    useEffect(() => {
        setDocs([{ uri: props.url, fileName: props.filename }])
    },[props.url, props.filename]);

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose}  size="lg" scrollable>
            <Modal.Header closeButton>
                <Modal.Title>Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <DocViewer 
                        documents={docs} 
                        pluginRenderers={DocViewerRenderers} 
                        config={{
                            header: {
                              disableHeader: false,
                              disableFileName: false,
                              retainURLParams: false,
                            },
                            csvDelimiter: ",", // "," as default,
                            pdfZoom: {
                              defaultZoom: 1.1, // 1 as default,
                              zoomJump: 0.2, // 0.1 as default,
                            },
                            pdfVerticalScrollByDefault: true, // false as default
                        }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    </div>
  )
}

export default PreviewModal;