import { createSlice, current } from '@reduxjs/toolkit';

export const modulePermissionsSlice = createSlice({
	name: 'modulePermissions',
	initialState: {
		data: [],
	},
	reducers: {
		setInitialData(state, action) {
			state.data = action.payload;
		},
		setPermission: (state, { payload }) => {
			const objIndex  = state.data.findIndex( e => e.slug === payload.slug )
			state.data[objIndex].permissions.map((obj) => {
				if(obj.id === payload.id){
					obj.active = payload.active;
					return;
				}
			})
		},
		setModulePermission: (state, { payload }) => {
			const objIndex  = state.data.findIndex( e => e.slug === payload.slug )
			state.data[objIndex].permissions.map((obj) => {
				obj.active = payload.active;
			})
		}
	}
});

export const { setInitialData, setPermission, setModulePermission } = modulePermissionsSlice.actions;

export default modulePermissionsSlice.reducer;
