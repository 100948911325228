import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import Select from "react-select";
import { useFormik } from 'formik';
import { Button } from '@app/components';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { attachFranchiseToPlan, detachFranchiseFromPlan, getAllUnlinkPlanFranchise, getFranchiseByPlanId, getPricingPlanById } from '@app/services/pricingPlans';
import moment from 'moment';

const LinkFranchiseToPlan = () => {

    let obj: any = {}
    let { id } = useParams();
    const selectRef = useRef(null);
    const [planDetails, setPlanDetails] = useState(obj);
    const [franchises, setFranchises] = useState([]);
    const [planFranchises, setPlanFranchises] = useState(obj);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingFranchise, setLoadingFranchise] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getPricingPlanDetailsById(id);
        getAllUnlinkFranchiseData();
        getAllFranchiseByPlanId(id);
    },[]);

    const getPricingPlanDetailsById = async (id) => {
        try {
            const resp = await getPricingPlanById(id);
            if(resp.success === true){
                setPlanDetails(resp.collections);
            }else{
                toast.error(resp?.errors[0]?.msg);
                navigate('/pricing-plans');
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllUnlinkFranchiseData = async () => {
        try {
            setLoadingFranchise(true);
            const resp = await getAllUnlinkPlanFranchise();
            if (resp?.success === true) {
                setLoadingFranchise(false);
                setFranchises(resp?.collections);
            } else {
                setLoadingFranchise(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoadingFranchise(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllFranchiseByPlanId = async (id) => {
        try {
            setLoading(true);
            const resp = await getFranchiseByPlanId(id);
            if (resp?.success === true) {
                setLoading(false);
                setPlanFranchises(resp?.collections);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const attachFranchiseToPlanAPI = async (payload) => {
        try {
            setLoading(true);
            const resp = await attachFranchiseToPlan(payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                selectRef.current.setValue('');
                getAllUnlinkFranchiseData();
                getAllFranchiseByPlanId(id);
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue, resetForm } = useFormik({
        initialValues: {
            name: planDetails?.name || '',
            start_date: planDetails?.start_date || '',
            end_date: planDetails?.end_date || '',
            franchise_id: ''
        },
        validationSchema: Yup.object({
            franchise_id: Yup.string().required('Please select franchise'),
        }),
        onSubmit: (values) => {
            attachFranchiseToPlanAPI({id: id, franchise_id:values.franchise_id});
            resetForm();
        },
        enableReinitialize: true,
    });

    const confirm = (e, title, message, payload) => {
        confirmAlert({
            title: title,
            message: message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => detachFranchiseFromPlanAPI(payload)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const detachFranchiseFromPlanAPI = async (payload) => {
        try {
            setLoading(true);
            const resp = await detachFranchiseFromPlan(payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                getAllUnlinkFranchiseData();
                getAllFranchiseByPlanId(id);
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Link Franchise To Plan</h3>
                            </div>
                            <div className="card-body">
                                <div className="row" style={{borderBottom: '1px solid #d1d1d1'}}>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Plan Name </label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" 
                                                name="name" 
                                                onChange={handleChange} 
                                                value={values.name}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Start Date </label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" 
                                                name="name" 
                                                onChange={handleChange} 
                                                value={moment(values.start_date).format('DD-MM-YYYY')}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">End Date </label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" 
                                                name="name" 
                                                onChange={handleChange} 
                                                value={moment(values.end_date).format('DD-MM-YYYY')}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit} style={{borderBottom: '1px solid #d1d1d1'}}>
                                    <div className="row mt-2">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="franchise_id">Franchise To Attach <span className="required">*</span></label>
                                                <Select
                                                    ref={selectRef}
                                                    id="franchise_id"
                                                    name="franchise_id"
                                                    placeholder="Select Franchise"
                                                    options={franchises}
                                                    getOptionValue={(option)=>`${option['id']}`}
                                                    getOptionLabel={(option)=>`${option['name']}`}
                                                    onChange={ (option) => { 
                                                        if(option){
                                                            setFieldValue("franchise_id", option.id)
                                                        }else{
                                                            setFieldValue("franchise_id", '')
                                                        }
                                                    }}
                                                    // value={values.franchise_id}
                                                    isLoading={isLoadingFranchise}
                                                    isClearable={true}
                                                />
                                                {(touched.franchise_id && errors.franchise_id) && (
                                                    <div className="text-danger">
                                                        {errors.franchise_id}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col-sm-4'>
                                            <div className='form-group'>
                                                <label htmlFor="franchise_id"></label>
                                                <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Add </Button>
                                                <Link className="btn btn-default" to='/pricing-plans'>Close</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <table className="table table-bordered" id="client_id_table">
                                            <tbody>
                                                <tr className='text-center'>
                                                    <th>Franchise</th>
                                                    <th>Action</th>
                                                </tr>
                                                {planFranchises?.length > 0  && planFranchises.map((franchise) => (
                                                    <tr key={franchise.id}>
                                                        <td>
                                                            <Link to={`/franchise/view/${franchise.id}`} role="button"> { franchise.name } </Link>
                                                        </td>
                                                        <td className='text-center'>
                                                            <div>
                                                                <button type="button" onClick={ (e) => confirm(e, 'Detach franchise', 'Are you sure to detach franchise?', {id:id, franchise_id: franchise.id})} className="btn btn-danger btn-xs mr-2">Remove</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                {planFranchises?.length == 0 && (
                                                    <tr>
                                                        <td colSpan={2} style={{ color: "red", textAlign: "center" }}>There are no records to display</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default LinkFranchiseToPlan;