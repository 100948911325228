import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {toast} from 'react-toastify';
import { Button } from '@app/components';
import { getPostcodeById, getPostcodeHistoryById } from '@app/services/postcode';
import DataTable, { TableStyles } from 'react-data-table-component';
import moment from 'moment';

const PostcodeHistory = () => {

    let obj: any = {}
    const [postcodeHistory, setPostcodeHistory] = useState([]);
    const [postcodeDetails, setPostcodeDetails] = useState(obj);
    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({name: '', country: '', region: '', area: '', franchiseId: '', status: '', action: ''});
    const actions = [
        {label: 'Attach', value: 'attach'}, 
        {label: 'Create', value: 'create'}, 
        {label: 'Detach', value: 'detach'}, 
        {label: 'Renewal', value: 'renewal'},
        {label: 'Update', value: 'update'}];
    const postcodeStatus = [ 
        {label:'Active', value:'active'},
        {label:'Available', value:'available'},
        {label:'Offered', value:'offered'},
        {label:'Reserved', value:'reserved'},
    ]
    const navigate = useNavigate();
    let { id } = useParams();

    useEffect(() => {
        getPostcodeDetailsById(id);
    },[]);

    useEffect(() => {
        getPostcodeHistoryDetails(1, perPage, sortField, sortDirection);
    },[search]);

    const getPostcodeDetailsById = async (id) => {
        try {
            // setLoading(true);
            const resp = await getPostcodeById(id);
            if(resp?.success === true){
                // setLoading(false);
                setPostcodeDetails(resp?.collections);
            }else{
                // setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            // setLoading(false);
            toast.error(error?.response?.message || 'Failed');
          }
    };

    const getPostcodeHistoryDetails = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const usersResp = await getPostcodeHistoryById(id, page, perPage, sortField, sortDirection, search);
            if (usersResp.success === true) {
                setLoading(false);
                setPostcodeHistory(usersResp?.collections?.data);
                setTotalRows(usersResp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(usersResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    const columns = [
        {
            name: 'Action',
            selector: (row: { action: string; }) => row.action,
            cell: (row: { action: string; }) => (
                row.action !== null ? <span className="badge badge-dark">{ row.action.charAt(0).toUpperCase() + row.action.slice(1)} </span> : '---'
            ),
            sortable: true,
            sortField: 'action',
        },
        // {
        //     name: 'Country',
        //     selector: (row: { country_name: string; }) => row.country_name,
        //     sortable: true,
        //     sortField: 'country',
        // },
        // {
        //     name: 'Region',
        //     selector: (row: { region_name: string; }) => row.region_name ? row.region_name : '---',
        //     sortable: true,
        //     sortField: 'region',
        // },
        // {
        //     name: 'Area',
        //     selector: (row: { area_name: string; }) => row.area_name ? row.area_name : '---',
        //     sortable: true,
        //     sortField: 'area',
        // },
        {
            name: 'Franchise',
            selector: (row: { franchise_name: string; }) => row.franchise_name ? row.franchise_name : '---',
            sortable: true,
            sortField: 'franchise_id',
            wrap: true,
        },
        // {
        //     name: 'Description',
        //     selector: (row: { description: string; }) => row.description ? row.description : '---',
        //     sortable: true,
        //     sortField: 'description',
        //     wrap: true,
        // },
        {
            name: 'Start Date',
            selector: (row: { start_date: string; }) => row.start_date,
            format: (row: { start_date: string; }) => row.start_date ? moment(row.start_date).format('DD MMM YYYY') : '---',
            sortable: true,
            sortField: 'start_date',
            wrap: true,
        },
        {
            name: 'End Date',
            selector: (row: { end_date: string; }) => row.end_date,
            format: (row: { end_date: string; }) => row.end_date ? moment(row.end_date).format('DD MMM YYYY') : '---',
            sortable: true,
            sortField: 'end_date',
            wrap: true,
        },
        {
            name: 'Status',
            selector: (row: { status: string; }) => row.status,
            cell: (row: { status: string; }) => (
                <span className="badge text-uppercase">{ row.status }</span>
            ),
            sortable: true,
            sortField: 'status',
        },
        {
            name: 'Comment',
            selector: (row: { comment: string; }) => row.comment ? row.comment : '---',
            sortable: true,
            sortField: 'comment',
            wrap: true,
        },
        {
            name: 'Action Date',
            selector: (row: { created_at: string; }) => row.created_at,
            format: (row: { created_at: string; }) => moment(row.created_at).format('DD MMM YYYY hh:mm A'),
            sortable: true,
            sortField: 'created_at',
            wrap: true,
        },
    ];

    const ExpandedComponent = ({ data }) => <div className='border border-secondary rounded'>
        <div className="row m-2" style={{fontSize: '14px'}}>
            <div className="col-sm-3">
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Country </label>
                    <dd>{data?.country_name ? data?.country_name : '---'}</dd>
                </div>
            </div>
            <div className="col-sm-3">
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Region </label>
                    <dd>{data?.region_name ? data?.region_name : '---'}</dd>
                </div>
            </div>
            <div className="col-sm-3">
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Area </label>
                    <dd>{data?.area_name ? data?.area_name : '---'}</dd>
                </div>
            </div>
            <div className="col-sm-3">
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Description </label>
                    <dd>{data?.description ? data?.description : '---'}</dd>
                </div>
            </div>
        </div>
    </div>;

    const handlePageChange = page => {
        getPostcodeHistoryDetails(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getPostcodeHistoryDetails(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getPostcodeHistoryDetails(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        setSearch({name: '', country: '', region: '', area: '', franchiseId: '', status: '', action: ''});
    };

    return (
        <div>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Postcode History</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item"><a href="/postcodes">Postcode</a></li>
                                <li className="breadcrumb-item active"> History</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div style={{ marginBottom: 10 }} className="row">
                                
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Postcode History - <strong> { postcodeDetails.name } </strong></h3>
                                    <div className="col-lg-12 text-right">
                                        <Link className="btn btn-default mr-2" to="/postcodes" > Back </Link>
                                        <Link className="btn btn-primary" to={ `/postcodes/edit/${id}`} > Edit </Link>
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <div className="row mb-2">
                                        <div className="col-sm-2">
                                            <select
                                                className="form-control"
                                                name="action"
                                                onChange={(e) => setSearch({ ...search, action: e.target.value })}
                                                value={search.action}
                                            >
                                                <option value="">Search By Action</option>
                                                {actions.map((obj) => (
                                                    <option key={obj.value} value={obj.value}>
                                                        {obj.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-2">
                                            <select
                                                className="form-control"
                                                name="status"
                                                onChange={(e) => setSearch({ ...search, status: e.target.value })}
                                                value={search.status}
                                            >
                                                <option value="">Search By Status</option>
                                                {postcodeStatus.map((obj) => (
                                                    <option key={obj.value} value={obj.value}>
                                                        {obj.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-sm-2'>
                                            <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                        </div>
                                    </div>

                                    <DataTable
                                        columns={columns}
                                        data={postcodeHistory}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={currentPage}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                        progressPending={isLoading}
                                        sortServer
                                        onSort={handleSort}
                                        customStyles={customStyle}
                                        expandableRows
                                        expandableRowsComponent={ExpandedComponent}
                                    />
                                </div>
                                {/* /.card-body */}
                            </div>
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>
    )
}

export default PostcodeHistory