import React, { useEffect, useState } from 'react'
import Table from '@app/components/datatable/Table';
import { Link } from 'react-router-dom';
import { deleteRoleById, getAllRoles } from '@app/services/role';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const ListRoles = () => {

    // const CryptoJS = require("crypto-js");
    const [roles, setRoles] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const permissions = useSelector((state: any) => state.auth.permissions);

    useEffect(() => {
        getAllRolesData();
    },[]);

    const getAllRolesData = async () => {
        try {
            setLoading(true);
            const rolesResp = await getAllRoles();
            if (rolesResp.success === true) {
                setLoading(false);
                setRoles(rolesResp.collections);
            } else {
                setLoading(false);
                toast.error(rolesResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const columns = [
        {
            name: 'Name',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
        },
        {
            name: 'Group',
            selector: (row: { group: string; }) => row.group,
            sortable: true,
        },
        // {
        //     name: 'Status',
        //     selector: (row: { status: string; }) => row.status,
        //     cell: (row: { status: string; }) => (
        //         row.status === 'Active' ? <span className="badge bg-success">{ row.status }</span> : <span className="badge bg-danger">{ row.status }</span>
        //     )
        // },
        {  
            name: 'Action',
            cell: (row: { id: number; }) => (
              <>
                { permissions?.roles?.edit &&
                    <Link
                    to={`/roles/edit/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    >
                    <i className="fas fa-pen mr-2"/>
                    </Link>
                }
                { permissions?.roles?.delete &&
                    <a href='#' onClick={ (e) => { deleteRoleConfirm(e, row.id)} }>
                        <i className="fas fa-trash mr-2"/>
                    </a>
                }
                { permissions?.roles['update-role-permissions'] &&      
                    <Link
                        to={`/roles/edit/permission/${row.id}`}
                        role="button"
                        state={{ id: row.id }}
                    >
                        <i className="fa fa-user-shield mr-2"/>
                    </Link>
                }   
                {/* <i className="fas fa-eye"/> */}
              </>
            ),
        },
    ];

    const deleteRoleConfirm = (e, id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to delete this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteRole(id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const deleteRole = async (id) => {
        try {
            setLoading(true);
            const resp = await deleteRoleById(id);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                getAllRolesData();
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manage Roles</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active">Manage Roles</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Manage Roles</h3>
                                        <div className="col-lg-12 text-right">
                                            { permissions?.roles?.create &&
                                                <Link className="btn btn-primary" to="/roles/add" > + Add New Role </Link>
                                            }
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <Table
                                            columns={columns}
                                            data={roles}
                                            pagination
                                            // selectableRows
                                            progressPending={ isLoading }
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>

        </div>
    )
};

export default ListRoles;
