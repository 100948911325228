import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { deleteHandbookById, getHandbooksWithPagination } from '@app/services/handbook';
import { getAllSubjectModules } from '@app/services/module';
import { getAllSubjects } from '@app/services/subject';

const ListHandbooks = () => {

    const [handbooks, setHandbooks] = useState([]);
    const [modules, setModules] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const canAccess = useSelector((state: any) => state.auth.permissions.handbooks);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({name: '', subjectId: '', moduleId: '', price: '', description: '', status: ''});
    const handbookStatus = [{value:'active', label:'Active'}, {value:'inactive', label:'Inactive'}]

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    useEffect(() => {
        getAllHandbooksData(1, perPage, sortField, sortDirection);
    },[search]);

    useEffect(() => {
        getAllModuleData();
        getAllSubjectsData();
    },[]);

    const getAllModuleData = async () => {
        try {
            const modulesResp = await getAllSubjectModules();
            if (modulesResp.success === true) {
                setModules(modulesResp.collections);
            } else {
                toast.error(modulesResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllSubjectsData = async () => {
        try {
            const subjectsResp = await getAllSubjects();
            if (subjectsResp.success === true) {
                setSubjects(subjectsResp.collections);
            } else {
                toast.error(subjectsResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllHandbooksData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const resp = await getHandbooksWithPagination(page, perPage, sortField, sortDirection, search);
            if (resp.success === true) {
                setLoading(false);
                setHandbooks(resp?.collections?.data);
                setTotalRows(resp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const columns = [
        {
            name: 'Subject',
            selector: (row: { subject_name: string; }) => row.subject_name,
            sortable: true,
            sortField: 'subject_name',
        },
        {
            name: 'Module Id',
            selector: (row: { module_abbr: string; }) => row.module_abbr,
            sortable: true,
            sortField: 'module_abbr',
        },
        {
            name: 'Handbook Name',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            sortField: 'name',
        },
        {
            name: 'Description',
            selector: (row: { description: string; }) => row.description,
            sortable: true,
            sortField: 'description',
        },
        {
            name: 'Price',
            selector: (row: { price: string; }) => '£' + row.price,
            sortable: true,
            sortField: 'price',
        },
        {
            name: 'Status',
            selector: (row: { status: string; }) => row.status,
            cell: (row: { status: string; }) => (
                row.status === 'active' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>
            ),
            sortable: true,
            sortField: 'status',
        },
        // {  
        //     name: 'Action',
        //     cell: (row: { id: number; }) => (
        //       <>
        //         { canAccess?.edit &&
        //             <Link
        //             to={`/handbooks/edit/${row.id}`}
        //             role="button"
        //             state={{ id: row.id }}
        //             >
        //             <i className="fas fa-pen mr-2"/>
        //             </Link>
        //         }
        //         { canAccess?.delete &&
        //             <a href='#' onClick={ (e) => { deleteItemConfirm(e, row.id)} }>
        //                 <i className="fas fa-trash mr-2"/>
        //             </a>
        //         }
        //       </>
        //     ),
        // },
    ];

    const deleteItemConfirm = (e, id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to delete this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteItem(id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const deleteItem = async (id) => {
        try {
            setLoading(true);
            const resp = await deleteHandbookById(id);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                getAllHandbooksData(currentPage, perPage, sortField, sortDirection);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const handlePageChange = page => {
        getAllHandbooksData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllHandbooksData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllHandbooksData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        setSearch({name: '', subjectId: '', moduleId: '', price: '', description: '', status: ''});
    };

    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manage Handbooks</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active">Manage Handbooks</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    <div className="col-lg-12 text-right">
                                    {/* { canAccess?.create &&
                                        <Link className="btn btn-primary" to="/handbooks/add" > + Add New Handbook </Link>
                                    } */}
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Manage Handbooks</h3>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="subjectId"
                                                    onChange={(e) => setSearch({ ...search, subjectId: e.target.value })}
                                                    value={search.subjectId}
                                                >
                                                    <option value="">Search By Subject</option>
                                                    {subjects.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="module"
                                                    onChange={(e) => setSearch({ ...search, moduleId: e.target.value })}
                                                    value={search.moduleId}
                                                >
                                                    <option value="">Search By Module</option>
                                                    {modules.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='name' className="form-control" value={search.name} onChange={(e) => setSearch({ ...search, name: e.target.value })} placeholder="Search By Handbook Name" />
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='description' className="form-control" value={search.description} onChange={(e) => setSearch({ ...search, description: e.target.value })} placeholder="Search By Description" />
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='price' className="form-control" value={search.price} onChange={(e) => setSearch({ ...search, price: e.target.value })} placeholder="Search By Price" />
                                            </div>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={(e) => setSearch({ ...search, status: e.target.value })}
                                                    value={search.status}
                                                >
                                                    <option value="">Search By Status</option>
                                                    {handbookStatus.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className='col-sm-2'>
                                                <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                            </div>
                                        </div>

                                        <DataTable
                                            columns={columns}
                                            data={handbooks}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationDefaultPage={currentPage}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            paginationPerPage={perPage}
                                            paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                            progressPending={isLoading}
                                            sortServer
                                            onSort={handleSort}
                                            // paginationComponent
                                            // selectableRows
                                            customStyles={customStyle}
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>

        </div>
    )
};

export default ListHandbooks;