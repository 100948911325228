import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@components';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { setWindowClass } from '@app/utils/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { recoverPassword, resendOTP } from '@app/services/auth';
import { useDispatch, useSelector } from 'react-redux';
import { setRecoverPasswordEmail } from '@app/store/reducers/auth';

const RecoverPassword = () => {
	const [t] = useTranslation();
	const [otp, setOtp] = useState(new Array(4).fill(""));
	const [isLoading, setLoading] = useState(false);
	const [isOTPError, setOTPError] = useState(false);
	const recoverPasswordEmail = useSelector((state: any) => state.auth.recoverPasswordEmail);
	const [ minutes, setMinutes ] = useState(3);
    const [ seconds, setSeconds ] =  useState(0);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		let myInterval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(myInterval)
				} else {
					setMinutes(minutes - 1);
					setSeconds(59);
				}
			}
			if(seconds === 0 && minutes === 0){
				clearInterval(myInterval)
			}
		}, 1000)
		return () => {
			clearInterval(myInterval);
		}
	});

	const submit = async (payload) => {
		try {
			setLoading(true);
			let otpStr = otp.join('');
			if(otpStr.length === 4 ){
				setOTPError(false);
				payload.email = recoverPasswordEmail;
				payload.otp = otpStr;
				const resp = await recoverPassword(payload);
				if (resp.success === true) {
					setLoading(false);
					dispatch(setRecoverPasswordEmail(''));
					toast.success(resp?.message);
					navigate('/login');
				} else {
					setLoading(false);
					toast.error(resp?.errors[0]?.msg);
				}
			}else{
				setOTPError(true);
			}
		} catch (error: any) {
			setLoading(false);
			toast.error(error?.response?.message || 'Failed');
		}
	}

	const resendOTPHandle = async () => {
		try {
			setLoading(true);
			const resp = await resendOTP({email: recoverPasswordEmail});
			if (resp.success === true) {
				setLoading(false);
				toast.success(resp?.message);
				setMinutes(3);
			} else {
				setLoading(false);
				toast.error(resp?.errors[0]?.msg);
			}
		} catch (error: any) {
			setLoading(false);
			toast.error(error?.response?.message || 'Failed');
		}
	}

	const { handleChange, values, handleSubmit, touched, errors } = useFormik({
		initialValues: {
			password: '',
			confirmPassword: ''
		},
		validationSchema: Yup.object({
			password: Yup.string()
				.min(8, 'Must be 8 characters or more')
				.max(30, 'Must be 30 characters or less')
				.required('Password is required'),
			confirmPassword: Yup.string()
				.min(8, 'Must be 8 characters or more')
				.max(30, 'Must be 30 characters or less')
				.required('Confirm password is required')
				.when('password', {
					is: (val: string) => !!(val && val.length > 0),
					then: Yup.string().oneOf(
						[Yup.ref('password')],
						'Both password need to be the same'
					)
				})
		}),
		onSubmit: (values) => {
			submit(values);
		}
	});

	const OTPChange = (element, index) => {
        if (isNaN(element.value)) return false;
		
		setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        //Focus next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

	setWindowClass('hold-transition login-page');
	return (
		<div className="login-box">
			<div className="card card-outline card-primary">
				<div className="card-header text-center">
					<Link to="/" className="h1">
						{/* <b>FCL </b> */}
						<span>Recover Password</span>
					</Link>
				</div>
				<div className="card-body">
					<p className="login-box-msg">{t('recover.oneStepAway')}</p>
					<form onSubmit={handleSubmit}>
						<div className="mb-3 d-flex justify-content-center">
							{otp.map((data, index) => {
								return (
									<input
										className="otp-field"
										type="text"
										name="otp"
										// defaultValue=""
										// pattern="\d*"
										maxLength={1}
										key={index}
										value={data}
										onChange={e => {
											OTPChange(e.target, index);
										}}
										onFocus={e => e.target.select()}
									/>
								);
							})}
						</div>
						{ isOTPError && (
							<div>
							<Form.Control.Feedback className='mb-3' type="invalid" style={{display:'block'}}>
								OTP is required
							</Form.Control.Feedback>
							</div>
						)}

						<div className="row mb-2">
							<p className="col-6" style={{color: '#007bff'}}>
								{ (minutes != 0 || seconds != 0) && (
									<>Resend OTP in {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</> 
								)}
							</p>
							<div className="col-6 text-right">
								<Button type="submit" 
									className='btn-xs btn-outline-primary' 
									theme="btn-outline-primary" 
									disabled={(minutes != 0 || seconds != 0)} 
									isLoading={isLoading}
									onClick={resendOTPHandle}
								>
									Resend OTP
								</Button>
							</div>
						</div>

						<div className="mb-3">
							<InputGroup className="mb-3">
								<Form.Control
									id="password"
									name="password"
									type="password"
									placeholder="Password"
									onChange={handleChange}
									value={values.password}
									isValid={touched.password && !errors.password}
									isInvalid={touched.password && !!errors.password}
									onFocus={e => e.target.select()}
									key="5"
								/>
								{touched.password && errors.password ? (
									<Form.Control.Feedback type="invalid">
										{errors.password}
									</Form.Control.Feedback>
								) : (
									<InputGroup.Append>
										<InputGroup.Text>
											<FontAwesomeIcon icon={faLock} />
										</InputGroup.Text>
									</InputGroup.Append>
								)}
							</InputGroup>
						</div>
						<div className="mb-3">
							<InputGroup className="mb-3">
								<Form.Control
									id="confirmPassword"
									name="confirmPassword"
									type="password"
									placeholder="Confirm password"
									onChange={handleChange}
									value={values.confirmPassword}
									isValid={touched.confirmPassword && !errors.confirmPassword}
									isInvalid={
										touched.confirmPassword && !!errors.confirmPassword
									}
								/>
								{touched.confirmPassword && errors.confirmPassword ? (
									<Form.Control.Feedback type="invalid">
										{errors.confirmPassword}
									</Form.Control.Feedback>
								) : (
									<InputGroup.Append>
										<InputGroup.Text>
											<FontAwesomeIcon icon={faLock} />
										</InputGroup.Text>
									</InputGroup.Append>
								)}
							</InputGroup>
						</div>
						<div className="row">
							<div className="col-12">
								<Button type="submit" block isLoading={isLoading}>
									{/* @ts-ignore */}
									{t('recover.changePassword')}
								</Button>
							</div>
						</div>
					</form>
					<p className="mt-3 mb-1">
						<Link to="/login">Login</Link>
					</p>
				</div>
			</div>
		</div>
	);
};

export default RecoverPassword;
