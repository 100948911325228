import React, { useEffect, useState } from 'react'
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button } from '@app/components';
import { Link } from 'react-router-dom';

const SortModulesOrder = () => {

    const modules = [
        {
            "id": "item-0",
            "content": "item 0"
        },
        {
            "id": "item-1",
            "content": "item 1"
        },
        {
            "id": "item-3",
            "content": "item 3"
        },
        {
            "id": "item-2",
            "content": "item 2"
        },
        {
            "id": "item-4",
            "content": "item 4"
        },
        {
            "id": "item-5",
            "content": "item 5"
        },
        {
            "id": "item-6",
            "content": "item 6"
        },
        {
            "id": "item-7",
            "content": "item 7"
        },
        {
            "id": "item-8",
            "content": "item 8"
        },
        {
            "id": "item-9",
            "content": "item 9"
        },
        {
            "id": "item-10",
            "content": "item 10"
        },
        {
            "id": "item-11",
            "content": "item 11"
        },
        {
            "id": "item-12",
            "content": "item 12"
        },
        {
            "id": "item-13",
            "content": "item 13"
        },
        {
            "id": "item-14",
            "content": "item 14"
        },
        {
            "id": "item-15",
            "content": "item 15"
        },
        {
            "id": "item-16",
            "content": "item 16"
        }
    ]
    const [items, setItems] = useState([]);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        setItems(modules);
    },[]);
    
    const grid = 6;
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        textAlign: "center",

        // change background colour if dragging
        background: isDragging ? "lightgreen" : "grey",

        // styles we need to apply on draggables
        ...draggableStyle
    });
    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        padding: grid,
        width: 450
    });

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        console.log(result)
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const modules = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(modules);
    }
    
    return (
        <div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Reorder Module</h3>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <Droppable droppableId="droppable">
                                                    {(provided, snapshot) => (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                            style={getListStyle(snapshot.isDraggingOver)}
                                                            className="scroll"
                                                        >
                                                            {items.map((item, index) => (
                                                                <Draggable key={item.id} draggableId={item.id} index={index} >
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={getItemStyle(
                                                                                snapshot.isDragging,
                                                                                provided.draggableProps.style
                                                                            )}
                                                                        >
                                                                            {item.content}
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </div>
                                        <div className='col-sm-6'>
                                            <h3>Keyboard shortcuts: keyboard dragging</h3>
                                            <p>When a drag is not occurring, the user will be able to navigate through the DRAGABBLE ITEM's on a page using the standard tab <kbd>tab ↹</kbd> key to move forward through the tabbable elements and (shift + tab) ( <kbd>shift</kbd> + ) <kbd>tab ↹</kbd> ) to move backwards. We achieve this by adding a tab-index to the DRAGABBLE ITEM. When a DRAGABBLE ITEM has focus the <b>spacebar</b> <kbd>space</kbd> will lift a DRAGABBLE ITEM. This will start the drag.</p>

                                            <p>Once a drag is started the following keyboard shortcuts can be used:</p>

                                            <p><li> <b>spacebar</b> <kbd>space</kbd> - drop the DRAGABBLE ITEM</li></p>
                                            <p><li> <b>escape</b> <kbd>esc</kbd> - cancel the drag</li></p>
                                            <p>The following commands are also available but they depend on the type of DROPPABLE ITEM that the DRAGABBLE ITEM is currently in:</p>

                                            <h4> Within a vertical list</h4>
                                             <p><li> <b>Up arrow</b> <kbd>↑</kbd> - move a DRAGABBLE ITEM upwards in a DROPPABLE ITEM</li> </p>
                                             <p><li> <b>Down arrow</b> <kbd>↓</kbd> - move a DRAGABBLE ITEM downwards in a DROPPABLE ITEM</li> </p>
                                             <p><li> <b>Right arrow</b> <kbd>→</kbd> - move a DRAGABBLE ITEM to a DROPPABLE ITEM to the right of the current DROPPABLE ITEM (move to new list)</li> </p>
                                             <p><li> <b>Left arrow</b> <kbd>←</kbd> - move a DRAGABBLE ITEM to a DROPPABLE ITEM to the left of the current DROPPABLE ITEM (move to new list)</li> </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Save </Button>
                                    <Link className="btn btn-default" to='/modules'>Cancel</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default SortModulesOrder;
