import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {toast} from 'react-toastify';
import { Button } from '@app/components';
import DataTable, { TableStyles } from 'react-data-table-component';
import moment from 'moment';
import { getPricingPlanById, getPricingPlanHistoryByPlanIdWithPagination } from '@app/services/pricingPlans';

const PlanHistory = () => {

    let obj: any = {}
    const [planHistory, setPlanHistory] = useState([]);
    const [tiers, setPlanTiers] = useState([]);
    const [plan, setPlanData] = useState(obj);
    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({type: ''});
    const actions = [
        {label: 'Created', value: 'created'}, 
        {label: 'Updated Default Plan', value: 'updated_default_plan'}, 
        {label: 'Attached Franchise', value: 'attached_franchise'}, 
        {label: 'Detached Franchise', value: 'detached_franchise'},
        {label: 'Updated Subject Prices', value: 'updated_subject_prices'}];
    
    const navigate = useNavigate();
    let { id } = useParams();

    useEffect(() => {
        getPlanDetailsById(id);
    },[]);

    useEffect(() => {
        getPricingPlanHistory(1, perPage, sortField, sortDirection);
    },[search]);

    const getPlanDetailsById = async (id) => {
        try {
            setLoading(true);
            const resp = await getPricingPlanById(id);
            if(resp.success === true){
                setLoading(false);
                setPlanData(resp.collections);
                setPlanTiers(resp?.collections?.tiers)
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
                navigate('/pricing-plans');
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getPricingPlanHistory = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const resp = await getPricingPlanHistoryByPlanIdWithPagination(id, page, perPage, sortField, sortDirection, search);
            if (resp.success === true) {
                setLoading(false);
                setPlanHistory(resp?.collections?.data);
                setTotalRows(resp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    const columns = [
        {
            name: 'Action',
            selector: (row: { type: string; }) => row.type,
            cell: (row: { type: string, planType: object}) => (
                <>
                {
                    // row.planType = actions.find((data) => data.value === 'created')
                    row.type !== null ? <span className="badge badge-dark">{ (actions.find((data) => data.value === row.type).label )} </span> : '---'
                }
            </>
            ),
            sortable: true,
            sortField: 'type',
        },
        {
            name: 'Franchise',
            selector: (row: { franchise_name: string; }) => row.franchise_name ? row.franchise_name : '---',
            sortable: true,
            sortField: 'franchise_id',
            wrap: true,
        },
        {
            name: 'Start Date',
            selector: (row: { start_date: string; }) => row.start_date,
            format: (row: { start_date: string; }) => row.start_date ? moment(row.start_date).format('DD MMM YYYY') : '---',
            // sortable: true,
            // sortField: 'start_date',
            wrap: true,
        },
        {
            name: 'End Date',
            selector: (row: { end_date: string; }) => row.end_date,
            format: (row: { end_date: string; }) => row.end_date ? moment(row.end_date).format('DD MMM YYYY') : '---',
            // sortable: true,
            // sortField: 'end_date',
            wrap: true,
        },
        {
            name: 'Status',
            selector: (row: { status: string; }) => row.status,
            cell: (row: { status: string; }) => (
                <span className="badge text-uppercase">{ row.status }</span>
            ),
            sortable: true,
            sortField: 'status',
        },
        {
            name: 'Is Default?',
            selector: (row: { is_default: string; }) => row.is_default == 'true' ? 'Yes' : 'No',
            sortable: true,
            sortField: 'is_default',
            wrap: true,
        },
        {
            name: 'Date',
            selector: (row: { created_at: string; }) => row.created_at,
            format: (row: { created_at: string; }) => moment(row.created_at).format('DD MMM YYYY hh:mm A'),
            sortable: true,
            sortField: 'created_at',
            wrap: true,
        },
    ];

    const ExpandedComponent = ({ data }) => <div className='border border-secondary rounded'>
        <div className="row m-2" style={{fontSize: '14px'}}>
            <div className='col-md-12 mt-3'>
                <h5>Pricing</h5>
            </div>
            <div className="col-md-6">
                {data.subjects?.length > 0 && (
                    <table className="table table-bordered" id="client_id_table">
                        <tbody>
                            <tr className='text-center'>
                                <th>Subject</th>
                                { tiers.map((tier) => (
                                    <th key={tier?.id}>{ tier?.name } (Range { tier?.from_count } - { tier?.to_count })</th>
                                ))}
                            </tr>
                            {data.subjects?.length > 0  && data.subjects.map((subject) => (
                                <tr key={subject.id}>
                                    <td>{ subject.name }</td>
                                    {(subject?.tiers).length > 0 && (subject?.tiers).map((tier) => (
                                        <td key={tier.id}>£ { tier.price }</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                {data.subjects?.length == 0 && (
                    <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                )}
            </div>
        </div>
    </div>;

    const handlePageChange = page => {
        getPricingPlanHistory(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getPricingPlanHistory(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getPricingPlanHistory(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        setSearch({type: ''});
    };

    return (
        <div>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Pricing Plan History</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item"><a href="/pricing-plans">Pricing Plan</a></li>
                                <li className="breadcrumb-item active"> History</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div style={{ marginBottom: 10 }} className="row">
                                
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Pricing Plan History - <strong> { plan.name } </strong></h3>
                                    <div className="col-lg-12 text-right">
                                        <Link className="btn btn-default mr-2" to="/pricing-plans" > Back </Link>
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <div className="row mb-2">
                                        <div className="col-sm-2">
                                            <select
                                                className="form-control"
                                                name="type"
                                                onChange={(e) => setSearch({ ...search, type: e.target.value })}
                                                value={search.type}
                                            >
                                                <option value="">Search By Action</option>
                                                {actions.map((obj) => (
                                                    <option key={obj.value} value={obj.value}>
                                                        {obj.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-sm-2'>
                                            <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                        </div>
                                    </div>

                                    <DataTable
                                        columns={columns}
                                        data={planHistory}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={currentPage}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                        progressPending={isLoading}
                                        sortServer
                                        onSort={handleSort}
                                        customStyles={customStyle}
                                        expandableRows
                                        expandableRowsComponent={ExpandedComponent}
                                    />
                                </div>
                                {/* /.card-body */}
                            </div>
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>
    )
}

export default PlanHistory