import { forgotPasswordURL, getDashboardURL, loginURL, logoutURL, recoverPasswordURL, resendOTPURL } from '@app/constants/ApiEndPoints';
import {removeWindowClass} from '@app/utils/helpers';
import axios from 'axios';
import {Gatekeeper} from 'gatekeeper-client-sdk';

export const loginByAuth = async (payload) => {
	try {
		const response = await axios.post(loginURL, payload);
		if (response.status === 200 /* && response.statusText === "OK"*/) {
			if (response.data.status === 200 && response.data.token) {
				localStorage.setItem('token', response.data.token);
				removeWindowClass('login-page');
				removeWindowClass('hold-transition');
			}
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const forgotPassword = async (payload) => {
	try {
		const response = await axios.post(forgotPasswordURL, payload);
		if (response.status === 201 /* && response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const recoverPassword = async (payload) => {
	try {
		const response = await axios.post(recoverPasswordURL, payload);
		if (response.status === 201 /* && response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const resendOTP = async (payload) => {
	try {
		const response = await axios.post(resendOTPURL, payload);
		if (response.status === 201 /* && response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getDashboard = async () => {
	try {
		const response = await axios.get(getDashboardURL);
		if (response.status === 200 /* && response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

// export const loginByAuth = async (email: string, password: string) => {
//   const token = await Gatekeeper.loginByAuth(email, password);
//   localStorage.setItem('token', token);
//   removeWindowClass('login-page');
//   removeWindowClass('hold-transition');
//   return token;
// };

export const registerByAuth = async (email: string, password: string) => {
  const token = await Gatekeeper.registerByAuth(email, password);
  localStorage.setItem('token', token);
  removeWindowClass('register-page');
  removeWindowClass('hold-transition');
  return token;
};

export const loginByGoogle = async () => {
  const token = await Gatekeeper.loginByGoogle();
  localStorage.setItem('token', token);
  removeWindowClass('login-page');
  removeWindowClass('hold-transition');
  return token;
};

export const registerByGoogle = async () => {
  const token = await Gatekeeper.registerByGoogle();
  localStorage.setItem('token', token);
  removeWindowClass('register-page');
  removeWindowClass('hold-transition');
  return token;
};

export const loginByFacebook = async () => {
  const token = await Gatekeeper.loginByFacebook();
  localStorage.setItem('token', token);
  removeWindowClass('login-page');
  removeWindowClass('hold-transition');
  return token;
};

export const registerByFacebook = async () => {
  const token = await Gatekeeper.registerByFacebook();
  localStorage.setItem('token', token);
  removeWindowClass('register-page');
  removeWindowClass('hold-transition');
  return token;
};

export const logOutUser = async () => {
	try {
		const response = await axios.get(logoutURL);
		if (response.status === 200 /* && response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};
