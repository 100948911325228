import { createCentreURL, deleteCentreURL, getAllActiveCentreURL, getCentreByIdURL, getCentresWithPaginationURL, updateCentreURL,getCentreByFranchiseIdURL } from "@app/constants/ApiEndPoints";
import axios from "axios";

export const getCentresWithPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(getCentresWithPaginationURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&name=${search.name}&franchiseId=${search.franchiseId}&postcodeId=${search.postcodeId}&areaCode=${search.areaCode}&status=${search.status}&email=${search.email}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllActiveCentre = async () => {
	try {
		const response = await axios.get(getAllActiveCentreURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const createCentre = async (payload) => {
	try {
		const response = await axios.post(createCentreURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getCentreById = async (id: number) => {
	try {
		const response = await axios.get(getCentreByIdURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getCentresByFranchiseId = async (id: number) => {
	try {
		const response = await axios.get(getCentreByFranchiseIdURL + id+'/all');
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateCentre = async (id, payload) => {
	try {
		const response = await axios.put(updateCentreURL + id, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const deleteCentreById = async (id: number) => {
	try {
		const response = await axios.delete(deleteCentreURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};