import "../../pages/order-form/OrderForm.css";
import { Form, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { getAllActiveModulesBySubject } from "@app/services/module";
import { Button } from "@app/components";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { addWSToPrintQueue, clearPrintQueueByStudentId, deleteItemFromPrintQueue, getPrintQueueByStudentId, getStudentById } from "@app/services/student";
import { Link, useNavigate, useParams } from "react-router-dom";
import { clearQueue, loadQueue } from "@app/store/reducers/printQueue";
import PrintWorksheetsPDF from "./set-work/PrintWorksheetsPDF";

const AdhocPrinting = () => {

    let { id } = useParams();
	let obj: any = {}
	const [subjects, setSubjects] = useState([]);
	const [modules, setModules] = useState([]);
	const [worksheets, setWorksheets] = useState([]);
	const [totalWSCount, setTotalWSCount] = useState(0);
    const [student, setStudent] = useState(obj);
	const [isSubjectsLoading, setSubjectsLoading] = useState(false);
    const [isModulesLoading, setModulesLoading] = useState(false);
    const [isQueueLoading, setQueueLoading] = useState(false);
    const queue = useSelector((state: any) => state.printQueue.queue);
    const dispatch = useDispatch();
	const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
	const navigate = useNavigate();

	useEffect(() => {
		getStudentDetailsById(id);
    },[]);

	useEffect(() => {
		getQueueByStudentId(id)
    },[show]);

	const getStudentDetailsById = async (id) => {
        try {
			setSubjectsLoading(true)
            const resp = await getStudentById(parseInt(id));
            if (resp.success === true) {
				if(resp?.collections?.status == 'active' && resp?.collections?.enabled_adhoc_printing == true){
					setSubjectsLoading(false)
					setStudent(resp?.collections)
					setSubjects(resp?.collections?.subjects);
				}else{
					navigate('/students');
				}
            } else {
				setSubjectsLoading(false)
                toast.error(resp?.errors[0]?.msg)
            }
        } catch (error: any) {
			setSubjectsLoading(false)
            toast.error(error?.message || 'Internal server error');
        }
    };

	const getQueueByStudentId = async (id) => {
        try {
            setQueueLoading(true);
            const resp = await getPrintQueueByStudentId(id);
            if (resp?.success === true) {
				setQueueLoading(false);
				setTotalWSCount(resp?.collections?.total_worksheets)
				dispatch(loadQueue(resp?.collections?.queue));
            } else {
				setQueueLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setQueueLoading(false);
			toast.error(error?.message || 'Internal server error');
        }
    };

	const getAllActiveModulesBySubjectData = async (subjectId) => {
		try {
			setModulesLoading(true);
            const resp = await getAllActiveModulesBySubject(subjectId);
            if (resp.success === true) {
				setModulesLoading(false);
                setModules(resp.collections);
            } else {
				setModulesLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
			setModulesLoading(false);
			toast.error(error?.message || 'Internal server error');
        }
	}

	const addWSToQueue = async (payload) => {
        try {
            setQueueLoading(true);
            const resp = await addWSToPrintQueue(payload);
            if(resp?.success === true){
                toast.success(resp?.message);
                setQueueLoading(false);
				getQueueByStudentId(id);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setQueueLoading(false);
            }
		} catch (error: any) {
			setQueueLoading(false);
			toast.error(error?.message || 'Internal server error');
		}
    };

	const deleteQueueItem = async (item_id) => {
        try {
			setQueueLoading(true);
			const resp = await deleteItemFromPrintQueue(item_id);
			if(resp.success === true){
				setQueueLoading(false);
				toast.success(resp?.message);
				getQueueByStudentId(id);
			}else{
				setQueueLoading(false);
				toast.error(resp?.errors[0]?.msg);
			}
		} catch (error: any) {
			setQueueLoading(false);
			toast.error(error?.message || 'Internal server error');
		}
    };

	const clearQueueConfirm = (e) => {
        confirmAlert({
            title: 'Please confirm!',
            message: 'Are you sure you want to clear the queue?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => clearPrintQueue()
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const clearPrintQueue = async () => {
        try {
            setQueueLoading(true);
            const resp = await clearPrintQueueByStudentId(id);
            if(resp.success === true){
                setQueueLoading(false);
                toast.success(resp?.message);
                dispatch(clearQueue());
				setTotalWSCount(0);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setQueueLoading(false);
            }
		} catch (error: any) {
			setQueueLoading(false);
			toast.error(error?.message || 'Internal server error');
		}
    };

	const onSubjectChange = (option, setFieldValue) => {
		if(option){
			setFieldValue("subject_id", option.id)
			getAllActiveModulesBySubjectData(option.id)
		}else{
			setFieldValue("subject_id", '')
			setFieldValue("module_id", '')
			setModules([])
			setWorksheets([])
		}
	}

	const onModuleChange = (option) => {
		if(option){
			setFieldValue("module_id", option.id)
			let options = [];
			// for(let i = 1; i <= option?.worksheet_count; i++){
			// 	options.push({ value: i, label: option?.name + '-' + i })
			// }
			options = Array.from({ length: option?.worksheet_count }, (_, index) => ({value: index + 1, label: option?.name + '-' + (++index)}) );
			setWorksheets(options)
		}else{
			setFieldValue("module_id", "")
			setWorksheets([])
		}
	}

	const { handleChange, values, handleSubmit, touched, errors, setFieldValue, resetForm} = useFormik({
        initialValues: {
			student_id : id,
			subject_id: "",
			module_id: "",
			worksheets: []
		},
        validationSchema: Yup.object({
            subject_id: Yup.string().required('Please select subject').nullable(),
            module_id: Yup.string().required('Please select module').nullable(),
            worksheets: Yup.array()
			.of(Yup.string().trim())
            .min(1, "Please select at least 1 worksheet")
			.required('Please select worksheet(s)'),
        }),
        onSubmit: (values) => {
			
			addWSToQueue(values);
			
			// Reset form
			resetForm()
			setWorksheets([])
			setFieldValue("subject_id", values.subject_id)
        },
		enableReinitialize: true,
    });

	return (
		<section className="modul_sec_wrapper">
			<div className="col-md-12">
				<div className="card card-primary mb-0">
					<div className="card-header">
						<h3 className="card-title">Adhoc Printing</h3>
						<div className="card-tools">
							<h3 className="card-title">You are printing for the student: <u>{ student?.first_name }  { student?.last_name }</u></h3>
						</div>
					</div>
				</div>
			</div>
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-12 col-lg-6 col-xl-6">
							<div className="tabs_wrapper">
								<div className="content">
									<Form onSubmit={handleSubmit}>
										<Row>
											<Col xl={6}>
												<Form.Group as={Row} className="mb-3">
													<Form.Label column xs="12">
														<b>Subject:</b>
													</Form.Label>
													<Col xs="12">
														<Select
															// className="form-control"
															name="subject_id"
															placeholder="Please Select Subject"
															options={subjects}
															getOptionValue={(option)=>`${option['id']}`}
															getOptionLabel={(option)=>`${option['name']}`}
															onChange={ (option) => {  
																handleChange
																onSubjectChange(option, setFieldValue) 
															}}
															defaultValue={subjects[0]}
															value={subjects.filter(function(option) {
																return option.id === values.subject_id;
															})}
															isLoading={isSubjectsLoading}
															isClearable={true}
														/>
														{(touched.subject_id && errors.subject_id) && (
															<div className="text-danger">
																{errors.subject_id}
															</div>
														)}
													</Col>
												</Form.Group>
											</Col>
											<Col xl={6}>
												<Form.Group as={Row} className="mb-3">
													<Form.Label column xs="12">
														<b>Module:</b>
													</Form.Label>
													<Col xs="12">
														<Select
															// className="form-control"
															name="module_id"
															placeholder="Please Select Module"
															options={modules}
															getOptionValue={(option)=>`${option['id']}`}
															getOptionLabel={(option)=>`${option['name']}`}
															onChange={ (option) => { 
																handleChange
																onModuleChange(option) 
															}}
															value={modules.filter(function(option) {
																return option.id === values.module_id;
															})}
															isLoading={isModulesLoading}
															isClearable={true}
														/>
														{(touched.module_id && errors.module_id) && (
															<div className="text-danger">
																{errors.module_id}
															</div>
														)}
													</Col>
												</Form.Group>
											</Col>
											<Col xl={12}>
												<Form.Group as={Row} className="mb-3">
													<Form.Label column xs="12">
														<b>Worksheet(s):</b>
													</Form.Label>
													<Col xs="12">
														<Select
															// className="form-control"
															isMulti
															name="worksheets"
															placeholder="Please Select Worksheets"
															options={worksheets}
															onChange={ (options) => {  
																handleChange
																setFieldValue("worksheets", [].slice.call(options).map((option) => option.value)) 
															}}
															value={
																worksheets?.length
																? worksheets.filter((obj) =>
																	values.worksheets.includes(obj.value)
																	)
																: values.worksheets
															} // set selected values
															isLoading={isSubjectsLoading}
															isClearable={true}
															closeMenuOnSelect={false}
														/>
														{(touched.worksheets && errors.worksheets) && (
															<div className="text-danger">
																{errors.worksheets}
															</div>
														)}
													</Col>
												</Form.Group>
											</Col>
										</Row>
										<div className="mt-4 d-flex justify-content-end">
											<Button type="submit" isLoading={isQueueLoading} className="btn btn-primary mr-2">Add To Queue </Button>
											<Link className="btn btn-default" to='/students'>Back</Link>
										</div>
									</Form>
								</div>
							</div>
					</div>
					<div className="col-md-12 col-lg-6 col-xl-6 d-flex">
						<div className="content">
							<h6 className="mb-2 grey_wrapper_title d-flex justify-content-between">Queue
								<span>
									<b>Total Worksheet(s):</b> { totalWSCount } <br></br>
								</span>
								<Button type="submit" disabled={queue.length == 0} isLoading={isQueueLoading} onClick={ clearQueueConfirm } className="btn btn-secondary btn-sm">Clear Queue </Button>
							</h6>
							<Form>
								{ queue.length == 0 && (
									<div className="content_inner_wrap"> 
									<Row>
										<Col xl={12}>
											Print queue is empty.
										</Col>
									</Row>
									</div>
								)}
								{ queue.length > 0 && (
									<div className="content_inner_wrap" key={3}>
										<Row>
											<Col xl={12}>
												<Row>
													<Form.Label column xs="4"><b>Module</b></Form.Label>
													<Form.Label column xs="4"><b>Worksheet(s)</b></Form.Label>
												</Row>
											</Col>
											<Col xl={12}>
												<div className="list_module_wrapper">
													<Row>
														<Col xl={12}>
															{ queue.length > 0 && queue.map((item, i) => (
																<Form.Group as={Row} className="mb-3" key={i}>
																	<Form.Label column xs="4">
																		<div className="d-flex">
																			{ item?.module_name }
																		</div>
																	</Form.Label>
																	<Col
																		xs="8"
																		className="d-flex align-items-center form_wrapper"
																	>
																		{ item?.worksheets.length > 0 ? Array.prototype.map.call(item?.worksheets, function(ws) { return ws; }).join(", ") : '---' }
																		<button 
																			type="button" 
																			className="cross_close"
																			onClick={ (e) => {  deleteQueueItem(item?.id) } }
																		>X</button>
																	</Col>
																</Form.Group>
															))}
														</Col>
													</Row>
												</div>
											</Col>
										</Row>
									</div>
								)}
								{ queue.length > 0 && (
									<div className="mt-4 d-flex justify-content-end align-items-center">
										<Button type="button" isLoading={isQueueLoading} onClick={handleShow} className="btn btn-primary float-right mr-2"> Process Queue </Button>
										<Link className="btn btn-default" to='/students'>Back</Link>
									</div>
								)}
							</Form>
						</div>
					</div>
				</div>
			</div>
			<PrintWorksheetsPDF
				isAdhoc={true}
				show={show}
				handleClose={handleClose}
				studId= {id}
				student={student}
			/>
		</section>
	);
};

export default AdhocPrinting;