import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { useSelector } from 'react-redux';
import { deleteModuleById, getModulesWithPagination } from '@app/services/module';
import { getAllSubjects } from '@app/services/subject';

const ListModules = () => {

    const [modules, setModules] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({subjectId: '', name: '', moduleAbbr: '', description: '', status: ''});
    const moduleStatus = [{value:'active', label:'Active'}, {value:'inactive', label:'Inactive'}]

    const canAccess = useSelector((state: any) => state.auth.permissions.modules);

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };
      
    useEffect(() => {
        getAllModulesData(1, perPage, sortField, sortDirection);
    },[search]);

    useEffect(() => {
        getAllSubjectsData();
    },[]);

    const getAllSubjectsData = async () => {
        try {
            const subjectsResp = await getAllSubjects();
            if (subjectsResp.success === true) {
                setSubjects(subjectsResp.collections);
            } else {
                toast.error(subjectsResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllModulesData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const resp = await getModulesWithPagination(page, perPage, sortField, sortDirection, search);
            if (resp.success === true) {
                setLoading(false);
                setModules(resp.collections.data);
                setTotalRows(resp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const columns = [
        {
            name: 'Subject',
            selector: (row: { subject_name: string; }) => row.subject_name,
            sortable: true,
            sortField: 'subject_id',
        },
        {
            name: 'Module Name',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            sortField: 'name',
            wrap: true,
        },
        {
            name: 'Module ID',
            selector: (row: { module_abbr: string; }) => row.module_abbr,
            sortable: true,
            sortField: 'module_abbr',
        },
        {
            name: 'Description',
            selector: (row: { description: string; }) => row.description ? row.description : '---',
            sortable: true,
            sortField: 'description',
            wrap: true,
        },
        {
            name: 'Status',
            selector: (row: { status: string; }) => row.status,
            cell: (row: { status: string; }) => (
                row.status === 'active' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>
            ),
            sortable: true,
            sortField: 'status',
        },
        {  
            name: 'Action',
            cell: (row: { id: number; }) => (
              <>
                { canAccess?.edit && 
                    <Link
                    to={`/modules/edit/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    >
                    <i className="fas fa-pen mr-2"/>
                    </Link>
                }
                { canAccess?.delete &&
                    <a href='#' onClick={ (e) => { deleteConfirm(e, row.id)} }>
                        <i className="fas fa-trash mr-2"/>
                    </a>
                }
                { canAccess?.history &&
                    <Link
                        to={`/modules/history/${row.id}`}
                        role="button"
                        state={{ id: row.id }}
                    >
                        <i className="fas fa-history mr-2" />
                    </Link>
                }
              </>
            ),
        },
    ];

    const deleteConfirm = (e, id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to delete this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteModule(id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const deleteModule = async (id) => {
        try {
            setLoading(true);
            const resp = await deleteModuleById(id);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                getAllModulesData(currentPage, perPage, sortField, sortDirection);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
          }
    };

    const handlePageChange = page => {
        getAllModulesData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllModulesData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllModulesData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        setSearch({subjectId: '', name: '', moduleAbbr: '', description: '', status: ''});
    };
    
    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manage Modules</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active">Manage Modules</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Manage Modules</h3>
                                        <div className="col-lg-12 text-right">
                                            { canAccess?.create && <Link className="btn btn-primary mr-2" to="/modules/add" > + Add New Module </Link> }
                                            {/* { canAccess?.reorder && <Link className="btn btn-secondary" to="/modules/reorder" ><i className="fas fa-sort mr-2"/> Reorder Modules </Link> } */}
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="subjectId"
                                                    onChange={(e) => setSearch({ ...search, subjectId: e.target.value })}
                                                    value={search.subjectId}
                                                >
                                                    <option value="">Search By Subject</option>
                                                    {subjects.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='name' className="form-control" value={search.name} onChange={(e) => setSearch({ ...search, name: e.target.value })} placeholder="Search By Module Name" />
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='moduleAbbr' className="form-control" value={search.moduleAbbr} onChange={(e) => setSearch({ ...search, moduleAbbr: e.target.value })} placeholder="Search By Module Id" />
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='description' className="form-control" value={search.description} onChange={(e) => setSearch({ ...search, description: e.target.value })} placeholder="Search By Description" />
                                            </div>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={(e) => setSearch({ ...search, status: e.target.value })}
                                                    value={search.status}
                                                >
                                                    <option value="">Search By Status</option>
                                                    {moduleStatus.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-sm-2'>
                                                <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                            </div>
                                        </div>
                                        
                                        <DataTable
                                            columns={columns}
                                            data={modules}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationDefaultPage={currentPage}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            paginationPerPage={perPage}
                                            paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                            progressPending={isLoading}
                                            sortServer
                                            onSort={handleSort}
                                            // paginationComponent
                                            // selectableRows
                                            customStyles={customStyle}
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>

        </div>
    )
};

export default ListModules;
