import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {toast} from 'react-toastify';
import { getLearningPathwayById } from '@app/services/learningPathway';
import { useSelector } from 'react-redux';
import PreviewWorksheetsPDF from './PreviewWorksheetsPDF';
import { getAllActiveFranchise } from '@app/services/franchise';

const ViewPathwayDetails = () => {

    const [pathwayDetails, setPathwayDetails] = useState([]);
    const [previewModuleId, setPreviewModuleId] = useState('');
    let { id } = useParams();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const canAccess = useSelector((state: any) => state.auth.permissions['learning-pathway']);
    const [wsPreview, setWsPreview] = useState(false);
    
    useEffect(() => {
        getAllActiveFranchiseData()
        getLearningPathwayByIdAPI(id);

        return () => {
            // Anything in here is fired on component unmount.
            // setPreviewModuleId('')
        }
    },[]);

    const getLearningPathwayByIdAPI = async (id) => {
        try {
            const resp = await getLearningPathwayById(id);
            if (resp?.success === true) {
                setPathwayDetails(resp?.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error');
        }
    };

    const getAllActiveFranchiseData = async () => {
        try {
            const resp = await getAllActiveFranchise();
            if (resp?.success === true) {
                if (resp?.collections.some(e => e.student_local_printing == true)) {
                    setWsPreview(true)
                }
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error');
        }
    };

    return (
        <div>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Learning Pathway Details</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item"><a href="/learning-pathway">Learning Pathways</a></li>
                                <li className="breadcrumb-item active"> Learning Pathway Details</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div style={{ marginBottom: 10 }} className="row">
                                
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Learning Pathway Details
                                        {pathwayDetails?.length > 0 && (
                                            <strong> { " - " + pathwayDetails[0]?.pathway_name } </strong>
                                        )}
                                    </h3>
                                    <div className="col-lg-12 text-right">
                                        <Link className="btn btn-default mr-2" to="/learning-pathway" > Back </Link>
                                        {/* <Link className="btn btn-primary" to={ `/learning-pathway/edit/${id}`} > Edit </Link> */}
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                {/* <th style={{ width: 10 }}>#</th> */}
                                                <th>Module Name</th>
                                                <th>Worksheet Range</th>
                                                { canAccess?.['worksheet-preview'] && wsPreview &&
                                                    <th>Worksheet Preview</th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { pathwayDetails && pathwayDetails.map((module, i) => (
                                                <tr key={i}>
                                                    <td> { module?.module_name }</td>
                                                    <td> { module?.worksheet_from + ' - ' + module?.worksheet_to }</td>
                                                    { canAccess?.['worksheet-preview'] && wsPreview &&
                                                        <td>  
                                                            <a  href="#"
                                                                data-toggle="tooltip" title="Worksheet Preview"
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setPreviewModuleId(module.module_id)
                                                                    handleShow()
                                                                }}
                                                            ><i className="fas fa-eye mr-2"/></a>
                                                        </td>
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>
                                {/* /.card-body */}
                            </div>
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
            {/* /.content */}
            <PreviewWorksheetsPDF
                moduleId={previewModuleId}
                show={show}
                handleClose={handleClose}
            />
        </div>
    )
}

export default ViewPathwayDetails