import React, { useEffect, useRef, useState } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { getAllParentWithPaginationService } from '@app/services/student';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAllActiveFranchise } from '@app/services/franchise';
import ReactSelect from 'react-select';

const ListParents = () => {
    const [parents, setParents] = useState([]);
    const [franchises, setFranchises] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({first_name: '',last_name: '', relation: '', email: '', franchise_id: '', status: ''});
    const studentStatus = [{label:'Active', value:'active'}, {label:'Inactive', value:'inactive'}]
    const canAccess = useSelector((state: any) => state.auth.permissions);
    const selectRef = useRef(null);

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    const customStylesSelect = {
        dropdownIndicator: () => ({
            display: 'none',
        }),
    }

    useEffect(() => {
        getAllParentService(1, perPage, sortField, sortDirection);
    },[search]);

    useEffect(() => {
        getAllActiveFranchiseData();
    },[]);


    const getAllActiveFranchiseData = async () => {
        try {
            const allActiveFranchiseResp = await getAllActiveFranchise();
            if (allActiveFranchiseResp.success === true) {
                setFranchises(allActiveFranchiseResp.collections);
            } else {
                toast.error(allActiveFranchiseResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    const getAllParentService = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const parentsResp = await getAllParentWithPaginationService(page, perPage, sortField, sortDirection, search);
            if (parentsResp.success === true) {
                setLoading(false);
                setParents(parentsResp.collections.data);
                setTotalRows(parentsResp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(parentsResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const columns = [
        {
            name: 'First Name',
            selector: (row: { first_name: string;}) => row.first_name,
            sortable: true,
            sortField: 'first_name',
            wrap: true,
        },
        {
            name: 'Last Name',
            selector: (row: {last_name: string;}) => row.last_name,
            sortable: true,
            sortField: 'last_name',
            wrap: true,
        },
        {
            name: 'Email',
            selector: (row: { email: string; }) => row.email ? row.email : '---',
            sortable: true,
            sortField: 'email',
            wrap: true,
        },
        {
            name: 'Gender',
            selector: (row: { gender: string; }) => row.gender ? row.gender.charAt(0).toUpperCase()+row.gender.slice(1) : '---',
            sortable: true,
            sortField: 'gender',
            wrap: true,
        },
        {
            name: 'Contact No.',
            selector: (row: { phone: string; }) => row.phone ? row.phone : '---',
            sortable: true,
            sortField: 'phone',
            wrap: true,
        },
        {
            name: 'Franchise',
            selector: (row: { franchise_name: string; }) => row.franchise_name ? row.franchise_name : '---',
            sortable: true,
            sortField: 'franchise_id',
            wrap: true,
        },
        {
            name: 'Status',
            selector: (row: { status: string; }) => row.status,
            cell: (row: { status: string; }) => (
                row.status === 'active' ? <span className="badge bg-success">Active</span> : <span className="capitalize badge bg-danger">{row.status}</span>
            ),
            sortable: true,
            sortField: 'status',
        },
        {  
            name: 'Action',
            cell: (row: { id: number; }) => (
              <>
                { canAccess?.student?.edit &&
                    <Link
                    to={`/parent/edit/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="Edit"
                    >
                    <i className="fas fa-pen mr-2"/>
                    </Link>
                }
                { canAccess?.student?.view &&
                    <Link
                    to={`/parent/view/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="View"
                    >
                    <i className="fas fa-eye mr-2"/>
                    </Link>
                }
              </>
            ),
        }
    ];

    const handlePageChange = page => {
        getAllParentService(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllParentService(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllParentService(currentPage, perPage, sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        selectRef.current.setValue('');
        setSearch({first_name: '',last_name: '', relation: '', email: '', franchise_id: '', status: ''});
    };

    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manage Parents</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active">Manage Parents</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Manage Parents</h3>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <div className="col-sm-2">
                                                <input type="text" name='firstName' className="form-control" value={search.first_name} onChange={(e) => setSearch({ ...search, first_name: e.target.value })} placeholder="Search By First Name" />
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='lastName' className="form-control" value={search.last_name} onChange={(e) => setSearch({ ...search, last_name: e.target.value })} placeholder="Search By Last Name" />
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='email' className="form-control" value={search.email} onChange={(e) => setSearch({ ...search, email: e.target.value })} placeholder="Search By Email" />
                                            </div>
                                            <div className="col-sm-2">
                                                <ReactSelect
                                                    ref={selectRef}
                                                    name="franchise_id"
                                                    placeholder="Search By Franchise"
                                                    options={franchises}
                                                    getOptionValue={(option)=>`${option['id']}`}
                                                    getOptionLabel={(option)=>`${option['name']}`}
                                                    onChange={ (option) => { setSearch({ ...search, franchise_id: option['id'] });}}
                                                    // onInputChange={ (option) => {onChangeFranchise(option)}}
                                                    // value={search.franchise_id}
                                                    styles={customStylesSelect}
                                                />
                                            </div>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={(e) => setSearch({ ...search, status: e.target.value })}
                                                    value={search.status}
                                                >
                                                    <option value="">Search By Status</option>
                                                    {studentStatus.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-sm-2'>
                                                <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                            </div>
                                        </div>

                                        <DataTable
                                            columns={columns}
                                            data={parents}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationDefaultPage={currentPage}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            paginationPerPage={perPage}
                                            paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                            progressPending={isLoading}
                                            sortServer
                                            onSort={handleSort}
                                            customStyles={customStyle}
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>

        </div>
    )
};

export default ListParents;