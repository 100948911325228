import axios from "axios";
import { getAllSettingsURL, getSettingByIdURL, opsmanualURL, updateSettingURL } from "@app/constants/ApiEndPoints";

export const getAllSetting = async () => {
	try {
		const response = await axios.get(getAllSettingsURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getSettingById = async (id: number) => {
	try {
		const response = await axios.get(getSettingByIdURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateSetting = async (payload) => {
	try {
		const response = await axios.post(updateSettingURL, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};