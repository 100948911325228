import { Form, Modal } from 'react-bootstrap';
import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import * as JSPM from 'jsprintmanager';
import { createStudAdhocWSPrintHistory, createStudentWSPrintHistory, downloadStudentAdhocWorksheets, downloadStudentWorksheets, updatePrinterStatusByHistoryId, updateStudAdhocWSPrinterStatus } from '@app/services/student';
import { Document, Page, pdfjs } from 'react-pdf';
import { Blob } from 'buffer';
import "../set-work/printWorksheetsPDF.css";
import { useDispatch, useSelector } from 'react-redux';
import { setPageNum } from '@app/store/reducers/studentWork';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;
import 'react-pdf/src/Page/TextLayer.css';
import 'react-pdf/src/Page/AnnotationLayer.css';
import { confirmAlert } from 'react-confirm-alert';

const PrintWorksheetsPDF = (props) => {

    let obj: any = {}
    const [isLoading, setLoading] = useState(false);
    const [pdfLoading, setPDFLoading] = useState(false);
    const [selPrinter, setSelPrinter] = useState(obj);   
    const [clientPrinters, setClientPrinters] = useState([]); 
    const [clientInfo, setClientInfo] = useState(''); 
    const [deviceId, setDeviceId] = useState(''); 
    const [jspmError, setJSPMError] = useState(''); 
    const [file, setFile] = useState<Blob>(); 
    const [fileURL, setFileURL] = useState(null); 
    const [numPages, setNumPages] = useState(null); 
    const [pageNumber, setPageNumber] = useState(1);
    const [printLog, setPrintLog] = useState(obj); 
    const [printJob, setPrintJob] = useState(obj); 
    const dispatch = useDispatch();
    const studentWorkStore = useSelector((state: any) => state.studentWork);


    const isNumeric = input => !isNaN(input) // you may also check if the value is a nonzero positive integer
    const isOrdered = (start, end) => parseInt(start) < parseInt(end)
    const isOutOfBound = range => range <= numPages
    const isRangeValid = range => range.length == 2 && range.every(item => isNumeric(item)) && isOrdered(range[0], range[1]) && isOutOfBound(range[0]) && isOutOfBound(range[1])
    const isSingleValid = single => single.length == 1 && isNumeric(single) && isOutOfBound(single)
    const pagesOptions = [{label: 'All', value: 0 }, {label: 'Odd pages only', value: 1}, {label: 'Even pages only', value: 2}, {label: 'Custom', value: 3}]
    useEffect(() => {
        if(props.show == true){

            getPrinters();
            props?.isAdhoc ? downloadAdhocWorksheets() : downloadWorksheets()

            return () => {
                // Anything in here is fired on component unmount.
                setClientPrinters([])
                setClientInfo('')
                setDeviceId('')
                setSelPrinter(obj)
                setPageNumber(1)
                setNumPages(null)
                setFileURL(null)
                setFile(null)
                dispatch(setPageNum(1))
                // setPrintLog([])
                JSPM.JSPrintManager.stop()
                resetForm()
            }
        }
    },[props.show]);

    useEffect(() => {
        if(Object.keys(printJob).length > 0){
            if(props?.isAdhoc){
                if(printLog.length > 0){
                    updateAdhocWSPrintHistory({
                        history_id: printLog,
                        printer_info: printJob
                    })
                }
            }else{
                if(Object.keys(printLog).length > 0){
                    updateWSPrintHistoryById({
                        id: printLog,
                        printer_info: printJob
                    })
                }
            }
            setPrintLog(obj);
            setPrintJob(obj);
        }
    },[printJob]);

    const jspmWSStatus = () => {
        if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open){
            setJSPMError('')
            return true;
        } else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Closed) {
            setJSPMError(`JSPrintManager (JSPM) is not installed or not running! <a target='_blank' href='https://neodynamic.com/downloads/jspm'>Download</a> JSPM Client App!`);
            return false;
        } else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Blocked) {
            setJSPMError('JSPM has blocked this website!');
            return false;
        }
    }

    const getPrinters = () => {
        setLoading(true)
        // let wlPort = (new URLSearchParams(window.location.search)).get('wlport');
        // if(wlPort == null) {
        //     wlPort = '27443';
        // }
        
        JSPM.JSPrintManager.license_url = process.env.REACT_APP_API_BASEURL + '/print-manager/licence';

        JSPM.JSPrintManager.auto_reconnect = true;
        // JSPM.JSPrintManager.start(true, 'localhost', wlPort);
        JSPM.JSPrintManager.start();
        
        JSPM.JSPrintManager.WS.onStatusChanged = async () => {
            if (jspmWSStatus()) {

                //get client installed printers
                const printersList:any = await JSPM.JSPrintManager.getPrintersInfo(JSPM.PrintersInfoLevel.Basic, '', JSPM.PrinterIcon.None);
                setLoading(false)
                console.log(printersList)
                if(printersList.length === 0){
                    toast.error('Printer(s) not found.')
                    return;
                }
                const virtualPrinterNames = ['Microsoft Print to PDF','Microsoft XPS Document Writer', 'Adobe PDF', 'Fax', 'OneNote (Desktop)', 'Microsoft Print to PDF'];
                var filterPrinters = printersList.filter(function(item){
                    return !virtualPrinterNames.includes(item.name);
                });
                setClientPrinters(filterPrinters);
                // setClientPrinters(printersList);

                // get client info 
                let data:any = await JSPM.JSPrintManager.getClientAppInfo();
                let jsonData = JSON.parse(data);
                let cInfo = "Version: " + jsonData.version + " OS: " + jsonData.os + " (" + jsonData.arch + ")";
                setClientInfo(cInfo)

                // get device id 
                let deviceId = await JSPM.JSPrintManager.getDeviceId();
                setDeviceId(deviceId)

                /*
                let os = (function () {
                    var ua = navigator.userAgent.toLowerCase();
                    return {
                        isWindows: /(windows|win32|win64)/.test(ua),
                        isLinux: /(linux)/.test(ua),
                        isIntelMac: /(mac)/.test(ua),
                        isRPi: /(Linux arm)/.test(ua),
                        isAndroid: /(android)/.test(ua)
                    };
                }());
        
                let OS =''
                if (os.isWindows) {
                    OS = "win";
                } else if (os.isAndroid) {
                    OS = "Android";
                } else if (os.isLinux) {
                    OS = "linux";
                } else if (os.isIntelMac) {
                    OS = "mac";
                } else if (os.isRPi) {
                    OS = "rpi";
                } else {
                    OS = "other";
                }
                console.log(OS)
                */
            }else{
                setLoading(false)
            }
        };
    }

    //Guessing whether a printer is Virtual or real/physical
    const isVirtualPrinter = (clientPrinter) => {
        let isVirtual = true;
        let printerPort = clientPrinter?.port?.toLowerCase();
       
        //For Windows
        // if (printerPort != "nul" && clientPrinter.isBIDIEnabled) return false;
        //For Unix
        // if (printerPort.indexOf("usb") >= 0 && printerPort.indexOf("?serial=") >= 0) return false;

        if (printerPort != "nul" && clientPrinter.BIDIEnabled) {
            isVirtual = false;
        } else if (printerPort.indexOf("usb") >= 0 && printerPort.indexOf("?serial=") >= 0) {
            isVirtual = false;
        }

        return isVirtual;
    }
    
    // const doPrinting = (printer) => {
    //     let cpj = createPrintJob(printer);
    //     if (cpj) {
    //         cpj.sendToClient();
    //     }
    // }

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue, resetForm } = useFormik({
        initialValues: {
            printerIndex: '',
            printerName: '',
            printerTrayName: '',
            printerPaperName: '',
            pages: 0,
            printRange: '',
            manualDuplex: false,
            driverDuplex: false,
            duplexOption: "Default",
            printAsGrayscale: false
        },
        validationSchema: Yup.object({
            printerIndex: Yup.string().required('Please select printer'),
            // printerName: Yup.string().required('Please select printer'),
            // printerTrayName: Yup.string().required('Please select printer tray'),
            printerPaperName: Yup.string().required('Please select printer paper'),
            pages: Yup.number().required('Please select pages'),
            printRange: Yup.string().when("pages", {
                is: (val: number) => (val == 3),
                then: Yup.string().required('Please enter page range')
                    .test('range-validate', 'Invalid page range. Use formats like 1-5, 8, 11-13', 
                        function(value) {
                            if(value != '' && value != undefined){
                                const inputs = value.split(',').map(x => x.trim());
                                for (let x of inputs) {
                                    if (!x) return false;
                                    const pages = x.split('-');
                                    if (!isSingleValid(pages) && !isRangeValid(pages))
                                        return false;
                                }
                            }
                            return true;
                        }
                    ),
            }),
        }),
        onSubmit: (values) => {
            // console.log(values)
            createPrintJob(values)
            // confirmAlert({
            //     title: 'Confirm to submit',
            //     message: 'Are you sure you want to print?',
            //     buttons: [
            //         {
            //             label: 'Yes',
            //             onClick: () => createPrintJob(values)
            //         },
            //         {
            //             label: 'No',
            //             onClick: () => {}
            //         }
            //     ]
            // });
        },
        enableReinitialize: true,
    });

    const downloadWorksheets = async() => {
        setPDFLoading(true);
        const resp = await downloadStudentWorksheets({student_id: props?.studId, subject_id: props.subjectId, dates: props.selectedDates});

        //Create a Blob from the PDF Stream
            // const file = new Blob( [resp], {type: 'application/pdf'});
            setFile(resp)

        //Build a URL from the file
            const pdfFileURL = URL.createObjectURL(resp);
            setFileURL(pdfFileURL);   
        
        setPDFLoading(false);
    }

    const downloadAdhocWorksheets = async() => {
        setPDFLoading(true);
        const resp = await downloadStudentAdhocWorksheets(props?.studId);

        //Create a Blob from the PDF Stream
            // const file = new Blob( [resp], {type: 'application/pdf'});
            setFile(resp)

        //Build a URL from the file
            const pdfFileURL = URL.createObjectURL(resp);
            setFileURL(pdfFileURL);   
        
        setPDFLoading(false);
    }

    const createWSPrintHistory = async (payload) => {
        try {
            const resp = await createStudentWSPrintHistory(payload);
            if(resp?.success === true){
                // console.log('Print log created');
                setPrintLog(resp?.collections)
            }
        } catch (error: any) {
            console.log(error?.message || 'Internal server error');
        }
    };

    const updateWSPrintHistoryById = async (payload) => {
        try {
            const resp = await updatePrinterStatusByHistoryId(payload);
            if(resp?.success === true){
                props.handleClose();
                // console.log('Printer status updated');
            }else{
                props.handleClose();
                console.log(resp?.message);
            }
        } catch (error: any) {
            props.handleClose();
            console.log(error?.message || 'Internal server error');
        }
    };

    const createAdhocWSPrintHistory = async (payload) => {
        try {
            const resp = await createStudAdhocWSPrintHistory(payload);
            if(resp?.success === true){
                // console.log('Adhoc print log created');
                setPrintLog(resp?.collections)
            }
        } catch (error: any) {
            console.log(error?.message || 'Internal server error');
        }
    };

    const updateAdhocWSPrintHistory = async (payload) => {
        try {
            const resp = await updateStudAdhocWSPrinterStatus(payload);
            if(resp?.success === true){
                props.handleClose();
                // console.log('Adhoc Printer status updated');
            }else{
                props.handleClose();
                console.log(resp?.message);
            }
        } catch (error: any) {
            props.handleClose();
            console.log(error?.message || 'Internal server error');
        }
    };

    const createPrintJob = async(printer) => {
        setLoading(true);
        let cpj = new JSPM.ClientPrintJob();
        cpj.clientPrinter = new JSPM.InstalledPrinter(printer?.printerName, false, printer.printerTrayName, printer.printerPaperName);
        
        let printRange = ''
        switch (printer.pages) {
            case '1':
                const odd = [];
                for (let i = 1; i <= numPages; i++) {
                    if (i % 2 !== 0) {
                        odd.push(i);
                    } 
                }
                printRange = odd.join(',')
                break;
            case '2':
                const even = [];
                for (let i = 1; i <= numPages; i++) {
                    if (i % 2 === 0) {
                        even.push(i);
                    } 
                }
                printRange = even.join(',')
                break;
            case '3':
                printRange = printer?.printRange
                break;
            default:
                break;
        }

        // const resp = await downloadStudentWorksheets({student_id: props?.studId});

        // //Create a Blob from the PDF Stream
        //     const file = new Blob( [resp], {type: 'application/pdf'});

        if (file) {
            let myPdfFile;

            myPdfFile = new JSPM.PrintFilePDF(file, JSPM.FileSourceType.BLOB, 'worksheets.pdf', 1)
            // myPdfFile = new JSPM.PrintFileTXT(file, 'worksheets.txt', 1, JSPM.FileSourceType.BLOB)
            // myPdfFile = new JSPM.PrintFileDOC(file, JSPM.FileSourceType.BLOB, 'worksheets.pdf', 1)
            // myPdfFile = new JSPM.PrintFileTIF(file, JSPM.FileSourceType.BLOB, 'worksheets.pdf', 1)
            
            // let files = [];
            // files.push(new JSPM.PrintFile(file, JSPM.FileSourceType.BLOB, 'worksheets.pdf', 1));
            // myPdfFile = new JSPM.PrintFileGroup(files, "MyFileGroup", 1);

            myPdfFile.printAnnotations = false;
            myPdfFile.printAsGrayscale = printer?.printAsGrayscale,
            myPdfFile.printInReverseOrder = false;
            myPdfFile.printRange = printRange;
            myPdfFile.printRotation = JSPM.PrintRotation['None'];
            myPdfFile.pageSizing = JSPM.Sizing['Fit'];
            if (printer?.manualDuplex === true) {
                myPdfFile.manualDuplex = printer?.manualDuplex
            } else if (printer?.driverDuplex == true && selPrinter?.duplex == true) {
                // cpj.clientPrinter.duplex = JSPM.DuplexMode[this.state.duplexOption];
                cpj.clientPrinter.duplex = JSPM.DuplexMode[printer?.duplexOption];
            }
            myPdfFile.printAutoRotate = false;
            myPdfFile.printAutoCenter = false;
            // myPdfFile.printScale = 100;

            cpj.files.push(myPdfFile);

            /* CPJ Events */
            let jobInfo = {
                states: "",
                printedPages: 0,
                printedBytes: 0,
                totalPages: 0,
                totalBytes: 0
            };

            cpj.onUpdated = function (data) {
                // console.log(data)
                if (data.id) {
                    if (data['completed-pages'] && data['completed-pages'] > jobInfo.printedPages) jobInfo.printedPages = data['completed-pages'];
                    if (data['total-pages'] && data['total-pages'] > jobInfo.totalPages) jobInfo.totalPages = data['total-pages'];
                    if (data['total-bytes'] && data['total-bytes'] > jobInfo.totalBytes) jobInfo.totalBytes = data['total-bytes'];
                    if (data['printed-bytes'] && data['printed-bytes'] > jobInfo.printedBytes) jobInfo.printedBytes = data['printed-bytes'];
                    if (data['state-description']) jobInfo.states += ',' + data['state-description'];
                }

                if (data.result === "ClientPrintJob done") {

                    // SOME SOURCES ABOUT THE MEANING OF SOME STATES
                    // RETAINED: https://stackoverflow.com/q/26862882
                    // COMPLETE: https://learn.microsoft.com/en-us/dotnet/api/system.printing.printjobstatus

                    let success = (jobInfo.totalBytes > 0 && jobInfo.printedBytes > 0 && jobInfo.printedBytes >= jobInfo.totalBytes) ||
                                  (jobInfo.totalPages > 0 && jobInfo.printedPages > 0 && jobInfo.printedPages >= jobInfo.totalPages) ||
                                  jobInfo.states.includes('PRINTED') ||
                                  jobInfo.states.includes('RETAINED') ||
                                  jobInfo.states.includes('COMPLETE');
                    // console.log('ClientPrintJob done ====================================')
                    if (success) {
                        setPrintJob(jobInfo)
                        // console.log("Print success");
                    } else {
                        // console.log("Print with error");
                    }
                }
            };

            cpj.onError = (data, is_critical) => {
                console.log('onError =>', data, is_critical)
            };

            // cpj.onFinished = function (data) {
            //     // $("#txtPrintJobTrace").val($("#txtPrintJobTrace").val() + "> " + JSON.stringify(data) + "\r\n");
            //     console.info("onFinished",data);
            // };

            // cpj.onUpdate = (data, last) => {
            //     console.log('onUpdate =>', data, last)
            // }

            cpj.sendToClient();

            if(props?.isAdhoc){
                createAdhocWSPrintHistory({
                    student_id : props?.student?.id, 
                    page_range: printRange
                })
            }else{
                createWSPrintHistory({
                    franchise_id : props?.student?.franchise_id,
                    centre_id : props?.student?.centre_id,
                    student_id : props?.student?.id,
                    subject_id : props.subjectId, 
                    weeks : props?.selectedWeeksCount,
                    dates: props?.selectedDates,
                    page_range: printRange
                });
            }
        }else{
            toast.error('something went wrong, please try again!')
        }
        
        setLoading(false);
        resetForm();
        // props.handleClose();        
        // return cpj;
    }

    const onPrinterChange = (printerIndex) => {
        if(printerIndex != ''){
            setSelPrinter(clientPrinters[printerIndex])
            setFieldValue('printerName', clientPrinters[printerIndex].name)
            setFieldValue('printerIndex', printerIndex)
        }else{
            setSelPrinter({})
            setFieldValue('printerName', '')
            setFieldValue('printerIndex', '')
        }
    }

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    }

    const prevPage = () => {
        if(pageNumber > 1){
            setPageNumber(pageNumber - 1)
            dispatch(setPageNum(pageNumber - 1))
        }
    }

    const nextPage = () => {
        if(pageNumber < numPages){
            setPageNumber(pageNumber + 1)
            dispatch(setPageNum(pageNumber + 1))
        }
    }

    const setPage = (pageNum) => {
        let page = parseInt(pageNum)
        if(page >= 1 && page <= numPages){
            dispatch(setPageNum(page))
            setPageNumber(page)
        }else{
            dispatch(setPageNum(pageNumber))
            setPageNumber(pageNumber)
        }
    }

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose} size='xl'>
            <div className={`overlay ${ isLoading ? '' : ' d-none'}`}>
                {/* <i className="fas fa-2x fa-sync fa-spin"></i> */}
            </div>
            <Modal.Header closeButton>
                <Modal.Title>{props.subjectName} Print Worksheet(s)</Modal.Title>
            </Modal.Header>
            {/* <FormikProvider value={formik}>*/}
                <form onSubmit={handleSubmit}> 
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 text-danger">
                                <p dangerouslySetInnerHTML={{ __html: jspmError}}></p>
                            </div>
                            <div className="col-md-8">
                                <div className={`overlay ${ pdfLoading ? '' : ' d-none'}`}>
                                    <i className="fas fa-2x fa-sync fa-spin"></i>
                                </div>

                                { numPages != null && (
                                    <div className='d-flex justify-content-center'>
                                        <Button className='btn btn-default mr-2' onClick={prevPage} disabled={ pageNumber === 1 }>Prev Page</Button>
                                        <Button className='btn btn-default' onClick={nextPage} disabled={ pageNumber === numPages }>Next Page</Button>
                                    </div>
                                )}

                                <Document 
                                    file={fileURL}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    onContextMenu={(e) => e.preventDefault() }
                                    className='pdf-container'
                                    loading="Loading Worksheet(s) PDF…."
                                >
                                    <Page 
                                        pageNumber={pageNumber}
                                        loading="Loading Page…."
                                    />
                                </Document>

                                { numPages != null && (
                                    <div className="row d-flex justify-content-center">
                                        <label className='col-md-1'> Page </label>
                                        <Form.Control
                                            className='col-md-1'
                                            type="number"
                                            min="1"
                                            value={ studentWorkStore.pageNumber }
                                            // onWheel={ (e) => OnWheelPreventChange(e) } 
                                            onChange={(e) =>  dispatch(setPageNum(e.target.value))}
                                            onBlur={ (e) =>  setPage(e.target.value)}
                                        />
                                        <label className='col-md-1'> of {numPages} </label>
                                    </div>
                                
                                    // <p className='text-center'>
                                    //     Page {pageNumber} of {numPages}
                                    // </p>
                                )}
                            </div>
                            <div className="col-md-4 border-left border-dark">
                                {/* <div className={`overlay ${ isLoading ? '' : ' d-none'}`}>
                                    <i className="fas fa-2x fa-sync fa-spin"></i>
                                </div> */}
                                <div className="d-flex justify-content-between">
                                    <h5>Print</h5>
                                    { numPages != null && (
                                        <p>{numPages} sheets of paper</p>
                                    )}
                                </div>
                                <div className="form-group row">
                                    <label className='col-sm-4 col-form-label' htmlFor="printerIndex">Printer <span className="required">*</span></label>
                                    <div className='col-md-8'>
                                        <select 
                                            id='printerIndex'
                                            className="form-control" 
                                            name="printerIndex" 
                                            value={values.printerIndex}
                                            // onChange={handleChange}
                                            onChange={ (e) => {
                                                onPrinterChange(e.target.value)
                                            }}
                                        >
                                            <option value="">Select Printer</option>
                                            {clientPrinters.map(function(item, i) {
                                                // if(!isVirtualPrinter(item)){
                                                    let opt = (
                                                        <option key={i} value={i}>
                                                            {item.name}
                                                        </option>
                                                    );
                                                    return opt;
                                                // }
                                            })}
                                        </select>
                                        {(touched.printerIndex && errors.printerIndex) && (
                                            <div className="text-danger">
                                                {errors.printerIndex}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row">    
                                    <label className='col-sm-4 col-form-label' htmlFor="printerTrayName">Tray</label>
                                    <div className="col-md-8">
                                        <select 
                                            id='printerTrayName'
                                            className="form-control" 
                                            name="printerTrayName" 
                                            value={values.printerTrayName}
                                            onChange={ handleChange}
                                        >
                                             <option value="">Select Printer Tray</option>
                                            {selPrinter?.trays?.length > 0 && selPrinter?.trays.map(function(item, i) {
                                                let opt = (
                                                    <option key={i} value={item}>
                                                        {item}
                                                    </option>
                                                );
                                                return opt;
                                            })}
                                        </select>
                                        {(touched.printerTrayName && errors.printerTrayName) && (
                                            <div className="text-danger">
                                                {errors.printerTrayName}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row">    
                                    <label className='col-sm-4 col-form-label' htmlFor="printerPaperName">Paper <span className="required">*</span></label>
                                    <div className="col-md-8">
                                        <select 
                                             id='printerPaperName'
                                             className="form-control" 
                                             name="printerPaperName" 
                                             value={values.printerPaperName}
                                             onChange={ handleChange}
                                        >
                                             <option value="">Select Printer Paper</option>
                                            { selPrinter?.papers?.length > 0 && selPrinter.papers.map(function(item, i) {
                                                let opt = (
                                                    <option key={i} value={item}>
                                                        {item}
                                                    </option>
                                                );
                                                return opt;
                                            })}
                                        </select>
                                        {(touched.printerPaperName && errors.printerPaperName) && (
                                            <div className="text-danger">
                                                {errors.printerPaperName}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row">    
                                    <label className='col-sm-4 col-form-label' htmlFor="printRange">Pages </label>
                                    <div className="col-md-8">
                                        <select 
                                             id='pages'
                                             className="form-control" 
                                             name="pages" 
                                             value={values.pages}
                                             onChange={ handleChange}
                                        >
                                            { pagesOptions?.length > 0 && pagesOptions.map(function(item, i) {
                                                let opt = (
                                                    <option key={i} value={item?.value}>
                                                        {item?.label}
                                                    </option>
                                                );
                                                return opt;
                                            })}
                                        </select>
                                        {(touched.pages && errors.pages) && (
                                            <div className="text-danger">
                                                {errors.pages}
                                            </div>
                                        )}
                                        { values?.pages == 3 && (
                                            <>
                                            <input type="text" id='printRange' className="form-control mt-2" name="printRange" value={values.printRange} onChange={ handleChange} placeholder='e.g. 1-5, 8, 11-13'/>
                                            {(touched.printRange && errors.printRange) && (
                                                <div className="text-danger">
                                                    {errors.printRange}
                                                </div>
                                            )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    {/* <label className='col-sm-3 col-form-label' htmlFor="printerIndex">Duplex</label> */}
                                    <div className='col-md-9'>
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" name='manualDuplex' 
                                                className="custom-control-input" 
                                                id="customSwitch1"
                                                onChange={ (e) => {
                                                    setFieldValue('manualDuplex', e.target.checked)
                                                    if(e.target.checked){
                                                        setFieldValue('driverDuplex', false)
                                                    }
                                                }}
                                                // onChange={handleChange}
                                                // defaultChecked={values?.manualDuplex}
                                                checked={values?.manualDuplex}
                                                // disabled={selPrinter?.duplex === false}
                                            />
                                            <label className="custom-control-label" htmlFor="customSwitch1">Manual Duplex Printing</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    {/* <label className='col-sm-3 col-form-label' htmlFor="printerIndex">Duplex</label> */}
                                    <div className='col-md-9'>
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" name='driverDuplex' 
                                                className="custom-control-input" 
                                                id="customSwitch2"
                                                onChange={ (e) => {
                                                    setFieldValue('driverDuplex', e.target.checked)
                                                    if(e.target.checked){
                                                        setFieldValue('manualDuplex', false)
                                                    }
                                                }}
                                                // onChange={ handleChange}
                                                // defaultChecked={values?.driverDuplex}
                                                disabled={selPrinter?.duplex === false}
                                                checked={values?.driverDuplex}
                                            />
                                            <label className="custom-control-label" htmlFor="customSwitch2">Driver Duplex Printing</label>
                                        </div>
                                    </div>
                                    { values?.driverDuplex && (
                                        <div className='col-md-9 mt-2'>
                                            <select 
                                                className="form-control form-control-sm" 
                                                name="duplexOption" 
                                                id='duplexOption'
                                                value={values.duplexOption}
                                                onChange={ handleChange}
                                            >
                                                <option key="Default" value="Default">Default</option>
                                                <option key="Simplex" value="Simplex">Simplex</option>
                                                <option key="DuplexLongEdge" value="DuplexLongEdge">Flip on long edge</option>
                                                <option key="DuplexShortEdge" value="DuplexShortEdge">Flip on short Edge</option>
                                            </select>
                                        </div>
                                    )}
                                </div>
                                <div className="form-group row">
                                    <div className='col-md-9'>
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" name='printAsGrayscale' 
                                                className="custom-control-input" 
                                                id="printAsGrayscale"
                                                onChange={ (e) => {
                                                    setFieldValue('printAsGrayscale', e.target.checked)
                                                }}
                                                checked={values?.printAsGrayscale}
                                            />
                                            <label className="custom-control-label" htmlFor="printAsGrayscale">Print As Grayscale</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-end border-top border-dark mb-2'>
                                    <Button type="submit" disabled={pdfLoading} className="btn btn-primary col-sm-3 mx-2 mt-4">Print </Button>
                                    <Button className='btn btn-default col-sm-3 mt-4' onClick={props.handleClose}>Cancel</Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Print </Button>
                        <Button className='btn btn-default' onClick={props.handleClose}>Close</Button> */}
                    </Modal.Footer>
                </form>
                <span className='m-2'>
                    <span className="badge badge-secondary">Client App Info</span>&nbsp;<small>{clientInfo}</small>&nbsp;
                    <span className="badge badge-secondary">Device ID:</span>&nbsp;<small>{deviceId}</small>
                </span>
            {/*</FormikProvider> */}
            {/* <div style={{display: isLoading ? 'block' : 'none' ,zIndex: 100, background: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100%', position: 'absolute'}}>
                <div className="loader" style={{top: 'calc(50% - 40px)', left: 'calc(50% - 40px)', position: 'fixed'}} />
            </div> */}
        </Modal>
    </div>
  )
}

export default PrintWorksheetsPDF;