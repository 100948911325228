import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import { getContractDocumentById, getFMSContractByFranchiseId } from '@app/services/FMScontracts';
import PreviewModal from '../../ops-manual/PreviewModal';
import { getExtension } from '@app/services/opsManual';
import fileDownload from 'js-file-download';

const ViewFMSContract = () => {
    let obj: any = {}
    const [isLoading, setLoading] = useState(false);
    const [contract, setContractData] = useState(obj);
    const [documents, setDocuments] = useState([]);
    const [dbsDocuments, setDBSDocuments] = useState([]);
    const [url, setUrl] = useState('');
    const [filename, setFileName] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const canAccess = useSelector((state: any) => state.auth.permissions.franchise);
    const navigate = useNavigate();
    let { id } = useParams();
    
    useEffect(() => {
        getContractByFranchiseId(id);

        return () => {
            setContractData(obj)
            setDocuments([])
        }
    }, []);

    useEffect(() => {
        if(!show){
            setUrl('')
            setFileName('')
        }
    }, [show]);

    const getContractByFranchiseId = async (id) => {
        try {
            setLoading(true);
            const resp = await getFMSContractByFranchiseId(id);
            if(resp.success === true){
                setLoading(false);
                if(Object.keys(resp?.collections).length > 0){
                    setContractData(resp?.collections);
                    setDocuments(resp?.collections?.documents.filter((doc) => doc.type == 'contract_documents'))
                    setDBSDocuments(resp?.collections?.documents.filter((doc) => doc.type == 'dbs_documents'))
                }
            }else{
                setLoading(false);
                toast.error(resp?.message || 'Something went wrong');
                navigate('/franchise');
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Something went wrong');
        }
    };

    const previewFile = async (docId, name) => {
        try{
            const resp = await getContractDocumentById(docId);
            setUrl(URL.createObjectURL(resp))
            setFileName(name)
            handleShow();

        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    }

    const downloadDocument = async (docId, name) => {
        try{
            setLoading(true);
            const resp = await getContractDocumentById(docId);
            fileDownload(resp, name);
            setLoading(false);        
        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    }

  return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {canAccess['view-contract'] && (
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">View FMS Contract Details - 
                                    {contract?.name && (contract?.name) }
                                </h3>
                            </div>
                            <div className="card-body">
                                
                                {Object.keys(contract).length == 0 && (
                                    <div className='col-md-12'>
                                        <span className='text-danger'>No contract has been added for this franchise.</span>
                                    </div>
                                )}
                                { Object.keys(contract).length > 0 && (
                                    <>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Franchise Name </dt>
                                                <dd>{ contract?.name ?? '---' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Kashflow License Code </dt>
                                                <dd>{ contract?.kashflow_license_account ?? '---' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Contract Term 11.1 (Years)</dt>
                                                <dd>{ contract?.contract_term ?? '---' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Contracted Initial Fee </dt>
                                                <dd><CurrencyFormat value={ contract?.contract_initial_fee  } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Deposit As Per Proposed Letter </dt>
                                                <dd><CurrencyFormat value={ contract?.deposit_amount  } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Deposit Paid Date </dt>
                                                <dd>{ contract?.deposit_paid_date ? moment(contract?.deposit_paid_date).format('DD/MM/YYYY') : '---' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Plan/Financed Amount <i className='fa fa-info-circle' title='By straight cash, as per proposed letter'/></dt>
                                                <dd><CurrencyFormat value={ contract?.plan_financed_amount  } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£  '} /></dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Special Payment Terms <i className='fa fa-info-circle' title='To claim initial fee portion via alt growth mechanism'/> </dt>
                                                <dd><CurrencyFormat value={ contract?.special_payment_amount  } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£  '} /></dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Monthly Franchise Fee </dt>
                                                <dd><CurrencyFormat value={ contract?.monthly_franchise_fees  } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£  '} /></dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Monthly Student Fee Before </dt>
                                                <dd><CurrencyFormat value={ contract?.monthly_student_fee_before  } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£  '} /></dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Monthly Student Fee After </dt>
                                                <dd><CurrencyFormat value={ contract?.monthly_student_fee_after  } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£  '} /></dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Subjects To </dt>
                                                <dd>{ contract?.subject_to ?? '---' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Renewal Fee (Under 'Term' in Contract) </dt>
                                                <dd><CurrencyFormat value={ contract?.renewal_fee  } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£  '} /></dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Commencement Date As Per Contract </dt>
                                                <dd>{ contract?.contract_commencement_date ? moment(contract?.contract_commencement_date).format('DD/MM/YYYY') : '---' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Billing Start Date As Per Kashflow </dt>
                                                <dd>{ contract?.kashflow_billing_start_date ? moment(contract?.kashflow_billing_start_date).format('DD/MM/YYYY') : '---' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>FMS Start Date (Management's reported date) </dt>
                                                <dd>{ contract?.start_date ? moment(contract?.start_date).format('DD/MM/YYYY') : '---' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>FMS Effective Start Date </dt>
                                                <dd>{ contract?.effective_start_date ? moment(contract?.effective_start_date).format('DD/MM/YYYY') : '---' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>FMS End Date </dt>
                                                <dd>{ contract?.end_date ? moment(contract?.end_date).format('DD/MM/YYYY') : '---' }</dd>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>DBS Cert. Date </dt>
                                                <dd>{ contract?.dbs_cert_date ? moment(contract?.dbs_cert_date).format('DD/MM/YYYY') : '---' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>DBS Cert. Number </dt>
                                                <dd>{ contract?.dbs_cert_number ?? '---' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>DOB For Update Service Check </dt>
                                                <dd>{ contract?.dbs_update_service_check_date ? moment(contract?.dbs_update_service_check_date).format('DD/MM/YYYY') : '---' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Update Service </dt>
                                                <dd>{ contract?.dbs_update_service ? 'Yes' : 'No' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>DBS Cert Copy Held? </dt>
                                                <dd>{ contract?.dbs_status_issues ? 'Yes' : 'No' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Surname Per DBS Cert For Update Service Check </dt>
                                                <dd>{ contract?.dbs_surname_cert ?? '---' }</dd>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                )}
                            </div>
                            <div className="card-footer">
                                { canAccess['add-contract'] && Object.keys(contract).length == 0 &&
                                    <Link className="btn btn-primary mr-2" to={`/franchise/add-contract/${id}`}>Add FMS Contract</Link>
                                }
                                { canAccess['edit-contract'] && Object.keys(contract).length > 0 &&
                                    <Link className="btn btn-primary mr-2" to={`/franchise/edit-contract/${id}`}>Edit FMS Contract</Link>
                                }
                                <Link className="btn btn-default" to='/franchise'>Close</Link>
                            </div>
                        </div>
                        )}
                    </div>
                    {canAccess['view-contract-documents'] && (
                        <>
                        <div className="col-md-6">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Contract Documents - 
                                        {contract?.name && (contract?.name) }
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {documents.length > 0 && (
                                            <table className="table table-bordered" id="client_id_table">
                                                <tbody>
                                                    <tr className='text-center'>
                                                        <th>Document</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    {documents.length > 0 && documents.map(file => (
                                                        <tr key={file?.id}>
                                                            <td>
                                                                <a href='javascript:void(0)' onClick={ (e) => { downloadDocument(file?.id, file?.document_name)} } data-toggle="tooltip" title="Preview" className='mx-3'> 
                                                                    {file?.document_name}
                                                                </a>
                                                            </td>
                                                            <td className='pl-5'>
                                                                <a href='javascript:void(0)' onClick={ (e) => { downloadDocument(file?.id, file?.document_name)} } data-toggle="tooltip" title="Download Document">
                                                                    <i className="fas fa-download mr-2"></i>
                                                                </a> 
                                                                { (['docx', 'doc', 'xlsx', 'xls', 'wav'].findIndex((ext) => ext == getExtension(file?.document_name))) < 0 &&
                                                                    <a href='javascript:void(0)'
                                                                    onClick={ (e) => { previewFile(file?.id, file?.document_name)} }
                                                                        data-toggle="tooltip" title="Preview Document">
                                                                        <i className="fas fa-eye fa-sm mr-2"/>
                                                                    </a>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                        {documents.length == 0 && (
                                            <span className='text-danger'>No contract documents have been uploaded for this franchise.</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">DBS Documents - 
                                        {contract?.name && (contract?.name) }
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {dbsDocuments.length > 0 && (
                                            <table className="table table-bordered" id="client_id_table">
                                                <tbody>
                                                    <tr className='text-center'>
                                                        <th>Document</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    {dbsDocuments.length > 0 && dbsDocuments.map(file => (
                                                        <tr key={file?.id}>
                                                            <td>
                                                                <a href='javascript:void(0)' onClick={ (e) => { downloadDocument(file?.id, file?.document_name)} } data-toggle="tooltip" title="Preview" className='mx-3'> 
                                                                    {file?.document_name}
                                                                </a>
                                                            </td>
                                                            <td className='pl-5'>
                                                                <a href='javascript:void(0)' onClick={ (e) => { downloadDocument(file?.id, file?.document_name)} } data-toggle="tooltip" title="Download Document">
                                                                    <i className="fas fa-download mr-2"></i>
                                                                </a> 
                                                                { (['docx', 'doc', 'xlsx', 'xls', 'wav'].findIndex((ext) => ext == getExtension(file?.document_name))) < 0 &&
                                                                    <a href='javascript:void(0)'
                                                                    onClick={ (e) => { previewFile(file?.id, file?.document_name)} }
                                                                        data-toggle="tooltip" title="Preview Document">
                                                                        <i className="fas fa-eye fa-sm mr-2"/>
                                                                    </a>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                        {dbsDocuments.length == 0 && (
                                            <span className='text-danger'>No DBS documents have been uploaded for this franchise.</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                    )}
                </div>
            </div>
        </section>
        <PreviewModal
            show={show}
            handleClose={handleClose}
            url={url}
            filename={filename}
        />
    </div>
  )
}

export default ViewFMSContract;