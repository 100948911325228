import ListOrders from './ListOrders';

const DispatchedCancelledOrders = () => {
    
    return (
        <ListOrders
            category='dispatched-cancelled'
            title='Manage Dispatched & Cancelled Orders' 
        />
    )
};

export default DispatchedCancelledOrders;