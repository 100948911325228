import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import { Modal } from 'react-bootstrap';
import { updateShippingAddressById } from '@app/services/franchise';

const UpdateShippingAddressModal = (props) => {

    const [isLoading, setLoading] = useState(false);

    const updateShippingAddressAPI = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateShippingAddressById(props.payload.id, payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                props.handleClose();
                props.handleFlag();
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue, resetForm } = useFormik({
        initialValues: {
            franchise_id: props.payload.franchise_id,
            address: props.payload.address,
            phone: props.payload.phone,
            is_default: props.payload.is_default,
        },
        validationSchema: Yup.object({
            address: Yup.string().required('Please enter address'),
            phone: Yup.string().required('Please enter phone')
            .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
				"Please enter valid number"
			),
        }),
        onSubmit: (values) => {
            updateShippingAddressAPI(values);
            resetForm();
        },
        enableReinitialize: true,
    });

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update Shipping Address</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className='row'>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Address <span className="required">*</span></label>
                                <textarea 
                                    className="form-control" 
                                    id="exampleInputEmail1" 
                                    placeholder="Enter address" 
                                    name="address" 
                                    onChange={handleChange} 
                                    value={values.address}
                                    rows={3}
                                ></textarea>
                                {(touched.address && errors.address) && (
                                    <div className="text-danger">
                                        {errors.address}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Phone <span className="required">*</span></label>
                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter phone" 
                                    name="phone" 
                                    onChange={handleChange} 
                                    value={values.phone}
                                />
                                {(touched.phone && errors.phone) && (
                                    <div className="text-danger">
                                        {errors.phone}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="franchise_id"><b> Do you want to make this a default shipping address? </b></label>
                                <div className="icheck-primary">
                                    <input 
                                        type="checkbox"
                                        id="is_default"  
                                        name="is_default" 
                                        className="form-check-input" 
                                        onChange={(e) => { 
                                            handleChange 
                                            setFieldValue("is_default", e.target.checked)
                                        }}
                                        checked={values.is_default}
                                        disabled={props.payload.is_default}
                                    />
                                    <label htmlFor="is_default" className="form-check-label"></label>
                                </div>
                                {(touched.is_default && errors.is_default) && (
                                    <div className="text-danger">
                                        {errors.is_default}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                    <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    </div>
  )
}

export default UpdateShippingAddressModal;