import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import { Modal } from 'react-bootstrap';
import Select from "react-select";
import { updateStudentCount } from '@app/services/studentCount';
import { getSubjectsByFranchise } from '@app/services/franchise';

const UpdateStudentCountsModal = (props) => {

    const [isLoading, setLoading] = useState(false);
    const months = [{id: 1, name:'January'}, {id: 2, name:'February'}, {id: 3, name:'March'}, 
                    {id: 4, name:'April'}, {id: 5, name:'May'}, {id: 6, name:'June'},
                    {id: 7, name:'July'},{id: 8, name:'August'},{id: 9, name:'September'},
                    {id: 10, name:'October'}, {id: 11, name:'November'}, {id: 12, name:'December'}];
    const years = Array.from(
        { length: 20 },
        (_, index) => new Date().getFullYear() - index );
    const sub = {maths: false, english: false, science: false};
    const [subjects, setSubjects] = useState(sub);

    useEffect(() => {
        if(props.payload.franchise_id){
            getAllActiveSubjectsData(props.payload.franchise_id);
        }
    },[props.payload.franchise_id])

    const getAllActiveSubjectsData = async (franchise_id) => {
        try {
            setLoading(true);
            const resp = await getSubjectsByFranchise(franchise_id);
            if (resp.success === true) {
                setLoading(false);
                if(resp.collections){
                    (resp.collections).map((subject)=> {
                        switch(subject?.name){
                            case 'Mathematics': sub.maths = subject.status == 'active' ? false : true;
                                                // if(subject.status === 'inactive'){
                                                //     setPayload({ ...payload, maths: 0});
                                                // }
                                                break;
                            case 'English': sub.english = subject.status == 'active' ? false : true;
                                            // if(subject.status === 'inactive'){
                                            //     setPayload({ ...payload, english: 0});
                                            // }
                                            break; 
                            case 'Science': sub.science = subject.status == 'active' ? false : true;
                                            // if(subject.status === 'inactive'){
                                            //     setPayload({ ...payload, science: 0});
                                            // }
                                            break; 
                            default:  break; 
                        }
                    })
                    setSubjects(sub);
                }
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const updateStudentCountAPI = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateStudentCount(payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                props.handleClose();
                props.handleFetchAgain();
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue, resetForm } = useFormik({
        initialValues: {
            franchise_id: props.payload.franchise_id,
            month: props.payload.month,
            year: props.payload.year,
            maths: props.payload.maths,
            english: props.payload.english,
            science: props.payload.science,
            total: props.payload.total,
        },
        validationSchema: Yup.object({
            franchise_id: Yup.string().required('Please select franchise'),
            month: Yup.string().required('Please select month'),
            year: Yup.string().required('Please select year'),
            // maths: Yup.string().required('Please enter maths count'),
            // english: Yup.string().required('Please enter english count'),
            // science: Yup.string().required('Please enter science count'),
            // total: Yup.string().required('Please enter total count'),
            maths: Yup.string().when([], {
                is: () => !subjects?.maths,
                then: Yup.string().required('Please enter maths count'),
                otherwise: Yup.string(),
            }),
            english: Yup.string().when([], {
                is: () => !subjects?.english,
                then: Yup.string().required('Please enter english count'),
                otherwise: Yup.string(),
            }),
            science: Yup.string().when([], {
                is: () => !subjects?.science,
                then: Yup.string().required('Please enter science count'),
                otherwise: Yup.string(),
            }),
        }),
        onSubmit: (values) => {
            values.total = parseInt(values.maths) + parseInt(values.english) + parseInt(values.science);
            updateStudentCountAPI(values);
            resetForm();
        },
        enableReinitialize: true,
    });

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update Students Count</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className='row'>
                    <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Franchise <span className="required">*</span></label>
                                <Select
                                    // ref={selectRef}
                                    name="franchiseId"
                                    placeholder="Search By Franchise"
                                    options={props.franchises}
                                    getOptionValue={(option)=>`${option['id']}`}
                                    getOptionLabel={(option)=> option['name'] }
                                    onChange={ (option) => { setFieldValue("franchise_id", option.id)}}
                                    value={props.franchises.filter(function(option) {
                                        return option.id === values.franchise_id;
                                    })}
                                    // onInputChange={ (option) => {onChangeFranchise(option)}}
                                    // value={search.franchiseId}
                                    // styles={customStylesSelect}
                                    isDisabled={true}
                                />
                                <span className='text-muted'> { props.payload.postcodes }</span>
                                {(touched.franchise_id && errors.franchise_id) && (
                                    <div className="text-danger">
                                        {errors.franchise_id}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Month <span className="required">*</span></label>
                                <select
                                    className="form-control"
                                    name="month"
                                    onChange={handleChange} 
                                    value={values.month}
                                    disabled={true}
                                >
                                    <option value="">--Please select--</option>
                                    {months.map((obj) => (
                                        <option key={obj.name} value={obj.id}>
                                            {obj.name}
                                        </option>
                                    ))}
                                </select>
                                {(touched.month && errors.month) && (
                                    <div className="text-danger">
                                        {errors.month}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Year <span className="required">*</span></label>
                                <select
                                    className="form-control"
                                    name="year"
                                    onChange={handleChange} 
                                    value={values.year}
                                    disabled={true}
                                >
                                    <option value="">--Please select--</option>
                                    {years.map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </select>
                                {(touched.year && errors.year) && (
                                    <div className="text-danger">
                                        {errors.year}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Mathematics { !subjects?.maths && <span className="required">*</span> }</label>
                                <input type="number" className="form-control" id="exampleInputEmail1" placeholder="Enter mathematics count" 
                                    name="maths" 
                                    onChange={handleChange} 
                                    value={values.maths}
                                    disabled={subjects?.maths}
                                />
                                {(touched.maths && errors.maths) && (
                                    <div className="text-danger">
                                        {errors.maths}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">English { !subjects?.english && <span className="required">*</span> }</label>
                                <input type="number" className="form-control" id="exampleInputEmail1" placeholder="Enter english count" 
                                    name="english" 
                                    onChange={handleChange} 
                                    value={values.english}
                                    disabled={subjects?.english}
                                />
                                {(touched.english && errors.english) && (
                                    <div className="text-danger">
                                        {errors.english}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Science { !subjects?.science && <span className="required">*</span> }</label>
                                <input type="number" className="form-control" id="exampleInputEmail1" placeholder="Enter science count" 
                                    name="science" 
                                    onChange={handleChange} 
                                    value={values.science}
                                    disabled={subjects?.science}
                                />
                                {(touched.science && errors.science) && (
                                    <div className="text-danger">
                                        {errors.science}
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Total <span className="required">*</span></label>
                                <input type="number" className="form-control" id="exampleInputEmail1" placeholder="Enter total count" 
                                    name="total" 
                                    onChange={handleChange} 
                                    value={values.total}
                                />
                                {(touched.total && errors.total) && (
                                    <div className="text-danger">
                                        {errors.total}
                                    </div>
                                )}
                            </div>
                        </div> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                    <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    </div>
  )
}

export default UpdateStudentCountsModal;