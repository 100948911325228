// Base Url
export const APIBaseURL = process.env.REACT_APP_API_BASEURL;

// Auth API URLs
export const loginURL = APIBaseURL + '/login'
export const logoutURL = APIBaseURL + '/logout'
export const forgotPasswordURL = APIBaseURL + '/forgot-password'
export const recoverPasswordURL = APIBaseURL + '/reset-password'
export const resendOTPURL = APIBaseURL + '/resend-otp'
export const getDashboardURL = APIBaseURL + '/admin/dashboard'

// Roles API Endpoints
export const getAllRolesURL = APIBaseURL + '/admin/roles'
export const getRoleGroupsURL = APIBaseURL + '/admin/roles/groups'
export const createRoleURL = APIBaseURL + '/admin/roles/create'
export const getRoleByIdURL = APIBaseURL + '/admin/roles/'
export const updateRoleURL = APIBaseURL + '/admin/roles/'
export const deleteRoleURL = APIBaseURL + '/admin/roles/'
export const getRolesByGroupNameURL = APIBaseURL + '/admin/roles/having-group/'
// export const getRolesPermissionURL = APIBaseURL + '/admin/roles/permission/'
export const getRolePermissionsByModuleURL = APIBaseURL + '/admin/permissions/by-role/'
export const updateRolePermissionsURL = APIBaseURL + '/admin/permissions/attach/role'

// Users API Endpoints
export const getAllUsersURL = APIBaseURL + '/admin/users'
export const createUserURL = APIBaseURL + '/admin/users/create'
export const getUserByIdURL = APIBaseURL + '/admin/users/'
export const viewUserByIdURL = APIBaseURL + '/admin/users/'
export const updateUserURL = APIBaseURL + '/admin/users/'
export const deleteUserURL = APIBaseURL + '/admin/users/'
export const updateUserPasswordURL = APIBaseURL + '/admin/users/password/update'
export const updateUserProfileURL = APIBaseURL + '/admin/users/profile/update'
export const getFranchiseAdminsURL = APIBaseURL + '/admin/franchise/users'
export const getCentreAdminsURL = APIBaseURL + '/admin/centre/users'

// Permissions API Endpoints
export const getAllPermissionsURL = APIBaseURL + '/admin/permissions'
export const getAllModulesURL = APIBaseURL + '/admin/roles/modules'
export const createPermissionURL = APIBaseURL + '/admin/permissions/create'
export const getPermissionByIdURL = APIBaseURL + '/admin/permissions/'
export const updatePermissionURL = APIBaseURL + '/admin/permissions/'
export const deletePermissionURL = APIBaseURL + '/admin/permissions/'
export const getCompactPermissionsURL = APIBaseURL + '/admin/permissions/by-role/compact/'

// Postcodes API Endpoints
export const getAllPostcodesURL = APIBaseURL + '/admin/postcodes'
export const createPostcodeURL = APIBaseURL + '/admin/postcodes/create'
export const getPostcodeByIdURL = APIBaseURL + '/admin/postcodes/'
export const updatePostcodeURL = APIBaseURL + '/admin/postcodes/'
export const deletePostcodeURL = APIBaseURL + '/admin/postcodes/'
export const attachFranchiseURL = APIBaseURL + '/admin/postcodes/add/franchise'
export const detachFranchiseURL = APIBaseURL + '/admin/postcodes/remove/franchise'
export const renewPostcodeFranchiseURL = APIBaseURL + '/admin/postcodes/renew/franchise'
export const getPostcodesByFranchiseURL = APIBaseURL + '/admin/postcodes/franchise/'
export const getPostcodesHistoryURL = APIBaseURL + '/admin/postcodes/history/'
export const getDemographicDeatailsByPostcodeURL = APIBaseURL + '/admin/demographic/'
export const updateFavouritePostcodeURL = APIBaseURL + '/admin/postcodes/update/favourite'
export const getAllActivePostcodesURL = APIBaseURL + '/admin/postcodes/export/active'

// Other Items API Endpoints
export const getAllItemsURL = APIBaseURL + '/admin/other-items'
export const getAllActiveItemsURL = APIBaseURL + '/admin/other-items/all-active'
export const createItemURL = APIBaseURL + '/admin/other-items/create'
export const getItemByIdURL = APIBaseURL + '/admin/other-items/'
export const updateItemURL = APIBaseURL + '/admin/other-items/'
export const deleteItemURL = APIBaseURL + '/admin/other-items/'

// Subject API Endpoints
export const getSubjectsWithPaginationURL = APIBaseURL + '/admin/subjects'
export const getAllSubjectsURL = APIBaseURL + '/admin/subjects/all'
export const getAllActiveSubjectsURL = APIBaseURL + '/admin/subjects/all-active'
export const createSubjectURL = APIBaseURL + '/admin/subjects/create'
export const getSubjectByIdURL = APIBaseURL + '/admin/subjects/'
export const updateSubjectURL = APIBaseURL + '/admin/subjects/'
export const deleteSubjectURL = APIBaseURL + '/admin/subjects/'

// Module API Endpoints
export const getModulesWithPaginationURL = APIBaseURL + '/admin/modules'
export const getAllSubjectModulesURL = APIBaseURL + '/admin/modules/all'
export const getAllActiveModulesBySubjectURL = APIBaseURL + '/admin/modules/active/'
export const createModuleURL = APIBaseURL + '/admin/modules/create'
export const getModuleByIdURL = APIBaseURL + '/admin/modules/'
export const updateModuleURL = APIBaseURL + '/admin/modules/'
export const deleteModuleURL = APIBaseURL + '/admin/modules/'
export const getAllActiveSetsBlocksAndHandbooksByModuleURL = APIBaseURL + '/admin//modules/hbs/'

// Franchise API Endpoints
export const getFranchisesWithPaginationURL = APIBaseURL + '/admin/franchise'
export const getAllActiveFranchiseURL = APIBaseURL + '/admin/franchise/all'
export const createFranchiseURL = APIBaseURL + '/admin/franchise/create'
export const getFranchiseByIdURL = APIBaseURL + '/admin/franchise/'
export const updateFranchiseURL = APIBaseURL + '/admin/franchise/'
export const deleteFranchiseURL = APIBaseURL + '/admin/franchise/'
export const getAllUnlinkFranchiseURL = APIBaseURL + '/admin/franchise/notin/group'
export const getFranchiseByGroupIdURL = APIBaseURL + '/admin/franchise/group/'
export const addToFranchiseGroupURL = APIBaseURL + '/admin/franchise/group/upsert'
export const removeFranchiseFromGroupURL = APIBaseURL + '/admin/franchise/group/drop'
export const makeDefaultFranchiseForGroupURL = APIBaseURL + '/admin/franchise/group/make/default'
export const getFranchiseShippingAddressWithPaginationURL = APIBaseURL + '/admin/franchise/shipping-details/'
export const createShippingAddressURL = APIBaseURL + '/admin/franchise/shipping-details/create'
export const shippingAddressCommonURL = APIBaseURL + '/admin/franchise/shipping-details/'
export const makeDefaultAddressURL = APIBaseURL + '/admin/franchise/shipping-details/make-default'
export const getSubjectsByFranchiseURL = APIBaseURL + '/admin/franchise/subjects/'
export const getPostcodeCountryByFranchiseURL = APIBaseURL + '/admin/postcodes/country/franchise/'

// Centre API Endpoints
export const getCentresWithPaginationURL = APIBaseURL + '/admin/centre'
export const getAllActiveCentreURL = APIBaseURL + '/admin/centre/all'
export const createCentreURL = APIBaseURL + '/admin/centre/create'
export const getCentreByIdURL = APIBaseURL + '/admin/centre/'
export const updateCentreURL = APIBaseURL + '/admin/centre/'
export const deleteCentreURL = APIBaseURL + '/admin/centre/'
export const getCentreByFranchiseIdURL = APIBaseURL + '/admin/centre/'

// Country API Endpoints
export const getAllCountriesURL = APIBaseURL + '/admin/country/all'

// Region API Endpoints
export const getAllRegionsURL = APIBaseURL + '/admin/region/'
export const getRegionsWithPaginationURL = APIBaseURL + '/admin/region'

// Area API Endpoints
export const getAllAreasURL = APIBaseURL + '/admin/area/'
export const getAreasWithPaginationURL = APIBaseURL + '/admin/area'

// Handbook API Endpoints
export const getHandbooksWithPaginationURL = APIBaseURL + '/admin/handbooks'
export const deleteHandbookURL = APIBaseURL + '/admin/handbooks/'

// Set API Endpoints
export const getSetsWithPaginationURL = APIBaseURL + '/admin/sets'
export const deleteSetURL = APIBaseURL + '/admin/sets/'

// Block API Endpoints
export const getBlocksWithPaginationURL = APIBaseURL + '/admin/blocks'
export const getAllBlocksURL = APIBaseURL + '/admin/blocks/all'
export const deleteBlockURL = APIBaseURL + '/admin/blocks/'

// Order API Endpoints
export const getOrdersWithPaginationURL = APIBaseURL + '/admin/orders/all'
export const getCartItemsByFranchiseURL = APIBaseURL + '/admin/cart/'
export const addItemsToCartURL = APIBaseURL + '/admin/cart/add'
export const updateCartURL = APIBaseURL + '/admin/cart/'
export const clearCartURL = APIBaseURL + '/admin/cart/'
export const placeOrderURL = APIBaseURL + '/admin/orders/'
export const makeOrderPaymentURL = APIBaseURL + '/admin/orders/payment'
export const getOrderDetailsURL = APIBaseURL + '/admin/orders/'
export const deleteCartItemURL = APIBaseURL + '/admin/cart/'
export const repeatOrderURL = APIBaseURL + '/admin/orders/load/'
export const makeDefaultOrderURL = APIBaseURL + '/admin/orders/make/default'
export const downloadOrderInvoiceURL = APIBaseURL + '/admin/orders/file/'
export const updateOrderStatusURL = APIBaseURL + '/admin/orders/change/status'
export const getOrderInvoiceByIdURL = APIBaseURL + '/admin/orders/'

// Pricing Plan API Endpoints
export const getPricingPlansWithPaginationURL = APIBaseURL + '/admin/pricing-plans'
export const getAllActivePricingPlansURL = APIBaseURL + '/admin/pricing-plans/all'
export const createPricingPlanURL = APIBaseURL + '/admin/pricing-plans/create'
export const getPricingPlanByIdURL = APIBaseURL + '/admin/pricing-plans/'
export const updatePricingPlanURL = APIBaseURL + '/admin/pricing-plans/'
export const deletePricingPlanURL = APIBaseURL + '/admin/pricing-plans/'
export const makeDefaultPricingPlanURL = APIBaseURL + '/admin/pricing-plans/make/default'
export const getAllUnlinkPlanFranchiseURL = APIBaseURL + '/admin/franchise/notin/plan'
export const getFranchiseByPlanIdURL = APIBaseURL + '/admin/franchise/'
export const attachFranchiseToPlanURL = APIBaseURL + '/admin/pricing-plans/attach/franchise'
export const detachFranchiseFromPlanURL = APIBaseURL + '/admin/pricing-plans/detach/franchise'
export const getSubjectwisePricesByPlanIdURL = APIBaseURL + '/admin/pricing-plans/'
export const updateSubjectAndTierWisePlanPricesURL = APIBaseURL + '/admin/pricing-plans/attach/subject'
export const updateSubjectWisePlanPricesURL = APIBaseURL + '/admin/pricing-plans/attach/bulk/subject'
export const getPricingPlanHistoryByPlanIdURL = APIBaseURL + '/admin/pricing-plans/'

// Settings API Endpoints
export const getAllSettingsURL = APIBaseURL + '/admin/setting/all'
export const getSettingByIdURL = APIBaseURL + '/admin/setting/'
export const updateSettingURL = APIBaseURL + '/admin/setting/update'

// Student Count API Endpoints
export const studentCountURL = APIBaseURL + '/admin/franchise/student-count'

// Ops Manual API Endpoints
export const opsmanualURL = APIBaseURL + '/admin/opsmanual/'

//Student API Endpoints
export const addStudentURL = APIBaseURL + '/admin/students/create';
export const updateStudentURL = APIBaseURL + '/admin/students/update/'
export const listStudentURL = APIBaseURL + '/admin/students';
export const getStudentByIdURL = APIBaseURL + '/admin/students/read/';
export const studentsURL = APIBaseURL + '/admin/students/';
export const studentURL = APIBaseURL + '/admin/students/';

//Guardian API Endpoints
export const addGuardianURL = APIBaseURL + '/admin/parents/create';
export const getAllGuardianURL = APIBaseURL + '/admin/parents/all';
export const getAllGuardianPaginateURL = APIBaseURL + '/admin/parents';
export const updateParentURL = APIBaseURL + '/admin/parents/update/';
export const getParentByIdURL   = APIBaseURL + '/admin/parents/read/';

//Schools API Endpoints
export const getAllSchoolsURL = APIBaseURL + '/admin/schools/all/';
export const getAllGradesURL  = APIBaseURL + '/admin/grades/all/';

//Learning Pathways API Endpoints
export const learningPathwayURL = APIBaseURL + '/admin/learning-pathway/';
export const createSchoolURL  = APIBaseURL + '/admin/schools/create/';

//Setting Pattern API Endpoints
export const settingPatternURL = APIBaseURL + '/admin/learning-pathway/setting-pattern';

//Student Work API Endpoints
export const studentWorkURL = APIBaseURL + '/admin/learning-pathway/student/';
export const downloadStudentWorksheetsURL = APIBaseURL + '/admin/learning-pathway/file/download';
export const adhocPrintURL = APIBaseURL + '/admin/students/print/queue';
export const adhocPrintHistoryURL = APIBaseURL + '/admin/students/print/';

//Camps API Endpoints
export const campsURL = APIBaseURL + '/admin/summer-camps';

//Print Reports API Endpoints
export const printReportsURL = APIBaseURL + '/admin/students/print/history';

//FMS Contracts API Endpoints
export const contrcatsURL = APIBaseURL + '/admin/franchise/contract/';
