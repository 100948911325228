import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { deleteCentreById, getCentresWithPagination } from '@app/services/centre';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { getAllActiveFranchise } from '@app/services/franchise';
import { getPostcodesByFranchiseId } from '@app/services/postcode';
import Select from "react-select";

const ListCenters = () => {

    const selectRef = useRef(null);
    const [centers, setCenters] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({name: '', franchiseId: '', postcodeId: '', areaCode: '', email: '', status: ''});
    const [franchises, setFranchises] = useState([]);
    const [postcodes, setPostcodes] = useState([]);
    const centreStatus = [{label:'Active', value:'active'}, {label:'Archived', value:'archived'}, {label:'Inactive', value:'inactive'}]
    const canAccess = useSelector((state: any) => state.auth.permissions?.centre);

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    const customStylesSelect = {
        dropdownIndicator: () => ({
            display: 'none',
        }),
    }

    useEffect(() => {
        getAllCentersData(1, perPage, sortField, sortDirection);
    },[search]);

    useEffect(() => {
        getAllActiveFranchiseData();
        getPostcodesByFranchiseData('all');
    },[]);

    const getAllCentersData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const usersResp = await getCentresWithPagination(page, perPage, sortField, sortDirection, search);
            if (usersResp.success === true) {
                setLoading(false);
                setCenters(usersResp.collections.data);
                setTotalRows(usersResp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(usersResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Failed');
        }
    };

    const getAllActiveFranchiseData = async () => {
        try {
            const resp = await getAllActiveFranchise();
            if (resp?.success === true) {
                setFranchises(resp?.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    const getPostcodesByFranchiseData = async (franchiseId) => {
        try {
                if(franchiseId){
                    const resp = await getPostcodesByFranchiseId(franchiseId);
                    if (resp?.success === true) {
                        setPostcodes(resp?.collections);
                    } else {
                        toast.error(resp?.errors[0]?.msg);
                    }
                }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    const columns = [
        {
            name: 'Centre Name',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            sortField: 'name',
            wrap: true,
        },
        {
            name: 'Franchise',
            selector: (row: { franchise_name: string; }) => row.franchise_name,
            sortable: true,
            sortField: 'franchise_name',
            wrap: true,
        },
        {
            name: 'Postcode',
            selector: (row: { postcode_name: string; }) => row.postcode_name,
            sortable: true,
            sortField: 'postcode_name',
        },
        {
            name: 'Area Code',
            selector: (row: { area_code: string; }) => row.area_code,
            sortable: true,
            sortField: 'area_code',
        },
        {
            name: 'Email',
            selector: (row: { email: string; }) => row.email ? row.email : '---',
            sortable: true,
            sortField: 'email',
            wrap: true,
        },
        {
            name: 'User(s)',
            selector: (row: { franchisee: string; }) => { return row?.franchisee.length > 0 ? ( Array.prototype.map.call(row.franchisee, function(user) { return user.first_name +' '+user.last_name; }).join(", ")) : '---' },
            wrap: true,
        },
        {
            name: 'Status',
            selector: (row: { status: string; }) => row.status,
            cell: (row: { status: string; }) => (
                row.status === 'active' ? <span className="badge bg-success">Active</span> : <span className="capitalize badge bg-danger">{row.status}</span>
            ),
            sortable: true,
            sortField: 'status',
        },
        {  
            name: 'Action',
            cell: (row: { id: number; }) => (
              <>
                { canAccess?.edit &&
                    <Link
                    to={`/centers/edit/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="Edit"
                    >
                    <i className="fas fa-pen mr-2"/>
                    </Link>
                }
                { canAccess?.view &&
                    <Link
                    to={`/centers/view/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="View"
                    >
                    <i className="fas fa-eye mr-2"/>
                    </Link>
                }
                { canAccess?.delete &&
                    <a href='#' onClick={ (e) => { deleteCenterConfirm(e, row.id)} } data-toggle="tooltip" title="Delete">
                        <i className="fas fa-trash mr-2"/>
                    </a>
                }
                { canAccess['page-content'] &&
                    <Link
                    to={`/centers/edit/page-content/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" 
                    title="Update Page Content"
                    >
                    <i className="fas fa-pager mr-2"/>
                    </Link>
                }
              </>
            ),
        },
    ];

    const deleteCenterConfirm = (e, id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to delete this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteCenter(id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const deleteCenter = async (id) => {
        try {
            setLoading(true);
            const resp = await deleteCentreById(id);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                getAllCentersData(currentPage, perPage, sortField, sortDirection);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Failed');
          }
    };

    const handlePageChange = page => {
        getAllCentersData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllCentersData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllCentersData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        selectRef.current.setValue('');
        setSearch({name: '', franchiseId: '', postcodeId: '', areaCode: '', email: '', status: ''});
    };

    const onChangeFranchise = (option) => {
        setSearch({ ...search, franchiseId: option.id });
        getPostcodesByFranchiseData(option.id);
    };

    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manage Centres</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active">Manage Centres</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Manage Centres</h3>
                                        <div className="col-lg-12 text-right">
                                        { canAccess?.create &&
                                            <Link className="btn btn-primary" to="/centers/add" > + Add New Centre </Link>
                                        }
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <div className="col-sm-2">
                                                <input type="text" name='name' className="form-control" value={search.name} onChange={(e) => setSearch({ ...search, name: e.target.value })} placeholder="Search By Centre Name" />
                                            </div>
                                            <div className="col-sm-2">
                                                <Select
                                                    ref={selectRef}
                                                    name="franchiseId"
                                                    placeholder="Search By Franchise"
                                                    options={franchises}
                                                    getOptionValue={(option)=>`${option['id']}`}
                                                    getOptionLabel={(option)=>`${option['name']}`}
                                                    onChange={ (option) => {onChangeFranchise(option)}}
                                                    // onInputChange={ (option) => {onChangeFranchise(option)}}
                                                    // value={search.franchiseId}
                                                    styles={customStylesSelect}
                                                />
                                            </div>
                                            { canAccess['list-search-by-postcode'] && 
                                                <div className="col-sm-2">
                                                    <select
                                                        className="form-control"
                                                        name="postcodeId"
                                                        onChange={(e) => {
                                                            setSearch({ ...search, postcodeId: e.target.value })
                                                        }}
                                                        value={search.postcodeId}
                                                    >
                                                        <option value="">Search By Postcode</option>
                                                        {postcodes.map((obj) => (
                                                            <option key={obj.id} value={obj.id}>
                                                                {obj.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            }
                                            <div className="col-sm-2">
                                                <input type="text" name='areaCode' className="form-control" value={search.areaCode} onChange={(e) => setSearch({ ...search, areaCode: e.target.value })} placeholder="Search By Area Code" />
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='email' className="form-control" value={search.email} onChange={(e) => setSearch({ ...search, email: e.target.value })} placeholder="Search By Email" />
                                            </div>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={(e) => setSearch({ ...search, status: e.target.value })}
                                                    value={search.status}
                                                >
                                                    <option value="">Search By Status</option>
                                                    {centreStatus.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-sm-2'>
                                                <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                            </div>
                                        </div>

                                        <DataTable
                                            columns={columns}
                                            data={centers}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationDefaultPage={currentPage}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            paginationPerPage={perPage}
                                            paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                            progressPending={isLoading}
                                            sortServer
                                            onSort={handleSort}
                                            customStyles={customStyle}
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>

        </div>
    )
};

export default ListCenters;