import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import { Form, Modal } from 'react-bootstrap';
import moment from 'moment';
import { subjectActivateInactivate } from '@app/services/franchise';

const SubjectStatusModal = (props) => {

    const [isLoading, setLoading] = useState(false);

    const subjectActivateInactivateAPI = async (status, payload) => {
        try {
            setLoading(true);
            const resp = await subjectActivateInactivate(status, payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                props.handleClose();
                props.getSubjectsByFranchiseId(payload.franchise_id);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            status: props?.payload?.subject?.status,
            date: moment().format('YYYY-MM-DD'),
        },
        validationSchema: Yup.object({
            date: Yup.date().required('Please enter date').when("status", {
                is: (val: string) => (val === 'active'),
                then:  Yup.date().required('Please enter date')
                .min(new Date(moment(props?.payload?.subject?.start_date !== null ? props?.payload?.subject?.start_date : new Date()).format('YYYY-MM-DD')), 'Date should be greater than start date'),
            }),
        }),
        onSubmit: (values) => {
            let payload = {}
            let status = ''
            if(props.payload?.subject?.status == 'active'){
                payload =  {franchise_id: props?.payload?.franchise_id, subject_id: props?.payload?.subject?.subject_id, end_date: values.date};
                status = 'inactive';
            }else{
                payload =  {franchise_id: props?.payload?.franchise_id, subject_id: props?.payload?.subject?.subject_id, start_date: values.date};
                status = 'active';
            }
            subjectActivateInactivateAPI(status, payload);
        },
        enableReinitialize: true,
    });

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><b> { (props.payload?.subject?.status == 'active') ? 'Inactivate' : 'Activate' } { props.payload?.subject?.name } ?</b></Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className='row'>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Please add the date of { (props.payload?.subject?.status == 'active') ? 'inactivation' : 'activation' } for { props.payload?.subject?.name } <span className="required">*</span></label>
                                <Form.Control 
                                    type="date" 
                                    name="date" 
                                    id="date" 
                                    placeholder="Enter dispatch date" 
                                    value={moment(values.date).format('YYYY-MM-DD')}
                                    onChange={handleChange}
                                />
                                {(touched.date && errors.date) && (
                                    <div className="text-danger">
                                        {errors.date}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Confirm </Button>
                    <Button className='btn btn-default' onClick={props.handleClose}>Cancel</Button>
                </Modal.Footer>
            </form>
        </Modal>
    </div>
  )
}

export default SubjectStatusModal;