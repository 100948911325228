import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { useSelector } from 'react-redux';
import { deletePricingPlanById, getPricingPlansWithPagination, makeDefaultPricingPlan } from '@app/services/pricingPlans';
import moment from 'moment';

const ListPricingPlans = () => {

    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({name: '', status: ''});
    const userStatus = [{label:'Active', value:'active'}, {label:'Inactive', value:'inactive'}]
    const canAccess = useSelector((state: any) => state.auth.permissions['pricing-plans']);
    const [pricingPlans, setPricingPlans] = useState([]);

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
                // borderBottomWidth: '0px',
                // borderBottomColor: theme.divider.default,
                // borderBottomStyle: 'solid',
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };
      
    useEffect(() => {
        getAllPricingPlansData(1, perPage, sortField, sortDirection);
    },[search]);
    
    const getAllPricingPlansData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const resp = await getPricingPlansWithPagination(page, perPage, sortField, sortDirection, search);
            if (resp?.success === true) {
                setLoading(false);
                setPricingPlans(resp?.collections?.data);
                setTotalRows(resp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const columns = [
        {
            name: 'Plan Name',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            sortField: 'name',
            wrap: true,
        },
        // {
        //     name: 'Tier(s)',
        //     selector: (row: { tiers: string; }) => { return row.tiers !== null ? ( Array.prototype.map.call(row.tiers, function(tier) { return tier.name; }).join(", ") ) : '---' },
        //     wrap: true,
        // },
        {
            name: 'Start Date',
            selector: (row: { start_date: string; }) => row.start_date,
            cell: (row: { start_date: string; }) => (
                row.start_date ? moment(row.start_date).format('DD/MM/YYYY') : '---'
            ),
            sortable: true,
            sortField: 'start_date',
        },
        {
            name: 'End Date',
            selector: (row: { end_date: string; }) => row.end_date,
            cell: (row: { end_date: string; }) => (
                row.end_date ? moment(row.end_date).format('DD/MM/YYYY') : '---'
            ),
            sortable: true,
            sortField: 'end_date',
        },
        {
            name: 'Status',
            selector: (row: { status: string; }) => row.status,
            cell: (row: { status: string; }) => (
                row.status === 'active' ? <span className="badge bg-success">Active</span> : <span className="capitalize badge bg-danger">{row.status}</span>
            ),
            sortable: true,
            sortField: 'status',
        },
        {  
            name: 'Action',
            cell: (row: { id: number, is_default: string }) => (
              <>
                {/* { canAccess?.edit && 
                    <Link
                    to={`/pricing-plans/edit/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="Edit Pricing Plans"
                    >
                    <i className="fas fa-pen mr-2"/>
                    </Link>
                } */}
                { canAccess?.view && 
                    <Link
                    to={`/pricing-plans/view/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="View Pricing Plans"
                    >
                    <i className="fas fa-eye mr-2"/>
                    </Link>
                }
                { canAccess?.attach && 
                    <Link
                        to={`/pricing-plans/link/${row.id}`}
                        role="button"
                        state={{ id: row.id }}
                        data-toggle="tooltip" title="Link Franchise To Plan"
                        >
                        <i className="fas fa-random mr-2"/>
                    </Link>
                }
                { canAccess?.['attach-subject'] && 
                    <Link
                        to={`/pricing-plans/attach/subject/${row.id}`}
                        role="button"
                        state={{ id: row.id }}
                        data-toggle="tooltip" title="Attach Subject To Plan"
                        >
                        <i className="fas fa-paperclip mr-2"></i>
                    </Link>
                }
                { canAccess?.history &&
                    <Link
                        to={`/pricing-plans/history/${row.id}`}
                        role="button"
                        state={{ id: row.id }}
                        data-toggle="tooltip" title="History"
                    >
                        <i className="fas fa-history mr-2" />
                    </Link>
                }
                { canAccess?.delete &&
                    <a href='#' onClick={ (e) => { confirm(e, row.id)} } data-toggle="tooltip" title="Delete Pricing Plans">
                        <i className="fas fa-trash mr-2"/>
                    </a>
                }
                { canAccess?.['make-default'] && (row.is_default !== "true") &&
                    <a href='#' onClick={ (e) => { confirmMakeDefault(e, row.id)} } data-toggle="tooltip" title="Make Default Plan">
                        <i className="fas fa-thumbtack mr-2"></i>
                    </a>
                }
              </>
            ),
        },
    ];

    const conditionalRowStyles = [
        {
            when: row => row.is_default === "true",
            style: {
                backgroundColor: 'rgb(228, 253, 254)',
                // color: 'white',
            },
        }
    ];

    const confirmMakeDefault = (e, id) => {
        confirmAlert({
            title: 'Make Default Plan!',
            message: 'Are you sure to make this default plan?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => makeDefaultOrderAPI(id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const makeDefaultOrderAPI = async (id) => {
        try {
            setLoading(true);
            const resp = await makeDefaultPricingPlan({id: id});
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                getAllPricingPlansData(currentPage, perPage, sortField, sortDirection);
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
          }
    };

    const confirm = (e, id) => {
        confirmAlert({
            title: 'Delete Plan',
            message: 'Are you sure to delete plan?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deletePricingPlans(id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const deletePricingPlans = async (id) => {
        try {
            setLoading(true);
            const resp = await deletePricingPlanById(id);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                getAllPricingPlansData(currentPage, perPage, sortField, sortDirection);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
          }
    };

    const handlePageChange = page => {
        getAllPricingPlansData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllPricingPlansData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllPricingPlansData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        setSearch({name: '', status: ''});
    };
    
    return (
        <div>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Manage Pricing Plans</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active">Manage Pricing Plans</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div style={{ marginBottom: 10 }} className="row">
                                
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Manage Pricing Plans</h3>
                                    <div className="col-lg-12 text-right">
                                        { canAccess?.create && <Link className="btn btn-primary" to="/pricing-plans/add" > + Add New Pricing Plan </Link> }
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <div className="row mb-2">
                                        <div className="col-sm-4">
                                            <input type="text" name='name' className="form-control" value={search.name} onChange={(e) => setSearch({ ...search, name: e.target.value })} placeholder="Search By Plan Name" />
                                        </div>
                                        <div className="col-sm-4">
                                            <select
                                                className="form-control"
                                                name="status"
                                                onChange={(e) => setSearch({ ...search, status: e.target.value })}
                                                value={search.status}
                                            >
                                                <option value="">Search By Status</option>
                                                {userStatus.map((obj) => (
                                                    <option key={obj.value} value={obj.value}>
                                                        {obj.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-sm-4'>
                                            <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                        </div>
                                    </div>
                                    
                                    <DataTable
                                        columns={columns}
                                        data={pricingPlans}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={currentPage}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                        progressPending={isLoading}
                                        sortServer
                                        onSort={handleSort}
                                        // paginationComponent
                                        // selectableRows
                                        customStyles={customStyle}
                                        conditionalRowStyles={conditionalRowStyles}
                                    />
                                </div>
                                {/* /.card-body */}
                            </div>
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>
    )
};

export default ListPricingPlans;