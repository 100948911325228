import { contrcatsURL } from "@app/constants/ApiEndPoints";
import axios from "axios";

// export const getSettingPatternWithPagination = async (page, perPage, sortField, sortDirection, search) => {
// 	try {
// 		const response = await axios.get(settingPatternURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&name=${search.name}&status=${search.status}`);
// 		if (response.status === 200 /*&& response.statusText === "OK"*/) {
// 			return response.data
// 		}
// 	} catch (error) {
// 		return error?.response?.data;
// 	}
// };

export const createFMSContract = async (payload) => {
	try {
		const response = await axios.post(contrcatsURL + 'create', payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getFMSContractByFranchiseId = async (id: number) => {
	try {
		const response = await axios.get(contrcatsURL + id );
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateFMSContract = async (payload) => {
	try {
		const response = await axios.put(contrcatsURL + 'update', payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getContractDocumentById = async (docId: number) => {
	try {
		const response = await axios.get(contrcatsURL + 'document/' + docId, { responseType: 'blob' });
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const deleteContractDocumentById = async (docId: number) => {
	try {
		const response = await axios.delete(contrcatsURL + 'document/' + docId);
		if (response.status === 201 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};
