import { printReportsURL } from "@app/constants/ApiEndPoints";
import axios from "axios";

export const getPrintHistoryWithPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(printReportsURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&franchiseId=${search.franchiseId}&kashflowOrderAccount=${search.kashflowOrderAccount}&month=${search.month}&year=${search.year}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getPrintReportDetailsByIdWithPagination = async (id, page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(printReportsURL + `/${id}?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&studentId=${search.studentId}&subjectId=${search.subjectId}&moduleId=${search.moduleId}&month=${search.month}&year=${search.year}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};