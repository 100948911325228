import axios from "axios";
import { createPermissionURL, deletePermissionURL, getAllModulesURL, getAllPermissionsURL, getCompactPermissionsURL, getPermissionByIdURL, updatePermissionURL } from "@app/constants/ApiEndPoints";

export const getPermissionsWithPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(getAllPermissionsURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&name=${search.name}&module=${search.module}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllModules = async () => {
	try {
		const response = await axios.get(getAllModulesURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const createPermission = async (payload) => {
	try {
		const response = await axios.post(createPermissionURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getPermissionById = async (id: number) => {
	try {
		const response = await axios.get(getPermissionByIdURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updatePermission = async (id, payload) => {
	try {
		const response = await axios.put(updatePermissionURL + id, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const deletePermissionById = async (id: number) => {
	try {
		const response = await axios.delete(deletePermissionURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};

export const getCompactPermissionByRoleId = async (id: number) => {
	try {
		const response = await axios.get(getCompactPermissionsURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};