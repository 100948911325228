import { createModuleURL, deleteModuleURL, getAllActiveModulesBySubjectURL, getAllActiveSetsBlocksAndHandbooksByModuleURL, getAllModulesURL, getAllSubjectModulesURL, getModuleByIdURL, getModulesWithPaginationURL, updateModuleURL } from "@app/constants/ApiEndPoints";
import axios from "axios";

export const getModulesWithPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(getModulesWithPaginationURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}
															&name=${search.name}&subjectId=${search.subjectId}&moduleAbbr=${search.moduleAbbr}&description=${search.description}&status=${search.status}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllSubjectModules = async () => {
	try {
		const response = await axios.get(getAllSubjectModulesURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllActiveModulesBySubject = async (subjectId) => {
	try {
		const response = await axios.get(getAllActiveModulesBySubjectURL + subjectId);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllActiveSetsBlocksAndHandbooksByModule = async (moduleId) => {
	try {
		const response = await axios.get(getAllActiveSetsBlocksAndHandbooksByModuleURL + moduleId + '/all-active');
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const createModule = async (payload) => {
	try {
		const response = await axios.post(createModuleURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getModuleById = async (id: number) => {
	try {
		const response = await axios.get(getModuleByIdURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateModule = async (id, payload) => {
	try {
		const response = await axios.put(updateModuleURL + id, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const deleteModuleById = async (id: number) => {
	try {
		const response = await axios.delete(deleteModuleURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};