import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import moment from 'moment';
import { getCampById } from '@app/services/summerCamp';
import { getPostcodeCountryByFranchiseId } from '@app/services/franchise';
import CurrencyFormat from 'react-currency-format';

const ViewCamp = () => {
    const campStatus = [{value:'active', label:'Active'}, {value:'inactive', label:'Inactive'}];
    let obj: any = {}
    const [isLoading, setLoading] = useState(false);
    const [batches, setBatches] = useState([]);
    const [camp, setCampData] = useState(obj);
    const [currency, setCurrency] = useState('');
    const navigate = useNavigate();
    let { id } = useParams();
    
    useEffect(() => {
        getCampDetailsById(id);
    }, []);

    const getCampDetailsById = async (id) => {
        try {
            setLoading(true);
            const resp = await getCampById(id);
            if(resp.success === true){
                setLoading(false);
                setCampData(resp.collections);
                setBatches(resp?.collections?.batches)
                getCountryByFranchiseId(resp?.collections?.franchise_id)
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
                navigate('/camps');
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal Server Error');
        }
    };

    const getCountryByFranchiseId = async (franchiseId) => {
        try {
            const resp = await getPostcodeCountryByFranchiseId(franchiseId);
            if (resp?.success === true) {
                setCurrency(resp?.collections?.currency)
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error');
        }
    };

  return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">View Camp Details</h3>
                            </div>
                            <div className="card-body">
                                <div className="row" style={{borderBottom: '1px solid #d1d1d1'}}>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <dt>Camp Name </dt>
                                            <dd>{camp?.name} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <dt>Start Date </dt>
                                            <dd>{camp?.start_date ? moment(camp.start_date).format('DD/MM/YYYY') : '---'} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <dt>End Date </dt>
                                            <dd>{camp?.end_date ? moment(camp.end_date).format('DD/MM/YYYY') : '---'} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <dt>Status </dt>
                                            <dd>{camp?.status ? (campStatus.find(obj => { return obj.value === camp?.status}))?.label : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <dt>Contact No. </dt>
                                            <dd>{camp?.contact_no ?? '---'} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <dt>Location </dt>
                                            <dd>{camp?.location ?? '---'} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <dt>Description </dt>
                                            { camp?.description && camp?.description.split("\n").map(function(item, idx) {
                                                return (
                                                    <span key={idx}>
                                                        {item}
                                                        <br/>
                                                    </span>
                                                )
                                            })}
                                            {/* <dd>{camp?.description ?? '---'} </dd> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12 mt-3'>
                                        <h5>Batches</h5>
                                    </div>
                                    <div className="col-md-12">
                                        <table className="table table-bordered" id="client_id_table">
                                            <tbody>
                                                <tr className='text-center'>
                                                    <th>Batch</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th>Batch Price</th>
                                                    <th>Day Price</th>
                                                    <th>Timings</th>
                                                </tr>
                                                { batches && (batches).map((batch) => (
                                                    <tr key={batch.id}>
                                                        <td>{ batch.name }</td>
                                                        <td>{ batch?.start_date ? moment(batch.start_date).format('DD/MM/YYYY') : '---' }</td>
                                                        <td>{ batch?.end_date ? moment(batch.end_date).format('DD/MM/YYYY') : '---' }</td>
                                                        <td>
                                                            <CurrencyFormat value={ batch?.batch_price } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency + ' '} />
                                                        </td>
                                                        <td>
                                                            <CurrencyFormat value={ batch?.day_price } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency + ' '} />
                                                        </td>
                                                        <td>
                                                            {batch?.timings.length > 0 && batch?.timings.map((time) => (
                                                                <p key={time?.id}>{ time.name}</p>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {batches?.length == 0 && (
                                            <span style={{ color: "red", textAlign: "center" }}>There are no batches to display</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                {/* <Link className="btn btn-primary mr-2" to={`/franchise/edit/${id}`}>Edit</Link> */}
                                <Link className="btn btn-default" to='/camps'>Close</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default ViewCamp;