import React, { useEffect, useState } from 'react'
import Table from '@app/components/datatable/Table';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import { downloadfile, getExtension, getListS3BucketItems, getSignedUrl } from '@app/services/opsManual';
import print from "print-js";
import PreviewModal from './PreviewModal';

const ListDocs = () => {

    const [bucketObjects, setBucketObjects] = useState([]);
    const [breadcrumbItems, setBreadcrumb] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [url, setUrl] = useState('');
    const [filename, setFileName] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getBucketObjects('OpsManual', '/', 'OpsManual/', false);
    },[]);

    const columns = [
        {
            name: 'Name',
            selector: (row: { itemName: string; }) => row.itemName,
            cell: (row: { itemName: string, Prefix: string, type: string }) => (                
                <>    
                    <a href='#' onClick={ (e) => { row.type =='Folder' ? getBucketObjects(row.itemName, '/', row.Prefix, false) : getFileURL(row.itemName, row.Prefix) }}>
                        { row.itemName }
                    </a>
                </>
            ),
        },
        // {
        //     name: 'Type',
        //     selector: (row: { type: string; }) => row.type,
        // },
        // {
        //     name: 'Prefix',
        //     selector: (row: { Prefix: string; }) => row.Prefix,
        // },
        {  
            name: 'Action',
            cell: (row: { itemName: string, Prefix: string, type: string }) => (
                <>
                    { row.type === 'File' ?
                        <>
                        <a href='#' onClick={ (e) => { getFileURL(row.itemName, row.Prefix)} } data-toggle="tooltip" title="Download File">
                            <i className="fas fa-download mr-3"></i>
                        </a> 
                        {row.itemName.split('.').pop() === 'pdf' && 
                            <a href='#' onClick={ (e) => { printPDF(row.itemName, row.Prefix)} } data-toggle="tooltip" title="Print PDF">
                                <i className="fas fa-print mr-3"></i>
                            </a>
                        }
                        { (['docx', 'doc', 'xlsx', 'xls', 'wav'].findIndex((ext) => ext == getExtension(row.itemName))) < 0 &&
                            <a href='#' onClick={ (e) => { previewFile(row.itemName, row.Prefix)} } data-toggle="tooltip" title="Preview">
                                <i className="fas fa-eye mr-3"></i>
                            </a> 
                        }
                        </>
                        : 
                        <a href='#' onClick={ (e) => { getBucketObjects(row.itemName, '/', row.Prefix, false)} } data-toggle="tooltip" title="Open Folder">
                            <i className="fas fa-share mr-2"></i>
                        </a>
                    }
                </>
            ),
        },
    ];

    const getBucketObjects = async (name, delimiter, prefix, breadcrumbFlag) => {
        try {
            setLoading(true);
            if(breadcrumbFlag){
                const index = breadcrumbItems.findIndex(
                    (item) => item.name === name
                );
                let breadcrumbItemsSplice = breadcrumbItems.splice(index)
                setBreadcrumb(breadcrumbItemsSplice);
            }

            const input = {
                bucket: process.env.REACT_APP_AWS_BUCKET_NAME, // required
                delimiter: delimiter,
                prefix: prefix
            };
            const resp = await getListS3BucketItems(input); 
            if (resp.success === true) {
                setLoading(false);

                const response = resp.response;
                setBreadcrumb([...breadcrumbItems, {name: name, prefix: prefix}]);
                let items = []
                if(response.CommonPrefixes){
                    (response.CommonPrefixes).map((row) => {
                        let str = (row.Prefix).toString().slice(0, -1);
                        str = str.substring(str.lastIndexOf('/') + 1)
                        items.push({Prefix: row.Prefix, itemName: str, type: 'Folder'})
                    })
                }

                if(response.Contents){
                    (response.Contents).map((row) => {
                        let str = row.Key.substring(row.Key.lastIndexOf('/') + 1)
                        items.push({Prefix: row.Key, itemName: str, type: 'File'})
                    })
                }
                setBucketObjects(items);
            } else {
                setLoading(false);
                toast .error(resp?.errors[0]?.msg);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const getFileURL = async (name, key) => {
        try{
            setLoading(true);
            const resp = await downloadfile({bucket: process.env.REACT_APP_AWS_BUCKET_NAME, key: key});
            fileDownload(resp, name);
            setLoading(false);        
        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    }

    const previewFile = async (name, key) => {
        try{
            setLoading(true);
            const resp = await downloadfile({bucket: process.env.REACT_APP_AWS_BUCKET_NAME, key: key});
            setLoading(false);
            setUrl(URL.createObjectURL(resp))
            setFileName(name)
            handleShow();

            // const fileType = name.split('.').pop();
            // if (1) {
                
            // }else{
            //     setLoading(false);
            //     toast .error(resp?.errors[0]?.msg);
            // }
        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    }

    const printPDF = async (name, key) => {
        try{
            setLoading(true);
            const resp = await downloadfile({bucket: process.env.REACT_APP_AWS_BUCKET_NAME, key: key});
            
            //Create a Blob from the PDF Stream
            // const file = new Blob( [response.data], {type: 'application/pdf'});
            
            //Build a URL from the file
            const fileURL = URL.createObjectURL(resp);
            
            //Open the URL on new Window
            // window.open(fileURL);
            
            //Open the URL on new Window
            print(fileURL);

            setLoading(false);        
        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    }

    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-4">
                                <h1>Manage Ops Manual</h1>
                            </div>
                            <div className="col-sm-8">
                                <ol className="breadcrumb float-sm-right">
                                    {/* <li className="breadcrumb-item"><a href="/">Home</a></li> */}
                                    {breadcrumbItems && breadcrumbItems.map((item, i) => (
                                        breadcrumbItems.length != i+1 ?
                                            <li className="breadcrumb-item" key={i}> <a href='#' onClick={ (e) => { getBucketObjects(item.name, '/', item.prefix, true)}}>{ item.name }</a></li>
                                        :
                                            <li className="breadcrumb-item" key={i}> { item.name }</li>
                                    ))}
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Manage Ops Manual</h3>
                                        <div className="col-lg-12 text-right">
                                            
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        {/* <div className="row mb-2"> 
                                            {breadcrumbItems && breadcrumbItems.map((item) => (
                                                <>
                                                    <a href="#" onClick={ (e) => { getBucketObjects(item.name, '/', item.prefix, true)}}>{ item.name }</a> / 
                                                </>
                                            ))}
                                        </div> */}
                                        <Table
                                            columns={columns}
                                            data={bucketObjects}
                                            // pagination
                                            // selectableRows
                                            progressPending={ isLoading }
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
                <PreviewModal
                    show={show}
                    handleClose={handleClose}
                    url={url}
                    filename={filename}
                />
            </div>
        </div>
    )
};

export default ListDocs;
