import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { getEnrolledStudnetsByCampWithPagination } from '@app/services/summerCamp';
import CurrencyFormat from 'react-currency-format';
import { getPostcodeCountryByFranchiseId } from '@app/services/franchise';
import ViewEnrollDetailsModal from './ViewEnrollDetailsModal';

const ListEnrolledStudents = () => {

    let { id } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({campId: id, type: '', totalAmount: ''});
    const enrollTypes = [{value: 'week' , name:'Week'}, {value: 'day', name:'Day'}];
    const [students, setStudents] = useState([]);
    const [currency, setCurrency] = useState('');
    const [campId, setCampId] = useState(0);
    const [studentId, setStudentId] = useState(0);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
                // borderBottomWidth: '0px',
                // borderBottomColor: theme.divider.default,
                // borderBottomStyle: 'solid',
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };
      
    useEffect(() => {
        getEnrolledStudnetsByCamp(1, perPage, sortField, sortDirection);
    },[search]);
    
    const getEnrolledStudnetsByCamp = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const resp = await getEnrolledStudnetsByCampWithPagination(page, perPage, sortField, sortDirection, search);
            if (resp?.success === true) {
                setLoading(false);
                setStudents(resp?.collections?.data);
                setTotalRows(resp?.collections?.pagination?.total);
                if(resp?.collections?.data.length > 0){
                    getCountryByFranchiseId(resp?.collections?.data[0].camp_franchise_id)
                }
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal Server Error');
        }
    };

    const getCountryByFranchiseId = async (franchiseId) => {
        try {
            const resp = await getPostcodeCountryByFranchiseId(franchiseId);
            if (resp?.success === true) {
                setCurrency(resp?.collections?.currency)
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error');
        }
    };

    const columns = [
        {
            name: 'Camp Name',
            selector: (row: { summer_camp_name: string; }) => row.summer_camp_name,
            // sortable: true,
            // sortField: 'summer_camp_name',
            wrap: true,
        },
        {
            name: 'Franchise Name',
            selector: (row: { franchise_name: string; }) => row.franchise_name,
            // sortable: true,
            // sortField: 'franchise_name',
            wrap: true,
        },
        {
            name: 'Student Name',
            selector: (row: { student_name: string; }) => row.student_name,
            // sortable: true,
            // sortField: 'student_name',
            wrap: true,
        },
        {
            name: 'Enroll Type',
            selector: (row: { type: string; }) => row.type.charAt(0).toUpperCase() + row.type.slice(1),
            // sortable: true,
            // sortField: 'summer_camp_name',
            wrap: true,
        },
        {
            name: 'Total Amount',
            selector: (row: { total_amount: string; }) => row.total_amount,
            format: (row: { total_amount: string }) => <CurrencyFormat value={ row.total_amount } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency + ' '} />,
            sortable: true,
            sortField: 'total_amount',
        },
        {  
            name: 'Action',
            cell: (row: { summer_camp_id: number, student_id: number }) => (
              <>
                <a href='#' onClick={ (e) => { handleViewDetailsModal(row.summer_camp_id, row.student_id)} }>
                    <i className="fas fa-eye mr-2" title="View Details"></i>
                </a>
              </>
            ),
        },
    ];

    const handleViewDetailsModal = (campId, studentId) => {
        handleShow();
        setCampId(campId);
        setStudentId(studentId)
    }

    const handlePageChange = page => {
        getEnrolledStudnetsByCamp(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getEnrolledStudnetsByCamp(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getEnrolledStudnetsByCamp(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        setSearch({campId: id, type: '', totalAmount: ''});
    };
    
    return (
        <div>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Manage Enrolled Students</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active">Manage Enrolled Students</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div style={{ marginBottom: 10 }} className="row">
                                
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Manage Enrolled Students</h3>
                                    <div className="col-lg-12 text-right">
                                        <Link className="btn btn-default" to="/camps" > Back </Link>
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <div className="row mb-2">
                                        <div className="col-sm-4">
                                            <select
                                                className="form-control"
                                                name="status"
                                                onChange={(e) => setSearch({ ...search, type: e.target.value })}
                                                value={search.type}
                                            >
                                                <option value="">Search By Enroll Type</option>
                                                {enrollTypes.map((obj) => (
                                                    <option key={obj.value} value={obj.value}>
                                                        {obj.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-4">
                                            <input type="number" name='totalAmount' className="form-control" value={search.totalAmount} onChange={(e) => setSearch({ ...search, totalAmount: e.target.value })} placeholder="Search By Total Amount" />
                                        </div>
                                        <div className='col-sm-4'>
                                            <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                        </div>
                                    </div>
                                    
                                    <DataTable
                                        columns={columns}
                                        data={students}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={currentPage}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                        progressPending={isLoading}
                                        sortServer
                                        onSort={handleSort}
                                        customStyles={customStyle}
                                    />
                                </div>
                                {/* /.card-body */}
                            </div>
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
            <ViewEnrollDetailsModal
                show={show}
                handleClose={handleClose}
                campId={campId}
                studentId={studentId}
                currency={currency}
            />
            {/* /.content */}
        </div>
    )
};

export default ListEnrolledStudents;