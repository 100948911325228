import { getAllAreasURL, getAreasWithPaginationURL } from "@app/constants/ApiEndPoints";
import axios from "axios";

export const getAreasWithPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(getAreasWithPaginationURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}
															&name=${search.name}&region=${search.region}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllAreas = async (slug: string) => {
	try {
		const response = await axios.get(getAllAreasURL + slug);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};