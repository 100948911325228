import React, { useState,useEffect } from 'react';
import {Form} from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@app/components';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { editGuardianService,getParentById } from './../../services/guardian';
import { getAllCountries } from '@app/services/postcode';
import Select from "react-select";
import { getAllActiveFranchise, getPostcodeCountryByFranchiseId } from '@app/services/franchise';

const EditGuardian = (props) => {
    const [isLoading, setLoading] = useState(false);
    const [franchise, setFranchise] = useState([]);
    const parentStatus = [{id:'active', name:'Active'}, {id:'inactive', name:'Inactive'}];
    const relationArr  = [{id:'father',name:'Father'},{id:'mother','name':'Mother'},{id:'guardian',name:'Guardian'}];
    const gender       = [{id:'male',name:"Male"},{id:'female',name:"Female"},{id:'other','name':'Rather Not Specify'}];
    let { id } = useParams();
    let obj: any = {};
    const [parentData,setParentData] = useState(obj);
    const [countries, setCountries] = useState([]);
    const navigate = useNavigate();

    const getCountriesData = async () => {
        try {
            const resp = await getAllCountries();
            if (resp.success === true) {
                setCountries(resp.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    const editGuardian = async (payload) => {
        try {
            setLoading(true);
            const resp = await editGuardianService(id,payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
                navigate('/parents');
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const getParentDetailsById = async (id) => {
        try {
            const resp = await getParentById(id);
            if(resp.success === true){
                setParentData(resp.collections);
            }else{
                toast.error(resp?.errors[0]?.msg);
                navigate('/parents');
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getCountryByFranchiseId = async (franchiseId) => {
        try {
            if(franchiseId){
                const resp = await getPostcodeCountryByFranchiseId(franchiseId);
                if (resp?.success === true) {
                    setFieldValue('country', resp?.collections?.country_slug)
                } else {
                    toast.error(resp?.errors[0]?.msg);
                }
            }else{
                setFieldValue('country', "")
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    const getAllActiveFranchiseData = async () => {
        try {
            const allActiveFranchiseResp = await getAllActiveFranchise();
            if (allActiveFranchiseResp.success === true) {
                setFranchise(allActiveFranchiseResp.collections);
            } else {
                toast.error(allActiveFranchiseResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    useEffect(() => {
        getParentDetailsById(id);
        getCountriesData();
        getAllActiveFranchiseData();
    },[]);

    const { handleChange, values, handleSubmit, touched, errors, resetForm, setFieldValue } = useFormik({
        initialValues: {
            first_name: parentData?.first_name || "",
            last_name : parentData?.last_name || "",
            email: parentData?.email || "",
            gender: parentData?.gender || "",
            parent_franchise: parentData?.franchise_id || "",
            phone: parentData?.phone || "",
            alt_phone: parentData?.alt_phone || "",
            address: parentData?.address || "",
            address_line_2: parentData?.address_line_2 || '',
            city: parentData?.city || '',
            postcode: parentData?.postcode || '',
            country: parentData?.country || '',
            status: parentData?.status || "",
        },
        validationSchema: Yup.object({
            first_name: Yup.string().trim().required('Please enter first name'),
            last_name: Yup.string().trim().required('Please enter last name'),
            email: Yup.string().trim().email().required('Please enter email'),
            gender:Yup.string().required('Please select gender'),
            parent_franchise: Yup.string().required('Please select franchise'),
            phone: Yup.number().typeError("Phone number should be numeric.").test('len', 'Contact no. must be exactly 10 digits', val => val.toString().length === 10).required('Please enter contact no.'),
            alt_phone: Yup.string().when({
                is: (exists) => !!exists,
                then: (rule) =>
                    rule.test('validate_phone', 'Alternate contact no. must be 10 digits.', (phone) => phone.length === 10),
            }),
            address: Yup.string().trim().required('Please enter address.'),
            city:Yup.string().trim().required('Please enter city'),
            status: Yup.string().required('Please select status'),
        }),
        onSubmit: (values) => {
            editGuardian(values);
        },
        enableReinitialize: true,
    });

    return (<div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Update Parent</h3>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="card-body">
                                                <div>
                                                    <div className='row'>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputFirstName">First Name <span className="required">*</span></label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    id="exampleInputFirstName" 
                                                                    placeholder="Enter first name"
                                                                    name="first_name" 
                                                                    onChange={handleChange} 
                                                                    value={values.first_name}
                                                                />
                                                                {(touched.first_name && errors.first_name) && (
                                                                    <div className="text-danger">
                                                                        {errors.first_name}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputLastName">Last name <span className="required">*</span></label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    id="exampleInputLastName" 
                                                                    placeholder="Enter last name"
                                                                    name="last_name" 
                                                                    onChange={handleChange} 
                                                                    value={values.last_name}
                                                                />
                                                                {(touched.last_name && errors.last_name) && (
                                                                    <div className="text-danger">
                                                                        {errors.last_name}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputGuardianEmail">Email <span className="required">*</span></label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    id="exampleInputGuardianEmail" 
                                                                    placeholder="Enter email"
                                                                    name="email" 
                                                                    onChange={handleChange} 
                                                                    value={values.email}
                                                                />
                                                                {(touched.email && errors.email) && (
                                                                    <div className="text-danger">
                                                                        {errors.email}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputGuardianGender">Gender <span className="required">*</span></label>
                                                                <select
                                                                    className="form-control"
                                                                    name="gender"
                                                                    onChange={handleChange} 
                                                                    value={values.gender}
                                                                >
                                                                <option value="">--Please Select--</option>
                                                                {gender.map((obj) => (
                                                                    <option key={obj.id} value={obj.id}>
                                                                        {obj.name}
                                                                    </option>
                                                                ))}
                                                                </select>
                                                                {(touched.gender && errors.gender) && (
                                                                    <div className="text-danger">
                                                                        {errors.gender}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputGrade">Franchise <span className="required">*</span></label>
                                                                <Select
                                                                    name="parent_franchise"
                                                                    placeholder="Select Franchise"
                                                                    options={franchise}
                                                                    getOptionValue={(option)=>`${option['id']}`}
                                                                    getOptionLabel={(option)=>`${option['name']}`}
                                                                    onChange={ (option) => { 
                                                                        if(option){
                                                                            setFieldValue("parent_franchise", option['id']);
                                                                            getCountryByFranchiseId(option['id']);
                                                                        }else{
                                                                            setFieldValue("parent_franchise", '')
                                                                            setFieldValue("country", '')
                                                                        }
                                                                    }}
                                                                    isClearable={true}
                                                                    value={franchise.filter(function(option) {
                                                                        return option.id === values.parent_franchise;
                                                                    })}
                                                                />
                                                                {(touched.parent_franchise && errors.parent_franchise) && (
                                                                    <div className="text-danger">
                                                                        {errors.parent_franchise}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputGuardianContactNo">Contact No. <span className="required">*</span></label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    id="exampleInputGuardianContactNo" 
                                                                    placeholder="Enter contact no."
                                                                    name="phone" 
                                                                    onChange={handleChange} 
                                                                    value={values.phone}
                                                                />
                                                                {(touched.phone && errors.phone) && (
                                                                    <div className="text-danger">
                                                                        {errors.phone}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>    
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputGuardianAltContactNo">Alternate Contact No.</label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    id="exampleInputGuardianAltContactNo" 
                                                                    placeholder="Enter alternate contact no."
                                                                    name="alt_phone" 
                                                                    onChange={handleChange} 
                                                                    value={values.alt_phone}
                                                                />
                                                                {(touched.alt_phone && errors.alt_phone) && (
                                                                    <div className="text-danger">
                                                                        {errors.alt_phone}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputAddress">Address Line 1<span className="required">*</span></label>
                                                                <textarea 
                                                                    className="form-control" 
                                                                    id="exampleInputAddress" 
                                                                    placeholder="Enter address line 1" 
                                                                    name="address" 
                                                                    onChange={handleChange} 
                                                                    value={values.address}
                                                                ></textarea>
                                                                {(touched.address && errors.address) && (
                                                                    <div className="text-danger">
                                                                        {errors.address}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputAddressLine2">Address Line 2</label>
                                                                <textarea 
                                                                    className="form-control" 
                                                                    id="exampleInputAddressLine2" 
                                                                    placeholder="Enter address line 2" 
                                                                    name="address_line_2" 
                                                                    onChange={handleChange} 
                                                                    value={values.address_line_2}
                                                                ></textarea>
                                                                {(touched.address_line_2 && errors.address_line_2) && (
                                                                    <div className="text-danger">
                                                                        {errors.address_line_2}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputCity">City <span className="required">*</span></label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    id="exampleInputCity" 
                                                                    placeholder="Enter city"
                                                                    name="city" 
                                                                    onChange={handleChange} 
                                                                    value={values.city}
                                                                />
                                                                {(touched.city && errors.city) && (
                                                                    <div className="text-danger">
                                                                        {errors.city}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputPostcode">Postcode </label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    id="exampleInputPostcode" 
                                                                    placeholder="Enter postcode"
                                                                    name="postcode" 
                                                                    onChange={handleChange} 
                                                                    value={values.postcode}
                                                                />
                                                                {(touched.postcode && errors.postcode) && (
                                                                    <div className="text-danger">
                                                                        {errors.postcode}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputCountry">Country </label>
                                                                <select
                                                                    id="exampleInputCountry" 
                                                                    className="form-control"
                                                                    name="country"
                                                                    onChange={ (e) => {
                                                                        handleChange(e);
                                                                    }} 
                                                                    value={values.country}
                                                                >
                                                                    <option value="">--Please select--</option>
                                                                    {countries.map((obj) => (
                                                                        <option key={obj.slug} value={obj.slug}>
                                                                            {obj.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {(touched.country && errors.country) && (
                                                                    <div className="text-danger">
                                                                        {errors.country}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputStatus">Status <span className="required">*</span></label>
                                                                <select
                                                                    className="form-control"
                                                                    name="status"
                                                                    onChange={handleChange} 
                                                                    value={values.status}
                                                                >
                                                                    <option value="">--Please select--</option>
                                                                    {parentStatus.map((obj) => (
                                                                        <option key={obj.id} value={obj.id}>
                                                                            {obj.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {(touched.status && errors.status) && (
                                                                    <div className="text-danger">
                                                                        {errors.status}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                   
                                        </div>
                                        <div className="card-footer">
                                            <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                                            <Link className="btn btn-default" to='/parents'>Cancel</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
    );
}

export default EditGuardian;