import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import { Form, Modal } from 'react-bootstrap';
import { updateSubjectRate } from '@app/services/franchise';

const UpdateSubjectRateModal = (props) => {

    const [isLoading, setLoading] = useState(false);

    const updateSubjectRateAPI = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateSubjectRate(payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                props.handleClose();
                props.getSubjectsByFranchiseId(props?.payload?.franchise_id);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            franchise_id: props?.payload?.franchise_id ?? '',
            subject_id: props?.payload?.subject?.subject_id ?? '',
            is_default: props?.payload?.subject?.is_default ?? false,
            rate: props?.payload?.subject?.rate ?? 0,
        },
        validationSchema: Yup.object({
            rate: Yup.number().required('Please enter subject rate').min(0, "Rate must be greater than or equal to 0"),
            // .max(10000, 'Rate must be less than or equal to 10,000'),
        }),
        onSubmit: (values) => {
            updateSubjectRateAPI(values);
        },
        enableReinitialize: true,
    });

    const handleSetDefault = (e, setFieldValue) => {
        setFieldValue('is_default', e.target.checked)
        if(e.target.checked){
            setFieldValue('rate', props?.payload?.subject?.default_rate)
        }else{
            setFieldValue('rate', 0)
        }
    }

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose} size="sm">
            <Modal.Header closeButton>
                <Modal.Title>Update Subject Rate</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className='row'>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <dt>Subject Name </dt>
                                <dd>{ props?.payload?.subject?.name } </dd>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Rate Per Student<span className="required">*</span></label>
                                <div className='input-group'>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">£</span>
                                    </div>
                                    <Form.Control 
                                        className='col-sm-9'
                                        type="number" 
                                        name="rate" 
                                        id="rate" 
                                        placeholder="Enter subject rate" 
                                        value={values.rate}
                                        disabled={values.is_default}
                                        onChange={handleChange}
                                        onWheel={ (e) => e.currentTarget.blur() }
                                    />
                                </div>
                                {(touched.rate && errors.rate) && (
                                    <div className="text-danger">
                                        {errors.rate}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input 
                                        type="checkbox" 
                                        className="form-check-input" 
                                        checked={values.is_default} 
                                        name="is_default" 
                                        onChange={(e) => handleSetDefault(e, setFieldValue)}
                                    />
                                    Set Default Rate
                                </label>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                    <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    </div>
  )
}

export default UpdateSubjectRateModal;