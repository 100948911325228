import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { getCentreById} from '@app/services/centre';

const ViewCentre = () => {

    let obj: any = {}
    let { id } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [centresData, setCentresData] = useState(obj);

    useEffect(() => {
        getCentreDetailsById(id);
    },[]);

    const getCentreDetailsById = async (id) => {
        try {
            setLoading(true);
            const resp = await getCentreById(id);
            if(resp.success === true){
                setLoading(false);
                setCentresData(resp?.collections);
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
          }
    };

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">View Centre</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="franchise_id">Franchise <span className="required">*</span></label>
                                            <dd>{centresData.franchise_name ? centresData.franchise_name : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="postcode_id">Postcode <span className="required">*</span></label>
                                            <dd>{centresData.postcode_name ? centresData.postcode_name : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Centre Name<span className="required">*</span></label>
                                            <dd>{centresData.name ? centresData.name : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="area_code">Area Code<span className="required">*</span></label>
                                            <dd>{centresData.area_code ? centresData.area_code : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1"> Email <span className="required">*</span></label>
                                            <dd>{centresData.email ? centresData.email : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Phone Number</label>
                                            <dd>{centresData.phone_number ? centresData.phone_number : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Address </label>
                                            <dd>{centresData.address ? centresData.address : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Status <span className="required">*</span></label>
                                            <dd>{ centresData.status === 'active' ? <span className="badge bg-success">Active</span> : <span className="capitalize badge bg-danger">{centresData.status}</span> }</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Assign Users <span className="required">*</span></label>
                                            <dd>{ centresData?.franchisee ? ( Array.prototype.map.call( centresData.franchisee, function(user) { return user.first_name +' '+user.last_name; }).join(", ")) : '---'}</dd>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className='col-md-12'>
                                    <Link className="btn btn-default" to='/centers'>Close</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default ViewCentre;