import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { deleteItemById, getAllItems } from '@app/services/otherItem';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import CurrencyFormat from 'react-currency-format';

const ListOtherItems = () => {

    const [items, setItems] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const canAccess = useSelector((state: any) => state.auth.permissions['other-items']);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({name: '', price: '', status: ''});
    const itemStatus = [{value:'active', label:'Active'}, {value:'inactive', label:'Inactive'}]

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    useEffect(() => {
        getAllOtherItemsData(1, perPage, sortField, sortDirection);
    },[search]);

    const getAllOtherItemsData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const otherItemsResp = await getAllItems(page, perPage, sortField, sortDirection, search);
            if (otherItemsResp.success === true) {
                setLoading(false);
                setItems(otherItemsResp?.collections?.data);
                setTotalRows(otherItemsResp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(otherItemsResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const columns = [
        {
            name: 'Item Name',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            sortField: 'name',
        },
        {
            name: 'Price',
            selector: (row: { price: string; }) => row.price,
            format: (row: { price:string }) => <CurrencyFormat value={ parseFloat(row.price).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} />,
            sortable: true,
            sortField: 'price',
        },
        {
            name: 'Availability',
            selector: (row: { in_stock: boolean; }) =>  row.in_stock,
            cell: (row: { in_stock: boolean; }) => (
                row.in_stock===true ? <span className="badge bg-danger">Out Of Stock</span> : <span className="badge bg-success">In Stock</span>
            ),
            sortable: true,
            sortField: 'in_stock',
        },
        {
            name: 'Status',
            selector: (row: { status: string; }) => row.status,
            cell: (row: { status: string; }) => (
                row.status === 'active' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>
            ),
            sortable: true,
            sortField: 'status',
        },
        {  
            name: 'Action',
            cell: (row: { id: number; }) => (
              <>
                { canAccess?.edit &&
                    <Link
                    to={`/other-items/edit/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    >
                    <i className="fas fa-pen mr-2"/>
                    </Link>
                }
                { canAccess?.delete &&
                    <a href='#' onClick={ (e) => { deleteItemConfirm(e, row.id)} }>
                        <i className="fas fa-trash mr-2"/>
                    </a>
                }
              </>
            ),
        },
    ];

    const deleteItemConfirm = (e, id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to delete this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteItem(id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const deleteItem = async (id) => {
        try {
            setLoading(true);
            const resp = await deleteItemById(id);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                getAllOtherItemsData(currentPage, perPage, sortField, sortDirection);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const handlePageChange = page => {
        getAllOtherItemsData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllOtherItemsData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllOtherItemsData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        setSearch({name: '', price: '', status: ''});
    };

    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manage Other Items</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active">Manage Other Items</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Manage Other Items</h3>
                                        <div className="col-lg-12 text-right">
                                            { canAccess?.create &&
                                                <Link className="btn btn-primary" to="/other-items/add" > + Add New Item </Link>
                                            }
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <div className="col-sm-3">
                                                <input type="text" name='name' className="form-control" value={search.name} onChange={(e) => setSearch({ ...search, name: e.target.value })} placeholder="Search By Item Name" />
                                            </div>
                                            <div className="col-sm-3">
                                                <input type="text" name='price' className="form-control" value={search.price} onChange={(e) => setSearch({ ...search, price: e.target.value })} placeholder="Search By Price" />
                                            </div>
                                            <div className="col-sm-3">
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={(e) => setSearch({ ...search, status: e.target.value })}
                                                    value={search.status}
                                                >
                                                    <option value="">Search By Status</option>
                                                    {itemStatus.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-sm-2'>
                                                <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                            </div>
                                        </div>

                                        <DataTable
                                            columns={columns}
                                            data={items}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationDefaultPage={currentPage}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            paginationPerPage={perPage}
                                            paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                            progressPending={isLoading}
                                            sortServer
                                            onSort={handleSort}
                                            // paginationComponent
                                            // selectableRows
                                            customStyles={customStyle}
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>

        </div>
    )
};

export default ListOtherItems;
