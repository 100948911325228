import axios from "axios";
import { useDispatch } from "react-redux";
// import jwtDecode from "jwt-decode";
import { APIBaseURL } from "../constants/ApiEndPoints";
import { logoutUser } from "@app/store/reducers/auth";
// import { APIBaseURL } from "../utlis/ApiEndPoints";

export const init = () => {
	const dispatch = useDispatch();
	setBaseUrl();
	setInterceptors(dispatch);
	// handleAuthentication();
}

const setBaseUrl = () => {
	axios.defaults.baseURL = APIBaseURL;
};

const setInterceptors = () => {
	const dispatch = useDispatch();
	axios.interceptors.response.use(
		(response) => {
			return response;
		},
		async (error) => {
			const originalRequest = error.config;
			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;
				dispatch(logoutUser());
				return axios(originalRequest);
			}
			return Promise.reject(error);
		}
	);
	axios.interceptors.request.use(
		(config) => {
			const access_token = getAccessToken();
			if (access_token) {
				config.headers["Authorization"] = `${access_token}`;
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);
};

const handleAuthentication = () => {
	const access_token = getAccessToken();
	if (!access_token) {
		logout();
		return;
	}
	if (!isAuthTokenValid(access_token)) {
		logout();
		return;
	}
	setSession(access_token);
};

export const setSession = (access_token) => {
	if (access_token) {
		const decode = jwtDecode(access_token);
		let userData = {
			id: decode.data.id,
			email: decode.data.email,
			name: decode.data.name,
			mobile: decode.data.mobile,
			verified: decode.data.verified,
			onboarding_step: decode.data.onboarding_step
		}
		sessionStorage.setItem('user_data', JSON.stringify(userData))
		sessionStorage.setItem("jwt_access_token", access_token);
		axios.defaults.headers.common.token = `${access_token}`;
	} else {
		sessionStorage.removeItem('user_data')
		sessionStorage.removeItem("jwt_access_token");
		delete axios.defaults.headers.common.token;
	}
};

export const setApiKey = (api_key) => {
	if (api_key) {
		sessionStorage.setItem("api_key", api_key);
		axios.defaults.headers.common['api-key'] = `${api_key}`;
	} else {
		sessionStorage.removeItem("api_key");
		delete axios.defaults.headers.common.Authorization;
	}
};

export const logout = (access_token) => {
	if (access_token) {
		localStorage.removeItem("token");
		delete axios.defaults.headers.common.token;
		// sessionStorage.clear();
		// window.location.replace(process.env.REACT_APP_LOGOUT_URL);
	}
};

const isAuthTokenValid = (access_token) => {
	if (!access_token) {
		return false;
	}
	const decoded = jwtDecode(access_token);
	const currentTime = Date.now() / 1000;
	if (decoded.exp < currentTime) {
		console.warn("access token expired");
		return false;
	}
	return true;
};

export const getAccessToken = () => {
	if(localStorage.getItem("token")){
		return localStorage.getItem("token");
	}
	return localStorage.getItem("Temptoken")
};
