import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import Select from "react-select";
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { useSelector } from 'react-redux';
import { deletePostcodeById, getAllActivePostcodes, getAllCountries, getAllPostcodes, updateFavouritePostcode } from '@app/services/postcode';
import { getAllActiveFranchise } from '@app/services/franchise';
import { getAllRegions } from '@app/services/region';
import { getAllAreas } from '@app/services/area';
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import fileDownload from 'js-file-download';
import moment from 'moment';

const ListPostcodes = () => {

    const selectRef = useRef(null);
    const [postcodes, setPostcodes] = useState([]);
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [areas, setAreas] = useState([]);
    const [franchises, setFranchises] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({country: '', region: '', area: '', name: '', description: '', status: '', franchiseId: '', favourite: ''});
    const postcodeStatus = [ 
        {id:'active', name:'Active'},
        {id:'available', name:'Available'},
        {id:'offered', name:'Offered'},
        {id:'reserved', name:'Reserved'},
    ]
    const canAccess = useSelector((state: any) => state.auth.permissions.postcodes);

    const customStylesSelect = {
        dropdownIndicator: () => ({
            display: 'none',
        }),
    }

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };
      
    useEffect(() => {
        getAllPostcodesData(1, perPage, sortField, sortDirection);
    },[search]);

    useEffect(() => {
        getAllActiveFranchiseData();
        getAllCountriesData();
        getAllRegionsData('all');
        getAllAreasData('all');
    },[]);

    const getAllCountriesData = async () => {
        try {
            const countriesResp = await getAllCountries();
            if (countriesResp.success === true) {
                setCountries(countriesResp.collections);
            } else {
                toast.error(countriesResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllRegionsData = async (slug) => {
        try {
            const resp = await getAllRegions(slug)
            if (resp?.success === true) {
                setRegions(resp?.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllAreasData = async (slug) => {
        try {
            const resp = await getAllAreas(slug)
            if (resp?.success === true) {
                setAreas(resp?.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllPostcodesData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const usersResp = await getAllPostcodes(page, perPage, sortField, sortDirection, search);
            if (usersResp.success === true) {
                setLoading(false);
                setPostcodes(usersResp.collections.data);
                setTotalRows(usersResp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(usersResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllActiveFranchiseData = async () => {
        try {
            const resp = await getAllActiveFranchise();
            if (resp?.success === true) {
                setFranchises(resp?.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const columns = [
        {
            name: 'Country',
            selector: (row: { country_name: string; }) => row.country_name,
            sortable: true,
            sortField: 'country_id',
        },
        {
            name: 'Region',
            selector: (row: { region_name: string; }) => row.region_name ? row.region_name : '---',
            sortable: true,
            sortField: 'region',
        },
        {
            name: 'Area',
            selector: (row: { area_name: string; }) => row.area_name ? row.area_name : '---',
            sortable: true,
            sortField: 'area',
        },
        {
            name: 'Postcode',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            sortField: 'name',
        },
        {
            name: 'Franchise',
            selector: (row: { franchise_name: string; }) => row.franchise_name ? row.franchise_name : '---',
            sortable: true,
            sortField: 'franchise_name',
            wrap: true,
        },
        {
            name: 'Status',
            selector: (row: { status: string; }) => row.status.charAt(0).toUpperCase() + row.status.slice(1),
            sortable: true,
            sortField: 'status',
        },
        // {
        //     name: 'Description',
        //     selector: (row: { description: string; }) => row.description ? row.description : '---',
        //     sortable: true,
        //     sortField: 'description',
        //     wrap: true,
        // },
        // {
        //     name: 'Comment',
        //     selector: (row: { comment: string; }) => row.comment ? row.comment : '---',
        //     sortable: true,
        //     sortField: 'comment',
        //     wrap: true,
        // },
        {  
            name: 'Action',
            cell: (row: { id: number, status:string, favourite:string }) => (
              <>
                { canAccess?.edit && 
                    <Link
                    to={`/postcodes/edit/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="Edit"
                    >
                    <i className="fas fa-pen mr-2"/>
                    </Link>
                }
                { canAccess?.history &&
                    <Link
                        to={`/postcodes/history/${row.id}`}
                        role="button"
                        state={{ id: row.id }}
                        data-toggle="tooltip" title="History"
                    >
                        <i className="fas fa-history mr-2" />
                    </Link>
                }
                { canAccess?.['attach-franchise'] && (row.status !== 'active') &&
                    <Link
                        to={`/postcodes/attach-franchise/${row.id}`}
                        role="button"
                        state={{ id: row.id }}
                        data-toggle="tooltip" title="Attach Franchise"
                    >
                        <i className="fas fa-link mr-2" />
                    </Link>
                }
                { canAccess?.['detach-franchise'] && (row.status === 'active') &&
                    <Link
                        to={`/postcodes/detach-franchise/${row.id}`}
                        role="button"
                        state={{ id: row.id }}
                        data-toggle="tooltip" title="Detach Franchise"
                    >
                        <i className="fas fa-unlink mr-2" />
                    </Link>
                }
                { canAccess?.delete &&
                    <a href='#' onClick={ (e) => { deleteConfirm(e, row.id)} } data-toggle="tooltip" title="Delete">
                        <i className="fas fa-trash mr-2"/>
                    </a>
                }
                { canAccess?.favourite &&
                    <a href="#" onClick={ () => { updatePostcodeFavourite({id: row.id, favourite: row.favourite === 'true' ? 'false' : 'true'})} } >
                        <i className={`${row.favourite === 'true' ? 'fas fa-star text-warning' : 'far fa-star'}`}></i>
                    </a>
                }
              </>
            ),
        },
    ];

    const conditionalRowStyles = [
        {
            when: row => row.status === 'reserved',
            style: {
                backgroundColor: 'rgb(248, 252, 208)',
                // color: 'white',
            },
        },
        {
            when: row => row.status === 'active',
            style: {
                backgroundColor: 'rgb(228, 253, 254)',
                // color: 'white',
            },
        },
        {
            when: row => row.status === 'available',
            style: {
                backgroundColor: 'rgb(222, 250, 216)',
                // color: 'white',
            },
        },
        {
            when: row => row.status === 'offered',
            style: {
                backgroundColor: 'rgb(255, 226, 138)',
                // color: 'white',
            },
        },
    ];

    const deleteConfirm = (e, id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to delete this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deletePostcode(id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const deletePostcode = async (id) => {
        try {
            setLoading(true);
            const resp = await deletePostcodeById(id);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                getAllPostcodesData(currentPage, perPage, sortField, sortDirection);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
          }
    };

    const updatePostcodeFavourite = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateFavouritePostcode(payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                getAllPostcodesData(currentPage, perPage, sortField, sortDirection);
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const handlePageChange = page => {
        getAllPostcodesData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllPostcodesData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllPostcodesData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        selectRef.current.setValue('');
        setSearch({country: '', region: '', area: '', name: '', description: '', status: '', franchiseId: '', favourite: ''});
    };

    const onChangeFranchise = (option) => {
        setSearch({ ...search, franchiseId: option.id })
    };
    
    const getAllActivePostcodesData = async () => {
        try {
            setLoading(true);
            const resp = await getAllActivePostcodes();
            if (resp.success === true) {
                setLoading(false);
                return resp.collections;
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const exportCSV = async() => {
        
        const activePostcodes = await getAllActivePostcodesData();
        if(activePostcodes.length === 0){
            toast.error('Active postocodes not found.')
            return;
        }

        const workbook = new ExcelJS.Workbook();
        workbook.addWorksheet("Active Postcodes");
        const worksheet = workbook.getWorksheet('Active Postcodes');
        worksheet.columns = [
            // { header: "ID", key: "id" },
            { header: "Postcode", key: "name" },
            { header: "Franchise", key: "franchise_name" },
            { header: "First Name", key: "first_name" },
            { header: "Last Name", key: "last_name" },
            { header: "Status", key: "status" }
        ];

        worksheet.addRows(activePostcodes);
        let filename = 'Active-Postcodes-' + moment().format('DD-MMM-YYYY') + '.csv';
        workbook.csv.writeBuffer().then(function(buffer) {
            fileDownload(buffer, filename);
        });
    };

    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manage Postcodes</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active">Manage Postcodes</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Manage Postcodes</h3>
                                        <div className="col-lg-12 text-right">
                                            <Button className="btn btn-success mr-2" isLoading={isLoading} onClick={exportCSV}><i className="fas fa-file-csv"></i> Export Active Postcodes </Button>
                                            { canAccess?.demographics && <Link className="btn btn-secondary mr-2" to="/postcodes/demographics" ><i className="fas fa-chart-pie"></i> Demographics </Link> }
                                            { canAccess?.create && <Link className="btn btn-primary" to="/postcodes/add" > + Add New Postcode </Link> }
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="countryId"
                                                    onChange={(e) => {
                                                        setSearch({ ...search, country: e.target.value })
                                                        getAllRegionsData(e.target.value);
                                                    }}
                                                    value={search.country}
                                                >
                                                    <option value="">Search By Country</option>
                                                    {countries.map((obj) => (
                                                        <option key={obj.slug} value={obj.slug}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="region"
                                                    onChange={(e) => {
                                                        setSearch({ ...search, region: e.target.value })
                                                        getAllAreasData(e.target.value);
                                                    }}
                                                    value={search.region}
                                                >
                                                    <option value="">Search By Region</option>
                                                    {regions.map((obj) => (
                                                        <option key={obj.slug} value={obj.slug}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="area"
                                                    onChange={(e) => setSearch({ ...search, area: e.target.value })}
                                                    value={search.area}
                                                >
                                                    <option value="">Search By Area</option>
                                                    {areas.map((obj) => (
                                                        <option key={obj.slug} value={obj.slug}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='name' className="form-control" value={search.name} onChange={(e) => setSearch({ ...search, name: e.target.value })} placeholder="Search By Postcode" />
                                            </div>
                                            {/* <div className="col-sm-2">
                                                <input type="text" name='description' className="form-control" value={search.description} onChange={(e) => setSearch({ ...search, description: e.target.value })} placeholder="Search description" />
                                            </div> */}
                                            <div className="col-sm-2">
                                                <Select
                                                    ref={selectRef}
                                                    name="franchiseId"
                                                    placeholder="Search By Franchise"
                                                    options={franchises}
                                                    getOptionValue={(option)=>`${option['id']}`}
                                                    getOptionLabel={(option)=>`${option['name']}`}
                                                    onChange={ (option) => {onChangeFranchise(option)}}
                                                    // onInputChange={ (option) => {onChangeFranchise(option)}}
                                                    // value={search.franchiseId}
                                                    styles={customStylesSelect}
                                                />
                                            </div>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={(e) => setSearch({ ...search, status: e.target.value })}
                                                    value={search.status}
                                                >
                                                    <option value="">Search By Status</option>
                                                    {postcodeStatus.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            { canAccess?.favourite &&
                                                <div className="col-sm-2">
                                                    <select
                                                        className="form-control"
                                                        name="favourite"
                                                        onChange={(e) => setSearch({ ...search, favourite: e.target.value })}
                                                        value={search.favourite}
                                                    >
                                                        <option value="">Search By Favourite</option>
                                                        <option key='1' value='true'>All Favourites</option>
                                                        <option key='0' value='false'>All Unfavourites</option>
                                                    </select>
                                                </div>
                                            }
                                            <div className='col-sm-2'>
                                                <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                            </div>
                                        </div>
                                        
                                        <DataTable
                                            columns={columns}
                                            data={postcodes}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationDefaultPage={currentPage}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            paginationPerPage={perPage}
                                            paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                            progressPending={isLoading}
                                            sortServer
                                            onSort={handleSort}
                                            // paginationComponent
                                            // selectableRows
                                            customStyles={customStyle}
                                            conditionalRowStyles={conditionalRowStyles}
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>

        </div>
    )
};

export default ListPostcodes;
