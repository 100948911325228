import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import moment from 'moment';
import { clearCartByFranchiseId, getCartItemsByFranchise, getOrderDetailsById, repeatOrderById } from '@app/services/order';
import { Col, Form, Row } from 'react-bootstrap';
import { Button } from '@app/components';
import "../../pages/order-form/OrderForm.css";
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { loadCart, setFranchise, setStep } from '@app/store/reducers/order';

const ViewOrder = () => {
    let obj: any = {modules:{english:[], mathematics:[]}, other_items:[]}
    const [isLoading, setLoading] = useState(false);
    const [order, setOrderData] = useState(obj);
    const canAccess = useSelector((state: any) => state.auth.permissions.orders);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { id } = useParams();
    const orderStatuses = [{label:'Unpaid', value:'unpaid'}, {label:'Sent to Print Partner', value:'sent'}, {label:'In Production', value:'acknowledged'},{label:'Failed', value:'failed'}, {label:'Dispatched', value:'dispatched'}, {label:'Cancelled', value:'cancelled'}];

    useEffect(() => {
        getOrderById(id);
    }, []);

    const getOrderById = async (id) => {
        try {
            setLoading(true);
            const resp = await getOrderDetailsById(id);
            if(resp?.success === true){
                setLoading(false);
                setOrderData(resp?.collections);
            }else{
                setLoading(false);
                toast.error(resp?.message);
                navigate('/in-progress-orders');
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const repeatOrderByIdData = async (orderId) => {
        try {
            setLoading(true);
            const resp = await repeatOrderById(orderId);
            if (resp?.success === true) {
                setLoading(false);
                dispatch(setFranchise({ id: order?.franchise_id, name: order?.franchise_name, phone: order?.franchise_phone, address: order?.franchise_address,}));
                dispatch(setStep(2));
                navigate('/orders/place');
                // getCartItemsByFranchiseData(order?.franchise_id);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const clearBasket = async () => {
        try {
            const resp = await clearCartByFranchiseId(order?.franchise_id);
            if(resp.success === true){
                // toast.success(resp?.message);
                dispatch(loadCart([]));
                repeatOrderByIdData(order?.id);
            }else{
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            toast.error(error.response.message || 'Failed');
          }
    };

    const handleRepeatOrder = () => {
        clearBasket();
    }

    return (
        <div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">View Order</h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Order Id</dt>
                                                <dd>#{ order?.id }</dd>
                                                {/* <label htmlFor="exampleInputEmail1">Order Id </label>
                                                <Form.Control plaintext readOnly value={ '#123'} /> */}
                                            </div>
                                        </div>
                                        {/* <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Invoice Id</dt>
                                                <dd>{ order?.invoice_no }</dd>
                                            </div>
                                        </div>
                                        */}
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Franchise</dt>
                                                <dd>{ order?.franchise_name }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Shipping Address </dt>
                                                <dd>{ order?.shipping_address }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Contact Number</dt>
                                                <dd>{ order?.contact_no }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Order Placed At </dt>
                                                <dd>{moment(order?.created_at).format('DD-MM-YYYY hh:mm A')}</dd>
                                            </div>
                                        </div>
                                        { order?.pcharge_type && (
                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <dt>Processing Charge - { order?.pcharge_type === 'first' ? ' First Year' : ' Standard'}</dt>
                                                    <dd><CurrencyFormat value={ order?.pcharge_net } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></dd>
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Net Amount </dt>
                                                <dd><CurrencyFormat value={ order?.net_price } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Vat Amount</dt>
                                                <dd><CurrencyFormat value={ (parseFloat(order?.vat) + parseFloat(order?.pcharge_vat)).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Total Amount </dt>
                                                <dd><CurrencyFormat value={ (parseFloat(order?.total_price) + parseFloat(order?.pcharge_total)).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></dd>
                                            </div>
                                        </div>
                                        {/* <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Shipping Status </dt>
                                                <dd>{ order?.shipping_status ? order?.shipping_status.charAt(0).toUpperCase() + order?.shipping_status.slice(1) : '---'}</dd>
                                            </div>
                                        </div> */}
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Handbook Binding </dt>
                                                <dd>{ order?.handbook_binding ?? '---' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Order Status </dt>
                                                <dd>{ order.order_status ? (orderStatuses.find(obj => { return obj.value === order.order_status}))?.label : '---' }</dd>
                                            </div>
                                        </div>
                                        { order?.dispatch_date && (
                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <dt>Dispatch Date </dt>
                                                    <dd>{moment(order?.dispatch_date).format('DD-MM-YYYY')}</dd>
                                                </div>
                                            </div>
                                        )}
                                        { order?.printer_order_no && (
                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <dt>Printer Order No. </dt>
                                                    <dd>#{ order?.printer_order_no}</dd>
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Stripe Payment Id </dt>
                                                <dd>{ order?.stripe_payment_id ?? '---' }</dd>
                                            </div>
                                        </div> 
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <dt>Kashflow Order Account </dt>
                                                <dd>{ order?.kashflow_order_account ?? '---' }</dd>
                                            </div>
                                        </div> 
                                    </div>
                                    { order?.instructions != null && (
                                        <div className='row'>
                                            <div className="col-sm-12 border border-warning">
                                                <div className="form-group">
                                                    <dt>Instructions </dt>
                                                    <dd>{ order?.instructions ?? '---' }</dd>
                                                </div>
                                            </div> 
                                        </div>
                                    )}
                                </div>
                                <section className='modul_sec_wrapper'>
                                    <div className='container-fluid'>
                                        <div className="row">
                                            <div className="col-md-12 col-lg-12 col-xl-12">
                                                <div className="content" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                    <h6 className="mb-4 grey_wrapper_title d-flex justify-content-between">Order Summary 
                                                        <span>
                                                            <b>Total Sets Ordered:</b> { order?.total_sets ? order?.total_sets : 0 } <br></br>
                                                            <b>Total Handbooks Ordered:</b> { order?.total_handbooks ? order?.total_handbooks : 0 }
                                                        </span>
                                                    </h6>
                                                    { order?.modules['english'] && (
                                                        <div className="content_inner_wrap" key={1}>
                                                            <Row>
                                                                <Col xl={12}>
                                                                    <b>English</b>
                                                                </Col>
                                                                <Col xl={12}>
                                                                { (order?.modules['english']).map((module) => (
                                                                    <div className="list_module_wrapper" key={module?.slug}>
                                                                        <Row>
                                                                            <Col xl={12}>
                                                                                <h6 className="mb-4 grey_wrapper_title">
                                                                                    { module.name } ({ module.description })
                                                                                </h6>
                                                                            </Col>
                                                                            <Col xl={4} md={4} sm={4}>
                                                                                <table className="table table-bordered" id="client_id_table">
                                                                                    <tbody>
                                                                                        { module?.allsets[0]?.qty && (
                                                                                            <tr>
                                                                                                <th> Entire Module</th>
                                                                                                <td> { module?.allsets[0]?.qty }</td>
                                                                                            </tr>
                                                                                        )}
                                                                                        { module?.handbooks.length > 0 && module?.handbooks.map((handbook, i) => (
                                                                                            <tr key={i}>
                                                                                                <th> Handbook
                                                                                                    {handbook?.combined_name && (
                                                                                                        <span className="font-italic font-weight-light ml-2">({handbook?.combined_name})</span>
                                                                                                    )}
                                                                                                </th>
                                                                                                <td> { handbook?.qty }</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                        { module?.handbook_suppliments.length > 0 && module?.handbook_suppliments.map((handbook, i) => (
                                                                                            <tr key={i}>
                                                                                                <th> Handbook Supplements</th>
                                                                                                <td> { handbook?.qty }</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </Col>
                                                                        
                                                                            { (module?.blocks).length > 0 && (
                                                                                <Col xl={4} md={4} sm={4}>
                                                                                    <table className="table table-bordered" id="client_id_table">
                                                                                        <tbody>
                                                                                            <tr className='text-center'>
                                                                                                <th> Blocks</th>
                                                                                                <th> Quantity</th>
                                                                                            </tr>
                                                                                            { module?.blocks.map((block, i) => (
                                                                                                <tr className='text-center'>
                                                                                                    <td> { (block?.name).slice(4) }</td>
                                                                                                    <td> { block?.qty } </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Col>
                                                                            )}
                                                                            { (module?.sets).length > 0 && (
                                                                                <Col xl={4} md={4} sm={4}>
                                                                                    <table className="table table-bordered" id="client_id_table">
                                                                                        <tbody>
                                                                                            <tr className='text-center'>
                                                                                                <th> Sets</th>
                                                                                                <th> Quantity</th>
                                                                                            </tr>
                                                                                            { module?.sets.map((set, i) => (
                                                                                                <tr className='text-center'>
                                                                                                    <td> { (set?.name).slice(4) }</td>
                                                                                                    <td> { set?.qty } </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Col>
                                                                            )}
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                    { order?.modules['mathematics'] && (
                                                        <div className="content_inner_wrap" key={2}>
                                                            <Row>
                                                                <Col xl={12}>
                                                                    <b>Mathematics</b>
                                                                </Col>
                                                                <Col xl={12}>
                                                                { (order?.modules['mathematics']).map((module) => (
                                                                    <div className="list_module_wrapper" key={module?.slug}>
                                                                        <Row>
                                                                            <Col xl={12}>
                                                                                <h6 className="mb-4 grey_wrapper_title">
                                                                                    { module.name } ({ module.description })
                                                                                </h6>
                                                                            </Col>
                                                                            <Col xl={4}>
                                                                                <table className="table table-bordered" id="client_id_table">
                                                                                    <tbody>
                                                                                        { module?.allsets[0]?.qty && (
                                                                                            <tr>
                                                                                                <th> Entire Module</th>
                                                                                                <td> { module?.allsets[0]?.qty }</td>
                                                                                            </tr>
                                                                                        )}
                                                                                        { module?.handbooks.length > 0 && module?.handbooks.map((handbook, i) => (
                                                                                            <tr key={i}>
                                                                                                <th> Handbook
                                                                                                    {handbook?.combined_name && (
                                                                                                        <span className="font-italic font-weight-light ml-2">({handbook?.combined_name})</span>
                                                                                                    )}
                                                                                                </th>
                                                                                                <td> { handbook?.qty }</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                        { module?.handbook_suppliments.length > 0 && module?.handbook_suppliments.map((handbook, i) => (
                                                                                            <tr key={i}>
                                                                                                <th> Handbook Supplements</th>
                                                                                                <td> { handbook?.qty }</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </Col>
                                                                            { (module?.blocks).length > 0 && (
                                                                                <Col xl={4}>
                                                                                    <table className="table table-bordered" id="client_id_table">
                                                                                        <tbody>
                                                                                            <tr className='text-center'>
                                                                                                <th> Blocks</th>
                                                                                                <th> Quantity</th>
                                                                                            </tr>
                                                                                            { module?.blocks.map((block, i) => (
                                                                                                <tr className='text-center'>
                                                                                                    <td> { (block?.name).slice(4) }</td>
                                                                                                    <td> { block?.qty } </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Col>
                                                                            )}
                                                                            { (module?.sets).length > 0 && (
                                                                                <Col xl={4}>
                                                                                    <table className="table table-bordered" id="client_id_table">
                                                                                        <tbody>
                                                                                            <tr className='text-center'>
                                                                                                <th> Sets</th>
                                                                                                <th> Quantity</th>
                                                                                            </tr>
                                                                                            { module?.sets.map((set, i) => (
                                                                                                <tr className='text-center'>
                                                                                                    <td> { (set?.name).slice(4) }</td>
                                                                                                    <td> { set?.qty } </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Col>
                                                                            )}
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                    { order?.modules['science'] && (
                                                        <div className="content_inner_wrap" key={3}>
                                                            <Row>
                                                                <Col xl={12}>
                                                                    <b>Science</b>
                                                                </Col>
                                                                <Col xl={12}>
                                                                { (order?.modules['science']).map((module) => (
                                                                    <div className="list_module_wrapper" key={module?.slug}>
                                                                        <Row>
                                                                            <Col xl={12}>
                                                                                <h6 className="mb-4 grey_wrapper_title">
                                                                                    { module.name } ({ module.description })
                                                                                </h6>
                                                                            </Col>
                                                                            <Col xl={4} md={4} sm={4}>
                                                                                <table className="table table-bordered" id="client_id_table">
                                                                                    <tbody>
                                                                                        { module?.allsets[0]?.qty && (
                                                                                            <tr>
                                                                                                <th> Entire Module</th>
                                                                                                <td> { module?.allsets[0]?.qty }</td>
                                                                                            </tr>
                                                                                        )}
                                                                                        { module?.handbooks.length > 0 && module?.handbooks.map((handbook, i) => (
                                                                                            <tr key={i}>
                                                                                                <th> Handbook
                                                                                                    {handbook?.combined_name && (
                                                                                                        <span className="font-italic font-weight-light ml-2">({handbook?.combined_name})</span>
                                                                                                    )}
                                                                                                </th>
                                                                                                <td> { handbook?.qty }</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                        { module?.handbook_suppliments.length > 0 && module?.handbook_suppliments.map((handbook, i) => (
                                                                                            <tr key={i}>
                                                                                                <th> Handbook Supplements</th>
                                                                                                <td> { handbook?.qty }</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </Col>
                                                                        
                                                                            { (module?.blocks).length > 0 && (
                                                                                <Col xl={4} md={4} sm={4}>
                                                                                    <table className="table table-bordered" id="client_id_table">
                                                                                        <tbody>
                                                                                            <tr className='text-center'>
                                                                                                <th> Blocks</th>
                                                                                                <th> Quantity</th>
                                                                                            </tr>
                                                                                            { module?.blocks.map((block, i) => (
                                                                                                <tr className='text-center'>
                                                                                                    <td> { (block?.name).slice(4) }</td>
                                                                                                    <td> { block?.qty } </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Col>
                                                                            )}
                                                                            { (module?.sets).length > 0 && (
                                                                                <Col xl={4} md={4} sm={4}>
                                                                                    <table className="table table-bordered" id="client_id_table">
                                                                                        <tbody>
                                                                                            <tr className='text-center'>
                                                                                                <th> Sets</th>
                                                                                                <th> Quantity</th>
                                                                                            </tr>
                                                                                            { module?.sets.map((set, i) => (
                                                                                                <tr className='text-center'>
                                                                                                    <td> { (set?.name).slice(4) }</td>
                                                                                                    <td> { set?.qty } </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Col>
                                                                            )}
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                    { (order?.other_items).length > 0 && (
                                                        <div className="content_inner_wrap" key={4}>
                                                            <Row>
                                                                <Col xl={12}>
                                                                    <b>Other Items</b>
                                                                </Col>
                                                                <Col xl={12}>
                                                                    <div className="list_module_wrapper">
                                                                        <Row>
                                                                            <Col xl={6}>
                                                                                <table className="table table-bordered" id="client_id_table">
                                                                                    <tbody>
                                                                                        <tr className='text-center'>
                                                                                            <th> Item</th>
                                                                                            <th> Quantity</th>
                                                                                        </tr>
                                                                                        { (order?.other_items).map((item, i) => (
                                                                                            <tr className='text-center'>
                                                                                                <td> { item.name }</td>
                                                                                                <td> { item.qty } </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div className="card-footer">
                                    { canAccess?.place &&
                                        <Button type="button" disabled={order?.franchise_status !== 'active'} isLoading={isLoading} onClick={ handleRepeatOrder } className="btn btn-primary mr-2">Repeat Order </Button>
                                    }
						            <Link className="btn btn-primary mr-2" to={`/orders/view/invoice/${id}`}>View Invoice</Link>
                                    <Link className="btn btn-default" to='/in-progress-orders'>Close</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ViewOrder;