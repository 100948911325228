import { learningPathwayURL } from "@app/constants/ApiEndPoints";
import axios from "axios";

export const getLearningPathwaysWithPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(learningPathwayURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&franchiseId=${search.franchiseId}&subjectId=${search.subjectId}&name=${search.name}&status=${search.status}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getLearningPathwayById = async (id: number) => {
	try {
		const response = await axios.get(learningPathwayURL + 'read/' + id );
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllActiveLearningPathways = async () => {
	try {
		const response = await axios.get(learningPathwayURL + "all");
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getStudSubjectLearningPathways = async (payload) => {
	try {
		const response = await axios.post(learningPathwayURL + "student/subjects", payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getWorksheetsPDFByModuleId = async (moduleId) => {
	try {
		const response = await axios.get(learningPathwayURL + 'module/preview/' + moduleId, { responseType: 'blob' });
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};