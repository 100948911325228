import { createSlice } from '@reduxjs/toolkit';

export interface printSummaryState {
	franchise: any;
}

const initialState: printSummaryState = {
	franchise: {},
};

export const PrintSummarySlice = createSlice({
	name: 'printSummary',
	initialState,
	reducers: {
		setFranchisePrintSummary: (state, {payload}) => {
			state.franchise = payload;
		},
		clearFranchisePrintSummary: (state) => {
			state.franchise= {};
		},
	}
});

export const { setFranchisePrintSummary, clearFranchisePrintSummary} = PrintSummarySlice.actions;

export default PrintSummarySlice.reducer;