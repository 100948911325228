import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import Select from "react-select";
import { useFormik } from 'formik';
import { Button } from '@app/components';
import { toast } from 'react-toastify';
import { addToFranchiseGroup, getAllUnlinkFranchise, getFranchiseByGroupId, getFranchiseById, makeDefaultFranchiseForGroup, removeFranchiseFromGroup } from '@app/services/franchise';
import { confirmAlert } from 'react-confirm-alert';

const LinkFranchise = () => {

    let obj: any = {}
    let { id } = useParams();
    const selectRef = useRef(null);
    const [franchiseData, setFranchiseData] = useState(obj);
    const [franchises, setFranchises] = useState([]);
    const [groupedFranchises, setGroupedFranchises] = useState(obj);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingFranchise, setLoadingFranchise] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getFranchiseDetailsById(id);
        getAllUnlinkFranchiseData();
    },[]);

    useEffect(() => {
        if(franchiseData?.group_id){
            getAllGroupedFranchiseData(franchiseData?.group_id);
        }
    },[franchiseData]);

    const getFranchiseDetailsById = async (id) => {
        try {
            const resp = await getFranchiseById(id);
            if(resp.success === true){
                setFranchiseData(resp.collections);
            }else{
                toast.error(resp?.errors[0]?.msg);
                navigate('/franchise');
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllUnlinkFranchiseData = async () => {
        try {
            setLoadingFranchise(true);
            const resp = await getAllUnlinkFranchise();
            if (resp?.success === true) {
                setLoadingFranchise(false);
                setFranchises(resp?.collections);
            } else {
                setLoadingFranchise(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoadingFranchise(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllGroupedFranchiseData = async (id) => {
        try {
            setLoading(true);
            const resp = await getFranchiseByGroupId(id);
            if (resp?.success === true) {
                setLoading(false);
                setGroupedFranchises(resp?.collections);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const addFranchiseToGroup = async (payload) => {
        try {
            setLoading(true);
            const resp = await addToFranchiseGroup(payload);
            if(resp.success === true){
                setLoading(false);
                setGroupedFranchises(resp?.collections);
                toast.success(resp?.message);
                selectRef.current.setValue('');
                getAllUnlinkFranchiseData();
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue, resetForm } = useFormik({
        initialValues: {
            name: franchiseData?.name || '',
            kashflow_order_account: franchiseData?.kashflow_order_account || '',
            kashflow_license_account: franchiseData?.kashflow_license_account || '',
            franchise_id: ''
        },
        validationSchema: Yup.object({
            franchise_id: Yup.string().required('Please select franchise'),
            // name: Yup.string().required('Please enter franchise name'),
            // kashflow_order_account: Yup.string().required('Please enter kashflow order account'),
            // kashflow_license_account: Yup.string().required('Please enter kashflow license account'),
        }),
        onSubmit: (values) => {
            addFranchiseToGroup({parent: id, child:values.franchise_id});
            resetForm();
        },
        enableReinitialize: true,
    });

    const confirm = (e, title, message, type, payload) => {
        confirmAlert({
            title: title,
            message: message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => (type === 'remove') ? deleteFranchiseFromGroup(payload) : makeDefaultFranchiseForGroupData(payload)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const deleteFranchiseFromGroup = async (payload) => {
        try {
            setLoading(true);
            const resp = await removeFranchiseFromGroup(payload);
            if(resp.success === true){
                setLoading(false);
                setGroupedFranchises(resp?.collections);
                toast.success(resp?.message);
                getAllUnlinkFranchiseData();
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
          }
    };

    const makeDefaultFranchiseForGroupData = async (payload) => {
        try {
            setLoading(true);
            const resp = await makeDefaultFranchiseForGroup(payload);
            if(resp.success === true){
                setLoading(false);
                setGroupedFranchises(resp?.collections);
                toast.success(resp?.message);
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
          }
    };

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Link Franchise</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Franchise Name </label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter franchise name" 
                                                name="name" 
                                                onChange={handleChange} 
                                                value={values.name}
                                                disabled
                                            />
                                            {(touched.name && errors.name) && (
                                                <div className="text-danger">
                                                    {errors.name}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Kashflow Licence Account </label>
                                            <input type="number" className="form-control" id="exampleInputEmail1" placeholder="Enter kashflow license account" 
                                                name="kashflow_license_account" 
                                                onChange={handleChange} 
                                                value={values.kashflow_license_account}
                                                disabled
                                            />
                                            {(touched.kashflow_license_account && errors.kashflow_license_account) && (
                                                <div className="text-danger">
                                                    {errors.kashflow_license_account}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Kashflow Order Account </label>
                                            <input type="number" className="form-control" id="exampleInputEmail1" placeholder="Enter kashflow order account" 
                                                name="kashflow_order_account" 
                                                onChange={handleChange} 
                                                value={values.kashflow_order_account}
                                                disabled
                                            />
                                            {(touched.kashflow_order_account && errors.kashflow_order_account) && (
                                                <div className="text-danger">
                                                    {errors.kashflow_order_account}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {(franchiseData?.group_id == null || groupedFranchises?.default_franchise_id == id) && (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="franchise_id">Franchise to Link <span className="required">*</span></label>
                                                    <Select
                                                        ref={selectRef}
                                                        id="franchise_id"
                                                        name="franchise_id"
                                                        placeholder="Select Franchise"
                                                        options={franchises}
                                                        getOptionValue={(option)=>`${option['id']}`}
                                                        getOptionLabel={(option)=>`${option['name']}`}
                                                        onChange={ (option) => { 
                                                            if(option){
                                                                setFieldValue("franchise_id", option.id)
                                                            }else{
                                                                setFieldValue("franchise_id", '')
                                                            }
                                                        }}
                                                        // value={values.franchise_id}
                                                        isLoading={isLoadingFranchise}
                                                        isClearable={true}
                                                    />
                                                    {(touched.franchise_id && errors.franchise_id) && (
                                                        <div className="text-danger">
                                                            {errors.franchise_id}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-sm-4'>
                                                <div className='form-group'>
                                                    <label htmlFor="franchise_id"></label>
                                                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Add </Button>
                                                    <Link className="btn btn-default" to='/franchise'>Close</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                )}
                                <div className="row">
                                    <div className="col-md-6">
                                        <table className="table table-bordered" id="client_id_table">
                                            <tbody>
                                                <tr className='text-center'>
                                                    <th>Franchise</th>
                                                    {(groupedFranchises?.default_franchise_id == id) && ( <th>Action</th> )}
                                                </tr>
                                                {(groupedFranchises?.franchises) && (groupedFranchises?.franchises).map((franchise) => (
                                                    <tr key={franchise.id}>
                                                        <td>
                                                            <Link to={`/franchise/view/${franchise.id}`} role="button"> { franchise.name } </Link>
                                                            { groupedFranchises?.default_franchise_id === franchise.id && ( <span className='text-success'>(Default Franchise)</span> )}
                                                        </td>
                                                        {(groupedFranchises?.default_franchise_id == id) && (
                                                            <td className={(id == franchise.id) ? 'text-center' : ''}>
                                                                {(id != franchise.id) && (
                                                                    <div>
                                                                        <button type="button" onClick={ (e) => confirm(e, 'Remove Franchise!', 'Are you sure to remove franchise?', 'remove', {franchise_id: franchise.id})} className="btn btn-danger btn-xs mr-2">Remove</button>
                                                                        <button type="button" onClick={ (e) => confirm(e, 'Make Default!', 'Are you sure to make default franchise?', 'make', {franchise_id: franchise.id})} className="btn btn-success btn-xs">Make default</button>
                                                                    </div>
                                                                )}
                                                                {(id == franchise.id) && (
                                                                    <span> --- </span>
                                                                )}
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}

                                            {!groupedFranchises?.franchises && (
                                                <tr>
                                                    <td colSpan={2} style={{ color: "red", textAlign: "center" }}>There are no records to display</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <Link className="btn btn-default" to='/franchise'>Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default LinkFranchise