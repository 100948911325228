import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { loginUser, setPermissions, setSelectedRole } from '@store/reducers/auth';
import { Button } from '@components';
import { setWindowClass } from '@app/utils/helpers';
import { getCompactPermissionByRoleId } from '@app/services/permission';
import { useEffect } from 'react';
import jwt_decode from "jwt-decode";
let base64 = require('base-64');
let utf8 = require('utf8');

const ChooseRole = () => {
	const [isAuthLoading, setAuthLoading] = useState(false);
	const currentUser = useSelector((state: any) => state.auth.currentUser);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
        if(currentUser?.roles.length == 0){
			navigate('/login');
		}
    }, []);

	const handleSubmit = async(role) => {
		try {
			setAuthLoading(true);
			const resp = await getCompactPermissionByRoleId(role.id);
            if (resp.success === true) {
                setAuthLoading(false);
				const decoded = jwt_decode(resp.token);
				let permissions = JSON.parse(utf8.decode(base64.decode(decoded['access'])));
				
				dispatch(setSelectedRole(role));
				dispatch(setPermissions(permissions));
				dispatch(loginUser(resp.token));
				navigate('/');
            } else {
				setAuthLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
			setAuthLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
	}

	setWindowClass('hold-transition login-page');

	return (
		<div className="login-box">
			<div className="card card-outline card-primary">
				<div className="card-header text-center">
					<div className="h1">
						<span>Welcome {currentUser.first_name }!</span>
					</div>
				</div>
				<div className="card-body">
					<p className="login-box-msg">Please select the appropriate role that you would like to log in with!</p>
					<div className="row">
						{currentUser.roles && currentUser.roles.map((role) => (
						<div className="col-6" key={role.id}>
							<Button
								block
								className='mt-3'
								type="button"
								isLoading={isAuthLoading}
								onClick={() => handleSubmit(role)}
							>
								{ role.name }
							</Button>
						</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChooseRole;
