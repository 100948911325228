import { getAllRegionsURL, getRegionsWithPaginationURL } from "@app/constants/ApiEndPoints";
import axios from "axios";

export const getRegionsWithPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(getRegionsWithPaginationURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}
															&name=${search.name}&country=${search.country}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllRegions = async (slug: string) => {
	try {
		const response = await axios.get(getAllRegionsURL + slug);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};