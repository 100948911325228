import axios from "axios";
import { createRoleURL, deleteRoleURL, getAllRolesURL, getRoleByIdURL, getRoleGroupsURL, getRolePermissionsByModuleURL, getRolesByGroupNameURL, updateRolePermissionsURL, updateRoleURL } from "@app/constants/ApiEndPoints";

export const getRolesByGroupName = async (payload) => {
    try {
        const response = await axios.get(getRolesByGroupNameURL + payload.roleGroup);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
    } catch (error) {
		return error?.response?.data;
    }
};

export const getAllRoles = async () => {
	try {
		const response = await axios.get(getAllRolesURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getRoleGroups = async () => {
	try {
		const response = await axios.get(getRoleGroupsURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const createRole = async (payload) => {
	try {
		const response = await axios.post(createRoleURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getRoleById = async (id: number) => {
	try {
		const response = await axios.get(getRoleByIdURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateRole = async (id, payload) => {
	try {
		const response = await axios.put(updateRoleURL + id, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const deleteRoleById = async (id: number) => {
	try {
		const response = await axios.delete(deleteRoleURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getRolePermissionsByModule = async (id: number) => {
	try {
		const response = await axios.get(getRolePermissionsByModuleURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateRolePermissions = async (payload) => {
	try {
		const response = await axios.post(updateRolePermissionsURL, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};