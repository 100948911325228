import React, { useState,useEffect } from 'react';
import {Form} from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@app/components';
import { Link, useNavigate } from 'react-router-dom';
import AddGuardian from '../student-details/AddGuardianModal';
import { getAllActiveFranchise, getPostcodeCountryByFranchiseId } from '@app/services/franchise';
import { toast } from 'react-toastify';
import Select from "react-select";
import { addStudentService, getStudentRollNumberCounter } from './../../services/student';
import { getAllSchools,getAllGrades,createSchoolService } from './../../services/school';
import { getCentresByFranchiseId } from './../../services/centre';
import { getAllActiveGuardianService } from './../../services/guardian';
import { getAllActiveSubjects } from './../../services/subject';
import CreatableSelect from 'react-select/creatable';
import { setActiveSubjects, setSubjectCheck, setSubjectRate, setSubjectRateError } from '@app/store/reducers/subject';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCountries } from '@app/services/postcode';

const AddStudent = () => {
    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [franchise, setFranchise] = useState([]);
    const [schools,setSchools] = useState([]);
    const [currency, setCurrency] = useState('');
    const [grades,setGrades] = useState([]);
    const [centre,setCentre] = useState([]);
    const [guardian,setGuardian] = useState([]);
    const [userFlag, setUserFlag] = useState(false);
    // const [subjects,setSubjects] = useState([]);
    const [isHovering, setIsHovering] = useState(false);
    const subjects = useSelector((state: any) => state.subject.data);
    const dispatch = useDispatch();    

    /***Creatable options code***/
    interface Option {
        readonly label: string;
        readonly value: string;
      }
      
    const createOption = (label:string,id) => ({
        label,
        value:id
    });

    let defaultSchoolOptions = [ ];
    const [schoolOptions, setSchoolOptions] = useState(defaultSchoolOptions);

    const handleCreateOptions = (inputValue: string) => {
        setLoading(true);
        const postData = {name:inputValue};
        setTimeout(() => {
          setLoading(false);
          createSchool(postData);
        }, 1000);
    };
    /***Creatable options code***/

    const setParentBillPayerCheck = event => {
        const val = event.target.checked ? true : false;
        setFieldValue(event.target.name,val);
    };

    const navigate = useNavigate();
    const errorSpanStyle = {
        marginLeft:"15px"
    };
    const tooltipStyle = {
            visibility: "visible",
            width: "100%",
            backgroundColor: "black",
            color: "#fff",
            textAlign: "center",
            borderRadius: "6px",
            padding: "5px 0",
            position: 'absolute',
            zIndex: "1"
    };

    const handleClose = () => setShow(false);
    const handleShow = () => {setShow(true);}

    const studentStatus = [{id:'active', name:'Active'}, {id:'inactive', name:'Inactive'}];
    const gender = [{id:'male',name:'Male'},{id:'female','name':'Female'},{id:'other','name':'Rather Not Specify'}];
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        getAllActiveFranchiseData();
        getAllActiveSchoolsData();
        getAllActiveGradesData();
        getAllActiveSubjectsData();
        getCountriesData();

        return () => {
            dispatch(setActiveSubjects([]))
        }
    },[]);

    useEffect(()=>{
        getAllActiveGuardianData({franchise_id: values.franchise_id == '' ? [] : [values.franchise_id] });
    },[userFlag])

    const createSchool = async (payload) => {
        try{
            const createSchoolResp = await createSchoolService(payload);
            if (createSchoolResp.success === true) {
                if(createSchoolResp.collections.length > 0){
                    createSchoolResp.collections.map((school)=>{
                        const newOption = createOption(school.name,school.id);
                        setSchoolOptions((prev) => [...schoolOptions, newOption]);
                        setFieldValue('school_id',school.id);
                    })
                }
            } else {
                toast.error(createSchoolResp?.errors[0]?.msg);
            }
        }catch(error : any){
            toast.error(error?.message || 'Failed');
        }
    }

    const getAllActiveGuardianData = async (payload) => {
        try {
            const allActiveGuardianResp = await getAllActiveGuardianService(payload);
            if (allActiveGuardianResp.success === true) {
                setGuardian(allActiveGuardianResp.collections);
            } else {
                toast.error(allActiveGuardianResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    const getAllActiveFranchiseData = async () => {
        try {
            const allActiveFranchiseResp = await getAllActiveFranchise();
            if (allActiveFranchiseResp.success === true) {
                setFranchise(allActiveFranchiseResp.collections.filter((obj) => obj?.enabled_manage_students))
            } else {
                toast.error(allActiveFranchiseResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    const getAllActiveSubjectsData = async () => {
        try {
            const allActiveSubjectsResp = await getAllActiveSubjects();
            if (allActiveSubjectsResp.success === true) {
                (allActiveSubjectsResp.collections).map((subject) => {
                    delete subject.prefix
                    delete subject.set_price
                    delete subject.status
                    delete subject.created_at
                    delete subject.updated_at
                    delete subject.deleted_at
                    subject.active = false;
                    subject.rate = '';
                    subject.error = '';
                    delete subject.subject_rate;
                })
                dispatch(setActiveSubjects(allActiveSubjectsResp.collections))
                // setSubjects(allActiveSubjectsResp.collections);
            } else {
                toast.error(allActiveSubjectsResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    const getAllActiveSchoolsData = async () => {
        try{
            const allActiveSchoolsResp = await getAllSchools();
            if (allActiveSchoolsResp.success === true) {
                allActiveSchoolsResp.collections.map((obj)=>{
                    defaultSchoolOptions.push(createOption(obj.name,obj.id));
                });
                setSchoolOptions(defaultSchoolOptions);
                setSchools(allActiveSchoolsResp.collections);
            } else {
                toast.error(allActiveSchoolsResp?.errors[0]?.msg);
            }
        }catch (error: any){
            toast.error(error?.message || 'Failed');
        }
    }

    const getAllActiveGradesData = async () => {
        try{
            const allActiveGradesResp = await getAllGrades();
            if (allActiveGradesResp.success === true) {
                setGrades(allActiveGradesResp.collections);
            } else {
                toast.error(allActiveGradesResp?.errors[0]?.msg);
            }
        }catch (error: any){
            toast.error(error?.message || 'Failed');
        }
    }
    
    const getAllActiveCentresData = async (id) => {
        try{
            const allActiveCentresResp = await getCentresByFranchiseId(id);
            if (allActiveCentresResp.success === true) {
                setCentre(allActiveCentresResp.collections);
            } else {
                toast.error(allActiveCentresResp?.errors[0]?.msg);
            }
        }catch (error: any){
            toast.error(error?.message || 'Failed');
        }
    }

    const addStudentFunction = async (payload) => {
        try {
            setLoading(true);
            const resp = await addStudentService(payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                navigate('/students');
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Failed');
          }
    };

    const getCountriesData = async () => {
        try {
            const resp = await getAllCountries();
            if (resp.success === true) {
                setCountries(resp.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };
    
    const handleAdminsChange = (options: readonly typeof Option[], setFieldValue) => {
        setFieldValue(
          "subjects",
          [].slice.call(options).map((option) => option.id)
        );
    };

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            roll_number: '',
            gender:'',
            grade_id:'',
            address:'',
            address_line_2: '',
            city: '',
            postcode: '',
            country: '',
            date_of_birth:'',
            school_id:'',
            subjects:[],
            franchise_id:'',
            centre_id:'',
            status: '',
            camp_only: false,
            parents:[],
            parent1:'',
            parent2:'',
            parent1_bill_payer:'',
            parent2_bill_payer:'',
            parent_msg:'',
            parent_same_msg:''
        },
        validationSchema: Yup.object({
            first_name: Yup.string().trim().required('Please enter first name'),
            last_name: Yup.string().trim().required('Please enter last name'),
            gender: Yup.string().required('Please select gender'),
            address:Yup.string().trim().required('Please enter address line 1'),
            city:Yup.string().trim().required('Please enter city'),
            roll_number:Yup.number().positive('Roll number must be a positive number').max(99999, 'Roll number must be less than or equal to 99999'),
            franchise_id: Yup.string().required('Please select franchise'),
            status: Yup.string().required('Please select status'),
            // subjects: Yup.array().of(Yup.string().trim()).min(1, "Please select at least 1 subject").required("Please select subject"),
            parent_msg: Yup.string().when(['parent1','parent2'],{
                is: (parent1,parent2) =>  parent1===undefined && parent2===undefined,
                 then: Yup.string().required('Please select any of the below'),
            }),
            parent_same_msg: Yup.string().when(['parent1','parent2'],{
                is: (parent1,parent2) => ((parent1===parent2) && parent1!==undefined && parent2!==undefined),
                 then: Yup.string().required('Parent1/Parent2 details should not be same'),
            })
        }),
        onSubmit: (values) => {
            //Assigned Subjects
            let studentSubjects = [];
            let flag = true;
            subjects.map( (subject) => {
                if(values.camp_only){
                    studentSubjects.push({id:subject.id, rate: 0})
                }else{
                    if(subject.active === true){
                        if(subject.error != ''){
                            flag = false;
                        }
                        studentSubjects.push({id:subject.id, rate: Math.abs(subject.rate)})
                    }
                }
            })

            if(studentSubjects.length == 0 && !values.camp_only){
                toast.error('Please select at least one subject.');
                return;
            }
            
            //Assigned Parents
            let parentObj = [];
            if(values.parent1){
                parentObj.push({"id":values.parent1,"relationship":"parent1","bill_payer":values.parent1_bill_payer ? values.parent1_bill_payer : false});
            }
            if(values.parent2){
                parentObj.push({"id":values.parent2,"relationship":"parent2","bill_payer":values.parent2_bill_payer ? values.parent2_bill_payer : false});
            }
            values.parents = parentObj;

            if(flag){
                values.subjects = studentSubjects;
                addStudentFunction(values);
            }
        }
    });

    const getCountryByFranchiseId = async (franchiseId) => {
        try {
            if(franchiseId){
                const resp = await getPostcodeCountryByFranchiseId(franchiseId);
                if (resp?.success === true) {
                    setFieldValue('country', resp?.collections?.country_slug)
                    setCurrency(resp?.collections?.currency)
                } else {
                    toast.error(resp?.errors[0]?.msg);
                }
            }else{
                setFieldValue('country', "")
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    const getStudentRollNoCounter = async (payload) => {
        try {
            const resp = await getStudentRollNumberCounter(payload);
            if (resp.success === true) {
                setFieldValue('roll_number', resp?.collections?.counter)
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    const handleSubjectRate = (e, subject) => {
        if(e.target.value == '') {
            dispatch(setSubjectRateError({slug: subject.slug, error: 'Please enter rate'}))
        }else if(isNaN(Math.abs(e.target.value))){
            dispatch(setSubjectRateError({slug: subject.slug, error: 'Please enter valid rate'}))
        }else if(e.target.value < 0){
            dispatch(setSubjectRateError({slug: subject.slug, error: 'Rate must be greater than or equal to 0'}))
        }
        // else if(e.target.value > 10000){
        //     dispatch(setSubjectRateError({slug: subject.slug, error: 'Rate must be less than or equal to 10,000'}))
        // }
        else{
            dispatch(setSubjectRateError({slug: subject.slug, error: ''}))
        }
        dispatch(setSubjectRate({rate: e.target.value, slug: subject.slug, id: subject.id}));
    }

    const handleSubjectCheck = (e, subject) => {
        dispatch(setSubjectCheck({active: e.target.checked, slug: subject.slug, id: subject.id}))
        if(e.target.checked){
            dispatch(setSubjectRateError({slug: subject.slug, error: 'Please enter rate'}))
        }else{
            dispatch(setSubjectRateError({slug: subject.slug, error: ''}))
        }
    }

    const OnWheelPreventChange = (e) => {
		e.currentTarget.blur();
	}
    
    return (
        <div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Add Student</h3>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputFirstName">First Name <span className="required">*</span></label>
                                                    <Form.Control 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="exampleInputFirstName" 
                                                        placeholder="Enter first name"
                                                        name="first_name" 
                                                        onChange={handleChange} 
                                                        value={values.first_name}
                                                    />
                                                    {(touched.first_name && errors.first_name) && (
                                                        <div className="text-danger">
                                                            {errors.first_name}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputLastName">Last name <span className="required">*</span></label>
                                                    <Form.Control 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="exampleInputLastName" 
                                                        placeholder="Enter last name"
                                                        name="last_name" 
                                                        onChange={handleChange} 
                                                        value={values.last_name}
                                                    />
                                                    {(touched.last_name && errors.last_name) && (
                                                        <div className="text-danger">
                                                            {errors.last_name}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Date Of Birth</label>
                                                    <Form.Control 
                                                        type="date" 
                                                        name="date_of_birth" 
                                                        id="date_of_birth" 
                                                        placeholder="Enter date of birth" 
                                                        value={values.date_of_birth}
                                                        onChange={handleChange}
                                                    />
                                                    {(touched.date_of_birth && errors.date_of_birth) && (
                                                        <div className="text-danger">
                                                            {errors.date_of_birth}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputGender">Gender <span className="required">*</span></label>
                                                    <select
                                                        className="form-control"
                                                        name="gender"
                                                        onChange={handleChange} 
                                                        value={values.gender}
                                                    >
                                                        <option value="">--Please select--</option>
                                                        {gender.map((obj) => (
                                                            <option key={obj.id} value={obj.id}>
                                                                {obj.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {(touched.gender && errors.gender) && (
                                                        <div className="text-danger">
                                                            {errors.gender}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">  
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputGrade">School <span onMouseOver={handleMouseOver} onMouseLeave={handleMouseOut}>&#9432;</span></label>
                                                    {isHovering && (
                                                    <div style={tooltipStyle}>
                                                        <p>You can create new school by entering school name if it is not already present in list.</p>
                                                    </div>
                                                    )}
                                                    <CreatableSelect
                                                        isClearable
                                                        isDisabled={isLoading}
                                                        isLoading={isLoading}
                                                        onChange={ (option) => { 
                                                            if(option){
                                                                setFieldValue("school_id", option.value)
                                                            }else{
                                                                setFieldValue("school_id", '')
                                                            }
                                                        }}
                                                        onCreateOption={handleCreateOptions}
                                                        options={schoolOptions}
                                                        value={schoolOptions.filter(function(option) {
                                                            return option.value === values.school_id;
                                                        })}
                                                        name="school_id"
                                                    />
                                                    {(touched.school_id && errors.school_id) && (
                                                        <div className="text-danger">
                                                            {errors.school_id}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputGrade">School Year</label>
                                                    <select
                                                        className="form-control"
                                                        name="grade_id"
                                                        onChange={handleChange} 
                                                        value={values.grade_id}
                                                    >
                                                        <option value="">--Please select--</option>
                                                        {grades.map((obj) => (
                                                                <option key={obj.id} value={obj.id}>
                                                                    {obj.name}
                                                                </option>
                                                        ))}
                                                    </select>
                                                    {(touched.grade_id && errors.grade_id) && (
                                                        <div className="text-danger">
                                                            {errors.grade_id}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputGrade">Franchise <span className="required">*</span></label>
                                                    <Select
                                                        name="franchise_id"
                                                        placeholder="Select Franchise"
                                                        options={franchise}
                                                        getOptionValue={(option)=>`${option['id']}`}
                                                        getOptionLabel={(option)=>`${option['name']}`}
                                                        onChange={ (option) => { 
                                                            if(option){
                                                                setFieldValue("franchise_id", option.id)
                                                                getAllActiveCentresData(option.id);
                                                                getCountryByFranchiseId(option.id);
                                                                getStudentRollNoCounter({student_id: 0, franchise_id: option.id, centre_id: values.centre_id})
                                                                getAllActiveGuardianData({franchise_id: [option.id]})
                                                            }else{
                                                                setFieldValue("franchise_id", '')
                                                                setFieldValue("country", '')
                                                                getAllActiveGuardianData({franchise_id: []})
                                                            }
                                                        }}
                                                        isClearable={true}
                                                    />
                                                    {(touched.franchise_id && errors.franchise_id) && (
                                                        <div className="text-danger">
                                                            {errors.franchise_id}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputGrade">Centre</label>
                                                    <select
                                                        className="form-control"
                                                        name="centre_id"
                                                        onChange={ (e) => { 
                                                            setFieldValue("centre_id", e.target.value)
                                                            getStudentRollNoCounter({student_id: 0, franchise_id: values.franchise_id, centre_id: e.target.value})
                                                        }}
                                                        value={values.centre_id}
                                                    >
                                                        <option value="">--Please select--</option>
                                                        {centre.map((obj) => (
                                                                <option key={obj.id} value={obj.id}>
                                                                    {obj.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                    {(touched.centre_id && errors.centre_id) && (
                                                        <div className="text-danger">
                                                            {errors.centre_id}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputRollNo">Roll No.</label>
                                                    <Form.Control 
                                                        type="number" 
                                                        className="form-control" 
                                                        id="exampleInputRollNo" 
                                                        placeholder="Enter roll no."
                                                        name="roll_number" 
                                                        onChange={handleChange} 
                                                        value={values.roll_number}
                                                        onWheel={ (e) => OnWheelPreventChange(e) }
                                                    />
                                                    {(touched.roll_number && errors.roll_number) && (
                                                        <div className="text-danger">
                                                            {errors.roll_number}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputAddress">Address Line 1<span className="required">*</span></label>
                                                    <textarea 
                                                        className="form-control" 
                                                        id="exampleInputAddress" 
                                                        placeholder="Enter address line 1" 
                                                        name="address" 
                                                        onChange={handleChange} 
                                                        value={values.address}
                                                    ></textarea>
                                                    {(touched.address && errors.address) && (
                                                        <div className="text-danger">
                                                            {errors.address}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="AddressLine2">Address Line 2</label>
                                                    <textarea 
                                                        className="form-control" 
                                                        id="AddressLine2" 
                                                        placeholder="Enter address line 2" 
                                                        name="address_line_2" 
                                                        onChange={handleChange} 
                                                        value={values.address_line_2}
                                                    ></textarea>
                                                    {(touched.address_line_2 && errors.address_line_2) && (
                                                        <div className="text-danger">
                                                            {errors.address_line_2}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputCity">City <span className="required">*</span></label>
                                                    <Form.Control 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="exampleInputCity" 
                                                        placeholder="Enter city"
                                                        name="city" 
                                                        onChange={handleChange} 
                                                        value={values.city}
                                                    />
                                                    {(touched.city && errors.city) && (
                                                        <div className="text-danger">
                                                            {errors.city}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPostcode">Postcode </label>
                                                    <Form.Control 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="exampleInputPostcode" 
                                                        placeholder="Enter postcode"
                                                        name="postcode" 
                                                        onChange={handleChange} 
                                                        value={values.postcode}
                                                    />
                                                    {(touched.postcode && errors.postcode) && (
                                                        <div className="text-danger">
                                                            {errors.postcode}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputCountry">Country </label>
                                                    <select
                                                        id="exampleInputCountry" 
                                                        className="form-control"
                                                        name="country"
                                                        onChange={ (e) => {
                                                            handleChange(e);
                                                            if(e.target.value != ''){
                                                                setCurrency((countries.find(obj => {return obj.slug == e.target.value}))?.currency)
                                                            }else{
                                                                setCurrency('')
                                                            }
                                                        }} 
                                                        value={values.country}
                                                    >
                                                        <option value="">--Please select--</option>
                                                        {countries.map((obj) => (
                                                            <option key={obj.slug} value={obj.slug}>
                                                                {obj.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {(touched.country && errors.country) && (
                                                        <div className="text-danger">
                                                            {errors.country}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputStatus">Status <span className="required">*</span></label>
                                                    <select
                                                        className="form-control"
                                                        name="status"
                                                        onChange={handleChange} 
                                                        value={values.status}
                                                    >
                                                        <option value="">--Please select--</option>
                                                        {studentStatus.map((obj) => (
                                                            <option key={obj.id} value={obj.id}>
                                                                {obj.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {(touched.status && errors.status) && (
                                                        <div className="text-danger">
                                                            {errors.status}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-sm-6">
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox" 
                                                        className="form-check-input" 
                                                        checked={values.camp_only}
                                                        id="camp_only"
                                                        name="camp_only"
                                                        onChange={ handleChange }
                                                    />
                                                    <label htmlFor="camp_only" className="form-check-label">
                                                        <b>Holiday Camp Only</b>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className='row'>
                                            <div className="col-sm-4 pr-4" style={{borderRight: "1px solid rgba(0, 0, 0, .1)"}}>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <label> Assign Subjects 
                                                                {!values.camp_only && ( <span className="required">*</span> )}
                                                            </label>
                                                            {(touched.subjects && errors.subjects) && (
                                                                <div className="text-danger">
                                                                    {errors.subjects}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {subjects.map((subject, index) => (
                                                    <div className="row" key={index}>
                                                        <div className="col-sm-6">
                                                            <div className="icheck-primary">
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="form-check-input" 
                                                                    value={subject.id} 
                                                                    checked={subject.active} 
                                                                    id={`${subject.slug}.subject`} 
                                                                    name={`${subject.slug}.subject`} 
                                                                    onChange={ (e) => handleSubjectCheck(e, subject)}
                                                                    disabled={values.camp_only}
                                                                />
                                                                <label htmlFor={`${subject.slug}.subject`} className="form-check-label">
                                                                    <b>{ subject.name }</b>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Rate Per Month </label>
                                                                <div className='input-group'>
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">{currency}</span>
                                                                    </div>
                                                                    <input type="number" className="form-control" id="exampleInputEmail1" placeholder="Enter rate" 
                                                                        name="rate" 
                                                                        value={subject.rate}
                                                                        disabled={!subject.active || (subject.is_default && subject.active)}
                                                                        onChange={ (e) => handleSubjectRate(e, subject) }
                                                                        onWheel={ (e) => OnWheelPreventChange(e) }
                                                                    />
                                                                </div>
                                                                <div className="text-danger"> {subject.error} </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-sm-8 pl-4">
                                                <h3 className="card-title">Parent/Guardian Details</h3>
                                                <span style={errorSpanStyle}>
                                                    {((touched.parent1 || touched.parent2) && errors.parent_msg) && (
                                                        <div className="text-danger">
                                                            {errors.parent_msg}
                                                        </div>
                                                    )}
                                                    {((touched.parent1 || touched.parent2) && errors.parent_same_msg) && (
                                                        <div className="text-danger">
                                                            {errors.parent_same_msg}
                                                        </div>
                                                    )}
                                                </span>
                                                <div className='row'>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputGrade">Parent 1 Details <span className="required">*</span></label>
                                                            <Select
                                                                name="parent1"
                                                                placeholder="Select parent1"
                                                                options={guardian}
                                                                getOptionValue={(option)=>`${option['id']}`}
                                                                getOptionLabel={(option)=>`${option['first_name'] +' '+option['last_name'] + ' ( ' +option['email']+' )'}`}
                                                                onChange={ (option) => { 
                                                                    if(option){
                                                                        setFieldValue("parent1", option.id) 
                                                                    }else{
                                                                        setFieldValue("parent1", '')
                                                                    }
                                                                }}
                                                                isClearable={true}
                                                            />
                                                            {(touched.parent1 && errors.parent1) && (
                                                                <div className="text-danger">
                                                                    {errors.parent1}
                                                                </div>
                                                            )}
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        className="form-check-input" 
                                                                        value={values.parent1_bill_payer}
                                                                        name="parent1_bill_payer"
                                                                        onChange={setParentBillPayerCheck}
                                                                    />
                                                                    <b>Bill Payer</b>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <a  href='#' 
                                                            onClick={(e) => {
                                                                handleShow()
                                                                e.preventDefault()
                                                            }}> 
                                                            + Add Parent Details 
                                                        </a>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputGrade">Parent 2 Details <span className="required">*</span></label>
                                                            <Select
                                                                name="parent2"
                                                                placeholder="Select parent2"
                                                                options={guardian}
                                                                getOptionValue={(option)=>`${option['id']}`}
                                                                getOptionLabel={(option)=>`${option['first_name'] +' '+option['last_name'] + ' ( ' +option['email']+' )'}`}
                                                                onChange={ (option) => { 
                                                                    if(option){
                                                                        setFieldValue("parent2", option.id) 
                                                                    }else{
                                                                        setFieldValue("parent2", '')
                                                                    }
                                                                }}
                                                                isClearable={true}
                                                            />
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        className="form-check-input" 
                                                                        value={values.parent2_bill_payer}
                                                                        name="parent2_bill_payer"
                                                                        onChange={setParentBillPayerCheck}
                                                                    />
                                                                    <b>Bill Payer</b>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <AddGuardian show={show} handleClose={handleClose} franchise={franchise} countries={countries} setUserFlag={setUserFlag} />
                                    <div className="card-footer">
                                        <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Add </Button>
                                        <Link className="btn btn-default" to='/students'>Cancel</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default AddStudent;