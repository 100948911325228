import { createItemURL, deleteItemURL, getAllActiveItemsURL, getAllItemsURL, getItemByIdURL, updateItemURL } from "@app/constants/ApiEndPoints";
import axios from "axios";

export const getAllItems = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(getAllItemsURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}
															&name=${search.name}&status=${search.status}&price=${search.price}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllActiveItems = async () => {
	try {
		const response = await axios.get(getAllActiveItemsURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const createItem = async (payload) => {
	try {
		const response = await axios.post(createItemURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getItemById = async (id: number) => {
	try {
		const response = await axios.get(getItemByIdURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateItem = async (id, payload) => {
	try {
		const response = await axios.put(updateItemURL + id, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const deleteItemById = async (id: number) => {
	try {
		const response = await axios.delete(deleteItemURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};