import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { downloadStudentWeekSummary, downloadStudentWorksheets, getStudentById, getStudentWorkSummaryById } from '@app/services/student';
import moment from 'moment';
import printJS from 'print-js';
import { Button } from '@app/components';
import { useSelector } from 'react-redux';
import PrintWorksheetsPDF from './PrintWorksheetsPDF';
import Select from "react-select";

const StudentWorkSummary = () => {

    let { id } = useParams();
    let obj: any = {}
    const [isLoading, setLoading] = useState(false);
    const [isWeekSummaryLoading, setWeekSummaryLoading] = useState(false);
    const [weeklyWork, setWeeklyWork] = useState([]);
    const [studentWork, setStudentWork] = useState([]);
    const [numOfWeeks, setNumOfWeeks] = useState(0);
    const [weeks, setWeeks] = useState([]);
    const [activeWeek, setActiveWeek] = useState(1);
    const [streamPerWeek, setStreamPerWeek] = useState(0);
    const [student, setStudent] = useState(obj);
    const canAccess = useSelector((state: any) => state.auth.permissions.student);
    const studentWorkStore = useSelector((state: any) => state.studentWork);
    const [search, setSearch] = useState({subject_id: studentWorkStore?.subject?.id ?? 0});
    const [subjects, setSubjects] = useState([]);
    const [stream1, setStream1] = useState('');
    const [stream2, setStream2] = useState('');
    const [stream3, setStream3] = useState('');
    const [stream4, setStream4] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedWeeks, setSelectedWeeks] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);
    const [weekError, setWeekError] = useState('');

    useEffect(() => {
        getStudentDetailsById()
        getStudentWorkSummary(id, search.subject_id)
    },[]);

    const getStudentDetailsById = async () => {
        try {
            setLoading(true)
            const resp = await getStudentById(parseInt(id));
            if (resp.success === true) {
                setLoading(false)
                setStudent(resp?.collections)
                setSubjects([{id: 0, name: 'Mix and Match'}].concat(resp?.collections?.subjects))
                // let subjects = (resp?.collections?.subjects.length == 1) ? resp?.collections?.subjects : [{id: 0, name: 'Mix and Match'}].concat(resp?.collections?.subjects);
                // setSubjects(subjects);
            } else {
                setLoading(false)
                toast.error(resp?.errors[0]?.msg)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.response?.message || 'Internal server error');
        }
    };

    const getStudentWorkSummary = async (id, subject_id) => {
        try {
            setLoading(true)
            const resp = await getStudentWorkSummaryById({student_id: id, subject_id: subject_id});
            if (resp.success === true) {
                setLoading(false)
                setStudentWork(resp?.collections?.summary)
                setStreamPerWeek(resp?.collections?.modules_per_week)
                setNumOfWeeks(resp?.collections?.summary?.length)
                if(resp?.collections?.summary?.length > 0){
                    setWeeklyWork(resp?.collections?.summary[0])
                    if(resp?.collections?.modules_per_week >= 1)
                        setStream1(resp?.collections?.summary[0][0]?.modules.length > 0 ? (resp?.collections?.summary[0][0].modules.find(obj => { return obj.module == 'module1'}))?.learning_pathway : '')
                    if(resp?.collections?.modules_per_week >= 2)
                        setStream2(resp?.collections?.summary[0][0]?.modules.length > 0 ? (resp?.collections?.summary[0][0].modules.find(obj => { return obj.module == 'module2'}))?.learning_pathway : '')
                    if(resp?.collections?.modules_per_week >= 3)
                        setStream3(resp?.collections?.summary[0][0]?.modules.length > 0 ? (resp?.collections?.summary[0][0].modules.find(obj => { return obj.module == 'module3'}))?.learning_pathway : '')
                    if(resp?.collections?.modules_per_week >= 4 )
                        setStream4(resp?.collections?.summary[0][0]?.modules.length > 0 ? (resp?.collections?.summary[0][0].modules.find(obj => { return obj.module == 'module4'}))?.learning_pathway : '')
                }else{
                    setWeeklyWork([])
                }
                let weeksArr = [];
                for (let i = 0; i < resp?.collections?.summary?.length; i++) {
                    weeksArr.push({id: i, name: 'Week '+ (i + 1)})
                }
                setWeeks(weeksArr);
            } else {
                setLoading(false)
                toast.error(resp?.errors[0]?.msg)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.message || 'Internal server error');
        }
    };

    const printWorksheets = async (payload) => {
        try{
            setLoading(true);
            const resp = await downloadStudentWorksheets(payload);
            
            //Create a Blob from the PDF Stream
            // const file = new Blob( [response.data], {type: 'application/pdf'});
            
            //Build a URL from the file
            const fileURL = URL.createObjectURL(resp);
            
            //Open the URL on new Window
            // window.open(fileURL);
            
            //Open the URL on new Window
            // print(fileURL).then(res => {
            //     console.log(res)
            // })
            printJS({printable:fileURL, type:'pdf', showModal:true})

            setLoading(false);        
        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    }

    const customStylesSelect = {
        dropdownIndicator: () => ({
            display: 'none',
        }),
    }

    const getPaginationContent = () => {
        let content = [];
        for (let i = 1; i <= numOfWeeks; i++) {
            content.push(<li className="page-item" key={i}><a className="page-link" style={{backgroundColor: activeWeek == i ? '#dee2e6' : '#fff'}} href="#" onClick={ (e) => changeWeek(e, i)}>Week {i}</a></li>);
        }
        return content;
    };

    const changeWeek = (e, week) => {
        e.preventDefault();
        setActiveWeek(week);
        setWeeklyWork(studentWork[week - 1]);
    }

    const handleSubjectChange = async (subject_id) => {
        setSearch({ ...search, subject_id: subject_id })
        getStudentWorkSummary(id, subject_id)
        setSelectedWeeks([])
    };

    const onSelectWeeks = (options: readonly typeof Option[]) => {
        setSelectedWeeks([].slice.call(options).map((option) => option.id))
    };

    const handlePrintWorksheets = () => {
        if(selectedWeeks.length > 0){
            let dates = [];
            selectedWeeks.sort()
            selectedWeeks.map((week) => {
                studentWork[week].map((obj) => {
                    dates.push(obj.date)
                })
            })
            setSelectedDates(dates)
            setWeekError('')
            handleShow()
        }else{
            setWeekError('Select week(s) to print the worksheets')
        }
    }

    const handlePrintWeekSummary = async() => {
        try{
            if(selectedWeeks.length > 0){
                setWeekSummaryLoading(true);
                setWeekError('')
                let dates = [];
                selectedWeeks.sort()
                selectedWeeks.map((week) => {
                    studentWork[week].map((obj) => {
                        dates.push(obj.date)
                    })
                })
                setSelectedDates(dates)
                let payload = {
                    student_id: id,
                    subject_id: search.subject_id,
                    weeks: selectedWeeks.length,
                    dates: dates
                }

                const resp = await downloadStudentWeekSummary(payload);
                
                //Build a URL from the file
                const fileURL = URL.createObjectURL(resp);
                
                printJS({printable:fileURL, type:'pdf', showModal:true})
                setWeekSummaryLoading(false);        
            }else{
                setWeekError('Select week(s) to print the summary')
                return
            }
        } catch (error) {
            setWeekSummaryLoading(false);
            console.log(error)
        }
    }

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <span style={{fontSize: '1.5rem', fontWeight: 'bold'}}>Student Work Summary</span>
                    </div>
                    <div className="col-md-12">
                        <div className="external-event" style={{backgroundColor: "#ffd316", position: 'relative', zIndex: 'auto', left: '0px', top: '0px', width: '15%', fontWeight: 400, cursor:'default'}}>Student Details</div>
                        <div className="card">
                            <div className="card-body" style={{backgroundColor: '#f9f1cc'}}>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="row">
                                            <dt className="col-sm-4">Student Name:</dt>
                                            <dd className="col-sm-8">{ student?.first_name + ' ' + student?.last_name} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="row">
                                            <dt className="col-sm-4">Year Group:</dt>
                                            <dd className="col-sm-8">{ student?.grade_name ?? '---'} </dd>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Work Summary {studentWork.length == 0 ? '' : `- Week ${activeWeek}`}</h3>
                                <div className='d-flex justify-content-end'>
                                    <div className="row">
                                        <div className="">
                                            <Select
                                                // ref={selectRef}
                                                isMulti
                                                name="week"
                                                placeholder="Select week(s) to print"
                                                options={weeks}
                                                getOptionValue={(option)=>`${option['id']}`}
                                                getOptionLabel={(option)=>`${option['name']}`}
                                                onChange={ (e) => {onSelectWeeks(e)}}
                                                value={
                                                    weeks?.length
                                                    ? weeks.filter((obj) =>
                                                        selectedWeeks.includes(obj.id)
                                                        )
                                                    : selectedWeeks
                                                } // set selected values
                                                styles={customStylesSelect}
                                                isLoading={isLoading}
                                                isClearable={true}
                                                closeMenuOnSelect={false}
                                            />
                                            <div className='text-danger'>
                                                { weekError }
                                            </div>
                                        </div>
                                        <div className="ml-2">
                                            { student?.student_local_printing && (
                                                <Button type="button" onClick={handlePrintWorksheets} className="btn btn-default" disabled={studentWork.length==0}><i className="fas fa-print"></i> Print Worksheet(s)</Button>
                                            )}
                                            <Button type="button" isLoading={isWeekSummaryLoading} onClick={handlePrintWeekSummary} className="btn btn-default ml-2" disabled={studentWork.length==0}><i className="fas fa-print"></i> Print Week(s) Summary</Button>
                                        </div>
                                    </div>
                                    {/* <Button type="button" isLoading={isLoading} onClick={ (e) => printWorksheets({student_id: id}) } className="btn btn-default" disabled={studentWork.length==0}><i className="fas fa-print"></i> Print </Button> */}
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <label>You are viewing work for the subject</label>
                                        <select
                                            className="form-control"
                                            name="subject_id"
                                            onChange={(e) => handleSubjectChange(e.target.value)}
                                            value={search.subject_id}
                                        >
                                            {subjects.length > 0 && subjects.map((obj) => (
                                                <option key={obj.id} value={obj.id}>
                                                    {obj.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <table className="table table-bordered">
                                    { weeklyWork.length > 0 && (
                                    <thead>
                                        <tr>
                                            <th style={{ width: 10 }}>#</th>
                                            <th>Date</th>
                                            { streamPerWeek >= 1 && (
                                                <th>{ stream1 }</th>
                                            )}
                                            { streamPerWeek >= 2 && (
                                                <th>{ stream2 }</th>
                                            )}
                                            { streamPerWeek >= 3 && (
                                                <th>{ stream3 }</th>
                                            )}
                                            { streamPerWeek >= 4 && (
                                                <th>{ stream4 }</th>
                                            )}
                                        </tr>
                                    </thead>
                                    )}
                                    <tbody>
                                        {weeklyWork.length > 0 && weeklyWork.map((obj, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}.</td>
                                                <td>{ moment(obj.date).format('DD/MM/YYYY, dddd') }</td>
                                                { streamPerWeek >= 1 && (
                                                    <td>{ obj?.modules !== null ? ( Array.prototype.map.call(obj?.modules.filter(mod => mod.module == 'module1'), function(module) { return module?.name + '-' + module?.worksheet_number }) ).join(', ') : '---'}</td>
                                                )}
                                                { streamPerWeek >= 2 && (
                                                    <td>{ obj?.modules !== null ? ( Array.prototype.map.call(obj?.modules.filter(mod => mod.module == 'module2'), function(module) { return module?.name + '-' + module?.worksheet_number }) ).join(', ') : '---'}</td>
                                                )}
                                                { streamPerWeek >= 3 && (
                                                    <td>{ obj?.modules !== null ? ( Array.prototype.map.call(obj?.modules.filter(mod => mod.module == 'module3'), function(module) { return module?.name + '-' + module?.worksheet_number }) ).join(', ') : '---'}</td>
                                                )}
                                                { streamPerWeek >= 4 && (
                                                    <td>{ obj?.modules !== null ? ( Array.prototype.map.call(obj?.modules.filter(mod => mod.module == 'module4'), function(module) { return module?.name + '-' + module?.worksheet_number }) ).join(', ') : '---'}</td>
                                                )}
                                            </tr>
                                        ))}
                                        {weeklyWork.length == 0 && (
                                            <tr>
                                                <td className='text-center' colSpan={3}>There are no work summary to display</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                            </div>
                            <div className="card-footer clearfix">
                                <Link className="btn btn-default mr-2" to='/students'>Close</Link>
                                { student?.status == 'active' && canAccess?.['set-work'] && (
                                    <Link className="btn btn-default mr-2" to={`/student/set-work/${id}`} role="button"> Set Student Work </Link>
                                )}
                                { canAccess?.['work-history'] && (
                                    <Link className="btn btn-default" to={`/student/work-history/${id}`} role="button">All Work History </Link>
                                )}
                                <ul className="pagination pagination-sm m-0 float-right">
                                    {/* <li className="page-item"><a className="page-link" href="#">«</a></li> */}
                                    { getPaginationContent() }
                                    {/* <li className="page-item"><a className="page-link" href="#">»</a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
        <PrintWorksheetsPDF
            isAdhoc={false}
            show={show}
            handleClose={handleClose}
            studId= {id}
            selectedDates={selectedDates}
            subjectId={search.subject_id}
            student={student}
            selectedWeeksCount={selectedWeeks.length}
        />
    </div>
  )
}

export default StudentWorkSummary;