import { studentCountURL } from "@app/constants/ApiEndPoints";
import axios from "axios";

export const getAllByPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(studentCountURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}
															&franchise_order_id=${search.franchise_order_id}&franchise=${search.franchise}&month=${search.month}&year=${search.year}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllTransactionByPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(studentCountURL + `/orders?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}
															&franchise=${search.franchise}&status=${search.status}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const createStudentCount = async (payload) => {
	try {
		const response = await axios.post(studentCountURL + '/create', payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const checkStudentCountExist = async (payload) => {
	try {
		const response = await axios.post(studentCountURL + '/exists', payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateStudentCount = async (payload) => {
	try {
		const response = await axios.post(studentCountURL + '/update', payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const deleteStudentCount = async (payload) => {
	try {
		const response = await axios.post(studentCountURL + '/destroy', payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};

export const getDefaulters = async (payload) => {
	try {
		const response = await axios.get(studentCountURL + '/export/defaulters' + `?franchise_id=${payload.franchise}&month=${payload.month}&year=${payload.year}`);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getMonthsByFranchiseId = async (payload) => {
	try {
		const response = await axios.post(studentCountURL + '/defaulters', payload);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getCartStudCountByFranchise = async (franchise_id) => {
	try {
		const response = await axios.get(studentCountURL + `/${franchise_id}` + '/unpaid');
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const placeOrder = async (payload) => {
	try {
		const response = await axios.post(studentCountURL +'/place/order', payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const makePayment = async (payload) => {
	try {
		const response = await axios.post(studentCountURL + '/place/failed/order', payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getTransactionDetails = async (id) => {
	try {
		const response = await axios.get(studentCountURL + `/${id}` + '/details');
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateTransactionStatus = async (payload) => {
	try {
		const response = await axios.post(studentCountURL + '/update/order/status', payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};