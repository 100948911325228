import React, { useState } from 'react';
import * as Yup from 'yup';
import { Button } from '@components';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { updateUserPassword } from '@app/services/user';
import { useSelector } from 'react-redux';

const SettingsTab = ({ isActive }: { isActive: boolean }) => {

	const [isLoading, setLoading] = useState(false);
	const user = useSelector((state: any) => state.auth.currentUser);
	
	const updatePassword = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateUserPassword(payload);
            if(resp.success === true){
                toast.success('Your password has been updated successfully');
                setLoading(false);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

	const { handleChange, values, handleSubmit, touched, errors, resetForm } = useFormik({
        initialValues: {
            oldPassword: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required('Old password required'),
            password: Yup.string().required('New password required')
            .matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
				"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
			),
            confirmPassword:  Yup.string().when("password", {
				is: val => (val && val.length > 0 ? true : false),
				then: Yup.string().oneOf(
				  [Yup.ref("password")],
				  "Both password need to be the same"
				),
			})
			.required("Confirm password required"),
        }),
        onSubmit: (values) => {
			
			const payload = {
				id: user.id,
				oldPassword: values.oldPassword,
				password: values.password,
				confirmPassword: values.confirmPassword
			}
            updatePassword(payload);
			resetForm();
        }
    });

	return (
		<div className={`tab-pane ${isActive ? 'active' : ''}`}>
			<form className="form-horizontal" onSubmit={handleSubmit}>
				<div className="form-group row">
					<label htmlFor="oldPassword" className="col-sm-2 col-form-label">
						Old	Password <span className="required">*</span>
					</label>
					<div className="col-sm-10">
						<input
							type="password"
							className="form-control"
							id="oldPassword"
							placeholder="Enter old password"
							name="oldPassword" 
							onChange={handleChange} 
							value={values.oldPassword}
						/>
						{(touched.oldPassword && errors.oldPassword) && (
							<div className="text-danger">
								{errors.oldPassword}
							</div>
						)}
					</div>
				</div>
				<div className="form-group row">
					<label htmlFor="password" className="col-sm-2 col-form-label">
						New Password <span className="required">*</span>
					</label>
					<div className="col-sm-10">
						<input
							type="password"
							className="form-control"
							id="password"
							placeholder="Enter your new password"
							name="password" 
							onChange={handleChange} 
							value={values.password}
						/>
						{(touched.password && errors.password) && (
							<div className="text-danger">
								{errors.password}
							</div>
						)}
					</div>
				</div>
				<div className="form-group row">
					<label htmlFor="confirmPassword" className="col-sm-2 col-form-label">
						Confirm Password <span className="required">*</span>
					</label>
					<div className="col-sm-10">
						<input
							type="password"
							className="form-control"
							id="confirmPassword"
							placeholder="Please confirm password"
							name="confirmPassword" 
							onChange={handleChange} 
							value={values.confirmPassword}
						/>
						{(touched.confirmPassword && errors.confirmPassword) && (
							<div className="text-danger">
								{errors.confirmPassword}
							</div>
						)}
					</div>
				</div>
				
				<div className="form-group row">
					<div className="offset-sm-2 col-sm-10">
						<Button type="submit" theme="danger" isLoading={isLoading}>Update </Button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default SettingsTab;