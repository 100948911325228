import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AddStudentCount from "./AddStudentCount";
import CheckoutStudentCount from "./CheckoutStudentCount";
import SelectFranchise from "./SelectFranchise";
import { setStep } from "@app/store/reducers/studentCount";
import { StripeContainer } from "./stripe/StripeContainer";

const StudentCountWrapper = () => {

    const studCountStore = useSelector((state: any) => state.studentCount);
    const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			dispatch(setStep(1));
		};
	}, []);

	return (
		<>
		<section className="modul_sec_wrapper">
			<div className="col-md-12">
				<div className="card card-primary mb-0">
					<div className="card-header">
						<h3 className="card-title">{ studCountStore.step === 1 ? 'Choose Franchise' : (studCountStore.step === 2 ? 'Submit Student Count' : (studCountStore.step === 3 ? 'Student Count Summary' : (studCountStore.step === 4 ? 'Make Payment' : 'Error')))}</h3>
						{ studCountStore.step !== 1 && (
							<div className="card-tools">
								<h3 className="card-title">You are submitting count for franchise : <u>{studCountStore?.franchise?.name}</u></h3>
							</div>
						)}
					</div>
				</div>
			</div>
			{ studCountStore.step == 1 && (
				<SelectFranchise/>
			)}
			{ studCountStore.step == 2 && (
				<AddStudentCount/>
			)}
			{ studCountStore.step == 3 && (
				<CheckoutStudentCount/>
			)}
			{ studCountStore.step == 4 && (
				<StripeContainer/>
			)}
		</section>
		</>
	);
};

export default StudentCountWrapper;