import "../../pages/order-form/OrderForm.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { Button } from "@app/components";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { getAllActiveFranchise } from "@app/services/franchise";
import { useDispatch } from "react-redux";
import { loadCart, setFranchise, setStep, updateDefaultOrderLoadFlag } from "@app/store/reducers/order";
import { getCartItemsByFranchise, loadDefaultOrder } from "@app/services/order";

const OrderFormStep1 = () => {

	const [franchises, setFranchises] = useState([]);
	const [selectedFranchise, setSelectedFranchise] = useState({});
	const [isLoadingFranchise, setLoadingFranchise] = useState(false);
    const [isCartLoading, setCartLoading] = useState(false);
    const dispatch = useDispatch();
	
	useEffect(() => {
        dispatch(setStep(1));
		getAllActiveFranchiseData();
    },[]);

	const getAllActiveFranchiseData = async () => {
        try {
            setLoadingFranchise(true);
            const resp = await getAllActiveFranchise();
            if (resp?.success === true) {
                setLoadingFranchise(false);
                setFranchises(resp?.collections);
            } else {
                setLoadingFranchise(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoadingFranchise(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

	const getCartItemsByFranchiseData = async (franchiseId) => {
        try {
            // setLoadingFranchise(true);
            const resp = await getCartItemsByFranchise(franchiseId);
            if (resp?.success === true) {
				dispatch(loadCart(resp?.collections));
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            // setLoadingFranchise(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const loadDefaultOrderData = async (franchiseId) => {
        try {
            setCartLoading(true);
            const resp = await loadDefaultOrder(franchiseId);
            if (resp?.success === true) {
                setCartLoading(false);
                getCartItemsByFranchiseData(franchiseId);
            } else {
                setCartLoading(false);
                toast.error(resp?.message);
            }
        } catch (error: any) {
            setCartLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

	const { handleChange, values, handleSubmit, touched, errors, setFieldValue} = useFormik({
        initialValues: {
            franchise_id: '',
            defaultOrder: false,
        },
        validationSchema: Yup.object({
            franchise_id: Yup.string().required('Please select franchise'),
        }),
        onSubmit: (values) => {
			dispatch(setFranchise(selectedFranchise));
            dispatch(setStep(2));
            dispatch(updateDefaultOrderLoadFlag({isDefaultOrderLoad: values.defaultOrder}))
            if(values.defaultOrder){
                loadDefaultOrderData(values.franchise_id)
            }else{
			    getCartItemsByFranchiseData(values.franchise_id);
            }
        }
    });

	return (
		<>
			<div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="franchise_id"><b> Franchise </b><span className="required">*</span></label>
                                                <Select
                                                    // className="form-control"
                                                    name="franchise_id"
                                                    placeholder="Select Franchise"
                                                    options={franchises}
                                                    getOptionValue={(option)=>`${option['id']}`}
                                                    getOptionLabel={(option)=>`${option['name']}`}
                                                    onChange={ (option) => { 
														handleChange
                                                        if(option){
                                                            setFieldValue("franchise_id", option.id)
															setSelectedFranchise(option)
                                                            // getPostcodesByFranchiseData(option.id)  
                                                        }else{
                                                            setFieldValue("franchise_id", '')
															setSelectedFranchise({})
                                                            // setPostcodes([]);
                                                        }
                                                    }}
                                                    // value={values.franchise_id}
                                                    isLoading={isLoadingFranchise}
                                                    isClearable={true}
                                                />
                                                {(touched.franchise_id && errors.franchise_id) && (
                                                    <div className="text-danger">
                                                        {errors.franchise_id}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="franchise_id"><b> Do you want to load default order? </b></label>
                                                <div className="icheck-primary">
                                                    <input type="checkbox"className="form-check-input" id="defaultOrder"  
                                                        onChange={(e) => { 
                                                            handleChange 
                                                            setFieldValue("defaultOrder", e.target.checked)
                                                        } }
                                                    />
                                                    <label htmlFor="defaultOrder" className="form-check-label"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
								<div className="card-footer">
                                    <Button type="submit" className="btn btn-primary mr-2">Next </Button>
                                    <Link className="btn btn-default" to='/in-progress-orders'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
		</>
	);
};

export default OrderFormStep1;