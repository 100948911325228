import { createSubjectURL, deleteSubjectURL, getAllActiveSubjectsURL, getAllSubjectsURL, getSubjectByIdURL, getSubjectsWithPaginationURL, updateSubjectURL } from "@app/constants/ApiEndPoints";
import axios from "axios";

export const getSubjectsWithPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(getSubjectsWithPaginationURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}
															&name=${search.name}&prefix=${search.prefix}&status=${search.status}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllSubjects = async () => {
	try {
		const response = await axios.get(getAllSubjectsURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllActiveSubjects = async () => {
	try {
		const response = await axios.get(getAllActiveSubjectsURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const createSubject = async (payload) => {
	try {
		const response = await axios.post(createSubjectURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getSubjectById = async (id: number) => {
	try {
		const response = await axios.get(getSubjectByIdURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateSubject = async (id, payload) => {
	try {
		const response = await axios.put(updateSubjectURL + id, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const deleteSubjectById = async (id: number) => {
	try {
		const response = await axios.delete(deleteSubjectURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateSubjectRate = async (payload) => {
	try {
		const response = await axios.post(updateSubjectURL + 'rate/update', payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};