import ListOrders from './ListOrders';

const InProgressOrders = () => {
    
    return (
        <ListOrders
            category='inprogress' 
            title='Manage In-Progress Orders' 
        />
    )
};

export default InProgressOrders;