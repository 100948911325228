import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import { Form, Modal } from 'react-bootstrap';
import { updateSetting } from '@app/services/setting';

const UpdateSettingModal = (props) => {

    const [isLoading, setLoading] = useState(false);

    const updateSettingAPI = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateSetting(payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                props.handleClose();
                props.getAllSettingssData();
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const { handleChange, values, handleSubmit, touched, errors } = useFormik({
        initialValues: {
            name: props?.payload?.name,
            value: props?.payload?.value,
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
            value: Yup.string().required('Please enter value'),
        }),
        onSubmit: (values) => {
            updateSettingAPI(values);
        },
        enableReinitialize: true,
    });

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update Setting</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className='row'>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <dt>Setting Name <span className="required">*</span></dt>
                                <dd>{ props?.payload?.description } </dd>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Setting Value <span className="required">*</span></label>
                                <Form.Control 
                                    type="text" 
                                    name="value" 
                                    id="value" 
                                    placeholder="Enter setting value" 
                                    value={values.value}
                                    onChange={handleChange}
                                />
                                {(touched.value && errors.value) && (
                                    <div className="text-danger">
                                        {errors.value}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                    <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    </div>
  )
}

export default UpdateSettingModal;