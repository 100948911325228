import { Link, useParams } from 'react-router-dom'
import { Button } from '@app/components';
import { useDispatch } from 'react-redux';
import { setSelectedSubject } from '@app/store/reducers/studentWork';
import { useEffect, useState } from 'react';
import { getStudentById } from '@app/services/student';
import { toast } from 'react-toastify';

const SelectSubject = (props) => {

    let { id } = useParams();
    let obj: any = {}
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [student, setStudent] = useState(obj);

    const handleSubmit = (subject) => {
	    dispatch(setSelectedSubject(subject))
	}

    useEffect(() => {
        getStudentDetailsById()
    },[]);

    const getStudentDetailsById = async () => {
        try {
            setLoading(true)
            const resp = await getStudentById(parseInt(id));
            if (resp.success === true) {
                setLoading(false)
                setStudent(resp?.collections)
            } else {
                setLoading(false)
                toast.error(resp?.errors[0]?.msg)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.response?.message || 'Internal server error');
        }
    };

    return (
    <div>
        <div className="row justify-content-center w-75 position-absolute">
            <div className="col-md-12 text-center">
                <span style={{fontSize: '1.5rem', fontWeight: 'bold'}}>Set Student Work - { student?.first_name +' '+ student?.last_name}</span>
            </div>
            <div className="col-md-6">
                <div className="external-event" style={{backgroundColor: "#ffd316", position: 'relative', zIndex: 'auto', left: '0px', top: '0px', width: '25%', fontWeight: 400, cursor:'default'}}>Set Work For</div>
                <div className="card">
                    <div className="card-body" style={{backgroundColor: '#f9f1cc'}}>
                        <div className="overlay-wrapper">
                            <div className="overlay" style={{flexDirection: 'column', display: props.isLoading ? 'flex' : 'none'}}><i className="fas fa-3x fa-sync-alt fa-spin" /><div className="text-bold pt-2">Loading...</div></div>
                            <div className="row">
                                {props.subjects.length > 0 && props.subjects.map((subject) => (
                                    <div className={props.subjects.length == 1 ? "col-sm-12 d-flex justify-content-center" : "col-sm-6"}  key={subject.id}>
                                        <div className='m-2'>
                                            <Button
                                                type="button"
                                                className='btn-info btn-block'
                                                isLoading={props.isLoading}
                                                onClick={() => handleSubmit(subject)}
                                            >
                                                { subject.name }
                                            </Button>
                                        </div>
                                    </div>
                                ))}

                                {props.subjects.length == 0 && (
                                    <p className='text-danger'> The student doesn't have any subjects assigned.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 text-center">
                {/* <Button type="submit" isLoading={isLoading} className="btn btn-warning px-5 mr-2" disabled={studentSubjects.length == 0}>Next </Button> */}
                <Link className="btn btn-default px-5" to='/students'>Cancel</Link>
            </div>
        </div>
    </div>
  )
}

export default SelectSubject;