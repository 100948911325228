import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link,useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { getStudentById } from '@app/services/student';
import { useSelector } from 'react-redux';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';

const ViewStudent = () => {
    let obj: any = {}
    const [isLoading, setLoading] = useState(false);
    const [student, setStudentData] = useState(obj);
    const canAccess = useSelector((state: any) => state.auth.permissions.student);
    const navigate = useNavigate();
    let { id } = useParams();

    useEffect(() => {
        getStudentDetailsById(id);
    }, []);

    const getStudentDetailsById = async (id) => {
        try {
            setLoading(true);
            const resp = await getStudentById(id);
            if(resp.success === true){
                setLoading(false);
                setStudentData(resp.collections);
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
                navigate('/students');
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

  return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">View Student Details</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Student Name </label>
                                            <dd>{student.first_name+' '+student.last_name} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Date Of Birth </label>
                                            <dd>{student.date_of_birth ? moment(student.date_of_birth).format('YYYY-MM-DD') : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Gender </label>
                                            <dd>{student.gender ? student.gender.charAt(0).toUpperCase()+ student.gender.slice(1) : '---'}</dd>   
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Roll No. </label>
                                            <dd>{student?.roll_number}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Grade </label>
                                            <dd>{student.grade_name ? student.grade_name : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">School </label>
                                            <dd>{student.school_name ? student.school_name : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Franchise </label>
                                            <dd>{student?.franchise_name}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Centre </label>
                                            <dd>{student.centre_name ? student.centre_name : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Status </label>
                                            <dd>{student.status ? student.status.charAt(0).toUpperCase()+ student.status.slice(1) : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Address Line 1 </label>
                                            <dd>{student?.address}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Address Line 2 </label>
                                            <dd>{student?.address_line_2 ?? '---' }</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">City </label>
                                            <dd>{student?.city ?? '---' }</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Postcode </label>
                                            <dd>{student?.postcode ?? '---' }</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Country </label>
                                            <dd>{student.country ? student.country.toUpperCase() : '---'}</dd>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        {student.subjects?.length > 0 && (
                                            <table className="table table-bordered" id="client_id_table">
                                                <tbody>
                                                    <tr className='text-center'>
                                                        <th>Subject</th>
                                                        <th>Rate</th>
                                                    </tr>
                                                    {student.subjects?.length > 0  && student.subjects.map((subject) => (
                                                        <tr key={subject.subject_id}>
                                                            <td>{ subject.name }</td>
                                                            <td className=''>
                                                                { subject?.rate !== '' ? <CurrencyFormat value={ subject?.rate } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /> : '---' }
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <Link className="btn btn-default" to='/students'>Close</Link>
                                <Link className="btn btn-default ml-2" to={`/student/edit/${student?.id}`}>Edit</Link>
                            </div>
                        </div>
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Parent Details</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    { student?.parents?.length > 0  && student.parents.map((parent) => (
                                        <div className="col-md-6 col-xl-4" key={parent.parent_id}>
                                            <div className="card m-b-30">
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <a href=""><img width={'30%'} height={'30%'} src="/img/default-profile.png" alt="" className="img-fluid rounded-circle w-60" /></a>
                                                        <p className="mt-2">{parent.relationship.charAt(0).toUpperCase()+parent.relationship.slice(1)}</p>
                                                    </div>
                                                </div>
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item"><i className="fa fa-user float-right"></i>Name : {parent.first_name+' '+parent.last_name}</li>
                                                    <li className="list-group-item"><i className="fa fa-envelope float-right"></i>Email : <a href="#">{parent.email}</a></li>
                                                    <li className="list-group-item"><i className="fa fa-phone float-right"></i>Contact No. : {parent.contact}</li>
                                                    <li className="list-group-item"><i className="fa fa-phone float-right"></i>Alt. Contact No. : {parent.alt_contact ? parent.alt_contact : 'Not Present'}</li>
                                                    <li className="list-group-item"><i className="fa fa-home float-right"></i>Address : {parent.address}</li>
                                                    <li className="list-group-item"><i className="fa fa-credit-card float-right"></i>Bill Payer : {(parent.bill_payer) ? <img width={'10%'} height={'10%'} src='/img/right.png'  /> : <img width={'5%'} height={'5%'} src='/img/wrong.png' className='ml-2'/>}
                                                    
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    ))}
                                    {student?.parents?.length == 0 && (
                                        <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default ViewStudent;