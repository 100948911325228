import React, { useState, useEffect, useRef } from 'react'
import { ErrorMessage, FieldArray, FormikProvider, useFormik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { enrollStudentToCamp, getAllActiveCamps, getStudentEnrollmentDetails } from '@app/services/summerCamp';
import Select from "react-select";
import { getStudentById } from '@app/services/student';
import { getPostcodeCountryByFranchiseId } from '@app/services/franchise';
import CurrencyFormat from 'react-currency-format';

const EnrollToCamp = () => {

    let { id } = useParams();
    let obj: any = {}
    const enrollTypes = [{value: 'week' , name:'Week'}, {value: 'day', name:'Day'}];
    const [isLoading, setLoading] = useState(false);
    const [student, setStudent] = useState(obj);
    const [camps, setCamps] = useState([]);
    const [batches, setBatches] = useState([]);
    const [timings, setTimings] = useState([]);
    const [currency, setCurrency] = useState('');
    const [enrollDetails, setEnrollDetails] = useState([]);
    const navigate = useNavigate();
    const emptyBatchWeek = {id: '', time_id:'', batch_price: '', day_price: '', date: ''}

    useEffect(() => {
        getStudDetailsById(id)
    },[]);

    const getStudDetailsById = async (id) => {
        try {
            setLoading(true)
            const resp = await getStudentById(id);
            if (resp.success === true) {
                setLoading(false)
                if(!resp?.collections?.enabled_holiday_camp){
                    navigate('/students');
                }
                setStudent(resp?.collections)
                getAllCamps(resp?.collections?.franchise_id)
                getCountryByFranchiseId(resp?.collections?.franchise_id)
            } else {
                setLoading(false)
                toast.error(resp?.message);
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.message || 'Internal server error');
        }
    };

    const getAllCamps = async (franchiseId) => {
        try {
            setLoading(true)
            const resp = await getAllActiveCamps(franchiseId);
            if (resp.success === true) {
                setLoading(false)
                setCamps(resp?.collections)
            } else {
                setLoading(false)
                toast.error(resp?.errors[0]?.msg)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.message || 'Internal server error');
        }
    };

    const getCountryByFranchiseId = async (franchiseId) => {
        try {
            const resp = await getPostcodeCountryByFranchiseId(franchiseId);
            if (resp?.success === true) {
                setCurrency(resp?.collections?.currency)
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error');
        }
    };

    const enrollStudent = async (payload) => {
        try {
            setLoading(true);
            const resp = await enrollStudentToCamp(payload);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                navigate('/students');
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal server error');
          }
    };

    const getEnrollmentDetails = async (payload) => {
        try {
            const resp = await getStudentEnrollmentDetails(payload);
            if (resp.success === true) {
                setEnrollDetails(resp?.collections)
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error');
        }
    };

    const formik = useFormik({
        initialValues: {
            summer_camp_id: '',
            student_id: id,
            franchise_id: student?.franchise_id,
            type: 'week',
            batches: [emptyBatchWeek],
            total_amount: ''
        },
        validationSchema: Yup.object({
            summer_camp_id: Yup.string().required('Please select camp'),
            type: Yup.string().required('Please select enroll type'),
            batches: Yup.array().of(Yup.object({
                id: Yup.string().required('Please select batch'),
                time_id: Yup.string().required('Please select batch time'),
                date: Yup.date().when([], {
                    is: () => formik.values.type === 'day',
                    then: Yup.date().required('Please select camp date')
                }),
            })),
            total_amount: Yup.number().required('Please enter amount')
                .min(0,'Total amount must be a greater than or equal to 0'),
                // .max(10000, 'Total amount must be less than or equal to 10,000')
        }),
        onSubmit: (values) => {
            enrollStudent(values);
        },
        enableReinitialize: true,
    });

    useEffect(()=>{
        if(formik.values.batches.length > 0){
            let total_amount:number = 0;
            if(formik.values.type === 'week'){
                formik.values.batches.map((batch) => {
                    if(batch.batch_price != ''){
                        total_amount += parseFloat(batch.batch_price); 
                    }
                })
            }else{
                formik.values.batches.map((batch) => {
                    if(batch.day_price != ''){
                        total_amount += parseFloat(batch.day_price); 
                    }
                })
            }
            formik.setFieldValue('total_amount', total_amount)
        }
        // if(formik.values.type === 'week'){
        //     formik.setFieldValue('total_amount', formik.values.batches.length * 1000)
        // }else{
        //     formik.setFieldValue('total_amount', formik.values.batches.length * 250)
        // }
    },[formik.values.batches, formik.values.type])

    const handleCampChange = async (campId) => {
        formik.setFieldValue('summer_camp_id', campId)
        if(campId != ''){
            let selCamp = (camps).find(obj => {
                return obj.id == campId 
            })
            setTimings(selCamp?.timings)
            setBatches(selCamp?.batches)
            getEnrollmentDetails({summer_camp_id: campId, student_id: id})
        }else{
            setBatches([])
            setTimings([])
            setEnrollDetails([])
        }
    };

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Enroll In Camp</h3>
                                <div className="card-tools">
                                    <h3 className="card-title">You are enrolling the student: <u>{ student?.first_name }  { student?.last_name }</u></h3>
                                </div>
                            </div>
                            <FormikProvider value={formik}>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="summer_camp_id">Camp <span className="required">*</span></label>
                                                <select
                                                    id="summer_camp_id"
                                                    className="form-control"
                                                    name="summer_camp_id"
                                                    onChange={ (e) => handleCampChange(e.target.value) }
                                                    value={formik.values.summer_camp_id}
                                                >
                                                    <option value="">Select Camp</option>
                                                    {camps.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(formik.touched.summer_camp_id && formik.errors.summer_camp_id) && (
                                                    <div className="text-danger">
                                                        {formik.errors.summer_camp_id}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="type">Enroll Type <span className="required">*</span></label>
                                                {(enrollTypes).map((obj) => (
                                                    <div className="form-check col-sm-12" key={obj?.value}>
                                                        <input 
                                                            className="form-check-input" 
                                                            id="type"
                                                            type="radio" 
                                                            name="type" 
                                                            onChange={ (e) => {
                                                                formik.setFieldValue('type', e.target.value)
                                                            }}
                                                            value={obj?.value}
                                                            checked={obj?.value == formik.values.type}
                                                        />
                                                        <label className="form-check-label">{ obj?.name }</label><br/>
                                                    </div>
                                                )) }
                                                {(formik.touched.type && formik.errors.type) && (
                                                    <div className="text-danger">
                                                        {formik.errors.type}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <FieldArray name="batches">
                                        {({ push, remove}) => (
                                            <>
                                            <div className='row'>
                                                <div className="col-sm-12">
                                                    <h6 className='mt-2 grey_wrapper_title'>Select Batches</h6>
                                                </div>
                                            </div>
                                            {formik.values.batches.map((batch, index) => (
                                                <div className="row" key={index}>
                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">Batch <span className="required">*</span></label>
                                                            <Select
                                                                name={`batches.${index}.id`}
                                                                placeholder="Select Batch"
                                                                options={batches}
                                                                getOptionValue={(option)=>`${option['id']}`}
                                                                getOptionLabel={(option)=>`${option['name']}`}
                                                                onChange={ (option) => {
                                                                    if(option){
                                                                        formik.setFieldValue(`batches.${index}.id`, option.id)
                                                                        formik.setFieldValue(`batches.${index}.batch_price`, option.batch_price)
                                                                        formik.setFieldValue(`batches.${index}.day_price`, option.day_price)
                                                                    }else{
                                                                        formik.setFieldValue(`batches.${index}.id`, '')
                                                                        formik.setFieldValue(`batches.${index}.batch_price`, '')
                                                                        formik.setFieldValue(`batches.${index}.day_price`, '')
                                                                    }
                                                                }}
                                                                // value={values.franchise_id}
                                                                isClearable={true}
                                                            />
                                                            <div className="text-danger">
                                                                <ErrorMessage name={`batches.${index}.id`} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">Time <span className="required">*</span></label>
                                                            <Select
                                                                name={`batches.${index}.time_id`}
                                                                placeholder="Select Time"
                                                                options={formik.values.batches[index].id != '' ? (timings.find(obj => {return obj.batch_id == formik.values.batches[index].id})).timings : []}
                                                                getOptionValue={(option)=>`${option['id']}`}
                                                                getOptionLabel={(option)=>`${option['name']}`}
                                                                onChange={ (option) => { 
                                                                    if(option){
                                                                        formik.setFieldValue(`batches.${index}.time_id`, option['id'])
                                                                    }else{
                                                                        formik.setFieldValue(`batches.${index}.time_id`, '')
                                                                    }
                                                                }}
                                                                // value={values.franchise_id}
                                                                isClearable={true}
                                                            />
                                                            <div className="text-danger">
                                                                <ErrorMessage name={`batches.${index}.time_id`} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { formik.values.type == 'day' && (
                                                        <div className="col-sm-3">
                                                            <div className="form-group">
                                                                <label htmlFor={`batches.${index}.date`}>Camp Date <span className="required">*</span></label>
                                                                <Form.Control 
                                                                    type="date" 
                                                                    id={`batches.${index}.date`} 
                                                                    name={`batches.${index}.date`}
                                                                    placeholder="Enter camp date" 
                                                                    // value={moment(values.date).format('YYYY-MM-DD')}
                                                                    min={moment((batches.find(obj => { return obj.id == formik.values.batches[index].id }))?.start_date).format('YYYY-MM-DD')}
                                                                    max={moment((batches.find(obj => { return obj.id == formik.values.batches[index].id }))?.end_date).format('YYYY-MM-DD')}
                                                                    onChange={formik.handleChange}
                                                                />
                                                                <div className="text-danger">
                                                                    <ErrorMessage name={`batches.${index}.date`} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    { formik.values.batches.length > 1 && (
                                                        <div className="col-sm-2 d-flex align-items-center form_wrapper">
                                                            <i className="fas fa-times-circle" onClick={() => remove(index)} style={{cursor:'pointer'}}></i>
                                                        </div>
                                                    )}
                                                    {/* <pre>{ JSON.stringify(formik.errors) }</pre> */}
                                                </div>
                                            ))}
                                                <i className="cursor-pointer fas fa-plus-circle" onClick={() => push(emptyBatchWeek)} style={{cursor:'pointer'}}></i>
                                            </>
                                        )}
                                    </FieldArray>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="total_amount">Total Amount <span className="required">*</span></label>
                                                <div className='input-group'>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">{currency}</span>
                                                    </div>
                                                    <Form.Control 
                                                        type="number" 
                                                        className="form-control" 
                                                        id="total_amount" 
                                                        placeholder="Enter Total Amount"
                                                        name="total_amount" 
                                                        onChange={formik.handleChange} 
                                                        value={formik.values.total_amount}
                                                    />
                                                </div>
                                                {(formik.touched.total_amount && formik.errors.total_amount) && (
                                                    <div className="text-danger">
                                                        {formik.errors.total_amount}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Enroll </Button>
                                    <Link className="btn btn-default" to='/students'>Close</Link>
                                </div>
                            </form>
                            </FormikProvider>
                        </div>
                        <div className="card card-primary">
                            <div className="card-body">
                                <h6 className="mb-4 grey_wrapper_title d-flex justify-content-between">Enrollment Details
                                    { enrollDetails.length > 0 && (
                                        <span className='p-2' style={{backgroundColor:'#eaeaea', border: '1px solid #000'}}>{enrollDetails[0]?.summer_camp_name ?? '---'}</span>
                                    )}
                                </h6>
                                { enrollDetails.length > 0 && (enrollDetails).map((enroll) => (
                                    <div className='content_inner_wrap'>
                                        <div className='row'>
                                            <div className="col-md-12 mt-2">
                                                <table className="table table-bordered" id="client_id_table">
                                                    <tbody>
                                                        <tr className='text-center'>
                                                            <th>Batch Name</th>
                                                            <th>Batch Time</th>
                                                            {enroll?.type == 'day' && ( <th>Camp Date(s)</th> )}
                                                            <th>Enroll Type</th>
                                                            <th>Total Amount</th>
                                                            <th>Enrolled At</th>
                                                            {/* <th>Price</th> */}
                                                        </tr>
                                                        { enroll?.details && (enroll?.details).map((obj) => (
                                                            <tr key={obj?.id} className='text-center'>
                                                                <td>{ obj?.batch_name }</td>
                                                                <td>{ obj?.batch_timing }</td>
                                                                {enroll?.type == 'day' && ( <td>{ obj?.date ? moment(obj?.date).format('DD-MM-YYYY') : '---' }</td> )}
                                                                <td>{enroll?.type ? enroll?.type.charAt(0).toUpperCase() + enroll?.type.slice(1) : '---'}</td>
                                                                <td><CurrencyFormat value={ enroll?.total_amount } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency + ' '} /></td>
                                                                <td>{enroll?.created_at ? moment(enroll?.created_at).format('DD-MM-YYYY hh:mm A') : '---'}</td>
                                                                {/* <td><CurrencyFormat value={ obj?.price } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={props?.currency + ' '} /></td> */}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                { enrollDetails.length == 0 && (
                                    <div className="row">
                                        <div className="col-md-12">
                                            <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default EnrollToCamp;