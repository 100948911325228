import { useSelector, useDispatch } from "react-redux";
import SelectSubject from "./SelectSubject";
import React, { useState, useEffect } from 'react'
import SetStudentWork from "./SetStudentWork";
import { useParams } from 'react-router-dom'
import { getStudentAssignedSubjectsById } from "@app/services/student";
import { toast } from "react-toastify";
import { setSelectedSubject } from "@app/store/reducers/studentWork";

const SetWorkWrapper = () => {

	const studentWorkStore = useSelector((state: any) => state.studentWork);
	let { id } = useParams();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [studentSubjects, setStudentSubjects] = useState([]);
    
    useEffect(() => {
        getStudentSubjects(id);
    },[]);

	const getStudentSubjects = async (id) => {
        try {
            setLoading(true)
            const resp = await getStudentAssignedSubjectsById(id)
            if (resp.success === true) {
                setLoading(false)
				if(Object.keys(studentWorkStore?.subject ?? {}).length > 0){
					let found = resp?.collections.find((subject) => {return subject.id == studentWorkStore?.subject?.id})
					if(found == undefined){
						dispatch(setSelectedSubject({}))
					}
				}

                if(resp?.collections.length > 0){
					let subjects = (resp?.collections.length == 1) ? resp?.collections : [{id: 0, name: 'Mix and Match'}].concat(resp?.collections);
                    setStudentSubjects(subjects);
				}else{
					dispatch(setSelectedSubject({}))
				}
            } else {
				dispatch(setSelectedSubject({}))
                setLoading(false)
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
			dispatch(setSelectedSubject({}))
            setLoading(false)
            toast.error(error?.response?.message || 'Internal server error');
        }
    };
	
	return (
		<section className="content">
            <div className="container-fluid">
				{  Object.keys(studentWorkStore?.subject ?? {}).length == 0 && (
					<SelectSubject 
						subjects={studentSubjects}
						isLoading={isLoading}
					/>
				)}
				{ Object.keys(studentWorkStore?.subject ?? {}).length > 0 && (
					<SetStudentWork/>
				)}
			</div>
		</section>
	);
};

export default SetWorkWrapper;