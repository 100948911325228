import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { getFranchiseById } from '@app/services/franchise';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import DataTable, { TableStyles } from 'react-data-table-component';
import CurrencyFormat from 'react-currency-format';

const FranchiseDashboard = () => {
    const franchiseTypes = [{value:'company', label:'Company'}, {value:'sole_trader', label:'Sole Trader'}];
    const franchiseStatus = [{value:'active', label:'Active'}, {value:'inactive', label:'Inactive'}, {value:'on_hold', label:'On Hold'}];
    const months = [{id: 1, name:'January'}, {id: 2, name:'February'}, {id: 3, name:'March'}, 
                    {id: 4, name:'April'}, {id: 5, name:'May'}, {id: 6, name:'June'},
                    {id: 7, name:'July'},{id: 8, name:'August'},{id: 9, name:'September'},
                    {id: 10, name:'October'}, {id: 11, name:'November'}, {id: 12, name:'December'}];
    let obj: any = {}
    const [isLoading, setLoading] = useState(false);
    const [franchise, setFranchiseData] = useState(obj);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [plan, setPlanData] = useState(obj);
    const [tiers, setPlanTiers] = useState([]);
    const [orders, setOrders] = useState([]);
    const [studentCounts, setStudentCounts] = useState([]);
    const [subjectWisePrices, setSubjectWisePrices] = useState([]);
    const [centers, setCenters] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const navigate = useNavigate();
    let { id } = useParams();
    
    useEffect(() => {
        getFranchiseDetailsById(id);

        setPlanData({
            "id": 1,
            "name": "Default Pricing Plan",
            "start_date": "2023-07-25T00:00:00.000Z",
            "end_date": "2027-07-26T00:00:00.000Z",
            "status": "active",
            "is_default": "true",
            "tiers": [
                {
                    "id": 1,
                    "name": "Tier 1",
                    "from_count": 1,
                    "to_count": 199,
                    "alert_threshold": 195
                },
                {
                    "id": 2,
                    "name": "Tier 2",
                    "from_count": 200,
                    "to_count": 299,
                    "alert_threshold": 290
                },
                {
                    "id": 3,
                    "name": "Tier 3",
                    "from_count": 300,
                    "to_count": 499,
                    "alert_threshold": 390
                },
                {
                    "id": 4,
                    "name": "Tier 4",
                    "from_count": 500,
                    "to_count": 10000,
                    "alert_threshold": 9999
                }
            ]
        });
        setPlanTiers([
            {
                "id": 1,
                "name": "Tier 1",
                "from_count": 1,
                "to_count": 199,
                "alert_threshold": 195
            },
            {
                "id": 2,
                "name": "Tier 2",
                "from_count": 200,
                "to_count": 299,
                "alert_threshold": 290
            },
            {
                "id": 3,
                "name": "Tier 3",
                "from_count": 300,
                "to_count": 499,
                "alert_threshold": 390
            },
            {
                "id": 4,
                "name": "Tier 4",
                "from_count": 500,
                "to_count": 10000,
                "alert_threshold": 9999
            }
        ])
        setSubjectWisePrices([
            {
                "id": 4,
                "name": "English",
                "slug": "english",
                "plan_id": 1,
                "tiers": [
                    {
                        "id": 1,
                        "name": "Tier 1",
                        "from_count": 1,
                        "to_count": 199,
                        "alert_threshold": 195,
                        "price": 0.75
                    },
                    {
                        "id": 2,
                        "name": "Tier 2",
                        "from_count": 200,
                        "to_count": 299,
                        "alert_threshold": 290,
                        "price": 0.74
                    },
                    {
                        "id": 3,
                        "name": "Tier 3",
                        "from_count": 300,
                        "to_count": 499,
                        "alert_threshold": 390,
                        "price": 0.73
                    },
                    {
                        "id": 4,
                        "name": "Tier 4",
                        "from_count": 500,
                        "to_count": 10000,
                        "alert_threshold": 9999,
                        "price": 0.72
                    }
                ]
            },
            {
                "id": 5,
                "name": "Mathematics",
                "slug": "mathematics",
                "plan_id": 1,
                "tiers": [
                    {
                        "id": 1,
                        "name": "Tier 1",
                        "from_count": 1,
                        "to_count": 199,
                        "alert_threshold": 195,
                        "price": 0.75
                    },
                    {
                        "id": 2,
                        "name": "Tier 2",
                        "from_count": 200,
                        "to_count": 299,
                        "alert_threshold": 290,
                        "price": 0.74
                    },
                    {
                        "id": 3,
                        "name": "Tier 3",
                        "from_count": 300,
                        "to_count": 499,
                        "alert_threshold": 390,
                        "price": 0.73
                    },
                    {
                        "id": 4,
                        "name": "Tier 4",
                        "from_count": 500,
                        "to_count": 10000,
                        "alert_threshold": 9999,
                        "price": 0.72
                    }
                ]
            },
            {
                "id": 7,
                "name": "Science",
                "slug": "science",
                "plan_id": 1,
                "tiers": [
                    {
                        "id": 1,
                        "name": "Tier 1",
                        "from_count": 1,
                        "to_count": 199,
                        "alert_threshold": 195,
                        "price": 0.75
                    },
                    {
                        "id": 2,
                        "name": "Tier 2",
                        "from_count": 200,
                        "to_count": 299,
                        "alert_threshold": 290,
                        "price": 0.74
                    },
                    {
                        "id": 3,
                        "name": "Tier 3",
                        "from_count": 300,
                        "to_count": 499,
                        "alert_threshold": 390,
                        "price": 0.73
                    },
                    {
                        "id": 4,
                        "name": "Tier 4",
                        "from_count": 500,
                        "to_count": 10000,
                        "alert_threshold": 9999,
                        "price": 0.72
                    }
                ]
            }
        ])
        setCenters([{
            "id": 325,
            "name": "Rane Nagar",
        },
        {
            "id": 324,
            "name": "Rajiv Nagar",
        }])
        setSubjects([
            {
                "id": 4,
                "name": "English",
            },
            {
                "id": 5,
                "name": "Mathematics",
            },
            {
                "id": 6,
                "name": "Physical Education",
            },
            {
                "id": 7,
                "name": "Science",
            }
        ])
        setOrders([
            {
                "id": 2,
                "user_id": 33,
                "franchise_id": 461,
                "total_price": "29.00",
                "net_price": "29.00",
                "vat": "0.00",
                "order_status": "cancelled",
                "shipping_address": "B1 to B4, Ghanshwam Bhavan, Mandore Road , Above State Bank of Bikaner & Jaipur, Paota",
                "qty_sets": 20,
                "qty_handbooks": 2,
                "contact_no": "9921819186",
                "created_at": "2023-07-26T09:41:49.414Z",
                "updated_at": "2023-07-26T09:42:05.000Z",
                "file_path": "orders/461-order-test/July/2.xlsx",
                "dispatch_date": null,
                "printer_order_no": null,
                "pcharge_total": "25.20",
                "pcharge_net": "21.00",
                "pcharge_vat": "4.20",
                "pcharge_type": "first",
                "handbook_binding": "Saddle Stitched",
                "stripe_payment_id": null,
                "stripe_payment_secret": null,
                "stripe_response": "{}",
                "franchise_name": "ORDER TEST",
                "franchise_slug": "order-test",
                "default_order": false
            },
            {
                "id": 1,
                "user_id": 33,
                "franchise_id": 461,
                "total_price": "860.85",
                "net_price": "772.65",
                "vat": "88.20",
                "order_status": "dispatched",
                "shipping_address": "B1 to B4, Ghanshwam Bhavan, Mandore Road , Above State Bank of Bikaner & Jaipur, Paota",
                "qty_sets": 390,
                "qty_handbooks": 9,
                "contact_no": "9921819186",
                "created_at": "2023-07-26T09:33:09.521Z",
                "updated_at": "2023-07-26T09:39:47.000Z",
                "file_path": "orders/461-order-test/July/1.xlsx",
                "dispatch_date": "2023-07-27T00:00:00.000Z",
                "printer_order_no": "1",
                "pcharge_total": "0.00",
                "pcharge_net": "0.00",
                "pcharge_vat": "0.00",
                "pcharge_type": null,
                "handbook_binding": "Spiral Bound",
                "stripe_payment_id": "pi_3NY45gAohRXzsSoW179Ytm5e",
                "stripe_payment_secret": "pi_3NY45gAohRXzsSoW179Ytm5e_secret_g1PvNgd0ueUZzlqoO84PvMiW8",
                "stripe_response": "{\"id\":\"pi_3NY45gAohRXzsSoW179Ytm5e\",\"object\":\"payment_intent\",\"amount\":86085,\"amount_capturable\":0,\"amount_details\":{\"tip\":{}},\"amount_received\":86085,\"application\":null,\"application_fee_amount\":null,\"automatic_payment_methods\":null,\"canceled_at\":null,\"cancellation_reason\":null,\"capture_method\":\"automatic\",\"client_secret\":\"pi_3NY45gAohRXzsSoW179Ytm5e_secret_g1PvNgd0ueUZzlqoO84PvMiW8\",\"confirmation_method\":\"automatic\",\"created\":1690363988,\"currency\":\"gbp\",\"customer\":\"cus_NtnV8rMnFEXPwm\",\"description\":null,\"invoice\":null,\"last_payment_error\":null,\"latest_charge\":\"ch_3NY45gAohRXzsSoW1FqIp5ws\",\"livemode\":false,\"metadata\":{},\"next_action\":null,\"on_behalf_of\":null,\"payment_method\":\"pm_1NY45eAohRXzsSoWwbh02bsE\",\"payment_method_options\":{\"card\":{\"installments\":null,\"mandate_options\":null,\"network\":null,\"request_three_d_secure\":\"automatic\"}},\"payment_method_types\":[\"card\"],\"processing\":null,\"receipt_email\":null,\"review\":null,\"setup_future_usage\":null,\"shipping\":null,\"source\":null,\"statement_descriptor\":null,\"statement_descriptor_suffix\":null,\"status\":\"succeeded\",\"transfer_data\":null,\"transfer_group\":null}",
                "franchise_name": "ORDER TEST",
                "franchise_slug": "order-test",
                "default_order": true
            },
            {
                "id": 2,
                "user_id": 33,
                "franchise_id": 461,
                "total_price": "29.00",
                "net_price": "29.00",
                "vat": "0.00",
                "order_status": "cancelled",
                "shipping_address": "B1 to B4, Ghanshwam Bhavan, Mandore Road , Above State Bank of Bikaner & Jaipur, Paota",
                "qty_sets": 20,
                "qty_handbooks": 2,
                "contact_no": "9921819186",
                "created_at": "2023-07-26T09:41:49.414Z",
                "updated_at": "2023-07-26T09:42:05.000Z",
                "file_path": "orders/461-order-test/July/2.xlsx",
                "dispatch_date": null,
                "printer_order_no": null,
                "pcharge_total": "25.20",
                "pcharge_net": "21.00",
                "pcharge_vat": "4.20",
                "pcharge_type": "first",
                "handbook_binding": "Saddle Stitched",
                "stripe_payment_id": null,
                "stripe_payment_secret": null,
                "stripe_response": "{}",
                "franchise_name": "ORDER TEST",
                "franchise_slug": "order-test",
                "default_order": false
            },
            {
                "id": 1,
                "user_id": 33,
                "franchise_id": 461,
                "total_price": "860.85",
                "net_price": "772.65",
                "vat": "88.20",
                "order_status": "dispatched",
                "shipping_address": "B1 to B4, Ghanshwam Bhavan, Mandore Road , Above State Bank of Bikaner & Jaipur, Paota",
                "qty_sets": 390,
                "qty_handbooks": 9,
                "contact_no": "9921819186",
                "created_at": "2023-07-26T09:33:09.521Z",
                "updated_at": "2023-07-26T09:39:47.000Z",
                "file_path": "orders/461-order-test/July/1.xlsx",
                "dispatch_date": "2023-07-27T00:00:00.000Z",
                "printer_order_no": "1",
                "pcharge_total": "0.00",
                "pcharge_net": "0.00",
                "pcharge_vat": "0.00",
                "pcharge_type": null,
                "handbook_binding": "Spiral Bound",
                "stripe_payment_id": "pi_3NY45gAohRXzsSoW179Ytm5e",
                "stripe_payment_secret": "pi_3NY45gAohRXzsSoW179Ytm5e_secret_g1PvNgd0ueUZzlqoO84PvMiW8",
                "stripe_response": "{\"id\":\"pi_3NY45gAohRXzsSoW179Ytm5e\",\"object\":\"payment_intent\",\"amount\":86085,\"amount_capturable\":0,\"amount_details\":{\"tip\":{}},\"amount_received\":86085,\"application\":null,\"application_fee_amount\":null,\"automatic_payment_methods\":null,\"canceled_at\":null,\"cancellation_reason\":null,\"capture_method\":\"automatic\",\"client_secret\":\"pi_3NY45gAohRXzsSoW179Ytm5e_secret_g1PvNgd0ueUZzlqoO84PvMiW8\",\"confirmation_method\":\"automatic\",\"created\":1690363988,\"currency\":\"gbp\",\"customer\":\"cus_NtnV8rMnFEXPwm\",\"description\":null,\"invoice\":null,\"last_payment_error\":null,\"latest_charge\":\"ch_3NY45gAohRXzsSoW1FqIp5ws\",\"livemode\":false,\"metadata\":{},\"next_action\":null,\"on_behalf_of\":null,\"payment_method\":\"pm_1NY45eAohRXzsSoWwbh02bsE\",\"payment_method_options\":{\"card\":{\"installments\":null,\"mandate_options\":null,\"network\":null,\"request_three_d_secure\":\"automatic\"}},\"payment_method_types\":[\"card\"],\"processing\":null,\"receipt_email\":null,\"review\":null,\"setup_future_usage\":null,\"shipping\":null,\"source\":null,\"statement_descriptor\":null,\"statement_descriptor_suffix\":null,\"status\":\"succeeded\",\"transfer_data\":null,\"transfer_group\":null}",
                "franchise_name": "ORDER TEST",
                "franchise_slug": "order-test",
                "default_order": true
            },
            {
                "id": 1,
                "user_id": 33,
                "franchise_id": 461,
                "total_price": "860.85",
                "net_price": "772.65",
                "vat": "88.20",
                "order_status": "dispatched",
                "shipping_address": "B1 to B4, Ghanshwam Bhavan, Mandore Road , Above State Bank of Bikaner & Jaipur, Paota",
                "qty_sets": 390,
                "qty_handbooks": 9,
                "contact_no": "9921819186",
                "created_at": "2023-07-26T09:33:09.521Z",
                "updated_at": "2023-07-26T09:39:47.000Z",
                "file_path": "orders/461-order-test/July/1.xlsx",
                "dispatch_date": "2023-07-27T00:00:00.000Z",
                "printer_order_no": "1",
                "pcharge_total": "0.00",
                "pcharge_net": "0.00",
                "pcharge_vat": "0.00",
                "pcharge_type": null,
                "handbook_binding": "Spiral Bound",
                "stripe_payment_id": "pi_3NY45gAohRXzsSoW179Ytm5e",
                "stripe_payment_secret": "pi_3NY45gAohRXzsSoW179Ytm5e_secret_g1PvNgd0ueUZzlqoO84PvMiW8",
                "stripe_response": "{\"id\":\"pi_3NY45gAohRXzsSoW179Ytm5e\",\"object\":\"payment_intent\",\"amount\":86085,\"amount_capturable\":0,\"amount_details\":{\"tip\":{}},\"amount_received\":86085,\"application\":null,\"application_fee_amount\":null,\"automatic_payment_methods\":null,\"canceled_at\":null,\"cancellation_reason\":null,\"capture_method\":\"automatic\",\"client_secret\":\"pi_3NY45gAohRXzsSoW179Ytm5e_secret_g1PvNgd0ueUZzlqoO84PvMiW8\",\"confirmation_method\":\"automatic\",\"created\":1690363988,\"currency\":\"gbp\",\"customer\":\"cus_NtnV8rMnFEXPwm\",\"description\":null,\"invoice\":null,\"last_payment_error\":null,\"latest_charge\":\"ch_3NY45gAohRXzsSoW1FqIp5ws\",\"livemode\":false,\"metadata\":{},\"next_action\":null,\"on_behalf_of\":null,\"payment_method\":\"pm_1NY45eAohRXzsSoWwbh02bsE\",\"payment_method_options\":{\"card\":{\"installments\":null,\"mandate_options\":null,\"network\":null,\"request_three_d_secure\":\"automatic\"}},\"payment_method_types\":[\"card\"],\"processing\":null,\"receipt_email\":null,\"review\":null,\"setup_future_usage\":null,\"shipping\":null,\"source\":null,\"statement_descriptor\":null,\"statement_descriptor_suffix\":null,\"status\":\"succeeded\",\"transfer_data\":null,\"transfer_group\":null}",
                "franchise_name": "ORDER TEST",
                "franchise_slug": "order-test",
                "default_order": true
            }
        ])
        setStudentCounts([
            {
                "name": "ORDER TEST",
                "franchise_id": 461,
                "month": "9",
                "year": "2023",
                "maths": "100",
                "english": "117",
                "science": "0",
                "total": "217"
            }
        ])
    }, []);

    const getFranchiseDetailsById = async (id) => {
        try {
            setLoading(true);
            const resp = await getFranchiseById(id);
            if(resp.success === true){
                setLoading(false);
                setFranchiseData(resp.collections);
                if(resp?.collections?.franchisee !== null){
                    setSelectedUsers(resp?.collections?.franchisee.map.call(resp?.collections?.franchisee, function(user) { return user.first_name +' '+user.last_name; }).join(", "))
                }
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
                navigate('/franchise');
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const columns = [
        {
            name: '#Id',
            selector: (row: { id: string; }) => `#${row.id}`,
            sortable: true,
            sortField: 'id',
            width: '8%',
            wrap: true
        },
        {
            name: 'Franchise',
            selector: (row: { franchise_name: string; }) => row.franchise_name,
            sortable: true,
            sortField: 'franchise_name',
        },
        {
            name: 'Sets Qty',
            selector: (row: { qty_sets: string; }) => row.qty_sets,
            sortable: true,
            sortField: 'qty_sets',
        },
        {
            name: 'Handbooks Qty',
            selector: (row: { qty_handbooks: string; }) => row.qty_handbooks,
            sortable: true,
            sortField: 'qty_handbooks',
        },
        {
            name: 'Total Amount',
            selector: (row: { total_price: string; }) => row.total_price,
            format: (row: { total_price: string, pcharge_total:string }) => <CurrencyFormat value={ (parseFloat(row.total_price) + parseFloat(row.pcharge_total)).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} />,
            sortable: true,
            sortField: 'total_price',
        },
        {
            name: 'Order Date',
            selector: (row: { created_at: string; }) => row.created_at,
            cell: (row: { created_at: string; }) => (
                row.created_at ? moment(row.created_at).format('DD/MM/YYYY') : '---'
            ),
            // sortable: true,
            sortField: 'created_at',
            // wrap: true
        },
        {
            name: 'Status',
            selector: (row: { order_status: string; }) => row.order_status,
            cell: (row: { id: number, order_status: string, franchise_name: string; }) => (
                <a href='#' data-toggle="tooltip">
                    { row.order_status !== null ? (row.order_status === 'dispatched' ? <span className="badge badge-success">{ row.order_status.charAt(0).toUpperCase() + row.order_status.slice(1)}</span> : <span className="badge badge-danger">{ row.order_status.charAt(0).toUpperCase() + row.order_status.slice(1) } </span>) : '---'}
                </a>
            ),
            sortable: true,
            sortField: 'order_status',
        },
    ];

    const studCountcolumns = [
        {
            name: 'Franchise',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            sortField: 'franchise_id',
            wrap: true,
        },
        {
            name: 'Month',
            selector: (row: { month: any; }) => { return (months.find(obj => +row.month === obj.id))?.name ?? '---' },
            sortable: true,
            sortField: 'month',
        },
        {
            name: 'Year',
            selector: (row: { year: string; }) => row.year,
            sortable: true,
            sortField: 'year',
        },
        {
            name: 'Mathematics',
            selector: (row: { maths: string; }) => row.maths != '0' ? row.maths : '---',
            // sortable: true,
            // sortField: 'maths',
            wrap: true,
        },
        {
            name: 'English',
            selector: (row: { english: string; }) => row.english != '0' ? row.english : '---',
            // sortable: true,
            // sortField: 'english',
            wrap: true,
        },
        {
            name: 'Science',
            selector: (row: { science: string; }) => row.science != '0' ? row.science : '---',
            // sortable: true,
            // sortField: 'science',
            wrap: true,
        },
        {
            name: 'Total',
            selector: (row: { total: string; }) => row.total ? row.total : '---',
            // sortable: true,
            // sortField: 'total',
            wrap: true,
        },
    ];

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

  return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title"> Franchise Details</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Franchise Name </label>
                                            <Form.Control plaintext readOnly value={franchise.name} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Franchise Type </label>
                                            <Form.Control plaintext readOnly value={franchise?.type ? (franchiseTypes.find(obj => { return obj.value === franchise?.type}))?.label : '---'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Address </label>
                                            <dd>{franchise.address ? franchise.address : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Franchisee(s) </label>
                                            <Form.Control plaintext readOnly value={selectedUsers ? selectedUsers : '---'} />   
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Kashflow Licence Account </label>
                                            <Form.Control plaintext readOnly value={franchise.kashflow_license_account ? franchise.kashflow_license_account : '---'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Kashflow Order Account </label>
                                            <Form.Control plaintext readOnly value={franchise.kashflow_order_account ? franchise.kashflow_order_account : '---'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Start Date </label>
                                            <Form.Control plaintext readOnly value={franchise?.start_date ? moment(franchise.start_date).format('DD/MM/YYYY') : '---'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">End Date </label>
                                            <Form.Control plaintext readOnly value={franchise?.end_date ? moment(franchise.end_date).format('DD/MM/YYYY') : '---'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Effective Start Date </label>
                                            <Form.Control plaintext readOnly value={franchise?.effective_start_date ? moment(franchise.effective_start_date).format('DD/MM/YYYY') : '---'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">VAT </label>
                                            <Form.Control plaintext readOnly value={franchise.vat ? franchise.vat : '---'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Phone </label>
                                            <Form.Control plaintext readOnly value={franchise.phone ? franchise.phone : '---'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Status </label>
                                            <Form.Control plaintext readOnly value={franchise?.status ? (franchiseStatus.find(obj => { return obj.value === franchise?.status}))?.label : '---'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Reason </label>
                                            <dd>{franchise.reason ? franchise.reason : '---'}</dd>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card-footer">
                                <Link className="btn btn-primary mr-2" to={`/franchise/edit/${id}`}>Edit</Link>
                                <Link className="btn btn-default" to='/franchise'>Close</Link>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Franchise Pricing Plan & Shipping Addresses</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className='col-md-12'>
                                        <h5>Pricing</h5>
                                    </div>
                                    <div className="col-md-12">
                                        {subjectWisePrices?.length > 0 && (
                                            <table className="table table-bordered" id="client_id_table">
                                                <tbody>
                                                    <tr className='text-center'>
                                                        <th>Subject</th>
                                                        { tiers.map((tier) => (
                                                            <th key={tier?.id}>{ tier?.name } (Range { tier?.from_count } - { tier?.to_count })</th>
                                                        ))}
                                                    </tr>
                                                    {subjectWisePrices?.length > 0  && subjectWisePrices.map((subject) => (
                                                        <tr key={subject.id}>
                                                            <td>{ subject.name }</td>
                                                            {(subject?.tiers).length > 0 && (subject?.tiers).map((tier) => (
                                                                <td key={tier.id}>£ { tier.price }</td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                        {subjectWisePrices?.length == 0 && (
                                            <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                                        )}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12 mt-3'>
                                        <h5>Shipping Addresses</h5>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Address 1 </label>
                                            <Form.Control plaintext readOnly value={'B1 to B4, Ghanshwam Bhavan, Mandore Road , Above State Bank of Bikaner & Jaipur, Paota'} />
                                            <Form.Control plaintext readOnly value={'9921819186'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Address 2 </label>
                                            <Form.Control plaintext readOnly value={'B1 to B4, Ghanshwam Bhavan, Mandore Road , Above State Bank of Bikaner & Jaipur, Paota'} />
                                            <Form.Control plaintext readOnly value={'9921819186'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Address 3 </label>
                                            <Form.Control plaintext readOnly value={'B1 to B4, Ghanshwam Bhavan, Mandore Road , Above State Bank of Bikaner & Jaipur, Paota'} />
                                            <Form.Control plaintext readOnly value={'9921819186'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card-footer">
                                <Link className="btn btn-primary mr-2" to={`/franchise/edit/${id}`}>Edit</Link>
                                <Link className="btn btn-default" to='/pricing-plans'>Close</Link>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title"> Franchise Centers, Subjects and Postcodes</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label htmlFor="exampleInputEmail1">Centers </label>
                                        {subjects.map((subject, index) => (
                                            <Link to='/'><dd className='mr-4'>{subject.name ? subject.name : '---'}</dd></Link>
                                        ))}
                                    </div>
                                    <div className="col-sm-4">
                                        <label htmlFor="exampleInputEmail1">Subjects </label>
                                        {subjects.map((subject, index) => (
                                            <Link to='/'><dd className='mr-4'>{subject.name ? subject.name : '---'}</dd></Link>
                                        ))}
                                    </div>
                                    <div className="col-sm-4">
                                        <label className='mr-4' htmlFor="exampleInputEmail1">Postcodes </label>
                                        {subjects.map((subject, index) => (
                                            <Link to='/'><dd className='mr-4'>{subject.name ? subject.name : '---'}</dd></Link>
                                        ))}
                                    </div>    
                                </div>
                            </div>
                            {/* <div className="card-footer">
                                <Link className="btn btn-primary mr-2" to={`/franchise/edit/${id}`}>Edit</Link>
                                <Link className="btn btn-default" to='/franchise'>Close</Link>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Student Current Month Count</h3>
                            </div>
                            <div className="card-body">
                                <DataTable
                                    columns={studCountcolumns}
                                    data={studentCounts}
                                    progressPending={isLoading}
                                    sortServer
                                    customStyles={customStyle}
                                    // conditionalRowStyles={conditionalRowStyles}
                                    highlightOnHover
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Recent Orders</h3>
                                {/* <div className=" text-right">
                                    { canAccess?.place &&
                                        <Link className="btn btn-primary" to="/orders/place" > + Place New Order </Link>
                                    }
                                </div> */}
                            </div>
                            {/* /.card-header */}
                            <div className="card-body">
                                <DataTable
                                    columns={columns}
                                    data={orders}
                                    progressPending={isLoading}
                                    sortServer
                                    customStyles={customStyle}
                                    // conditionalRowStyles={conditionalRowStyles}
                                    highlightOnHover
                                />
                            </div>
                            {/* /.card-body */}
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    </div>
  )
}

export default FranchiseDashboard;