import axios from "axios";
import { addGuardianURL,getAllGuardianURL,getParentByIdURL,updateParentURL } from "@app/constants/ApiEndPoints";

export const addGuardianService = async (payload) => {
	try {
		const response = await axios.post(addGuardianURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};


export const getAllActiveGuardianService = async (payload) => {
	try {
		const response = await axios.post(getAllGuardianURL, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
}

export const editGuardianService = async (id, payload) => {
	try {
		const response = await axios.put(updateParentURL + id, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getParentById = async (id: number) => {
	try {
		const response = await axios.get(getParentByIdURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};
