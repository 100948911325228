import { settingPatternURL } from "@app/constants/ApiEndPoints";
import axios from "axios";

export const getSettingPatternWithPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(settingPatternURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&name=${search.name}&status=${search.status}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const createSettingPattern = async (payload) => {
	try {
		const response = await axios.post(settingPatternURL + '/create', payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getSettingPatternById = async (id: number) => {
	try {
		const response = await axios.get(settingPatternURL + '/read/' + id );
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateSettingPattern = async (id, payload) => {
	try {
		const response = await axios.put(settingPatternURL + '/update/' + id, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getSettingPatternByStudentId = async (studId: number) => {
	try {
		const response = await axios.get(settingPatternURL + '/student/' + studId );
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllActiveSettingPatterns = async () => {
	try {
		const response = await axios.get(settingPatternURL + '/all');
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};