import React, { useState, useEffect } from 'react'
import { ErrorMessage, Field, FieldArray, FormikProvider, useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import { Form } from 'react-bootstrap';
import { createPricingPlan } from '@app/services/pricingPlans';
import moment from 'moment';

const AddPlan = () => {

    const franchiseStatus = [{id:'active', name:'Active'}, {id:'inactive', name:'Inactive'}];
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const emptyTier = {name: '', from_count:'', to_count:'', alert_threshold:''}

    const addPlan = async (payload) => {
        try {
            setLoading(true);
            const resp = await createPricingPlan(payload);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                navigate('/pricing-plans');
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
          }
    };
    
    //{ handleChange, values, handleSubmit, touched, errors, setFieldValue }
    const formik = useFormik({
        initialValues: {
            name: '',
            is_default: false,
            start_date: '',
            end_date: '',
            status: '',
            tiers: [emptyTier]
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter plan name'),
            start_date: Yup.string().required('Please enter start date'),
            // end_date: Yup.string().required('Please enter end date'),
            end_date: Yup.date().required('Please enter end date'),
            // .max(new Date(moment(state.start_date).format('YYYY-MM-DD')), "End date can't be before start date"),
            status: Yup.string().required('Please select status'),
            tiers: Yup.array().of(Yup.object({
                name: Yup.string().required('Enter tier name'),
                from_count: Yup.number().integer().required('Enter from count'),
                to_count: Yup.number().required('Enter to count')
                .moreThan(Yup.ref("from_count"), 'Range to must be greater than range from'),
                alert_threshold: Yup.number().required('Enter alert threshold')
                .moreThan(Yup.ref("from_count"), 'Alert threshold must be greater than range from')
                .lessThan(Yup.ref("to_count"), 'Alert threshold must be less than range to'),
            }))
        }),
        onSubmit: (values) => {
            // console.log(values)
            addPlan(values);
        }
    });

    const OnWheelPreventChange = (e) => {
		e.currentTarget.blur();
	} 

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Add Plan</h3>
                            </div>
                            <FormikProvider value={formik}>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Plan Name <span className="required">*</span></label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter plan name" 
                                                    name="name" 
                                                    onChange={formik.handleChange} 
                                                    value={formik.values.name}
                                                />
                                                {(formik.touched.name && formik.errors.name) && (
                                                    <div className="text-danger">
                                                        {formik.errors.name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Status <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={ formik.handleChange }
                                                    value={formik.values.status}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {franchiseStatus.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(formik.touched.status && formik.errors.status) && (
                                                    <div className="text-danger">
                                                        {formik.errors.status}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="franchise_id"><b> Do you want to make this a default plan? </b></label>
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox"
                                                        id="is_default"  
                                                        name="is_default" 
                                                        className="form-check-input" 
                                                        onChange={(e) => { 
                                                            formik.handleChange 
                                                            formik.setFieldValue("is_default", e.target.checked)
                                                        }}
                                                    />
                                                    <label htmlFor="is_default" className="form-check-label"></label>
                                                </div>
                                                {(formik.touched.is_default && formik.errors.is_default) && (
                                                    <div className="text-danger">
                                                        {formik.errors.is_default}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Start Date <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="date" 
                                                    name="start_date" 
                                                    id="start_date" 
                                                    placeholder="Enter start date" 
                                                    value={formik.values.start_date}
                                                    onChange={formik.handleChange}
                                                />
                                                {(formik.touched.start_date && formik.errors.start_date) && (
                                                    <div className="text-danger">
                                                        {formik.errors.start_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">End Date <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="date" 
                                                    name="end_date" 
                                                    id="end_date" 
                                                    placeholder="Enter end date" 
                                                    value={formik.values.end_date}
                                                    onChange={formik.handleChange}
                                                    min={moment(formik.values.start_date ? formik.values.start_date : new Date()).format('YYYY-MM-DD')}
                                                />
                                                {(formik.touched.end_date && formik.errors.end_date) && (
                                                    <div className="text-danger">
                                                        {formik.errors.end_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <FieldArray name="tiers">
                                        {({ push, remove }) => (
                                            <>
                                            <div className='row'>
                                                <div className="col-sm-12">
                                                    <h6 className='mt-2 grey_wrapper_title'>Add Tiers</h6>
                                                </div>
                                            </div>
                                            {formik.values.tiers.map((tier, index) => (
                                                <div className="row" key={index}>
                                                    <div className="col-sm-2">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">Tier Name <span className="required">*</span></label>
                                                            <Field type="text" id="exampleInputEmail1" className="form-control" placeholder="Enter tier name" name={`tiers.${index}.name`}/>
                                                            <div className="text-danger">
                                                                <ErrorMessage name={`tiers.${index}.name`} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">Range From <span className="required">*</span></label>
                                                            <Field type="number" id="exampleInputEmail1" className="form-control" placeholder="Enter range from" name={`tiers.${index}.from_count`} onWheel={ (e) => OnWheelPreventChange(e) }/>
                                                            <div className="text-danger">
                                                                <ErrorMessage name={`tiers.${index}.from_count`} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">Range To <span className="required">*</span></label>
                                                            <Field type="number" id="exampleInputEmail1" className="form-control" placeholder="Enter range to" name={`tiers.${index}.to_count`} onWheel={ (e) => OnWheelPreventChange(e) }/>
                                                            <div className="text-danger">
                                                                <ErrorMessage name={`tiers.${index}.to_count`} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">Alert Threshold <span className="required">*</span></label>
                                                            <Field type="number" id="exampleInputEmail1" className="form-control" placeholder="Enter alert threshold" name={`tiers.${index}.alert_threshold`} onWheel={ (e) => OnWheelPreventChange(e) }/>
                                                            <div className="text-danger">
                                                                <ErrorMessage name={`tiers.${index}.alert_threshold`} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2 d-flex align-items-center form_wrapper">
                                                        <i className="fas fa-times-circle" onClick={() => remove(index)} style={{cursor:'pointer'}}></i>
                                                    </div>
                                                    {/* <pre>{ JSON.stringify(formik.errors) }</pre> */}
                                                </div>
                                            ))}
                                                <i className="cursor-pointer fas fa-plus-circle" onClick={() => push(emptyTier)} style={{cursor:'pointer'}}></i>
                                            </>
                                        )}
                                    </FieldArray>
                                </div>
                                <div className="card-footer">
                                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Add </Button>
                                    <Link className="btn btn-default" to='/pricing-plans'>Close</Link>
                                </div>
                            </form>
                            </FormikProvider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default AddPlan;