import "../../pages/order-form/OrderForm.css";
import { Tabs, Tab, Form, Col, Row, Popover, OverlayTrigger } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { getAllActiveModulesBySubject, getAllActiveSetsBlocksAndHandbooksByModule } from "@app/services/module";
import { getAllActiveItems } from "@app/services/otherItem";
import { Button } from "@app/components";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { deleteBlock, deleteHandbook, deleteHBSupp, deleteModuleAllSets, deleteOtherItem, deleteSet, loadCart, setStep, setSynch, updateBlockQty, updateDefaultOrderLoadFlag, updateHandbookQty, updateHBSuppQty, updateModuleQty, updateOtherItemQty, updateSetQty } from "@app/store/reducers/order";
import { addItemsToCart, clearCartByFranchiseId, deleteCartItemById, getCartInvoiceByFranchise, getCartItemsByFranchise, loadDefaultOrder, updateCart } from "@app/services/order";
import { confirmAlert } from "react-confirm-alert";
import CurrencyFormat from 'react-currency-format';
import { getSubjectsByFranchise } from "@app/services/franchise";

const OrderFormStep2 = () => {

	const [subjects, setSubjects] = useState([]);
	const [modules, setModules] = useState([]);
	const [blocks, setBlocks] = useState([]);
	const [sets, setSets] = useState([]);
	const [handbooks, setHandbooks] = useState([]);
	const [handbookWithQty, setHandbookWithQty] = useState([]);
	const [HBSupplements, setHBSupplements] = useState([]);
	const [HBSuppWithQty, setHBSuppWithQty] = useState([]);
	const [setWithQty, setSetWithQty] = useState([]);
	const [blockWithQty, setBlockWithQty] = useState([]);
	const [itemWithQty, setItemWithQty] = useState([]);
	const [otherItems, setOtherItems] = useState([]);
	const [isSubjectsLoading, setSubjectsLoading] = useState(false);
    const [isModulesLoading, setModulesLoading] = useState(false);
    const [isCartLoading, setCartLoading] = useState(false);
    const [orderLimitExceeded, setOrderLimitExceeded] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
	const [tierLimit, setTierLimit] = useState(0);
	const [payableAmt, setPayableAmt] = useState(0);
	const [invoiceTotal, setInvoiceTotal] = useState({net: 0, payable: 0, vat: 0, pcharge_net: 0});
    const [activeTab, setActiveTab] = useState('Module');
    const orderStore = useSelector((state: any) => state.order);
    const dispatch = useDispatch();
	const formRef = useRef(null)
	const moduleFormRef = useRef(null)

	useEffect(() => {
		getAllActiveSubjectsData();
		getAllActiveOtherItemsData();
		getCartItemsByFranchiseData(orderStore?.franchise?.id);
		getCartInvoiceDataByFranchise(orderStore?.franchise?.id);
    },[]);

    const getAllActiveSubjectsData = async () => {
        try {
			setSubjectsLoading(true);
            const resp = await getSubjectsByFranchise(orderStore?.franchise?.id);
            if (resp.success === true) {
				setSubjectsLoading(false);
				if(resp.collections){
					(resp.collections).map((subject)=> {
						subject.isDisabled = subject.status == 'active' ? false : true;
					})
					setSubjects(resp.collections);
				}
            } else {
				setSubjectsLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
			setSubjectsLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

	const getAllActiveModulesBySubjectData = async (subjectId) => {
		try {
			setModulesLoading(true);
            const resp = await getAllActiveModulesBySubject(subjectId);
            if (resp.success === true) {
				setModulesLoading(false);
                setModules(resp.collections);
            } else {
				setModulesLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
			setModulesLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
	}

	const getAllActiveSetsBlocksAndHandbooksByModuleData = async (subjectId) => {
		try {
			const resp = await getAllActiveSetsBlocksAndHandbooksByModule(subjectId);
            if (resp.success === true) {
				if((resp?.collections?.sets).length > 0){
					setSets(resp?.collections?.sets)
				}
				if((resp?.collections?.blocks).length > 0){
					setBlocks(resp?.collections?.blocks)
				}
				setHandbooks(resp?.collections?.handbooks)
				setHBSupplements(resp?.collections?.handbook_supplements)
            } else {
			    toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
			toast.error(error?.response?.message || 'Failed');
        }
	}

	const getAllActiveOtherItemsData = async () => {
        try {
            const resp = await getAllActiveItems();
            if (resp.success === true) {
				setOtherItems(resp.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

	const getCartItemsByFranchiseData = async (franchiseId) => {
        try {
            setCartLoading(true);
            const resp = await getCartItemsByFranchise(franchiseId);
            if (resp?.success === true) {
				setCartLoading(false);
				dispatch(loadCart(resp?.collections));
				if((resp?.collections?.tier && resp.collections?.total_sets >= resp?.collections?.tier.alert_threshold) && !orderLimitExceeded){
					const diff = (resp?.collections?.tier.to_count + 1) - resp.collections?.total_sets 
					toast.info(`Adding ${ diff } more sets would reduce the overall cost of your order!`)
				}
            } else {
				setCartLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setCartLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

	const getCartInvoiceDataByFranchise = async (franchiseId) => {
        try {
            setCartLoading(true);
            const resp = await getCartInvoiceByFranchise(franchiseId);
            if (resp?.success === true) {
				setCartLoading(false);
				setPayableAmt(resp?.collections?.total?.payable);
				setInvoiceTotal(resp?.collections?.total);
				setOrderLimitExceeded(false);
				setBtnDisabled(false);
				// toast.success('Total payable amount synched successfully');
            } else {
				setCartLoading(false);
                toast.error(resp?.message || 'Failed');
				setTierLimit(resp?.collections?.to_count)
				setOrderLimitExceeded(true);
				setBtnDisabled(true);
            }
        } catch (error: any) {
            setCartLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

	const addItemsToBasket = async (payload) => {
        try {
            setCartLoading(true);
            const resp = await addItemsToCart(payload);
            if(resp?.success === true){
                toast.success(resp?.message);
                setCartLoading(false);
				
				// Modules form
				setSets([]);
				setBlocks([]);
				setHandbooks([]);
				setHBSupplements([]);
				setSetWithQty([]);
				setBlockWithQty([]);
				setHandbookWithQty([])
				setHBSuppWithQty([])
				// setModules([]);
				
				// Other Items form
				formRef.current.reset();
				setItemWithQty([])
				
				getCartItemsByFranchiseData(orderStore?.franchise?.id);
				getCartInvoiceDataByFranchise(orderStore?.franchise?.id);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setCartLoading(false);
            }
          } catch (error: any) {
            setCartLoading(false);
            toast.error(error?.response?.message || 'Failed');
          }
    };

	const updateBasket = async (payload, proceedFlag) => {
        try {
            setCartLoading(true);
            const resp = await updateCart(orderStore?.franchise?.id, payload);
            if(resp?.success === true){
                setCartLoading(false);
				if(proceedFlag == true){
					dispatch(setStep(3));
					toast.success(resp?.message);
				}else{
					getCartItemsByFranchiseData(orderStore?.franchise?.id);
					getCartInvoiceDataByFranchise(orderStore?.franchise?.id);
					dispatch(setSynch(false));
				}
            }else{
                toast.error(resp?.errors[0]?.msg);
                setCartLoading(false);
            }
          } catch (error: any) {
            setCartLoading(false);
            toast.error(error?.response?.message || 'Failed');
          }
    };

	const clearCartConfirm = (e) => {
        confirmAlert({
            title: 'Please confirm!',
            message: 'Do you want to clear the basket?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => clearBasket()
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const clearBasket = async () => {
        try {
            setCartLoading(true);
            const resp = await clearCartByFranchiseId(orderStore?.franchise?.id);
            if(resp.success === true){
                setCartLoading(false);
                toast.success(resp?.message);
                dispatch(loadCart([]));
                setPayableAmt(0);
				setInvoiceTotal({net: 0, payable: 0, vat: 0, pcharge_net: 0})
				dispatch(updateDefaultOrderLoadFlag({isDefaultOrderLoad: false}))
            }else{
                toast.error(resp?.errors[0]?.msg);
                setCartLoading(false);
            }
          } catch (error: any) {
            setCartLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

	const onSubjectChange = (option, setFieldValue) => {
		setSets([]);
		setBlocks([]);
		if(option){
			setFieldValue("subject_id", option.subject_id)
			getAllActiveModulesBySubjectData(option.subject_id)
		}else{
			setFieldValue("subject_id", '')
			setFieldValue("module_id", '')
			setModules([]);
		}
	}

	const onModuleChange = (option) => {
		setSetWithQty([]);
		setBlockWithQty([]);
		setHandbookWithQty([]);
		setHBSuppWithQty([]);
		values.module_qty = '';
		if(option){
			setFieldValue("module_id", option.id)
			getAllActiveSetsBlocksAndHandbooksByModuleData(option.id)
		}else{
			setFieldValue("module_id", "")
			setSets([]);
			setBlocks([]);
			setHandbooks([])
			setHBSupplements([])
		}
	}

	const initialValues = {
		subject_id: "",
		module_id: "",
		module_qty: "",
		sets: "",
		blocks: "",
	}
	const { handleChange, values, handleSubmit, touched, errors, setFieldValue, resetForm} = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            subject_id: Yup.string().required('Please select subject').nullable(),
            module_id: Yup.string().required('Please select module').nullable(),
            module_qty: Yup.number().positive('Quantity must be a positive number').integer('Quantity must be an integer'),
        }),
        onSubmit: (values) => {

			if(handbookWithQty.length === 0 && HBSuppWithQty.length === 0 && setWithQty.length === 0 && blockWithQty.length === 0 && values.module_qty === ''){
				toast.error('Please enter a quantity');
				return;
			}

			const payload = {
				franchise_id: orderStore.franchise.id,
				subject_id: values.subject_id,
				module_id: values.module_id,
				module_qty: values.module_qty,
				centre_id: null,
				handbooks: handbookWithQty,
				handbook_supplements: HBSuppWithQty,
				sets: setWithQty,
				blocks: blockWithQty,
			}
			addItemsToBasket(payload);
			
			// Reset form
			resetForm();
			setFieldValue("subject_id", values.subject_id)
        },
		enableReinitialize: true,
    });

	const handleBack = () => {
		dispatch(setStep(1));
	}

	const handleSetQtyChange = (set, event) => {
		let data = [...setWithQty];
		let updatedSets = [...sets];
		const index = data.findIndex(
			(obj) => obj.id === set.id
		);
		const index2 = updatedSets.findIndex(
			(obj) => obj.id === set.id
		);
		if(event.target.value <= 0){
			// toast.error('Quantity must be a positive number');
			if(index !== -1){
				const newData = data.filter(
					(obj) => obj.id !== set.id
				);
				setSetWithQty(newData);
			}
			updatedSets[index2].qty = '';	
		}else{
			if(set.selected){
				updatedSets.map((item, i) => {
					if(item.selected){
						const index = data.findIndex(
							(obj) => obj.id === item.id
						);
						if(index !== -1){
							data[index].qty = event.target.value;
							updatedSets[i].qty =  event.target.value;
						}else{
							data.push({id: item.id, qty:event.target.value});
							updatedSets[i].qty =  event.target.value;
						}	
					}
				})
			}else{
				if(index !== -1){
					data[index].qty = event.target.value 
				}else{
					data.push({id: set.id, qty:event.target.value});
				}	
				index2 !== -1 ? updatedSets[index2].qty =  event.target.value : '';
			}
			setSetWithQty(data);
			setSets(updatedSets);
		}
	}

	const handleBlockQtyChange = (block, event) => {
		let data = [...blockWithQty];
		let updatedBlocks = [...blocks];
		const index = data.findIndex(
			(obj) => obj.id === block.id
		);
		const index2 = updatedBlocks.findIndex(
			(obj) => obj.id === block.id
		);
		if(event.target.value <= 0){
			// toast.error('Quantity must be a positive number');
			if(index !== -1){
				const newData = data.filter(
					(obj) => obj.id !== block.id
				);
				setBlockWithQty(newData);
			}	
			updatedBlocks[index2].qty = '';
		}else{
			if(block.selected){
				updatedBlocks.map((item, i) => {
					if(item.selected){
						const index = data.findIndex(
							(obj) => obj.id === item.id
						);
						if(index !== -1){
							data[index].qty = event.target.value;
							updatedBlocks[i].qty =  event.target.value;
						}else{
							data.push({id: item.id, qty:event.target.value});
							updatedBlocks[i].qty =  event.target.value;
						}	
					}
				})
			}else{
				if(index !== -1){
					data[index].qty = event.target.value 
				}else{
					data.push({id: block.id, qty:event.target.value});
				}	
				index2 !== -1 ? updatedBlocks[index2].qty =  event.target.value : '';
			}
			setBlockWithQty(data);
			setBlocks(updatedBlocks);
		}
	}
	
	const handleOtherItemQtyChange = (item_id, event) => {
		let data = [...itemWithQty];
		const index = data.findIndex(
			(item) => item.id === item_id
		);
		if(event.target.value <= 0){
			// toast.error('Quantity must be a positive number');
			if(index !== -1){
				const newData = data.filter(
					(item) => item.id !== item_id
				);
				setItemWithQty(newData);
			}	
		}else{
			if(index !== -1){
				data[index].qty = event.target.value 
			}else{
				data.push({id: item_id, qty:event.target.value});
			}	
			setItemWithQty(data);
		}
	}

	const handleHBQtyChange = (handbook, event) => {
		let data = [...handbookWithQty];
		const index = data.findIndex(
			(item) => item.combined_id === handbook?.combined_id
		);
		if(event.target.value <= 0){
			if(index !== -1){
				const newData = data.filter(
					(item) => item.combined_id !== handbook?.combined_id
				);
				setHandbookWithQty(newData);
			}	
		}else{
			if(index !== -1){
				data[index].qty = event.target.value 
			}else{
				data.push({id: handbook?.id, combined_id: handbook?.combined_id, qty: event.target.value});
			}	
			setHandbookWithQty(data);
		}
	}

	const handleHBSuppQtyChange = (handbook, event) => {
		let data = [...HBSuppWithQty];
		const index = data.findIndex(
			(item) => item.id === handbook?.id
		);
		if(event.target.value <= 0){
			if(index !== -1){
				const newData = data.filter(
					(item) => item.id !== handbook?.id
				);
				setHBSuppWithQty(newData);
			}	
		}else{
			if(index !== -1){
				data[index].qty = event.target.value 
			}else{
				data.push({id: handbook?.id, qty: event.target.value});
			}	
			setHBSuppWithQty(data);
		}
	}

	const handleOtherItemSubmit = () => {
		if(itemWithQty.length === 0){
			toast.error('Please enter a positive number quantity');
			return
		}
		const payload = {
			franchise_id: orderStore.franchise.id,
			centre_id: null,
			other_items: itemWithQty,

			subject_id: "",
			module_id: "",
			module_qty: "",
			handbooks: [],
			handbook_supplements: [],
			sets: [],
			blocks: [],
		}
		addItemsToBasket(payload);
	}

	const handleProceed = () => {
		updateBasket(orderStore.cart, true);
	}

	const OnWheelPreventChange = (e) => {
		e.currentTarget.blur();
	} 

	const deleteCartItem = async (id) => {
        try {
			setCartLoading(true);
			const resp = await deleteCartItemById(id);
			if(resp.success === true){
				setCartLoading(false);
				toast.success(resp?.message);
				getCartItemsByFranchiseData(orderStore?.franchise?.id);
				getCartInvoiceDataByFranchise(orderStore?.franchise?.id);
				return resp?.success;
			}else{
				setCartLoading(false);
				toast.error(resp?.errors[0]?.msg);
				return resp?.success;
			}
			} catch (error: any) {
				setCartLoading(false);
				toast.error(error?.response?.message || 'Failed');
				return false;
			}
    };

	const loadDefaultOrderData = async (franchiseId) => {
        try {
            setCartLoading(true);
            const resp = await loadDefaultOrder(franchiseId);
            if (resp?.success === true) {
                setCartLoading(false);
				dispatch(updateDefaultOrderLoadFlag({isDefaultOrderLoad: true}))
                getCartItemsByFranchiseData(franchiseId);
				getCartInvoiceDataByFranchise(franchiseId);
            } else {
                setCartLoading(false);
                toast.error(resp?.message);
            }
        } catch (error: any) {
            setCartLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

	const handleLoadDefaultOrder = () => {
		loadDefaultOrderData(orderStore?.franchise?.id);
	};

	const handleSynchPayable = () => {
		updateBasket(orderStore.cart, false);
	}

	const handleMultiSelectBlocks = (e, block) => {
		if(e.ctrlKey || e.metaKey){
			let updatedBlocks = [...blocks];
			const index = updatedBlocks.findIndex(
				(item) => item.id === block.id
			);
			updatedBlocks[index].selected = !updatedBlocks[index].selected;
			setBlocks(updatedBlocks);
		}else{
			let updatedBlocks = [...blocks];
			setBlocks(
				updatedBlocks.map((item) => {
				  	return { ...item, selected: false };
				})
			);
		}
	}

	const handleMultiSelectSets = (e, set) => {
		let updatedSets = [...sets];
		if(e.ctrlKey || e.metaKey){
			const index = updatedSets.findIndex(
				(item) => item.id === set.id
			);
			updatedSets[index].selected = !updatedSets[index].selected;
			setSets(updatedSets);
		}else{
			setSets(
				updatedSets.map((item) => {
				  	return { ...item, selected: false };
				})
			);
		}
	}

	const popover = (
        <Popover id="popover-basic">
            <div className='m-2'>
                {/* The module name should be in the format of: <b>E-M-HW1</b><br/> */}
                <b>Net:</b> <CurrencyFormat value={ invoiceTotal?.net - invoiceTotal?.pcharge_net } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /><br/>
                <b>Processing Charge:</b> <CurrencyFormat value={ invoiceTotal?.pcharge_net } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /><br/>
                <b>Vat:</b> <CurrencyFormat value={ invoiceTotal?.vat } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /><br/>
                <b>Payable:</b> <CurrencyFormat value={ invoiceTotal?.payable } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /><br/>
                 
            </div>
        </Popover>
    );

	const handleUpdateQty = async(actionType, payload) => {
		if(parseInt(payload.qty) === 0) {
			payload.qty = 1;
			// await deleteCartItem(payload?.cart_id) ? dispatch(deleteModuleAllSets({subject:payload.subject, module_id:payload.module_id})) : ''
			setBtnDisabled(false)
		}else {
			if(payload.qty === ''){
				setBtnDisabled(true)
			}else{
				payload.qty =  Math.abs(payload.qty)
				setBtnDisabled(false)
			}			
		}

		switch(actionType){
			case 'module' : dispatch(updateModuleQty(payload));
							break;
			case 'handbook' : dispatch(updateHandbookQty(payload));
							break;
			case 'handbook-supp' : dispatch(updateHBSuppQty(payload));
							break;
			case 'set' : dispatch(updateSetQty(payload));
							break;
			case 'block' : dispatch(updateBlockQty(payload));
							break;
			case 'other-item' : dispatch(updateOtherItemQty(payload))
							break;
		}
	}

	return (
		<>
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-12 col-lg-6 col-xl-6">
					<div className="tabs_wrapper">
						<Button type="button" isLoading={isCartLoading} onClick={ () => handleLoadDefaultOrder() } className="btn btn-info" disabled={ orderStore?.isDefaultOrderLoad }>Load Default Order </Button>
						<Tabs defaultActiveKey="Module" id="uncontrolled-tab-example" onSelect={ (e) => setActiveTab(e) }>
							<Tab eventKey="Module" title="Module">
								<div className="content">
									<Form ref={moduleFormRef} onSubmit={handleSubmit}>
										<div className="d-flex justify-content-end">
											<Button type="submit" isLoading={isCartLoading} className="btn btn-primary mr-2">Add To Basket </Button>
											<button type="button" onClick={ handleBack } className="btn btn-default"> Back </button>
										</div>
										<Row>
											<Col xl={6}>
												<Form.Group as={Row} className="mb-3">
													<Form.Label column xs="12">
														<b>Subject:</b>
													</Form.Label>
													<Col xs="12">
														<Select
															// className="form-control"
															name="subject_id"
															placeholder="Please Select Subject"
															options={subjects}
															getOptionValue={(option)=>`${option['subject_id']}`}
															getOptionLabel={(option)=>`${option['name']}`}
															onChange={ (option) => {  
																handleChange
																onSubjectChange(option, setFieldValue) 
															}}
															defaultValue={subjects[0]}
															// value={values.subject_id}
															value={subjects.filter(function(option) {
																return option.subject_id === values.subject_id;
															})}
															isLoading={isSubjectsLoading}
															isClearable={true}
														/>
														{(touched.subject_id && errors.subject_id) && (
															<div className="text-danger">
																{errors.subject_id}
															</div>
														)}
													</Col>
												</Form.Group>
											</Col>
											<Col xl={6}>
												<Form.Group as={Row} className="mb-3">
													<Form.Label column xs="12">
														<b>Module:</b>
													</Form.Label>
													<Col xs="12">
														<Select
															// className="form-control"
															name="module_id"
															placeholder="Please Select Module"
															options={modules}
															getOptionValue={(option)=>`${option['id']}`}
															getOptionLabel={(option)=>`${option['name']}`}
															onChange={ (option) => { 
																handleChange
																onModuleChange(option) 
															}}
															// value={values.module_id}
															value={modules.filter(function(option) {
																return option.id === values.module_id;
															})}
															isLoading={isModulesLoading}
															isClearable={true}
														/>
														{(touched.module_id && errors.module_id) && (
															<div className="text-danger">
																{errors.module_id}
															</div>
														)}
													</Col>
												</Form.Group>
											</Col>
											<Col xl={12}>
												<Form.Group as={Row} className="mb-3">
													<Form.Label column xs="8" sm="6">
														<div className="d-flex">
															{/* <Form.Check type="checkbox" />  */}
															Order Entire Module
														</div>
													</Form.Label>
													<Col xs="4" sm="6">
														<Form.Control
															id="module_qty"
															name="module_qty"
															type="number"
															className="quantity_input"
															placeholder="Quantity"
															onChange={handleChange}
															value={values.module_qty}
															onWheel={ (e) => OnWheelPreventChange(e) } 
															min="1"
														/>
														{(touched.module_qty && errors.module_qty) && (
															<div className="text-danger">
																{errors.module_qty}
															</div>
														)}
													</Col>
												</Form.Group>

												{ handbooks.length > 0 && handbooks.map((handbook, index) => (
													<Form.Group as={Row} className="mb-3" key={index}>
														<Form.Label column xs="8" sm="6">
															<div className="d-flex">
																{/* <Form.Check type="checkbox" />  */}
																Handbook <span className="font-italic font-weight-light ml-2">{handbook?.combined_name ? `(${handbook?.combined_name})` : ''}</span>
															</div>
														</Form.Label>
														<Col xs="4" sm="6">
															<Form.Control
																id="handbook_qty"
																name="handbook_qty"
																type="number"
																className="quantity_input"
																placeholder="Quantity"
																// onChange={handleChange}
																onChange={ (e) => handleHBQtyChange(handbook, e)}
																onWheel={ (e) => OnWheelPreventChange(e) } 
																min="1"
															/>
														</Col>
													</Form.Group>
												))}
												{ HBSupplements.length > 0 && HBSupplements.map((handbook, index) => (
													<Form.Group as={Row} className="mb-3" key={index}>
														<Form.Label column xs="8" sm="6">
															<div className="d-flex">
																{/* <Form.Check type="checkbox" />  */}
																Handbook Supplements
															</div>
														</Form.Label>
														<Col xs="4" sm="6">
															<Form.Control
																id="handbook_suppliment_qty"
																name="handbook_suppliment_qty"
																type="number"
																className="quantity_input"
																placeholder="Quantity"
																onChange={ (e) => handleHBSuppQtyChange(handbook, e)}
																onWheel={ (e) => OnWheelPreventChange(e) } 
																min="1"
															/>
														</Col>
													</Form.Group>
												))}
											</Col>
											{ blocks.length > 0 && (
												<Col xl={6}>
													<Form.Group as={Row} className="mb-3">
														<Form.Label column xs="12">
															Order Blocks:
														</Form.Label>
													</Form.Group>
													{blocks.map((block) => (
														<Form.Group key={block.slug} as={Row} className="mb-3">
															<Form.Label column xs="8" sm="6">
																<div className="d-flex">
																	{/* <Form.Check type="checkbox" />  */}
																	{ (block.name).slice(4) }
																</div>
															</Form.Label>
															<Col xs="4" sm="6">
																<Form.Control
																	type="number"
																	className={`quantity_input${block.selected ? ' border border-danger' : ''}`}
																	placeholder="Quantity"
																	onChange={ (e) => handleBlockQtyChange(block, e)}
																	onWheel={ (e) => OnWheelPreventChange(e) } 
																	min="1"
																	value={block?.qty}
																	onClick={ (e) => handleMultiSelectBlocks(e, block) } 
																/>
															</Col>
														</Form.Group>
													))}
												</Col>
											)}
											{ sets.length > 0 && (
												<Col xl={6}>
													<Form.Group as={Row} className="mb-3">
														<Form.Label column xs="12">
															Order Sets:
														</Form.Label>
													</Form.Group>
													{sets.map((set) => (
														<Form.Group key={set.slug} as={Row} className="mb-3">
															<Form.Label column xs="8" sm="6">
																<div className="d-flex">
																	{/* <Form.Check type="checkbox" />  */}
																	{ (set.name).slice(4) }
																</div>
															</Form.Label>
															<Col xs="4" sm="6">
																<Form.Control
																	type="number"
																	className={`quantity_input${set.selected ? ' border border-danger' : ''}`}
																	placeholder="Quantity"
																	onChange={ (e) => handleSetQtyChange(set, e)}
																	onWheel={ (e) => OnWheelPreventChange(e) } 
																	min="1"
																	value={set?.qty}
																	onClick={ (e) => handleMultiSelectSets(e, set) } 
																/>
															</Col>
														</Form.Group>
													))}
												</Col>
											)}
										</Row>
										<div className="mt-4 d-flex justify-content-end">
											<Button type="submit" isLoading={isCartLoading} className="btn btn-primary mr-2">Add To Basket </Button>
											<button type="button" onClick={ handleBack } className="btn btn-default"> Back </button>
										</div>
										{/* <p>
											We can keep order summary as a floating menu. The
											changes in the order from will be reflected only it is
											confirmed
										</p> */}
									</Form>
								</div>
							</Tab>
							<Tab eventKey="Other-Items" title="Other Items">
								<div className="content">
									<Form ref={formRef}>
										<Row>
											<Col xl={12}>
												{otherItems.map((otherItem) => (
													<Form.Group key={otherItem.slug} as={Row} className="mb-3">
														<Form.Label column xs="6" sm="6">
															<div className="d-flex">
																{/* <Form.Check type="checkbox" />  */}
																{ otherItem.name }
															</div>
														</Form.Label>
														{!otherItem.in_stock && (
															<Col xs="3" sm="3">
																<Form.Control
																	type="number"
																	className="quantity_input"
																	placeholder={otherItem.in_stock ? "No Stock" : "Quantity"}
																	onChange={ (e) => handleOtherItemQtyChange(otherItem.id, e)}
																	onWheel={ (e) => OnWheelPreventChange(e) } 
																	min="1"
																	// readOnly={otherItem.in_stock}
																/>
															</Col>
														)}
														{otherItem.in_stock && (
															<Form.Label column xs="3" sm="3">
																<div className="text-danger">
																	Out Of Stock
																</div>
															</Form.Label>
														)}
														<Form.Label column xs="3" sm="3">
															<div className="d-flex">
																<CurrencyFormat value={ (otherItem.price).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} suffix={'/per item'} />
															</div>
														</Form.Label>
													</Form.Group>
												))}
											</Col>
										</Row>
										<div className="mt-4 mb-5 ">
											<Button 
												type="button" 
												isLoading={isCartLoading} 
												className="btn btn-primary mr-2"
												onClick={ handleOtherItemSubmit }
											>Add To Basket </Button>
											<button type="button" onClick={ handleBack } className="btn btn-default"> Back </button>
										</div>
										{/* <p>
											We can keep order summary as a floating menu. The
											changes in the order from will be reflected only it is
											confirmed
										</p> */}
									</Form>
								</div>
							</Tab>
						</Tabs>
					</div>
				</div>
				<div className="col-md-12 col-lg-6 col-xl-6 d-flex">
					<div className="content">
						<h6 className="mb-2 grey_wrapper_title d-flex justify-content-between">Basket
							<span>
								<b>Total Sets Ordered:</b> { orderStore?.cart?.total_sets ? orderStore?.cart?.total_sets : 0 } <br></br>
								<b>Total Handbooks Ordered:</b> { orderStore?.cart?.total_handbooks ? orderStore?.cart?.total_handbooks : 0 } <br></br>
								{/* <b>Total Payable:</b> <CurrencyFormat value={ payableAmt } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /> */}
							</span>
							<Button type="submit" isLoading={isCartLoading} onClick={ clearCartConfirm } className="btn btn-secondary btn-sm">Clear Basket </Button>
						</h6>
						<span>
							{ !orderLimitExceeded && (
								<>
								<b>Total Payable: </b> 
								<CurrencyFormat value={ payableAmt.toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} />
								<OverlayTrigger placement="right" overlay={popover}>
									<i className='mx-1 fa fa-info-circle'/>
								</OverlayTrigger>
								{ orderStore.sync && (
									<a href='#' onClick={ (e) => { handleSynchPayable() } } data-toggle="tooltip" title="Refresh the payable">
										<i className="fas fa-sync mr-2"></i>
									</a>
								)}
								{ invoiceTotal?.pcharge_net > 0 && (
									<>
										<span className="text-danger"> Inclusive of processing fee of £{invoiceTotal?.pcharge_net}. </span>
										<span className="text-danger d-block"> Increase order value to eliminate the processing fee.</span>
									</>
								)}
								</>
							)}

							{ orderLimitExceeded && (
								<>
									{ orderStore.sync && (
										<a href='#' onClick={ (e) => { handleSynchPayable() } } data-toggle="tooltip" title="Refresh the payable">
											<i className="fas fa-sync mr-2"></i>
										</a>
									)}
									<span className="text-danger"> <b> You can order total sets per order up to {tierLimit}. </b></span>
									<span className="text-danger d-block"> Decrease order total sets count to proceed.</span>
								</>
							)}
						</span>
						
						<Form>
							{ Object.keys(orderStore.cart).length == 0 && (
								<div className="content_inner_wrap"> 
								<Row>
									<Col xl={12}>
										Your basket is empty.
									</Col>
								</Row>
								</div>
							)}
							{ orderStore.cart['english'] && (
								<div className="content_inner_wrap" key={1}>
									{/* <button className="cross_close">X</button> */}
									<Row>
										<Col xl={12}>
											<b>English</b>
										</Col>
										<Col xl={12}>
											{ (orderStore.cart['english']).map((module, i) => (
												<div key={ i}>
													{ ((module?.allsets).length > 0 || (module?.handbooks).length > 0 || (module?.blocks).length > 0 || (module?.sets).length > 0 || (module?.handbook_suppliments).length > 0) && (  
														<div className="list_module_wrapper" key={module?.slug}>
															{/* <button className="cross_close">X</button> */}
															<Row>
																<Col xl={12}>
																	<h6 className="mb-4 grey_wrapper_title">
																		{ module.name } ({ module.description })
																	</h6>
																</Col>
																{(module?.allsets).length > 0 && (
																	<Col xl={6}>
																		<Form.Group as={Row} className="mb-3">
																			<Form.Label column xs="6">
																				Entire Module
																			</Form.Label>
																			<Col
																				xs="6"
																				className="d-flex align-items-center form_wrapper"
																			>
																				<Form.Control
																					type="number"
																					min="1"
																					className="quantity_input"
																					placeholder="Quantity"
																					value={ module?.allsets[0]?.qty }
																					onWheel={ (e) => OnWheelPreventChange(e) } 
																					onChange={ (e) =>  handleUpdateQty('module', {subject:'english', module_id:module.id, qty:e.target.value, cart_id: module?.allsets[0]?.cart_id})}
																				/>
																				<button 
																					type="button" 
																					className="cross_close"
																					onClick={ async(e) => { await deleteCartItem(module?.allsets[0]?.cart_id) ? dispatch(deleteModuleAllSets({subject:'english', module_id:module.id})) : ''} }
																				>X</button>
																			</Col>
																		</Form.Group>
																	</Col>
																)}
																{(module?.handbooks).length > 0 && module?.handbooks.map((handbook, i) => (
																	<Col xl={6} key={i}>
																		<Form.Group as={Row} className="mb-3">
																			<Form.Label column xs="6">
																				Handbook
																				{handbook?.combined_name && (
																					<a title={handbook?.combined_name}>
																						<i className='mx-1 fa fa-info-circle'/>
																					</a>
																				)}
																			</Form.Label>
																			<Col
																				xs="6"
																				className="d-flex align-items-center form_wrapper"
																			>
																				<Form.Control
																					type="number"
																					min="1"
																					className="quantity_input"
																					placeholder="Quantity"
																					value={ handbook?.qty }
																					onWheel={ (e) => OnWheelPreventChange(e) } 
																					onChange={ (e) => handleUpdateQty('handbook', {subject:'english', module_id:module.id, qty:e.target.value, cart_id: handbook?.cart_id}) }
																				/>
																				<button 
																					type="button" 
																					className="cross_close"
																					onClick={ async(e) => { await  await deleteCartItem(handbook?.cart_id) ? dispatch(deleteHandbook({subject:'english', module_id:module.id})) : ''} }
																				>X</button>
																			</Col>
																		</Form.Group>
																	</Col>
																))}
																{(module?.handbook_suppliments).length > 0 && module?.handbook_suppliments.map((handbook, i) => (
																	<Col xl={6} key={i}>
																		<Form.Group as={Row} className="mb-3">
																			<Form.Label column xs="6">
																				Handbook Supplement
																			</Form.Label>
																			<Col
																				xs="6"
																				className="d-flex align-items-center form_wrapper"
																			>
																				<Form.Control
																					type="number"
																					min="1"
																					className="quantity_input"
																					placeholder="Quantity"
																					value={ handbook?.qty }
																					onWheel={ (e) => OnWheelPreventChange(e) } 
																					onChange={ (e) => handleUpdateQty('handbook-supp', {subject:'english', module_id:module.id, qty:e.target.value, cart_id: handbook?.cart_id}) }
																				/>
																				<button 
																					type="button" 
																					className="cross_close"
																					onClick={ async(e) => { await deleteCartItem(handbook?.cart_id) ? dispatch(deleteHBSupp({subject:'english', module_id:module.id})) : ''} }
																				>X</button>
																			</Col>
																		</Form.Group>
																	</Col>
																))}
															</Row>
															<Row>
																{ (module?.blocks).length > 0 && (
																	<Col xl={6}>
																		<Form.Group as={Row} className="mb-3">
																			<Form.Label column xs="12">
																				<b>Blocks:</b>
																			</Form.Label>
																		</Form.Group>
																		{ module?.blocks.map((block, i) => (
																			<Form.Group as={Row} className="mb-3" key={i}>
																				<Form.Label column xs="6">
																					<div className="d-flex">
																						{ (block?.name).slice(4) }
																					</div>
																				</Form.Label>
																				<Col
																					xs="6"
																					className="d-flex align-items-center form_wrapper"
																				>
																					<Form.Control
																						type="number"
																						min="1"
																						className="quantity_input"
																						placeholder="Quantity"
																						value={ block?.qty }
																						onWheel={ (e) => OnWheelPreventChange(e) } 
																						onChange={ (e) => handleUpdateQty('block', {subject:'english', module_id:module.id, cart_id:block.cart_id, qty:e.target.value}) }
																					/>
																					<button 
																						type="button" 
																						className="cross_close"
																						onClick={ async(e) => { await deleteCartItem(block?.cart_id) ? dispatch(deleteBlock({subject:'english', module_id:module.id, cart_id:block.cart_id})) : ''} }
																					>X</button>
																				</Col>
																			</Form.Group>
																		))}
																	</Col>
																)}
																{ (module?.sets).length > 0 && (
																	<Col xl={6}>
																		<Form.Group as={Row} className="mb-3">
																			<Form.Label column xs="12">
																				<b>Sets:</b>
																			</Form.Label>
																		</Form.Group>
																		{ module?.sets.map((set, i) => (
																			<Form.Group as={Row} className="mb-3" key={i}>
																				<Form.Label column xs="6">
																					<div className="d-flex">
																						{ (set?.name).slice(4) }
																					</div>
																				</Form.Label>
																				<Col
																					xs="6"
																					className="d-flex align-items-center form_wrapper"
																				>
																					<Form.Control
																						type="number"
																						min="1"
																						className="quantity_input"
																						placeholder="Quantity"
																						value={ set?.qty }
																						onWheel={ (e) => OnWheelPreventChange(e) } 
																						onChange={ (e) => handleUpdateQty('set', {subject:'english', module_id:module.id, cart_id:set.cart_id, qty:e.target.value}) }
																					/>
																					<button 
																						type="button" 
																						className="cross_close"
																						onClick={ async(e) => { await deleteCartItem(set?.cart_id) ? dispatch(deleteSet({subject:'english', module_id:module.id, cart_id:set.cart_id})) : '' }}
																					>X</button>
																				</Col>
																			</Form.Group>
																		))}
																	</Col>
																)}
															</Row>
														</div>
													)}
												</div>
											))}
										</Col>
									</Row>
								</div>
							)}
							{ orderStore.cart['mathematics'] && (
							<div className="content_inner_wrap" key={2}>
								{/* <button className="cross_close">X</button> */}
								<Row>
									<Col xl={12}>
										<b>Mathematics</b>
									</Col>
									<Col xl={12}>
										{ (orderStore.cart['mathematics']).map((module, i) => (
											<div key={i}>
												{ ((module?.allsets).length > 0 || (module?.handbooks).length > 0 || (module?.handbook_suppliments).length > 0 || (module?.blocks).length > 0 || (module?.sets).length > 0) && (  
													<div className="list_module_wrapper" key={module?.slug}>
														<Row>
															<Col xl={12}>
																<h6 className="mb-4 grey_wrapper_title">
																	{ module.name } ({ module.description })
																</h6>
															</Col>
															{ (module?.allsets).length > 0 && (
																<Col xl={6}>
																	<Form.Group as={Row} className="mb-3">
																		<Form.Label column xs="6">
																			Entire Module
																		</Form.Label>
																		<Col
																			xs="6"
																			className="d-flex align-items-center form_wrapper"
																		>
																			<Form.Control
																				type="number"
																				min="1"
																				className="quantity_input"
																				placeholder="Quantity"
																				value={ module?.allsets[0]?.qty }
																				onWheel={ (e) => OnWheelPreventChange(e) } 
																				onChange={ (e) => handleUpdateQty('module', {subject:'mathematics', module_id:module.id, qty:e.target.value, cart_id: module?.allsets[0]?.cart_id}) }
																			/>
																			<button 
																				type="button" 
																				className="cross_close"
																				onClick={ async(e) => { await deleteCartItem(module?.allsets[0]?.cart_id) ? dispatch(deleteModuleAllSets({subject:'mathematics', module_id:module.id})) : ''} }
																			>X</button>
																		</Col>
																	</Form.Group>
																</Col>
															)}
															{ (module?.handbooks).length > 0 && module?.handbooks.map((handbook, i) => (
																<Col xl={6} key={i}>
																	<Form.Group as={Row} className="mb-3">
																		<Form.Label column xs="6">
																			Handbook
																			{handbook?.combined_name && (
																				<a title={handbook?.combined_name}>
																					<i className='mx-1 fa fa-info-circle'/>
																				</a>
																			)}
																		</Form.Label>
																		<Col
																			xs="6"
																			className="d-flex align-items-center form_wrapper"
																		>
																			<Form.Control
																				type="number"
																				min="1"
																				className="quantity_input"
																				placeholder="Quantity"
																				value={ handbook?.qty }
																				onWheel={ (e) => OnWheelPreventChange(e) } 
																				onChange={ (e) => handleUpdateQty('handbook', {subject:'mathematics', module_id:module.id, qty:e.target.value, cart_id: handbook?.cart_id}) }
																			/>
																			<button 
																				type="button" 
																				className="cross_close"
																				onClick={ async(e) => { await deleteCartItem(handbook?.cart_id) ? dispatch(deleteHandbook({subject:'mathematics', module_id:module.id})) : ''} }
																			>X</button>
																		</Col>
																	</Form.Group>
																</Col>
															))}
															{(module?.handbook_suppliments).length > 0 && module?.handbook_suppliments.map((handbook, i) => (
																<Col xl={6} key={i}>
																	<Form.Group as={Row} className="mb-3">
																		<Form.Label column xs="6">
																			Handbook Supplement
																		</Form.Label>
																		<Col
																			xs="6"
																			className="d-flex align-items-center form_wrapper"
																		>
																			<Form.Control
																				type="number"
																				min="1"
																				className="quantity_input"
																				placeholder="Quantity"
																				value={ handbook?.qty }
																				onWheel={ (e) => OnWheelPreventChange(e) } 
																				onChange={ (e) => handleUpdateQty('handbook-supp', {subject:'mathematics', module_id:module.id, qty:e.target.value, cart_id: handbook?.cart_id}) }
																			/>
																			<button 
																				type="button" 
																				className="cross_close"
																				onClick={ async(e) => { await deleteCartItem(handbook?.cart_id) ? dispatch(deleteHBSupp({subject:'mathematics', module_id:module.id})) : ''} }
																			>X</button>
																		</Col>
																	</Form.Group>
																</Col>
															))}
														</Row>
														<Row>
															{ (module?.blocks).length > 0 && (
															<Col xl={6}>
																<Form.Group as={Row} className="mb-3">
																	<Form.Label column xs="12">
																		<b>Blocks:</b>
																	</Form.Label>
																</Form.Group>
																{ module?.blocks.map((block, i) => (
																	<Form.Group as={Row} className="mb-3" key={i}>
																		<Form.Label column xs="6">
																			<div className="d-flex">
																				{ (block?.name).slice(4) }
																			</div>
																		</Form.Label>
																		<Col
																			xs="6"
																			className="d-flex align-items-center form_wrapper"
																		>
																			<Form.Control
																				type="number"
																				min="1"
																				className="quantity_input"
																				placeholder="Quantity"
																				value={ block?.qty }
																				onWheel={ (e) => OnWheelPreventChange(e) } 
																				onChange={ (e) => handleUpdateQty('block', {subject:'mathematics', module_id:module.id, cart_id:block.cart_id, qty:e.target.value}) }
																			/>
																			<button 
																				type="button" 
																				className="cross_close"
																				onClick={ async(e) => { await deleteCartItem(block?.cart_id) ? dispatch(deleteBlock({subject:'mathematics', module_id:module.id, cart_id:block.cart_id})) : ''} }
																			>X</button>
																		</Col>
																	</Form.Group>
																))}
															</Col>
															)}
															{ (module?.sets).length > 0 && (
															<Col xl={6}>
																<Form.Group as={Row} className="mb-3">
																	<Form.Label column xs="12">
																		<b>Sets:</b>
																	</Form.Label>
																</Form.Group>
																{ module?.sets.map((set, i) => (
																<Form.Group as={Row} className="mb-3" key={i}>
																	<Form.Label column xs="6">
																		<div className="d-flex">
																			{ (set?.name).slice(4) }
																		</div>
																	</Form.Label>
																	<Col
																		xs="6"
																		className="d-flex align-items-center form_wrapper"
																	>
																		<Form.Control
																			type="number"
																			min="1"
																			className="quantity_input"
																			placeholder="Quantity"
																			value={ set?.qty }
																			onWheel={ (e) => OnWheelPreventChange(e) } 
																			onChange={ (e) => handleUpdateQty('set', {subject:'mathematics', module_id:module.id, cart_id:set.cart_id, qty:e.target.value}) }
																		/>
																		<button 
																			type="button" 
																			className="cross_close"
																			onClick={ async(e) => { await deleteCartItem(set?.cart_id) ? dispatch(deleteSet({subject:'mathematics', module_id:module.id, cart_id:set.cart_id})) : ''} }
																		>X</button>
																	</Col>
																</Form.Group>
																))}
															</Col>
															)}
														</Row>
													</div>
												)}
											</div>
										))}
									</Col>
								</Row>
							</div>
							)}
							{ orderStore.cart['science'] && (
								<div className="content_inner_wrap" key='science'>
									{/* <button className="cross_close">X</button> */}
									<Row>
										<Col xl={12}>
											<b>Science</b>
										</Col>
										<Col xl={12}>
											{ (orderStore.cart['science']).map((module, i) => (
												<div key={ i}>
													{ ((module?.allsets).length > 0 || (module?.handbooks).length > 0 || (module?.blocks).length > 0 || (module?.sets).length > 0 || (module?.handbook_suppliments).length > 0) && (  
														<div className="list_module_wrapper" key={module?.slug}>
															{/* <button className="cross_close">X</button> */}
															<Row>
																<Col xl={12}>
																	<h6 className="mb-4 grey_wrapper_title">
																		{ module.name } ({ module.description })
																	</h6>
																</Col>
																{(module?.allsets).length > 0 && (
																	<Col xl={6}>
																		<Form.Group as={Row} className="mb-3">
																			<Form.Label column xs="6">
																				Entire Module
																			</Form.Label>
																			<Col
																				xs="6"
																				className="d-flex align-items-center form_wrapper"
																			>
																				<Form.Control
																					type="number"
																					min="1"
																					className="quantity_input"
																					placeholder="Quantity"
																					value={ module?.allsets[0]?.qty }
																					onWheel={ (e) => OnWheelPreventChange(e) } 
																					onChange={ (e) =>  handleUpdateQty('module', {subject:'science', module_id:module.id, qty:e.target.value, cart_id: module?.allsets[0]?.cart_id})}
																				/>
																				<button 
																					type="button" 
																					className="cross_close"
																					onClick={ async(e) => { await deleteCartItem(module?.allsets[0]?.cart_id) ? dispatch(deleteModuleAllSets({subject:'science', module_id:module.id})) : ''} }
																				>X</button>
																			</Col>
																		</Form.Group>
																	</Col>
																)}
																{(module?.handbooks).length > 0 && module?.handbooks.map((handbook, i) => (
																	<Col xl={6} key={i}>
																		<Form.Group as={Row} className="mb-3">
																			<Form.Label column xs="6">
																				Handbook 
																				<a title={handbook?.combined_name}>
																					<i className='mx-1 fa fa-info-circle'/>
																				</a>
																			</Form.Label>
																			<Col
																				xs="6"
																				className="d-flex align-items-center form_wrapper"
																			>
																				<Form.Control
																					type="number"
																					min="1"
																					className="quantity_input"
																					placeholder="Quantity"
																					value={ handbook?.qty }
																					onWheel={ (e) => OnWheelPreventChange(e) } 
																					onChange={ (e) => handleUpdateQty('handbook', {subject:'science', module_id:module.id, qty:e.target.value, cart_id: handbook?.cart_id}) }
																				/>
																				<button 
																					type="button" 
																					className="cross_close"
																					onClick={ async(e) => { await  await deleteCartItem(handbook?.cart_id) ? dispatch(deleteHandbook({subject:'science', module_id:module.id})) : ''} }
																				>X</button>
																			</Col>
																		</Form.Group>
																	</Col>
																))}
																{(module?.handbook_suppliments).length > 0 && module?.handbook_suppliments.map((handbook, i) => (
																	<Col xl={6} key={i}>
																		<Form.Group as={Row} className="mb-3">
																			<Form.Label column xs="6">
																				Handbook Supplement
																			</Form.Label>
																			<Col
																				xs="6"
																				className="d-flex align-items-center form_wrapper"
																			>
																				<Form.Control
																					type="number"
																					min="1"
																					className="quantity_input"
																					placeholder="Quantity"
																					value={ handbook?.qty }
																					onWheel={ (e) => OnWheelPreventChange(e) } 
																					onChange={ (e) => handleUpdateQty('handbook-supp', {subject:'science', module_id:module.id, qty:e.target.value, cart_id: handbook?.cart_id}) }
																				/>
																				<button 
																					type="button" 
																					className="cross_close"
																					onClick={ async(e) => { await deleteCartItem(handbook?.cart_id) ? dispatch(deleteHBSupp({subject:'science', module_id:module.id})) : ''} }
																				>X</button>
																			</Col>
																		</Form.Group>
																	</Col>
																))}
															</Row>
															<Row>
																{ (module?.blocks).length > 0 && (
																	<Col xl={6}>
																		<Form.Group as={Row} className="mb-3">
																			<Form.Label column xs="12">
																				<b>Blocks:</b>
																			</Form.Label>
																		</Form.Group>
																		{ module?.blocks.map((block, i) => (
																			<Form.Group as={Row} className="mb-3" key={i}>
																				<Form.Label column xs="6">
																					<div className="d-flex">
																						{ (block?.name).slice(4) }
																					</div>
																				</Form.Label>
																				<Col
																					xs="6"
																					className="d-flex align-items-center form_wrapper"
																				>
																					<Form.Control
																						type="number"
																						min="1"
																						className="quantity_input"
																						placeholder="Quantity"
																						value={ block?.qty }
																						onWheel={ (e) => OnWheelPreventChange(e) } 
																						onChange={ (e) => handleUpdateQty('block', {subject:'science', module_id:module.id, cart_id:block.cart_id, qty:e.target.value}) }
																					/>
																					<button 
																						type="button" 
																						className="cross_close"
																						onClick={ async(e) => { await deleteCartItem(block?.cart_id) ? dispatch(deleteBlock({subject:'science', module_id:module.id, cart_id:block.cart_id})) : ''} }
																					>X</button>
																				</Col>
																			</Form.Group>
																		))}
																	</Col>
																)}
																{ (module?.sets).length > 0 && (
																	<Col xl={6}>
																		<Form.Group as={Row} className="mb-3">
																			<Form.Label column xs="12">
																				<b>Sets:</b>
																			</Form.Label>
																		</Form.Group>
																		{ module?.sets.map((set, i) => (
																			<Form.Group as={Row} className="mb-3" key={i}>
																				<Form.Label column xs="6">
																					<div className="d-flex">
																						{ (set?.name).slice(4) }
																					</div>
																				</Form.Label>
																				<Col
																					xs="6"
																					className="d-flex align-items-center form_wrapper"
																				>
																					<Form.Control
																						type="number"
																						min="1"
																						className="quantity_input"
																						placeholder="Quantity"
																						value={ set?.qty }
																						onWheel={ (e) => OnWheelPreventChange(e) } 
																						onChange={ (e) => handleUpdateQty('set', {subject:'science', module_id:module.id, cart_id:set.cart_id, qty:e.target.value}) }
																					/>
																					<button 
																						type="button" 
																						className="cross_close"
																						onClick={ async(e) => { await deleteCartItem(set?.cart_id) ? dispatch(deleteSet({subject:'science', module_id:module.id, cart_id:set.cart_id})) : '' }}
																					>X</button>
																				</Col>
																			</Form.Group>
																		))}
																	</Col>
																)}
															</Row>
														</div>
													)}
												</div>
											))}
										</Col>
									</Row>
								</div>
							)}
							{ orderStore.cart['other_items'] && (
								<div className="content_inner_wrap" key={3}>
									<Row>
										<Col xl={12}>
											<b>Other Items</b>
										</Col>
										<Col xl={12}>
											<div className="list_module_wrapper">
												<Row>
													<Col xl={12}>
														{ (orderStore.cart['other_items']).length > 0 && (orderStore.cart['other_items']).map((item, i) => (
														<Form.Group as={Row} className="mb-3" key={i}>
															{/* <Form.Label column xs="12">
																Order Blocks:
															</Form.Label> */}
															<Form.Label column xs="6">
																<div className="d-flex">
																	{ item.name }
																</div>
															</Form.Label>
															<Col
																xs="3"
																className="d-flex align-items-center form_wrapper"
															>
																<Form.Control
																	type="number"
																	min="1"
																	className="quantity_input"
																	placeholder="Quantity"
																	value={ item.qty }
																	onWheel={ (e) => OnWheelPreventChange(e) } 
																	onChange={ (e) => handleUpdateQty('other-item', {id:item.cart_id, qty:e.target.value}) }
																/>
																<button 
																	type="button" 
																	className="cross_close"
																	onClick={ async(e) => { await deleteCartItem(item?.cart_id) ? dispatch(deleteOtherItem({cart_id:item.cart_id})) : ''} }
																>X</button>
															</Col>
														</Form.Group>
														))}

														{ (orderStore.cart['other_items']).length == 0 && (
															<span>Your Other Item's basket is empty.</span>
														)}
													</Col>
												</Row>
											</div>
										</Col>
									</Row>
								</div>
							)}
							{ Object.keys(orderStore.cart).length !== 0 && (
								<div className="mt-4 d-flex justify-content-end align-items-center">
									{/* <span>
										<b>Total Sets:240</b>
									</span> */}
									<Button type="button" disabled={btnDisabled} isLoading={isCartLoading} onClick={ handleProceed } className="btn btn-primary float-right mr-2"> Proceed </Button>
									<Button type="button" onClick={ handleBack } className="btn btn-default"> Back </Button>
								</div>
							)}
						</Form>
					</div>
				</div>
			</div>
		</div>
		</>
	);
};

export default OrderFormStep2;