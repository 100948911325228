import { createSlice } from '@reduxjs/toolkit';

export interface TierState {
	planTiers: any[]
}

const initialState: TierState = {
	planTiers:[{
		name: null,
		from_count: null,
		to_count: null,
		alert_threshold: null
	}]
};

export const tierSlice = createSlice({
	name: 'tier',
	initialState,
	reducers: {
		addTier: (state, { payload }) => {
			state.planTiers = [...state.planTiers, {
				name: null,
				from_count: null,
				to_count: null,
				alert_threshold: null
			}]
		},
		removeTier: (state, { payload }) => {
			const newTiers = state.planTiers.filter(
				(e,i) => i !== payload.index
			);
			state.planTiers = newTiers;
		},
	}
});

export const { addTier, removeTier } = tierSlice.actions;

export default tierSlice.reducer;