import { addItemsToCartURL, clearCartURL, deleteCartItemURL, downloadOrderInvoiceURL, getCartItemsByFranchiseURL, getOrderDetailsURL, getOrderInvoiceByIdURL, getOrdersWithPaginationURL, makeDefaultOrderURL, makeOrderPaymentURL, placeOrderURL, repeatOrderURL, updateCartURL, updateOrderStatusURL } from "@app/constants/ApiEndPoints";
import axios from "axios";

export const getOrderssWithPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(getOrdersWithPaginationURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}
				&franchiseId=${search.franchiseId}&invoiceNumber=${search.invoiceNumber}&totalSets=${search.totalSets}&totalHandbooks=${search.totalHandbooks}&net=${search.net}&vat=${search.vat}&orderTotal=${search.orderTotal}&transactionNumber=${search.transactionNumber}&orderStatus=${search.orderStatus}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const addItemsToCart = async (payload) => {
	try {
		const response = await axios.post(addItemsToCartURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateCart = async (franchiseId, payload) => {
	try {
		const response = await axios.put(updateCartURL + franchiseId, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getCartItemsByFranchise = async (franchiseId) => {
	try {
		const response = await axios.get(getCartItemsByFranchiseURL + franchiseId + '/all');
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getCartInvoiceByFranchise = async (franchiseId) => {
	try {
		const response = await axios.get(getCartItemsByFranchiseURL + franchiseId + '/invoice');
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const clearCartByFranchiseId = async (franchiseId: number) => {
	try {
		const response = await axios.delete(clearCartURL + franchiseId + '/clear');
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};

export const placeOrder = async (franchiseId, payload) => {
	try {
		const response = await axios.post(placeOrderURL + franchiseId +'/place', payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getOrderDetailsById = async (orderId) => {
	try {
		const response = await axios.get(getOrderDetailsURL + orderId + '/details');
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const deleteCartItemById = async (id: number) => {
	try {
		const response = await axios.delete(deleteCartItemURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};

export const repeatOrderById = async (orderId: number) => {
	try {
		const response = await axios.get(repeatOrderURL + orderId + '/repeated');
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};

export const loadDefaultOrder = async (franchiseId: number) => {
	try {
		const response = await axios.get(repeatOrderURL + franchiseId + '/default');
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};

export const makeDefaultOrder = async (payload) => {
	try {
		const response = await axios.post(makeDefaultOrderURL, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const downloadOrderInvoice = async (orderId) => {
	try {
		// const platform = window.navigator.platform;  //+ `?platform=${platform}`
		const response = await axios.get(downloadOrderInvoiceURL + orderId + '/download', { responseType: 'blob' });
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateOrderStatus = async (payload) => {
	try {
		const response = await axios.post(updateOrderStatusURL, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getOrderInvoiceById = async (orderId) => {
	try {
		const response = await axios.get(getOrderInvoiceByIdURL + orderId + '/invoice');
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const makeOrderPayment = async (payload) => {
	try {
		const response = await axios.post(makeOrderPaymentURL, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};