import React from 'react';
import DataTable, {TableStyles} from 'react-data-table-component';

export interface TableProps {
    pagination?:boolean;
    columns:any;
    data:any;
    selectableRows?:boolean;
    progressPending?:boolean;
}

const Table = ({columns, data, pagination, selectableRows, progressPending}: TableProps) => {
  const customStyle: TableStyles = {
    head: {
      style: {
        color: "#5E5873",
        fontSize: "13px",
        fontWeight: 900,
        // borderBottomWidth: '0px',
			// borderBottomColor: theme.divider.default,
			// borderBottomStyle: 'solid',
      },
    },
    headCells: {
      style: {
        borderColor: "#E9ECEF",
        textTransform: "uppercase",
        letterSpacing: "1px",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#F7F7F8",
        minHeight: "44px",
        fontWeight: 500,
      },
    },
  };

  return (
    <>
      <DataTable
          pagination={pagination}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
          columns={columns}
          data={data}
          customStyles={customStyle}
          progressPending={progressPending}
          selectableRows={selectableRows}
      />
    </>
  );
};

export default Table;
