import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@app/components";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from 'react-currency-format';
import { deleteStudentCount, getCartStudCountByFranchise, updateTransactionStatus } from "@app/services/studentCount";
import { clearCart, loadCart, setStep } from "@app/store/reducers/studentCount";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";

const CheckoutStudentCount = () => {

	const [payableAmt, setPayableAmt] = useState(0);
	const [isCartLoading, setCartLoading] = useState(false);
    const studCountStore = useSelector((state: any) => state.studentCount);
	const dispatch = useDispatch();
    const navigate = useNavigate();

	useEffect(() => {
        getCartStudCountByFranchiseData(studCountStore?.franchise?.id);
    },[]);

	const getCartStudCountByFranchiseData = async (franchiseId) => {
        try {
            setCartLoading(true);
            const resp = await getCartStudCountByFranchise(franchiseId);
            if (resp?.success === true) {
				setCartLoading(false);
				dispatch(loadCart(resp?.collections));
				setPayableAmt(resp?.collections?.total_price)
				if(Object.keys(resp?.collections).length === 0){
					toast.warning('Your cart is empty');
					dispatch(setStep(2));
				}
            } else {
				setCartLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setCartLoading(false);
            toast.error(error?.response?.message || 'Internal server error');
        }
    };

	const handleBack = () => {
		dispatch(setStep(2));
	}

	const handleCheckout = () => {
		dispatch(setStep(4));
	}

	const handleSubmit = async () => {
		try {
            setCartLoading(true);
            const resp = await updateTransactionStatus({order_id: studCountStore?.cart?.id, status: 'success' });
            if(resp.success === true){
				setCartLoading(false);
                toast.success('Student count submitted successfully.');
                dispatch(setStep(1));
				dispatch(clearCart());
				navigate('/student-count-transactions');
            }else{
                toast.error(resp?.errors[0]?.msg);
                setCartLoading(false);
            }
        } catch (error: any) {
            setCartLoading(false);
            toast.error(error.response.message || 'Internal server error');
        }
	}

	const deleteConfirm = (e, payload) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to delete this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteStudentCountAPI(payload)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const deleteStudentCountAPI = async (payload) => {
        try {
            setCartLoading(true);
            const resp = await deleteStudentCount(payload);
            if(resp.success === true){
                toast.success(resp?.message);
                setCartLoading(false);
                getCartStudCountByFranchiseData(studCountStore?.franchise?.id);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setCartLoading(false);
            }
          } catch (error: any) {
            setCartLoading(false);
            toast.error(error.response.message || 'Internal server error');
          }
    };

	return (
		<>
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-12 col-lg-12 col-xl-12">
					<div className="content">
						<h6 className="mb-4 grey_wrapper_title d-flex justify-content-between">Checkout 
							{/* <span>
								<b>Total Net Price:</b>  <CurrencyFormat value={ studCountStore?.cart['net_price'] } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /><br></br>
								<b>Total VAT:</b> <CurrencyFormat value={ studCountStore?.cart['vat'] } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} /><br></br>
								<b>Total Amount:</b> <CurrencyFormat value={ studCountStore?.cart['total_price'] } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} />
							</span> */}
						</h6>
						<div className="m-2 d-flex justify-content-between align-items-center">
							{/* <span>
								<b>Total Payable:</b> <CurrencyFormat value={ payableAmt } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} />
							</span> */}
							<span></span>
							<span>
								{studCountStore?.cart['details'] && (studCountStore?.cart['details']).length > 0 && payableAmt > 0 && (
									<Button type="submit" isLoading={isCartLoading} onClick={handleCheckout} className="btn btn-primary mr-2"> Pay and Submit </Button>
								)}
								{studCountStore?.cart['details'] && (studCountStore?.cart['details']).length > 0 && payableAmt == 0 && (
									<Button type="submit" isLoading={isCartLoading} onClick={handleSubmit} className="btn btn-primary mr-2"> Submit </Button>
								)}
								<Button type="button" isLoading={isCartLoading} onClick={ handleBack } className="btn btn-default" title="Add the student count for pending month(s)"> Add Student Count </Button>
							</span>
						</div>
						{ Object.keys(studCountStore.cart).length === 0 && (
							<div className="content_inner_wrap"> 
							<Row>
								<Col xl={12}>
									Your basket is empty.
								</Col>
							</Row>
							</div>
						)}
						{ studCountStore?.cart['details'] && studCountStore.cart['details'] && (
							<>
								<div className="content_inner_wrap" key={1}>
									<Row>
										<Col xl={12}>
											<b>Student Counts</b>
										</Col>
										<Col xl={12}>
											<div className="list_module_wrapper">
												<Row>
													{(studCountStore.cart['details']).length > 0 && (
														<Col xl={12}>
															<table className="table table-bordered" id="client_id_table">
																<tbody>
																	<tr className='text-center'>
																		<th> Month</th>
																		<th> Year</th>
																		<th> Mathematics</th>
																		<th> English</th>
																		<th> Science</th>
																		<th> Total Subjects</th>
																		<th> Net Amount</th>
																		<th> VAT Amount</th>
																		<th> Total Amount</th>
																		<th> Action</th>
																	</tr>
																	{(studCountStore.cart['details']).map((count, i) => (
																		<tr className='text-center' key={i}>
																			<td> {moment(count.month).format('MMMM')}</td>
																			<td> {count.year} </td>
																			<td> {count.maths} </td>
																			<td> {count.english} </td>
																			<td> {count.science} </td>
																			<td> {count.total} </td>
																			<td> <CurrencyFormat value={count.net_price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /> </td>
																			<td> <CurrencyFormat value={count.vat_price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /> </td>
																			<td> <CurrencyFormat value={count.total_price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /> </td>
																			<td>
																				<button 
																					type="button" 
																					className="cross_close"
																					title="Delete and Re-Input Count"
																					onClick={ (e) => { deleteConfirm(e, {franchise_id: count.franchise_id, month: count.month, year: count.year})} }
																				>X</button>
																			</td>
																		</tr>
																	))}
																	<tr className='text-center'>
																		<td colSpan={6}> </td>
																		<td colSpan={2}> <b>Amount Payable Now</b> </td>
																		{/* <td><CurrencyFormat value={ studCountStore?.cart['net_price'] } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'}></CurrencyFormat></td>
																		<td><CurrencyFormat value={ studCountStore?.cart['vat'] } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'}></CurrencyFormat></td> */}
																		<td> <CurrencyFormat value={payableAmt} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /> </td>
																	</tr>
																</tbody>
															</table>
														</Col>
													)}

													{(studCountStore.cart['details']).length == 0 && (
														<span>Your student count basket is empty.</span>
													)}
												</Row>
											</div>
										</Col>
									</Row>
								</div>
								<div className="content_inner_wrap" key={2}>
									<Row>
										<Col xl={12}>
											<b>Monthly Student Subject Rate (excl VAT)  </b>
										</Col>
										<Col xl={12}>
											<div className="list_module_wrapper">
												<Row>
													{(studCountStore?.cart['details']).length > 0 && (
														<Col xl={6}>
															<table className="table table-bordered" id="client_id_table">
																<tbody>
																	<tr className='text-center'>
																		<th> Month</th>
																		<th> Year</th>
																		<th> Mathematics</th>
																		<th> English</th>
																		<th> Science</th>
																	</tr>
																	{(studCountStore.cart['details']).map((count, i) => (
																		<tr className='text-center' key={i}>
																			<td> {moment(count.month).format('MMMM')}</td>
																			<td> {count.year} </td>
																			<td> <CurrencyFormat value={count.maths_rate} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /> </td>
																			<td> <CurrencyFormat value={count.english_rate} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /> </td>
																			<td> <CurrencyFormat value={count.science_rate} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /> </td>
																		</tr>
																	))}
																</tbody>
															</table>
														</Col>
													)}

													{(studCountStore.cart['details']).length == 0 && (
														<span>Your student count basket is empty.</span>
													)}
												</Row>
											</div>
										</Col>
									</Row>
								</div>
							</>
						)}
						<div className="mt-2 d-flex justify-content-between align-items-center">
							<span className="text-danger"> <a className="text-danger" href="#" onClick={handleBack}> <b>Click here </b></a> to add data for previous year. </span>
							<div>
								{studCountStore?.cart['details'] && (studCountStore?.cart['details']).length > 0 && payableAmt > 0 && (
									<Button type="submit" isLoading={isCartLoading} onClick={handleCheckout} className="btn btn-primary mr-2"> Pay and Submit </Button>
								)}

								{studCountStore?.cart['details'] && (studCountStore?.cart['details']).length > 0 && payableAmt == 0 && (
									<Button type="submit" isLoading={isCartLoading} onClick={handleSubmit} className="btn btn-primary mr-2"> Submit </Button>
								)}
								<Button type="button" isLoading={isCartLoading} onClick={ handleBack } className="btn btn-default" title="Add the student count for pending month(s)"> Add Student Count </Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	);
};

export default CheckoutStudentCount;