import { campsURL } from "@app/constants/ApiEndPoints";
import axios from "axios";

export const getCampsWithPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(campsURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&name=${search.name}&status=${search.status}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getEnrolledStudnetsByCampWithPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(campsURL + `/enrolled?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&campId=${search.campId}&type=${search.type}&totalAmount=${search.totalAmount}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllActiveCamps = async (franchiseId) => {
	try {
		const response = await axios.get(campsURL + '/' + franchiseId + '/all');
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const enrollStudentToCamp = async (payload) => {
	try {
		const response = await axios.post(campsURL + '/enroll', payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getCampById = async (id: number) => {
	try {
		const response = await axios.get(campsURL + '/' +id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getStudentEnrollmentDetails = async (payload) => {
	try {
		const response = await axios.post(campsURL + '/enrolled/all', payload);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};