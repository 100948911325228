import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import { Form, Modal } from 'react-bootstrap';
import { updateOrderStatus } from '@app/services/order';
import moment from 'moment';

const UpdateOrderStatusModal = (props) => {

    const statuses = [{id: 1, slug: 'unpaid', name: 'Unpaid', disabled: false},
                        {id: 2, slug: 'sent', name: 'Sent to Print Partner', disabled: false},
                        {id: 3, slug: 'acknowledged', name: 'In Production', disabled: false},
                        {id: 4, slug: 'dispatched', name: 'Dispatch', disabled: false}];

    const [orderStatus, setOrderStatus] = useState([{id: 1, slug: 'unpaid', name: 'Unpaid', disabled: false}]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const index = statuses.findIndex(
            (obj) => obj.slug == props?.payload?.status
        );
        
        if(index == 0){
            setOrderStatus([statuses[index + 1], {id: 5, slug: 'cancelled', name: 'Cancel', disabled: false}]);
        }else{
            setOrderStatus([statuses[index + 1]]);
        }
    },[props.show]);

    const updateOrderStatusAPI = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateOrderStatus(payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                props.handleClose();
                props.getAllOrdersData();
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            // order_id: props?.payload?.order_id,
            status: props?.payload?.status,
            dispatch_date: moment().format('YYYY-MM-DD'),
        },
        validationSchema: Yup.object({
            // order_id: Yup.string().required('Order id is required'),
            status: Yup.string().required('Please select status'),
            dispatch_date: Yup.string().required('Please enter start date'),
        }),
        onSubmit: (values) => {
            const platform = window.navigator.platform;
            let payload = values.status === 'dispatched' ?  {order_id: props?.payload?.order_id, status: values.status, dispatch_date: values.dispatch_date, platform: platform} : {order_id: props?.payload?.order_id, status: values.status, platform: platform};
            updateOrderStatusAPI(payload);
        }
    });

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update Order Status</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className='row'>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <dt>Order Id</dt>
                                <dd>#{ props?.payload?.order_id } </dd>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <dt>Franchise</dt>
                                <dd>{ props?.payload?.franchise_name }</dd>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Status <span className="required">*</span></label>
                                <select 
                                    className="form-control"
                                    name="status"
                                    onChange={handleChange} 
                                    value={values.status}
                                >
                                    <option value="">--Please select--</option>
                                    {orderStatus.length > 0 && orderStatus.map((obj) => (
                                        <option key={obj.slug} value={obj.slug} disabled={ obj.disabled }>
                                            {obj.name}
                                        </option>
                                    ))}
                                </select>
                                {(touched.status && errors.status) && (
                                    <div className="text-danger">
                                        {errors.status}
                                    </div>
                                )}
                            </div>
                        </div>

                        {values.status === 'dispatched' && (
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Dispatch Date <span className="required">*</span></label>
                                    <Form.Control 
                                        type="date" 
                                        name="dispatch_date" 
                                        id="dispatch_date" 
                                        placeholder="Enter dispatch date" 
                                        value={moment(values.dispatch_date).format('YYYY-MM-DD')}
                                        onChange={handleChange}
                                    />
                                    {(touched.dispatch_date && errors.dispatch_date) && (
                                        <div className="text-danger">
                                            {errors.dispatch_date}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                    <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    </div>
  )
}

export default UpdateOrderStatusModal;