import { createSlice, current } from '@reduxjs/toolkit';

export const subjectSlice = createSlice({
	name: 'subject',
	initialState: {
		data: [],
	},
	reducers: {
		setActiveSubjects(state, action) {
			state.data = action.payload;
		},
		setSubjectCheck: (state, { payload }) => {
			const objIndex  = state.data.findIndex( e => e.slug === payload.slug )
			state.data[objIndex].active = payload.active;
			if(!payload.active){
				state.data[objIndex].is_default = false;
				state.data[objIndex].rate = '';
			}
			return;
		},
		setDefaultCheck: (state, { payload }) => {
			const objIndex  = state.data.findIndex( e => e.slug === payload.slug )
			state.data[objIndex].is_default = payload.is_default;
			return;
		},
		setSubjectRate: (state, { payload }) => {
			const objIndex  = state.data.findIndex( e => e.slug === payload.slug )
			state.data[objIndex].rate = payload.rate;
			return;
		},
		setSubjectRateError: (state, { payload }) => {
			const objIndex  = state.data.findIndex( e => e.slug === payload.slug )
			state.data[objIndex].error = payload.error;
			return;
		}
	}
});

export const { setActiveSubjects, setSubjectCheck, setDefaultCheck, setSubjectRate, setSubjectRateError } = subjectSlice.actions;

export default subjectSlice.reducer;
