import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import {Form} from 'react-bootstrap';
import { ErrorMessage, Field, FieldArray, FormikProvider, useFormik } from 'formik';
import { Button } from '@app/components';
import { toast } from 'react-toastify';
import { getCentreById, updateCentre } from '@app/services/centre';

const EditCentreContent = () => {

    let obj: any = {}
    let { id } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [centresData, setCentresData] = useState(obj);
    const navigate = useNavigate();

    useEffect(() => {
        getCentreDetailsById(id);
    },[]);

    const getCentreDetailsById = async (id) => {
        try {
            setLoading(true);
            const resp = await getCentreById(id);
            if(resp.success === true){
                setLoading(false);
                setCentresData(resp?.collections);
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
          }
    };

    const putCentre = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateCentre(id, payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                navigate('/centers');
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    // const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
    const formik = useFormik({    
        initialValues: {
            name: centresData?.name || '',
            url: centresData?.url || '',
            free_assessment: centresData?.free_assessment || '',
            email: centresData?.email || '',
            postcode: centresData?.postcode || '',
            address: centresData?.address || '',
            latitude: centresData?.latitude || '',
            longitude: centresData?.longitude || '',
            phone_numbers: [{phone: '9921819186'}, {phone: '9096107296'}] 
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter centre name'),
            email: Yup.string().required('Please enter centre email')
            .email("Enter a valid email"),
            phone_numbers:Yup.array()
            .of(
              Yup.object().shape({
                phone: Yup.string().required('Please enter phone number'),
              })
            )
            // .required('Please enter phone number') // these constraints are shown if and only if inner constraints are satisfied
            // .min(3, 'Minimum of 3 friends'),
        }),
        onSubmit: (values) => {
            putCentre(values);
        },
        enableReinitialize: true,
    });

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Update Centre Page Content</h3>
                            </div>
                            <FormikProvider value={formik}>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Centre Name<span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter centre name"
                                                    name="name" 
                                                    onChange={formik.handleChange} 
                                                    value={formik.values.name}
                                                />
                                                {(formik.touched.name && formik.errors.name) && (
                                                    <div className="text-danger">
                                                        {formik.errors.name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Centre URL<span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    name="url" 
                                                    onChange={formik.handleChange} 
                                                    value={formik.values.name
                                                        .toLowerCase()
                                                        .trim()
                                                        .replace(/[^\w\s-]/g, '')
                                                        .replace(/[\s_-]+/g, '-')
                                                        .replace(/^-+|-+$/g, '')}
                                                    disabled
                                                />
                                                {(formik.touched.url && formik.errors.url) && (
                                                    <div className="text-danger">
                                                        {formik.errors.url}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="franchise_id"><b> Free Assessment? </b></label>
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox"
                                                        id="free_assessment"  
                                                        name="free_assessment" 
                                                        className="form-check-input" 
                                                        onChange={(e) => { 
                                                            formik.handleChange 
                                                            formik.setFieldValue("free_assessment", e.target.checked ? 1 : 0)
                                                        }}
                                                        checked={formik.values.free_assessment}
                                                    />
                                                    <label htmlFor="free_assessment" className="form-check-label"></label>
                                                </div>
                                                {(formik.touched.free_assessment && formik.errors.free_assessment) && (
                                                    <div className="text-danger">
                                                        {formik.errors.free_assessment}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1"> Email <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="email" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter centre email"
                                                    name="email" 
                                                    onChange={formik.handleChange} 
                                                    value={formik.values.email}
                                                />
                                                {(formik.touched.email && formik.errors.email) && (
                                                    <div className="text-danger">
                                                        {formik.errors.email}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Postcode <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter postcode"
                                                    name="postcode" 
                                                    onChange={formik.handleChange} 
                                                    value={formik.values.postcode}
                                                />
                                                {(formik.touched.postcode && formik.errors.postcode) && (
                                                    <div className="text-danger">
                                                        {formik.errors.postcode}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Address </label>
                                                <textarea 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter address" 
                                                    name="address" 
                                                    onChange={formik.handleChange} 
                                                    value={formik.values.address}
                                                ></textarea>
                                                {(formik.touched.address && formik.errors.address) && (
                                                    <div className="text-danger">
                                                        {formik.errors.address}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Latitude <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter Latitude"
                                                    name="latitude" 
                                                    onChange={formik.handleChange} 
                                                    value={formik.values.latitude}
                                                    disabled
                                                />
                                                {(formik.touched.latitude && formik.errors.latitude) && (
                                                    <div className="text-danger">
                                                        {formik.errors.latitude}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Longitude <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter longitude"
                                                    name="longitude" 
                                                    onChange={formik.handleChange} 
                                                    value={formik.values.longitude}
                                                    disabled
                                                />
                                                {(formik.touched.longitude && formik.errors.longitude) && (
                                                    <div className="text-danger">
                                                        {formik.errors.longitude}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <FieldArray name="phone_numbers">
                                            {({ push, remove }) => (
                                                <>
                                                {formik.values.phone_numbers.map((phone, index) => (
                                                    <>
                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">Phone Number <span className="required">*</span></label>
                                                            <Field type="text" id="exampleInputEmail1" className="form-control" placeholder="Enter phone number" name={`phone_numbers.${index}.phone`}/>
                                                            <div className="text-danger">
                                                                <ErrorMessage name={`phone_numbers.${index}.phone`} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-1 d-flex align-items-center form_wrapper">
                                                        {(formik.values.phone_numbers).length > 1 &&
                                                            <i className="fas fa-times-circle" onClick={() => remove(index)} style={{cursor:'pointer'}}></i>
                                                        }
                                                        { (formik.values.phone_numbers).length === index + 1 &&
                                                            <i className="cursor-pointer fas fa-plus-circle" onClick={() => push({phone: ''})} style={{cursor:'pointer'}}></i>
                                                        }
                                                    </div>
                                                    </>
                                                ))}
                                                </>
                                            )}
                                        </FieldArray>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className='col-md-12'>
                                        <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                                        <Link className="btn btn-default" to='/centers'>Cancel</Link>
                                    </div>
                                </div>
                            </form>
                            </FormikProvider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default EditCentreContent