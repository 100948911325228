import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import Select from "react-select";
import {Form} from 'react-bootstrap';
import { useFormik } from 'formik';
import { Button } from '@app/components';
import { toast } from 'react-toastify';
import { getPostcodesByFranchiseId } from '@app/services/postcode';
import { getAllActiveFranchise } from '@app/services/franchise';
import { getCentreAdmins } from '@app/services/user';
import { getCentreById, updateCentre } from '@app/services/centre';
import AddCentreUserModal from '../user/AddCentreUserModal';
import { useSelector } from 'react-redux';

const EditCentre = () => {

    let obj: any = {}
    let { id } = useParams();
    const [franchises, setFranchises] = useState([]);
    const [postcodes, setPostcodes] = useState([]);
    const [centreUsers, setCentreUsers] = useState([]);
    const [selectedFranchise, setSelectedFranchise] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingFranchise, setLoadingFranchise] = useState(false);
    const [isLoadingUsers, setLoadingUsers] = useState(false);
    const [centresData, setCentresData] = useState(obj);
    const canAccess = useSelector((state: any) => state.auth.permissions.users);
    const [show, setShow] = useState(false);
    const [userFlag, setUserFlag] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const centerStatus = [{value:'active', label:'Active'}, {value:'inactive', label:'Inactive'}]

    useEffect(() => {
        getAllActiveFranchiseData();
        getCentreDetailsById(id);
    },[]);

    useEffect(() => {
        getCentreUsersData();
    },[userFlag]);

    const getCentreDetailsById = async (id) => {
        try {
            setLoading(true);
            const resp = await getCentreById(id);
            if(resp.success === true){
                setLoading(false);
                setCentresData(resp?.collections);
                if(resp?.collections?.franchise_id){
                    getPostcodesByFranchiseData(resp?.collections?.franchise_id)
                    // setFieldValue("franchise_id", resp?.collections?.franchise_name)
                    setSelectedFranchise(resp?.collections?.franchise_id)
                }
                if(resp?.collections?.franchisee){
                    setTimeout(() => {
                        setFieldValue("users",[].slice.call(resp.collections.franchisee).map((option) => option.id));
                    }, 1000);
                }
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Failed');
          }
    };

    const getAllActiveFranchiseData = async () => {
        try {
            setLoadingFranchise(true);
            const resp = await getAllActiveFranchise();
            if (resp?.success === true) {
                setLoadingFranchise(false);
                setFranchises(resp?.collections);
            } else {
                setLoadingFranchise(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoadingFranchise(false);
            toast.error(error?.message || 'Failed');
        }
    };

    const getCentreUsersData = async () => {
        try {
            setLoadingUsers(true);
            const resp = await getCentreAdmins();
            if (resp?.success === true) {
                setLoadingUsers(false);
                setCentreUsers(resp?.collections);
            } else {
                setLoadingUsers(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoadingUsers(false);
            toast.error(error?.message || 'Failed');
        }
    };

    const getPostcodesByFranchiseData = async (franchiseId) => {
        try {
                if(franchiseId){
                    const resp = await getPostcodesByFranchiseId(franchiseId);
                    if (resp?.success === true) {
                        setPostcodes(resp?.collections);
                    } else {
                        toast.error(resp?.errors[0]?.msg);
                    }
                }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    const putCentre = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateCentre(id, payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                navigate('/centers');
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Failed');
          }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            franchise_id: selectedFranchise,
            postcode_id: centresData?.postcode_id || '',
            name: centresData?.name || '',
            area_code: centresData?.area_code || '',
            email: centresData?.email || '',
            phone_number: centresData?.phone_number || '',
            address: centresData?.address || '',
            users: [],
            status: centresData?.status || '',
        },
        validationSchema: Yup.object({
            franchise_id: Yup.string().required('Please select franchise'),
            postcode_id: Yup.string().required('Please select postcode'),
            name: Yup.string().required('Please enter centre name'),
            area_code: Yup.string().required('Please enter centre postcode'),
            email: Yup.string().required('Please enter centre email')
            .email("Enter a valid email"),
            phone_number: Yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
            "Please enter valid number"
            ),
            // address: Yup.string().required('Please enter address'),
            users: Yup.array()
            .of(Yup.string().trim())
            .min(1, "Please select at least 1 user")
            .required("Please select user"),
            status: Yup.string().required('Please select status'),
        }),
        onSubmit: (values) => {
            putCentre(values);
        },
        enableReinitialize: true,
    });

    const usersChange = (options: readonly typeof Option[], setFieldValue) => {
        setFieldValue("users", [].slice.call(options).map((option) => option.id));
    };

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Update Centre</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="franchise_id">Franchise <span className="required">*</span></label>
                                                <Select
                                                    id="franchise_id"
                                                    name="franchise_id"
                                                    placeholder="Select Franchise"
                                                    options={franchises}
                                                    getOptionValue={(option)=>`${option['id']}`}
                                                    getOptionLabel={(option)=>`${option['name']}`}
                                                    onChange={ (option) => { 
                                                        if(option){
                                                            setFieldValue("franchise_id", option.id)
                                                            getPostcodesByFranchiseData(option.id)  
                                                            setFieldValue("postcode_id", '')
                                                        }else{
                                                            setFieldValue("franchise_id", '')
                                                            setPostcodes([]);
                                                            setFieldValue("postcode_id", '')
                                                        }
                                                    }}
                                                    value={franchises.filter(function(option) {
                                                        return option.id === values.franchise_id;
                                                    })}
                                                    isLoading={isLoadingFranchise}
                                                    isClearable={true}
                                                />
                                                {(touched.franchise_id && errors.franchise_id) && (
                                                    <div className="text-danger">
                                                        {errors.franchise_id}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="postcode_id">Postcode <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    id="postcode_id"
                                                    name="postcode_id"
                                                    onChange={handleChange} 
                                                    value={values.postcode_id}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {postcodes.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.postcode_id && errors.postcode_id) && (
                                                    <div className="text-danger">
                                                        {errors.postcode_id}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Centre Name<span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter centre name"
                                                    name="name" 
                                                    onChange={handleChange} 
                                                    value={values.name}
                                                />
                                                {(touched.name && errors.name) && (
                                                    <div className="text-danger">
                                                        {errors.name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="area_code">Area Code<span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="area_code" 
                                                    placeholder="Enter centre postcode"
                                                    name="area_code" 
                                                    onChange={handleChange} 
                                                    value={values.area_code}
                                                />
                                                {(touched.area_code && errors.area_code) && (
                                                    <div className="text-danger">
                                                        {errors.area_code}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1"> Email <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="email" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter centre email"
                                                    name="email" 
                                                    onChange={handleChange} 
                                                    value={values.email}
                                                />
                                                {(touched.email && errors.email) && (
                                                    <div className="text-danger">
                                                        {errors.email}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Phone Number</label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter phone number" 
                                                    name="phone_number" 
                                                    onChange={handleChange} 
                                                    value={values.phone_number}
                                                />
                                                {(touched.phone_number && errors.phone_number) && (
                                                    <div className="text-danger">
                                                        {errors.phone_number}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Address </label>
                                                <textarea 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter address" 
                                                    name="address" 
                                                    onChange={handleChange} 
                                                    value={values.address}
                                                ></textarea>
                                                {(touched.address && errors.address) && (
                                                    <div className="text-danger">
                                                        {errors.address}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Status <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={handleChange} 
                                                    value={values.status}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {centerStatus.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.status && errors.status) && (
                                                    <div className="text-danger">
                                                        {errors.status}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Assign Users <span className="required">*</span></label>
                                                <Select
                                                    placeholder={<div>-- Please select --</div>}
                                                    isMulti
                                                    options={centreUsers}
                                                    closeMenuOnSelect={false}
                                                    getOptionLabel={(option)=>option.first_name +' '+option.last_name}
                                                    getOptionValue={(option)=>option.id}
                                                    name="users"
                                                    onChange={ (e) => {usersChange(e, setFieldValue)}}
                                                    value={
                                                        centreUsers?.length
                                                        ? centreUsers.filter((obj) =>
                                                            values.users.includes(obj.id)
                                                            )
                                                        : values.users
                                                    } // set selected values
                                                    isLoading={isLoadingUsers}
                                                />
                                                <div style={{ marginBottom: 10 }} className="row">
                                                    <div className="col-sm-12">
                                                        {(touched.users && errors.users) && (
                                                            <div className="text-danger">
                                                                {errors.users}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-sm-12 text-right">
                                                        { canAccess?.create && <a href='#' onClick={handleShow}> + Add New User </a> }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className='col-md-12'>
                                        <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                                        <Link className="btn btn-default" to='/centers'>Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <AddCentreUserModal 
                show={show}
                handleClose={handleClose}
                setUserFlag={setUserFlag}
            />
        </section>
    </div>
  )
}

export default EditCentre;