import React, { useEffect, useState } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { deleteShippingAddressById, getFranchiseById, getFranchiseShippingAddressWithPagination, makeDefaultAddress } from '@app/services/franchise';
import DataTable, { TableStyles } from 'react-data-table-component';
import AddShippingAddressModal from './AddShippingAddressModal';
import UpdateShippingAddressModal from './UpdateShippingAddressModal';
import { confirmAlert } from 'react-confirm-alert';

const ManageShippingAddress = () => {

    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [flag, setFlag] = useState(false);
    const handleFlag = () => setFlag(!flag);
    const [shipAddressPayload, setUpdateDhipAddressPayload] = useState({franchise_id: '', id: '', address: '', phone: '', is_default: ''});

    const [addresses, setAddresses] = useState([]);
    const [franchise, setFranchise] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({address: '', phone: '', isDefault: ''});
    let { franchise_id } = useParams();

    useEffect(() => {
        getFranchiseShippingAddressWithPaginationData(franchise_id, 1, perPage, sortField, sortDirection);
    },[flag]);

    useEffect(() => {
        getFranchiseDetailsById(franchise_id);
    },[]);

    const getFranchiseDetailsById = async (franchise_id) => {
        try {
            setLoading(true);
            const resp = await getFranchiseById(franchise_id);
            if(resp.success === true){
                setLoading(false);
                setFranchise(resp.collections);
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getFranchiseShippingAddressWithPaginationData = async (franchise_id, page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const usersResp = await getFranchiseShippingAddressWithPagination(franchise_id, page, perPage, sortField, sortDirection, search);
            if (usersResp.success === true) {
                setLoading(false);
                setAddresses(usersResp.collections.data);
                setTotalRows(usersResp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(usersResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
                // borderBottomWidth: '0px',
                // borderBottomColor: theme.divider.default,
                // borderBottomStyle: 'solid',
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    const columns = [
        {
            name: 'Address',
            selector: (row: { address: string; }) => row.address,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: (row: { phone: string; }) => row.phone,
            sortable: true,
        },
        {  
            name: 'Action',
            cell: (row: { id: number, address: string, phone: string, is_default: boolean }) => (
              <>
                <a href='#' onClick={ (e) => { handleUpdateShippingAddress({franchise_id: franchise_id, id: row.id, address: row.address, phone: row.phone, is_default: row.is_default}) }} data-toggle="tooltip" title="Edit Shipping Address">
                    <i className="fas fa-pen mr-2"/>
                </a>
                {(row.is_default !== true) &&
                    <a href='#' onClick={ (e) => { deleteConfirm(e, row.id)} } data-toggle="tooltip" title="Delete Shipping Address">
                        <i className="fas fa-trash mr-2"/>
                    </a>
                }
                {(row.is_default !== true) &&
                    <a href='#' onClick={ (e) => { confirm(e, row.id)} } data-toggle="tooltip" title="Make Default Address">
                        <i className="fas fa-thumbtack mr-2"></i>
                    </a>
                }
              </>
            ),
        },
    ];

    const conditionalRowStyles = [
        {
            when: row => row.is_default === true,
            style: {
                backgroundColor: 'rgb(228, 253, 254)',
                // color: 'white',
            },
        }
    ];

    const handlePageChange = page => {
        getFranchiseShippingAddressWithPaginationData(franchise_id, page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getFranchiseShippingAddressWithPaginationData(franchise_id, page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getFranchiseShippingAddressWithPaginationData(franchise_id, currentPage, perPage, column.sortField, sortDirection);
    };

    // const handleResetFilter = async () => {
    //     setSearch({address: '', phone: '', isDefault: ''});
    // };

    const handleUpdateShippingAddress= async(payload) => {
        handleShow2();
        setUpdateDhipAddressPayload(payload)
    }

    const deleteConfirm = (e, id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to delete this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteAddress(id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const deleteAddress = async (id) => {
        try {
            setLoading(true);
            const resp = await deleteShippingAddressById(id);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                getFranchiseShippingAddressWithPaginationData(franchise_id, currentPage, perPage, sortField, sortDirection);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const confirm = (e, id) => {
        confirmAlert({
            title: 'Make Default Shipping Address!',
            message: 'Are you sure to make this default shipping address for order?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => makeDefaultAddressAPI(id, franchise_id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const makeDefaultAddressAPI = async (id, franchise_id) => {
        try {
            setLoading(true);
            const resp = await makeDefaultAddress({id: id, franchise_id: franchise_id});
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                getFranchiseShippingAddressWithPaginationData(franchise_id, currentPage, perPage, sortField, sortDirection);
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
          }
    };

    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manage Shipping Addresses</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active">Manage Shipping Addresses</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">{ franchise?.['name'] } - Shipping Addresses</h3>
                                        <div className="col-lg-12 text-right">
                                            <Link className="btn btn-default bg-gradient-secondary mr-2" to='/franchise'><i className="fas fa-caret-left"></i> Back</Link>
                                            <a className="btn btn-primary" onClick={handleShow}> + Add New Address </a>
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                    <DataTable
                                        columns={columns}
                                        data={addresses}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={currentPage}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                        progressPending={isLoading}
                                        sortServer
                                        onSort={handleSort}
                                        // paginationComponent
                                        // selectableRows
                                        customStyles={customStyle}
                                        conditionalRowStyles={conditionalRowStyles}
                                    />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                    
                    <UpdateShippingAddressModal
                        show={show2}
                        handleClose={handleClose2}
                        handleFlag={handleFlag}
                        payload={shipAddressPayload}
                    />
                    <AddShippingAddressModal
                        show={show}
                        handleClose={handleClose}
                        franchise_id={franchise_id}
                        handleFlag={handleFlag}
                    />
                </section>
                {/* /.content */}
            </div>

        </div>
    )
};

export default ManageShippingAddress;
