import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { useSelector } from 'react-redux';
import { getSettingPatternWithPagination } from '@app/services/settingPattern';
import ViewPatternDetailsModal from './ViewPatternDetailsModal';

const ListSettingPattern = () => {

    const [patterns, setPatterns] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({name: '', status: ''});
    const statuses = [{value:'active', label:'Active'}, {value:'inactive', label:'Inactive'}]
    // const selectRef = useRef(null);
    const [patternId, setPatternId] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const canAccess = useSelector((state: any) => state.auth.permissions['setting-pattern']);

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };
      
    useEffect(() => {
        getSettingPatternData(1, perPage, sortField, sortDirection);
    },[search]);

    const getSettingPatternData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const resp = await getSettingPatternWithPagination(page, perPage, sortField, sortDirection, search);
            if (resp.success === true) {
                setLoading(false);
                setPatterns(resp?.collections?.data);
                setTotalRows(resp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Internal server error');
        }
    };

    const columns = [
        {
            name: 'Name',
            selector: (row: { name: string; }) => row.name ?? '---',
            sortable: true,
            sortField: 'name',
        },
        {
            name: 'Streams Per Week',
            selector: (row: { modules_per_week: string; }) => row.modules_per_week,
            sortable: true,
            sortField: 'modules_per_week',
        },
        {
            name: 'Status',
            selector: (row: { status: string; }) => row.status,
            cell: (row: { status: string; }) => (
                row.status === 'active' ? <span className="badge bg-success">Active</span> : <span className="capitalize badge bg-danger">{row.status}</span>
            ),
            sortable: true,
            sortField: 'status',
        },
        {  
            name: 'Action',
            cell: (row: { id: number; }) => (
              <>
                { canAccess?.view &&
                    <a href='#' onClick={ (e) => { handleViewPatternModal(row.id)} }>
                        <i className="fas fa-eye mr-2" title="View Pattern Details"></i>
                    </a>
                }
                { canAccess?.edit &&
                    <Link
                        to={`/setting-patterns/edit/${row.id}`}
                        role="button"
                        state={{ id: row.id }}
                        title="Edit Pattern Details"
                    >
                        <i className="fas fa-pen mr-2" />
                    </Link>
                }
              </>
            ),
            omit: !(canAccess?.view || canAccess?.edit)
        },
    ];

    const handleViewPatternModal = (id) => {
        handleShow();
        setPatternId(id);
    }

    const handlePageChange = page => {
        getSettingPatternData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getSettingPatternData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getSettingPatternData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        // selectRef.current.setValue('');
        setSearch({name: '', status: ''});
    };
    
    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Setting Patterns</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active">Setting Patterns</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Setting Patterns</h3>
                                        <div className="col-lg-12 text-right">
                                            { canAccess?.create && 
                                                <Link className="btn btn-primary mr-2" to="/setting-patterns/add" > + Add New Pattern </Link> 
                                            }
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <div className="col-sm-3">
                                                <input type="text" name='name' className="form-control" value={search.name} onChange={(e) => setSearch({ ...search, name: e.target.value })} placeholder="Search By Pathway Name" />
                                            </div>
                                            <div className="col-sm-3">
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={(e) => setSearch({ ...search, status: e.target.value })}
                                                    value={search.status}
                                                >
                                                    <option value="">Search By Status</option>
                                                    {statuses.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-sm-2'>
                                                <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                            </div>
                                        </div>
                                        
                                        <DataTable
                                            columns={columns}
                                            data={patterns}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationDefaultPage={currentPage}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            paginationPerPage={perPage}
                                            paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                            progressPending={isLoading}
                                            sortServer
                                            onSort={handleSort}
                                            // paginationComponent
                                            // selectableRows
                                            customStyles={customStyle}
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            <ViewPatternDetailsModal
                show={show}
                handleClose={handleClose}
                id={patternId}
            />
        </div>
    )
};

export default ListSettingPattern;