import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CurrencyFormat from 'react-currency-format';
import { getTransactionDetails } from "@app/services/studentCount";
import moment from "moment";
import { Link, useParams } from 'react-router-dom';

const ViewStudCountTransaction = () => {

    let { id } = useParams();
	let obj: any = {}
	const [payableAmt, setPayableAmt] = useState(0);
	const [transaction, setTransaction] = useState(obj);
    const statuses = [{label:'Failed', value:'failed'}, {label:'Success', value:'success'}, {label:'Unpaid', value:'unpaid'}];

	useEffect(() => {
        getTransactionDetailsAPI(id);
    },[]);

	const getTransactionDetailsAPI = async (id) => {
        try {
            const resp = await getTransactionDetails(id);
            if (resp?.success === true) {
				setTransaction(resp?.collections);
				setPayableAmt(resp?.collections?.total_price)
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Internal server error');
        }
    };

	return (
		<>
		<section className="content">
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-12 col-lg-12 col-xl-12">
						<div className="card card-primary">
							<div className="card-header">
								<h3 className="card-title">View Transaction Details</h3>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-sm-3">
										<div className="form-group">
											<dt>Transaction Id</dt>
											<dd>#{ transaction?.id }</dd>
										</div>
									</div>
									{/* <div className="col-sm-3">
										<div className="form-group">
											<dt>Invoice Id</dt>
											<dd>{ transaction?.invoice_no }</dd>
										</div>
									</div>
									*/}
									<div className="col-sm-3">
										<div className="form-group">
											<dt>Franchise Name</dt>
											<dd>{ transaction?.name }</dd>
										</div>
									</div>
									<div className="col-sm-3">
										<div className="form-group">
											<dt>Transaction Date </dt>
											<dd>{moment(transaction?.created_at).format('llll')}</dd>
										</div>
									</div>
									<div className="col-sm-3">
										<div className="form-group">
											<dt>Transaction Status </dt>
											<dd>
												{ <span className={ transaction?.status == 'success' ? 'badge badge-success' : (transaction?.status == 'unpaid' ? 'badge badge-warning' : 'badge badge-danger') }>{ (statuses.find(obj => { return obj.value === transaction.status}))?.label }</span> }
											</dd>
										</div>
									</div>
									<div className="col-sm-3">
										<div className="form-group">
											<dt>Net Amount </dt>
											<dd><CurrencyFormat value={ transaction?.net_price } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></dd>
										</div>
									</div>
									<div className="col-sm-3">
										<div className="form-group">
											<dt>Vat Amount</dt>
											<dd><CurrencyFormat value={ transaction?.vat } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></dd>
										</div>
									</div>
									<div className="col-sm-3">
										<div className="form-group">
											<dt>Total Amount </dt>
											<dd><CurrencyFormat value={ transaction?.total_price } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /></dd>
										</div>
									</div>
									<div className="col-sm-3">
										<div className="form-group">
											<dt>Stripe Payment Id </dt>
											<dd>{ transaction?.stripe_payment_id ?? '---' }</dd>
										</div>
									</div> 
									<div className="col-sm-3">
										<div className="form-group">
											<dt>Kashflow Order Account </dt>
											<dd>{ transaction?.kashflow_order_account ?? '---' }</dd>
										</div>
									</div> 
								</div>
							</div>
							<section className='modul_sec_wrapper'>
								<div className='container-fluid'>
									<div className="row">
										<div className="col-md-12 col-lg-12 col-xl-12">
											<div className="content" style={{paddingTop: 0}}>
												{/* <h6 className="mb-4 grey_wrapper_title d-flex justify-content-between">Student Count Details 
													<span>
														<b>Total Student Count:</b> { transaction?.total_count ??  0 }
													</span>
												</h6> */}
												{/* <div className="m-2 d-flex justify-content-between align-items-center">
													<span>
														<b>Total Amount:</b> <CurrencyFormat value={ payableAmt } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} />
													</span>
													<span>
														<Link className="btn btn-default" to='/student-count-transactions'>Back</Link>
													</span>
												</div> */}
												{ transaction?.['details'] && transaction?.['details'] && (
													<>
														<div className="content_inner_wrap" key={1}>
															<Row>
																<Col xl={12}>
																	<b>Student Counts</b>
																</Col>
																<Col xl={12}>
																	<div className="list_module_wrapper">
																		<Row>
																			{(transaction?.['details']).length > 0 && (
																				<Col xl={12}>
																					<table className="table table-bordered" id="client_id_table">
																						<tbody>
																							<tr className='text-center'>
																								<th> Month</th>
																								<th> Year</th>
																								<th> Mathematics</th>
																								<th> English</th>
																								<th> Science</th>
																								<th> Total Subjects</th>
																								<th> Net Amount</th>
																								<th> VAT Amount</th>
																								<th> Total Amount</th>
																							</tr>
																							{(transaction?.['details']).map((count, i) => (
																								<tr className='text-center' key={i}>
																									<td> {moment(count.month).format('MMMM')}</td>
																									<td> {count.year} </td>
																									<td> {count.maths} </td>
																									<td> {count.english} </td>
																									<td> {count.science} </td>
																									<td> {count.total} </td>
																									<td> <CurrencyFormat value={count.net_price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /> </td>
																									<td> <CurrencyFormat value={count.vat_price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /> </td>
																									<td> <CurrencyFormat value={count.total_price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /> </td>
																								</tr>
																							))}
																							<tr className='text-center'>
																								<td colSpan={7}> </td>
																								<td> <b>Total Payable</b> </td>
																								<td> <CurrencyFormat value={payableAmt} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /> </td>
																							</tr>
																						</tbody>
																					</table>
																				</Col>
																			)}

																			{(transaction?.['details']).length == 0 && (
																				<span>There are no records to display.</span>
																			)}
																		</Row>
																	</div>
																</Col>
															</Row>
														</div>
														<div className="content_inner_wrap" key={2}>
															<Row>
																<Col xl={12}>
																	<b>Monthly Student Subject Rate (excl VAT) </b>
																</Col>
																<Col xl={12}>
																	<div className="list_module_wrapper">
																		<Row>
																			{(transaction?.['details']).length > 0 && (
																				<Col xl={6}>
																					<table className="table table-bordered" id="client_id_table">
																						<tbody>
																							<tr className='text-center'>
																								<th> Month</th>
																								<th> Year</th>
																								<th> Mathematics</th>
																								<th> English</th>
																								<th> Science</th>
																							</tr>
																							{(transaction?.['details']).map((count, i) => (
																								<tr className='text-center' key={i}>
																									<td> {moment(count.month).format('MMMM')}</td>
																									<td> {count.year} </td>
																									<td> <CurrencyFormat value={count.maths_rate} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /> </td>
																									<td> <CurrencyFormat value={count.english_rate} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /> </td>
																									<td> <CurrencyFormat value={count.science_rate} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /> </td>
																								</tr>
																							))}
																						</tbody>
																					</table>
																				</Col>
																			)}

																			{(transaction?.['details']).length == 0 && (
																				<span>There are no records to display.</span>
																			)}
																		</Row>
																	</div>
																</Col>
															</Row>
														</div>
													</>
												)}
											</div>
										</div>
									</div>
								</div>
							</section>
							<div className="card-footer">
								{ transaction?.status !== 'success' && payableAmt > 0 &&
									<Link  to={`/student-counts/pay/${transaction?.id}`}
									role="button"
									data-toggle="tooltip" title="Pay Amount"
									className="btn btn-success mr-2"><i className="far fa-credit-card"></i> Pay Amount</Link>
								}
								<Link className="btn btn-default" to='/student-count-transactions'>Back</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>	
		</>
	);
};

export default ViewStudCountTransaction;