import "../../pages/order-form/OrderForm.css";
import { Form, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@app/components";
import { useDispatch, useSelector } from "react-redux";
import { loadCart, setShippingAddress, setStep, updateShippingDetails } from "@app/store/reducers/order";
import { getCartInvoiceByFranchise, getCartItemsByFranchise } from "@app/services/order";
import { useFormik } from "formik";
import * as Yup from 'yup';
import CurrencyFormat from 'react-currency-format';
import ShippingAddressModal from "./ShippingAddressModal";
import { getAllShippingAddressByFranchise } from "@app/services/franchise";

const OrderFormStep3 = () => {

	const [payableAmt, setPayableAmt] = useState(0);
	const [isCartLoading, setCartLoading] = useState(false);
    const orderStore = useSelector((state: any) => state.order);
	const shipAddr = useSelector((state: any) => state.order?.shippingAddress);
    const dispatch = useDispatch();
	
	const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

	const [addresses, setAddresses] = useState([]);
    const [isLoading, setLoading] = useState(false);

	useEffect(() => {
        getCartItemsByFranchiseData(orderStore?.franchise?.id);
		getCartInvoiceDataByFranchise(orderStore?.franchise?.id);
		getAllShippingAddressData();
    },[]);

	const getCartItemsByFranchiseData = async (franchiseId) => {
        try {
            setCartLoading(true);
            const resp = await getCartItemsByFranchise(franchiseId);
            if (resp?.success === true) {
				setCartLoading(false);
				dispatch(loadCart(resp?.collections));
				if(Object.keys(resp?.collections).length === 0){
					toast.warning('Your basket is empty');
					dispatch(setStep(2));
				}
            } else {
				setCartLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setCartLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

	const getCartInvoiceDataByFranchise = async (franchiseId) => {
        try {
            setCartLoading(true);
            const resp = await getCartInvoiceByFranchise(franchiseId);
            if (resp?.success === true) {
				setCartLoading(false);
				setPayableAmt(resp?.collections?.total?.payable)
            } else {
				setCartLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setCartLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

	const getAllShippingAddressData = async () => {
        try {
            setLoading(true);
            const resp = await getAllShippingAddressByFranchise(orderStore?.franchise?.id);
            if (resp?.success === true) {
                setLoading(false);
                if((resp?.collections).length > 0){
					setAddresses(resp?.collections);
					let defaultShipAddr = (resp?.collections).find(obj => {
						return obj.is_default == true 
					})
					if(defaultShipAddr){
						dispatch(setShippingAddress(defaultShipAddr))
					}else{
						dispatch(setShippingAddress(resp?.collections[0]))
					}
				}
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

	const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            address: shipAddr?.address || '',
            phone: shipAddr?.phone || '',
			default_order: false,
			handbook_binding: orderStore?.franchise?.handbook_binding || '',
			instructions: orderStore?.franchise?.instructions || ''
        },
        validationSchema: Yup.object({
            address: Yup.string().required('Please enter shipping address'),
            phone: Yup.string().required('Please enter contact number')
            .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
				"Please enter valid number"
			),
			handbook_binding: Yup.string().required('Please select handbook binding'),
			instructions: Yup.string().max(300, 'Instructions must be at most 300 characters'),
        }),
        onSubmit: (values) => {
			dispatch(updateShippingDetails(values));
            dispatch(setStep(4));
        },
		enableReinitialize: true,
    });

	const handleBack = () => {
		dispatch(setStep(2));
	}

	return (
		<>
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-12 col-lg-12 col-xl-12">
					<div className="content">
						<h6 className="mb-4 grey_wrapper_title d-flex justify-content-between">Checkout 
							<span>
								<b>Total Sets Ordered:</b> { orderStore?.cart?.total_sets ? orderStore?.cart?.total_sets : 0 } <br></br>
								<b>Total Handbooks Ordered:</b> { orderStore?.cart?.total_handbooks ? orderStore?.cart?.total_handbooks : 0 }
							</span>
						</h6>
						<Form onSubmit={handleSubmit}>
							<div className="m-2 d-flex justify-content-between align-items-center">
								<span>
									<b>Total Payable:</b> <CurrencyFormat value={ payableAmt } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} />
								</span>
								
								<span>
									<Button type="submit" isLoading={isCartLoading} className="btn btn-primary mr-2"> Checkout </Button>
									<Button type="button" isLoading={isCartLoading} onClick={ handleBack } className="btn btn-default "> Back </Button>
								</span>
							</div>
							<div className="content_inner_wrap">
								<div className="row">
									<div className="col-sm-4">
										<div className="form-group">
											<label htmlFor="exampleInputEmail1"><b>Shipping Address</b> <span className="required">*</span></label>
											<textarea 
												className="form-control" 
												id="exampleInputEmail1" 
												placeholder="Enter address" 
												name="address" 
												onChange={handleChange} 
												value={values.address}
												rows={2}
											></textarea>
											{(touched.address && errors.address) && (
												<div className="text-danger">
													{errors.address}
												</div>
											)}
										</div>
									</div>
									<div className="col-sm-4">
										<div className="form-group">
											<label htmlFor="exampleInputEmail1"><b>Contact Number</b> <span className="required">*</span></label>
											<input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter contact number" 
												name="phone" 
												onChange={handleChange} 
												value={values.phone}
											/>
											{(touched.phone && errors.phone) && (
												<div className="text-danger">
													{errors.phone}
												</div>
											)}
											{addresses.length > 0 && (
												<a href="#" onClick={handleShow} className="text-right">Choose the shipping address.</a>
											)}
										</div>
									</div>
									<div className="col-sm-4">
										<div className="form-group">
											<label htmlFor="exampleInputEmail1"><b>Handbook Binding</b> <span className="required">*</span></label>
											<div className="custom-control custom-radio" key="spiralBound">
												<input className="custom-control-input" type="radio" id="customRadio1" 
													name="handbook_binding" 
													value="Spiral Bound" 
													onChange={(e) => {
														handleChange
														setFieldValue("handbook_binding", e.target.value)
													}} 
													checked={values.handbook_binding === 'Spiral Bound'}
												/>
												<label htmlFor="customRadio1" className="custom-control-label">Spiral Bound (default)</label>
											</div>
											<div className="custom-control custom-radio" key="saddleStitched">
												<input className="custom-control-input" type="radio" id="customRadio2" 
													name="handbook_binding" 
													value="Saddle Stitched" 
													onChange={(e) => {
														handleChange
														setFieldValue("handbook_binding", e.target.value)
													}}
													checked={values.handbook_binding === 'Saddle Stitched'} 
												/>
												<label htmlFor="customRadio2" className="custom-control-label">Saddle Stitched</label>
											</div>
											{(touched.handbook_binding && errors.handbook_binding) && (
												<div className="text-danger">
													{errors.handbook_binding}
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-4">
										<div className="form-group">
											<label htmlFor="exampleInputEmail1"><b>Instructions If No One At Home To Receive Delivery</b> </label>
											<textarea 
												className="form-control" 
												id="exampleInputEmail1" 
												placeholder="Enter instructions if no one at home to receive delivery  (failure to complete may affect delivery of your order)" 
												name="instructions" 
												onChange={handleChange} 
												value={values.instructions}
												rows={3}
											></textarea>
											{(touched.instructions && errors.instructions) && (
												<div className="text-danger">
													{errors.instructions}
												</div>
											)}
										</div>
									</div>
									<div className="col-sm-4">
										<div className="form-group">
											<label htmlFor="franchise_id"><b> Do you want to make this a default order? </b></label>
											<div className="icheck-primary">
												<input type="checkbox"className="form-check-input" id="default_order"  
													onChange={(e) => { 
														handleChange 
														setFieldValue("default_order", e.target.checked)
													}}
												/>
												<label htmlFor="default_order" className="form-check-label"></label>
											</div>
										</div>
									</div>
								</div>
							</div>
							{ Object.keys(orderStore.cart).length === 0 && (
								<div className="content_inner_wrap"> 
								<Row>
									<Col xl={12}>
										Your basket is empty.
									</Col>
								</Row>
								</div>
							)}
							{ orderStore.cart['english'] && (
								<div className="content_inner_wrap" key={1}>
									<Row>
										<Col xl={12}>
											<b>English</b>
										</Col>
										<Col xl={12}>
										{ (orderStore.cart['english']).map((module) => (
											<div className="list_module_wrapper" key={module?.slug}>
												<Row>
													<Col xl={12}>
														<h6 className="mb-4 grey_wrapper_title">
															{ module.name } ({ module.description })
														</h6>
													</Col>
													<Col xl={4} md={4} sm={4}>
														<table className="table table-bordered" id="client_id_table">
															<tbody>
																{ module?.allsets[0]?.qty && (
																	<tr>
																		<th> Entire Module</th>
																		<td> { module?.allsets[0]?.qty }</td>
																	</tr>
																)}
																{ module?.handbooks.length > 0 && module?.handbooks.map((handbook, i) => (
																	<tr key={i}>
																		<th> Handbook
																			{handbook?.combined_name && (
																				<span className="font-italic font-weight-light ml-2">({handbook?.combined_name})</span>
																			)}
																		</th>
																		<td> { handbook?.qty }</td>
																	</tr>
																))}
																{ module?.handbook_suppliments.length > 0 && module?.handbook_suppliments.map((handbook, i) => (
																	<tr key={i}>
																		<th> Handbook Supplements</th>
																		<td> { handbook?.qty }</td>
																	</tr>
																))}
															</tbody>
														</table>
													</Col>
													{ (module?.blocks).length > 0 && (
														<Col xl={4} md={4} sm={4}>
															<table className="table table-bordered" id="client_id_table">
																<tbody>
																	<tr className='text-center'>
																		<th> Blocks</th>
																		<th> Quantity</th>
																	</tr>
																	{ module?.blocks.map((block, i) => (
																		<tr className='text-center' key={i}>
																			<td> { (block?.name).slice(4) }</td>
																			<td> { block?.qty } </td>
																		</tr>
																	))}
																</tbody>
															</table>
														</Col>
													)}
													{ (module?.sets).length > 0 && (
														<Col xl={4} md={4} sm={4}>
															<table className="table table-bordered" id="client_id_table">
																<tbody>
																	<tr className='text-center'>
																		<th> Sets</th>
																		<th> Quantity</th>
																	</tr>
																	{ module?.sets.map((set, i) => (
																		<tr className='text-center' key={i}>
																			<td> { (set?.name).slice(4) }</td>
																			<td> { set?.qty } </td>
																		</tr>
																	))}
																</tbody>
															</table>
														</Col>
													)}
												</Row>
											</div>
										))}
										</Col>
									</Row>
								</div>
							)}
							{ orderStore.cart['mathematics'] && (
								<div className="content_inner_wrap" key={2}>
									{/* <button className="cross_close">X</button> */}
									<Row>
										<Col xl={12}>
											<b>Mathematics</b>
										</Col>
										<Col xl={12}>
										{ (orderStore.cart['mathematics']).map((module) => (
											<div className="list_module_wrapper" key={module?.slug}>
												{/* <button className="cross_close">X</button> */}
												<Row>
													<Col xl={12}>
														<h6 className="mb-4 grey_wrapper_title">
															{ module.name } ({ module.description })
														</h6>
													</Col>
													<Col xl={4}>
														<table className="table table-bordered" id="client_id_table">
															<tbody>
																{ module?.allsets[0]?.qty && (
																	<tr>
																		<th> Entire Module</th>
																		<td> { module?.allsets[0]?.qty }</td>
																	</tr>
																)}
																{ module?.handbooks.length > 0 && module?.handbooks.map((handbook, i) => (
																	<tr key={i}>
																		<th> Handbook 
																			{handbook?.combined_name && (
																				<span className="font-italic font-weight-light ml-2">({handbook?.combined_name})</span>
																			)}
																		</th>
																		<td> { handbook?.qty }</td>
																	</tr>
																))}
																{ module?.handbook_suppliments.length > 0 && module?.handbook_suppliments.map((handbook, i) => (
																	<tr key={i}>
																		<th> Handbook Supplements</th>
																		<td> { handbook?.qty }</td>
																	</tr>
																))}
															</tbody>
														</table>
													</Col>
													{ (module?.blocks).length > 0 && (
														<Col xl={4}>
															<table className="table table-bordered" id="client_id_table">
																<tbody>
																	<tr className='text-center'>
																		<th> Blocks</th>
																		<th> Quantity</th>
																	</tr>
																	{ module?.blocks.map((block, i) => (
																		<tr className='text-center' key={i}>
																			<td> { (block?.name).slice(4) }</td>
																			<td> { block?.qty } </td>
																		</tr>
																	))}
																</tbody>
															</table>
														</Col>
													)}
													{ (module?.sets).length > 0 && (
														<Col xl={4}>
															<table className="table table-bordered" id="client_id_table">
																<tbody>
																	<tr className='text-center'>
																		<th> Sets</th>
																		<th> Quantity</th>
																	</tr>
																	{ module?.sets.map((set, i) => (
																		<tr className='text-center' key={i}>
																			<td> { (set?.name).slice(4) }</td>
																			<td> { set?.qty } </td>
																		</tr>
																	))}
																</tbody>
															</table>
														</Col>
													)}
												</Row>
											</div>
										))}
										</Col>
									</Row>
								</div>
							)}
							{ orderStore.cart['science'] && (
								<div className="content_inner_wrap" key='science'>
									<Row>
										<Col xl={12}>
											<b>Science</b>
										</Col>
										<Col xl={12}>
										{ (orderStore.cart['science']).map((module) => (
											<div className="list_module_wrapper" key={module?.slug}>
												<Row>
													<Col xl={12}>
														<h6 className="mb-4 grey_wrapper_title">
															{ module.name } ({ module.description })
														</h6>
													</Col>
													<Col xl={4} md={4} sm={4}>
														<table className="table table-bordered" id="client_id_table">
															<tbody>
																{ module?.allsets[0]?.qty && (
																	<tr>
																		<th> Entire Module</th>
																		<td> { module?.allsets[0]?.qty }</td>
																	</tr>
																)}
																{ module?.handbooks.length > 0 && module?.handbooks.map((handbook, i) => (
																	<tr key={i}>
																		<th> Handbook 
																			{handbook?.combined_name && (
																				<span className="font-italic font-weight-light ml-2">({handbook?.combined_name})</span>
																			)}
																		</th>
																		<td> { handbook?.qty }</td>
																	</tr>
																))}
																{ module?.handbook_suppliments.length > 0 && module?.handbook_suppliments.map((handbook, i) => (
																	<tr key={i}>
																		<th> Handbook Supplements</th>
																		<td> { handbook?.qty }</td>
																	</tr>
																))}
															</tbody>
														</table>
													</Col>
													{ (module?.blocks).length > 0 && (
														<Col xl={4} md={4} sm={4}>
															<table className="table table-bordered" id="client_id_table">
																<tbody>
																	<tr className='text-center'>
																		<th> Blocks</th>
																		<th> Quantity</th>
																	</tr>
																	{ module?.blocks.map((block, i) => (
																		<tr className='text-center' key={i}>
																			<td> { (block?.name).slice(4) }</td>
																			<td> { block?.qty } </td>
																		</tr>
																	))}
																</tbody>
															</table>
														</Col>
													)}
													{ (module?.sets).length > 0 && (
														<Col xl={4} md={4} sm={4}>
															<table className="table table-bordered" id="client_id_table">
																<tbody>
																	<tr className='text-center'>
																		<th> Sets</th>
																		<th> Quantity</th>
																	</tr>
																	{ module?.sets.map((set, i) => (
																		<tr className='text-center' key={i}>
																			<td> { (set?.name).slice(4) }</td>
																			<td> { set?.qty } </td>
																		</tr>
																	))}
																</tbody>
															</table>
														</Col>
													)}
												</Row>
											</div>
										))}
										</Col>
									</Row>
								</div>
							)}
							{ orderStore.cart['other_items'] && (
								<div className="content_inner_wrap" key={3}>
									{/* <button className="cross_close">X</button> */}
									<Row>
										<Col xl={12}>
											<b>Other Items</b>
										</Col>
										<Col xl={12}>
											<div className="list_module_wrapper">
												<Row>
													{ (orderStore.cart['other_items']).length > 0 && (
														<Col xl={6}>
															<table className="table table-bordered" id="client_id_table">
																<tbody>
																	<tr className='text-center'>
																		<th> Item</th>
																		<th> Quantity</th>
																	</tr>
																	{ (orderStore.cart['other_items']).map((item, i) => (
																		<tr className='text-center' key={i}>
																			<td> { item.name }</td>
																			<td> { item.qty } </td>
																		</tr>
																	))}
																</tbody>
															</table>
														</Col>
													)}

													{ (orderStore.cart['other_items']).length == 0 && (
														<span>Your Other Item's basket is empty.</span>
													)}
												</Row>
											</div>
										</Col>
									</Row>
								</div>
							)}
							<div className="mt-2 d-flex justify-content-end align-items-center">
								{/* <span>
									<b>Total Sets:240</b>
								</span> */}
								<Button type="submit" isLoading={isCartLoading} className="btn btn-primary mr-2"> Checkout </Button>
								<Button type="button" isLoading={isCartLoading} onClick={ handleBack } className="btn btn-default "> Back </Button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
		<ShippingAddressModal
			show={show}
			handleClose={handleClose}
			addresses={addresses}
		/>
		</>
	);
};

export default OrderFormStep3;