// import "../../pages/order-form/OrderForm.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { Button } from "@app/components";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { getAllActiveFranchise } from "@app/services/franchise";
import { useDispatch } from "react-redux";
import { setFranchise, setStep } from "@app/store/reducers/studentCount";

const SelectFranchise = () => {

	const [franchises, setFranchises] = useState([]);
    const [postcodes, setPostcodes] = useState('');
	const [selectedFranchise, setSelectedFranchise] = useState({});
	const [isLoadingFranchise, setLoadingFranchise] = useState(false);
    const [isCartLoading, setCartLoading] = useState(false);
    const dispatch = useDispatch();
	
	useEffect(() => {
        dispatch(setStep(1));
        dispatch(setFranchise(selectedFranchise));
		getAllActiveFranchiseData();
    },[]);

	const getAllActiveFranchiseData = async () => {
        try {
            setLoadingFranchise(true);
            const resp = await getAllActiveFranchise();
            if (resp?.success === true) {
                setLoadingFranchise(false);
                setFranchises(resp?.collections.filter((franchise) => franchise.enabled_student_count == true));
            } else {
                setLoadingFranchise(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoadingFranchise(false);
            toast.error(error?.response?.message || 'Internal server error');
        }
    };

	const { handleChange, values, handleSubmit, touched, errors, setFieldValue} = useFormik({
        initialValues: {
            franchise_id: '',
        },
        validationSchema: Yup.object({
            franchise_id: Yup.string().required('Please select franchise'),
        }),
        onSubmit: (values) => {
			dispatch(setFranchise(selectedFranchise));
            dispatch(setStep(2));
        }
    });

	return (
		<>
			<div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="franchise_id"><b> Franchise </b><span className="required">*</span></label>
                                                <Select
                                                    // className="form-control"
                                                    name="franchise_id"
                                                    placeholder="Select Franchise"
                                                    options={franchises}
                                                    getOptionValue={(option)=>`${option['id']}`}
                                                    getOptionLabel={(option)=>`${option['name']}`}
                                                    onChange={ (option) => { 
														handleChange
                                                        if(option){
                                                            setFieldValue("franchise_id", option.id)
															setSelectedFranchise(option) 
                                                            setPostcodes((option.postcodes).length > 0 ? ( Array.prototype.map.call(option.postcodes, function(postcode) { return postcode.name; }).join(", ") ) : '') 
                                                        }else{
                                                            setFieldValue("franchise_id", '')
															setSelectedFranchise({})
                                                        }
                                                    }}
                                                    // value={values.franchise_id}
                                                    isLoading={isLoadingFranchise}
                                                    isClearable={true}
                                                />
                                                <span className='text-muted'> { postcodes }</span>
                                                {(touched.franchise_id && errors.franchise_id) && (
                                                    <div className="text-danger">
                                                        {errors.franchise_id}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
								<div className="card-footer">
                                    <Button type="submit" className="btn btn-primary mr-2">Next </Button>
                                    <Link className="btn btn-default" to='/student-counts'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
		</>
	);
};

export default SelectFranchise;