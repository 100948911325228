import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import { Form, OverlayTrigger, Popover} from 'react-bootstrap';
import { useFormik } from 'formik';
import { Button } from '@app/components';
import { toast } from 'react-toastify';
import { getModuleById, updateModule } from '@app/services/module';
import { getAllSubjects } from '@app/services/subject';

const EditModule = () => {

    const moduleStatus = [ 
        {id:'active', name:'Active'},
        {id:'inactive', name:'Inactive'}
    ]
    let obj: any = {}
    const [subjects, setSubjects] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [show, setOverlayShow] = useState(false);
    const [modulesData, setModulesData] = useState(obj);
    const navigate = useNavigate();
    let { id } = useParams();

    useEffect(() => {
        getSubjectsData();
        getModuleDetailsById(id);
    },[]);

    const getModuleDetailsById = async (id) => {
        try {
            setLoading(true);
            const resp = await getModuleById(id);
            if(resp.success === true){
                setLoading(false);
                setModulesData(resp.collections);
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };
    
    const getSubjectsData = async () => {
        try {
            const resp = await getAllSubjects();
            if (resp.success === true) {
                setSubjects(resp.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const putModule = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateModule(id, payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                navigate('/modules');
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const { handleChange, values, handleSubmit, touched, errors } = useFormik({
        initialValues: {
            subject_id: modulesData.subject_id || '',
            name: modulesData.name || '',
            module_id: modulesData.module_id || '',
            description: modulesData.description || '',
            status: modulesData.status || '',
        },
        validationSchema: Yup.object({
            subject_id: Yup.string().required('Please select subject'),
            name: Yup.string().required('Please enter module name'),
            module_id: Yup.string().required('Please enter module id'),
            status: Yup.string().required('Please select status'),
        }),
        onSubmit: (values) => {
            putModule(values);
        },
        enableReinitialize: true,
    });

    const popover = (
        <Popover id="popover-basic">
            <div className='m-2'>
                The module name should be in the format of: <b>E-M-HW1</b><br/>
                <b>E</b> for English (selected module subject) <br/>
                <b>M</b> for Module<br/>
                <b>HW1</b> for module name short form (Handwriting 1)
            </div>
        </Popover>
    );

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Update Module</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Subject <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="subject_id"
                                                    onChange={handleChange} 
                                                    value={values.subject_id}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {subjects.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.subject_id && errors.subject_id) && (
                                                    <div className="text-danger">
                                                        {errors.subject_id}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Module <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter module name"
                                                    name="name" 
                                                    onChange={handleChange} 
                                                    value={values.name}
                                                />
                                                {(touched.name && errors.name) && (
                                                    <div className="text-danger">
                                                        {errors.name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Module Id
                                                    <span className="required"> * </span>
                                                </label>
                                                
                                                <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
                                                    <i className='fa fa-info-circle' onClick={() => setOverlayShow(!show)} />
                                                </OverlayTrigger>

                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter module id (E-M-HW1)"
                                                    name="module_id" 
                                                    onChange={handleChange} 
                                                    value={values.module_id}
                                                />
                                                {(touched.module_id && errors.module_id) && (
                                                    <div className="text-danger">
                                                        {errors.module_id}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Description </label>
                                                <textarea 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter module description" 
                                                    name="description" 
                                                    onChange={handleChange} 
                                                    value={values.description}
                                                ></textarea>
                                                {(touched.description && errors.description) && (
                                                    <div className="text-danger">
                                                        {errors.description}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Status <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={handleChange} 
                                                    value={values.status}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {moduleStatus.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.status && errors.status) && (
                                                    <div className="text-danger">
                                                        {errors.status}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className='col-md-12'>
                                        <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                                        <Link className="btn btn-default" to='/modules'>Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default EditModule