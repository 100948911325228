import { createSlice } from '@reduxjs/toolkit';

export interface OrderState {
	step: number;
	franchise: any;
	centre_id: number;
	isDefaultOrderLoad: boolean;
	cart: [];
	sync: boolean;
	shippingAddress: any;
}

const initialState: OrderState = {
	step: 1,
	franchise: null,
	centre_id: null,
	isDefaultOrderLoad:false,
	cart: [],
	sync: false,
	shippingAddress:null
};

export const orderSlice = createSlice({
	name: 'order',
	initialState,
	reducers: {
		loadCart: (state, { payload }) => {
			state.cart = payload;
		},
		clearCart: (state) => {
			state.step= 1;
			state.franchise= null;
			state.centre_id= null;
			state.isDefaultOrderLoad= false;
			state.cart = [];
		},
		updateModuleQty: (state, { payload }) => {
			const index = state.cart[`${payload.subject}`].findIndex(
				(module) => module.id === payload.module_id
			);
			state.cart[`${payload.subject}`][index].allsets[0].qty = payload.qty;
			orderSlice.caseReducers.setSynch(state, { payload: true, type: null });
		},
		deleteModuleAllSets: (state, { payload }) => {
			const moduleIndex = state.cart[`${payload.subject}`].findIndex(
				(module) => module.id === payload.module_id
			);
			state.cart[`${payload.subject}`][moduleIndex].allsets = [];
		},
		updateHandbookQty: (state, { payload }) => {
			const moduleIndex = state.cart[`${payload.subject}`].findIndex(
				(module) => module.id === payload.module_id
			);

			const index = state.cart[`${payload.subject}`][moduleIndex].handbooks.findIndex(
				(set) => set.cart_id === payload.cart_id
			);
			state.cart[`${payload.subject}`][moduleIndex].handbooks[index].qty = payload.qty;
			orderSlice.caseReducers.setSynch(state, { payload: true, type: null });
		},
		deleteHandbook: (state, { payload }) => {
			const moduleIndex = state.cart[`${payload.subject}`].findIndex(
				(module) => module.id === payload.module_id
			);
			state.cart[`${payload.subject}`][moduleIndex].handbooks = [];
		},
		updateHBSuppQty: (state, { payload }) => {
			const index = state.cart[`${payload.subject}`].findIndex(
				(module) => module.id === payload.module_id
			);
			state.cart[`${payload.subject}`][index].handbook_suppliments[0].qty = payload.qty;
			orderSlice.caseReducers.setSynch(state, { payload: true, type: null });
		},
		deleteHBSupp: (state, { payload }) => {
			const moduleIndex = state.cart[`${payload.subject}`].findIndex(
				(module) => module.id === payload.module_id
			);
			state.cart[`${payload.subject}`][moduleIndex].handbook_suppliments = [];
		},
		updateSetQty: (state, { payload }) => {
			const moduleIndex = state.cart[`${payload.subject}`].findIndex(
				(module) => module.id === payload.module_id
			);
			const index = state.cart[`${payload.subject}`][moduleIndex].sets.findIndex(
				(set) => set.cart_id === payload.cart_id
			);
			state.cart[`${payload.subject}`][moduleIndex].sets[index].qty = payload.qty;
			orderSlice.caseReducers.setSynch(state, { payload: true, type: null });
		},
		deleteSet: (state, { payload }) => {
			const moduleIndex = state.cart[`${payload.subject}`].findIndex(
				(module) => module.id === payload.module_id
			);
			const sets = state.cart[`${payload.subject}`][moduleIndex].sets.filter(
				(data) => data.cart_id !== payload.cart_id
			);
			state.cart[`${payload.subject}`][moduleIndex].sets = sets;
		},
		updateBlockQty: (state, { payload }) => {
			const moduleIndex = state.cart[`${payload.subject}`].findIndex(
				(module) => module.id === payload.module_id
			);
			const index = state.cart[`${payload.subject}`][moduleIndex].blocks.findIndex(
				(set) => set.cart_id === payload.cart_id
			);
			state.cart[`${payload.subject}`][moduleIndex].blocks[index].qty = payload.qty;
			orderSlice.caseReducers.setSynch(state, { payload: true, type: null });
		},
		deleteBlock: (state, { payload }) => {
			const moduleIndex = state.cart[`${payload.subject}`].findIndex(
				(module) => module.id === payload.module_id
			);
			const blocks = state.cart[`${payload.subject}`][moduleIndex].blocks.filter(
				(data) => data.cart_id !== payload.cart_id
			);
			state.cart[`${payload.subject}`][moduleIndex].blocks = blocks;
		},
		updateOtherItemQty: (state, { payload }) => {
			const index = state.cart['other_items'].findIndex(
				(item) => item.cart_id === payload.id
			);
			state.cart['other_items'][index].qty = payload.qty;
			orderSlice.caseReducers.setSynch(state, { payload: true, type: null });
		},
		deleteOtherItem: (state, { payload }) => {
			const newDayData = state.cart['other_items'].filter(
				(data) => data.cart_id !== payload.cart_id
			);
			state.cart['other_items'] = newDayData;
		},
		setFranchise: (state, {payload}) => {
			state.franchise = payload;
			state.cart = [];
			state.shippingAddress = null;
		},
		setStep: (state, {payload}) => {
			state.step = payload;
		},
		updateShippingDetails: (state, {payload}) => {
			state.franchise.address = payload.address;
			state.franchise.phone = payload.phone;
			state.franchise.default_order = payload.default_order;
			state.franchise.handbook_binding = payload.handbook_binding;
			state.franchise.instructions = payload.instructions;
		},
		updateDefaultOrderLoadFlag: (state, {payload}) => {
			state.isDefaultOrderLoad = payload.isDefaultOrderLoad;
		},
		setSynch: (state, {payload}) => {
			state.sync = payload;
		},
		setCartAmt: (state, { payload }) => {
			state.cart['amount'] = payload;
		},
		setShippingAddress: (state, {payload}) => {
			state.shippingAddress = payload;
		},
	}
});

export const { loadCart, clearCart, setFranchise, setStep, updateSetQty, deleteSet, updateBlockQty, deleteBlock, updateModuleQty, deleteModuleAllSets, updateHandbookQty, deleteHandbook, updateOtherItemQty, deleteOtherItem, updateShippingDetails, updateDefaultOrderLoadFlag, updateHBSuppQty, deleteHBSupp, setSynch, setCartAmt, setShippingAddress } = orderSlice.actions;

export default orderSlice.reducer;