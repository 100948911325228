import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebarMenu } from '@app/store/reducers/ui';   //  toggleControlSidebar
// import {Button} from '@app/components';
// import MessagesDropdown from '@app/modules/main/header/messages-dropdown/MessagesDropdown';
// import NotificationsDropdown from '@app/modules/main/header/notifications-dropdown/NotificationsDropdown';
// import LanguagesDropdown from '@app/modules/main/header/languages-dropdown/LanguagesDropdown';
import UserDropdown from '@app/modules/main/header/user-dropdown/UserDropdown';
import { loginUser, setPermissions, setSelectedRole } from '@app/store/reducers/auth';
import { toast } from 'react-toastify';
import jwt_decode from "jwt-decode";
import { getCompactPermissionByRoleId } from '@app/services/permission';
import { confirmAlert } from 'react-confirm-alert';
let base64 = require('base-64');
let utf8 = require('utf8');

const Header = () => {
	const dropdownRef = useRef<any>(null);
	const [t] = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const navbarVariant = useSelector((state: any) => state.ui.navbarVariant);
	const headerBorder = useSelector((state: any) => state.ui.headerBorder);
	const currentUser = useSelector((state: any) => state.auth.currentUser);
	const selectedRole = useSelector((state: any) => state.auth.selectedRole);
	const [dropdownOpen, setDropdownOpen] = useState(false);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside, false);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside, false);
		};
	});

	const handleClickOutside = (event: any) => {
		if (
			dropdownRef &&
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target)
		) {
			setDropdownOpen(false);
		}
	};

	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen);
	};

	const handleRoleSwitch = async (role) => {
		try {
			confirmAlert({
				title: 'Switch Role!',
				message: 'Do you want to switch to the ' + role.name + '?',
				buttons: [
					{
						label: 'Yes',
						onClick: async () => {
							// setAuthLoading(true);
							const resp = await getCompactPermissionByRoleId(role.id);
							if (resp.success === true) {
								// setAuthLoading(false);
								const decoded = jwt_decode(resp.token);
								let permissions = JSON.parse(utf8.decode(base64.decode(decoded['access'])));

								dispatch(setSelectedRole(role));
								dispatch(setPermissions(permissions));
								dispatch(loginUser(resp.token));
								navigate('/');
							} else {
								// setAuthLoading(false);
								toast.error(resp?.errors[0]?.msg);
							}
						}
					},
					{
						label: 'No',
						onClick: () => { }
					}
				]
			});
			// e.preventDefault();
		} catch (error: any) {
			// setAuthLoading(false);
			toast.error(error?.response?.message || 'Failed');
		}
	}

	const handleToggleMenuSidebar = () => {
		dispatch(toggleSidebarMenu());
	};

	// const handleToggleControlSidebar = () => {
	//   dispatch(toggleControlSidebar());
	// };

	const getContainerClasses = useCallback(() => {
		let classes = `main-header navbar navbar-expand ${navbarVariant}`;
		if (headerBorder) {
			classes = `${classes} border-bottom-0`;
		}
		return classes;
	}, [navbarVariant, headerBorder]);

	return (
		<nav className={getContainerClasses()}>
			<ul className="navbar-nav">
				<li className="nav-item">
					<button
						onClick={handleToggleMenuSidebar}
						type="button"
						className="nav-link"
					>
						<i className="fas fa-bars" />
					</button>
				</li>
				{/* <li className="nav-item d-none d-sm-inline-block">
				<Link to="/" className="nav-link">
					{t('header.label.home')}
				</Link>
				</li>
				<li className="nav-item d-none d-sm-inline-block">
				<Link to="/" className="nav-link">
					{t('header.label.contact')}
				</Link>
				</li> */}
			</ul>
			<ul className="navbar-nav ml-auto">
				<li className="nav-item dropdown" ref={dropdownRef} onClick={toggleDropdown}>
					<button type="button" className="nav-link"><i className="fas fa-people-arrows" /></button>
					<div className={dropdownOpen ? "dropdown-menu dropdown-menu-right dropdown-menu-md show" : "dropdown-menu dropdown-menu-right dropdown-menu-md"} style={{ left: 'inherit', right: 0, padding: 0 }}>
						{currentUser.roles && currentUser.roles.map((role) => (
							<button
								key={role.id} type="button"
								className={selectedRole.id === role.id ? "dropdown-item active" : "dropdown-item"}
								onClick={() => {
									handleRoleSwitch(role);
									setDropdownOpen(false);
								}}>
								<i className={selectedRole.id === role.id ? "fas fa-check mr-2" : "fas fa-circle mr-2"} />
								<span>{role.name}</span>
							</button>
						))}
					</div>
				</li>
				<UserDropdown />
				{/*
				<LanguagesDropdown />
				<MessagesDropdown />
				<NotificationsDropdown /> 
				<li className="nav-item">
				<Button className="nav-link" onClick={handleToggleControlSidebar}>
					<i className="fas fa-th-large" />
				</Button>
				</li> 
				*/}
			</ul>
		</nav>
	);
};

export default Header;