import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import {Form} from 'react-bootstrap';
import {toast} from 'react-toastify';
import { Button } from '@app/components';
import { attachFranchise, getPostcodeById } from '@app/services/postcode';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import { getAllActiveFranchise } from '@app/services/franchise';
import Select from "react-select";

const AttachFranchise = () => {

    let obj: any = {}
    let { id } = useParams();
    const navigate = useNavigate();
    const [franchises, setFranchises] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingFranchise, setLoadingFranchise] = useState(false);
    const [postcodeDetails, setPostcodeDetails] = useState(obj);
    // const [dateErrorFlag, setDateErrorFlag] = useState(false);
    // const [range, setRange] = useState([
    //     {
    //       startDate: new Date(),
    //       endDate: new Date(),
    //       key: 'selection'
    //     }
    // ]);

    useEffect(() => {
        getAllActiveFranchiseData();
        getPostcodeDetailsById(id);
    },[]);

    const getAllActiveFranchiseData = async () => {
        try {
            setLoadingFranchise(true);
            const resp = await getAllActiveFranchise();
            if (resp?.success === true) {
                setLoadingFranchise(false);
                setFranchises(resp?.collections);
            } else {
                setLoadingFranchise(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoadingFranchise(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getPostcodeDetailsById = async (id) => {
        try {
            setLoading(true);
            const resp = await getPostcodeById(id);
            if(resp.success === true){
                setLoading(false);
                setPostcodeDetails(resp.collections);
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const attachFranchiseToPostcode = async (payload) => {
        try {
            setLoading(true);
            const resp = await attachFranchise(payload);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                navigate('/postcodes');
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue} = useFormik({
        initialValues: {
            franchise_id: postcodeDetails?.franchise_id || '',
            start_date: postcodeDetails?.start_date || '',
        },
        validationSchema: Yup.object({
            franchise_id: Yup.string().required('Please select franchise'),
            start_date: Yup.string().required('Please enter start date'),
        }),
        onSubmit: (values) => {
            // let startDate = moment(range[0].startDate);
            // let endDate = moment(range[0].endDate);
            // if(startDate.isValid() && endDate.isValid()){
                attachFranchiseToPostcode({
                    postcode_id: id,
                    franchise_id: values.franchise_id,
                    start_date: moment(values.start_date).format('YYYY-MM-DD'),
                    // start_date: startDate.format('YYYY-MM-DD'),
                    // end_date: endDate.format('YYYY-MM-DD'),
                })
            //     setDateErrorFlag(false);
            // }else{
            //     setDateErrorFlag(true);
            // }
        },
        enableReinitialize: true,
    });

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Attach Franchise</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row" style={{borderBottom: '1px solid #d1d1d1'}}>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Postcode </label>
                                                <Form.Control 
                                                    plaintext 
                                                    readOnly 
                                                    defaultValue=''
                                                    value={postcodeDetails.name}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Status </label>
                                                <Form.Control className='capitalize' plaintext readOnly defaultValue='' value={ postcodeDetails.status } />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Comment </label>
                                                <Form.Control 
                                                    plaintext 
                                                    readOnly 
                                                    defaultValue=''
                                                    value={postcodeDetails.comment ? postcodeDetails.comment : '---'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Franchise <span className="required">*</span></label>
                                                <Select
                                                    //ref={selectRef}
                                                    // className="form-control"
                                                    name="franchise_id"
                                                    placeholder="Select Franchise"
                                                    options={franchises}
                                                    getOptionValue={(option)=>`${option['id']}`}
                                                    getOptionLabel={(option)=>`${option['name']}`}
                                                    onChange={ (option) => { setFieldValue("franchise_id", option ? option.id : '')}}
                                                    // value={values.franchise_id}
                                                    isDisabled={(postcodeDetails.status === 'active') ? true : false }
                                                    isLoading={isLoadingFranchise}
                                                    isClearable={true}
                                                />
                                                {(touched.franchise_id && errors.franchise_id) && (
                                                    <div className="text-danger">
                                                        {errors.franchise_id}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Start Date <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="date" 
                                                    name="start_date" 
                                                    id="start_date" 
                                                    placeholder="Enter start date" 
                                                    value={moment(values.start_date).format('YYYY-MM-DD')}
                                                    onChange={handleChange}
                                                />
                                                {(touched.start_date && errors.start_date) && (
                                                    <div className="text-danger">
                                                        {errors.start_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        
                                        {/* <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Start Date - End Date <span className="required">*</span></label>
                                                <div>
                                                    <DateRange
                                                        startDatePlaceholder="Start Date"
                                                        endDatePlaceholder="End Date"
                                                        onChange={item => setRange([item.selection])}
                                                        showSelectionPreview={true}
                                                        moveRangeOnFirstSelection={true}
                                                        months={1}
                                                        ranges={range}
                                                        editableDateInputs={true}
                                                        direction="vertical"
                                                        scroll={{ enabled: true }}
                                                    />
                                                    { dateErrorFlag && (
                                                        <div className="text-danger">
                                                            Please select date range/duration
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Save </Button>
                                    <Link className="btn btn-default" to='/postcodes'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default AttachFranchise
