import React, { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { downloadOrderInvoice, getOrderssWithPagination, makeDefaultOrder, updateOrderStatus } from '@app/services/order';
import CurrencyFormat from 'react-currency-format';
import { getAllActiveFranchise } from '@app/services/franchise';
import Select from "react-select";
import { confirmAlert } from 'react-confirm-alert';
import UpdateOrderStatusModal from './UpdateOrderStatusModal';
import fileDownload from 'js-file-download';
import moment from 'moment';

const ListOrders = (props) => {

    const initStatus = (props.category === 'inprogress') ? ['failed', 'unpaid', 'sent', 'acknowledged'] : ['dispatched', 'cancelled'];
    let initSearch = {franchiseId: '', invoiceNumber: '', totalSets: '', totalHandbooks: '', net: '', vat: '', orderTotal: '', transactionNumber: '', orderStatus: initStatus};
    const initOrderStatuses = (props.category === 'inprogress') ? 
    [{label:'Unpaid', value:'unpaid'}, {label:'Sent to Print Partner', value:'sent'}, {label:'In Production', value:'acknowledged'},{label:'Failed', value:'failed'}] :
    [{label:'Dispatched', value:'dispatched'}, {label:'Cancelled', value:'cancelled'}];

    const selectRef = useRef(null);
    const [orders, setOrders] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [franchises, setFranchises] = useState([]);
    const [orderStatuses, setOrderStatuses] = useState([]);
    const [search, setSearch] = useState(initSearch);
    const canAccess = useSelector((state: any) => state.auth.permissions.orders);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [updateStatusPayload, setUpdateStatusPayload] = useState({order_id: '', status: '', franchise_name:''});
    const navigate = useNavigate();

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    const customStylesSelect = {
        dropdownIndicator: () => ({
            display: 'none',
        }),
    }
      
    useEffect(() => {
        getAllOrdersData(1, perPage, sortField, sortDirection);
        getAllActiveFranchiseData();
        setOrderStatuses(initOrderStatuses);
    },[search]);

    const getAllOrdersData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const resp = await getOrderssWithPagination(page, perPage, sortField, sortDirection, search);
            if (resp?.success === true) {
                setLoading(false);
                setOrders(resp?.collections?.data);
                setTotalRows(resp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllActiveFranchiseData = async () => {
        try {
            const resp = await getAllActiveFranchise();
            if (resp?.success === true) {
                setFranchises(resp?.collections);
            } else {
                toast.error(resp?.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const columns = [
        {
            name: '#Id',
            selector: (row: { id: string; }) => `#${row.id}`,
            sortable: true,
            sortField: 'id',
            width: '8%',
            wrap: true
        },
        {
            name: 'Printer No',
            selector: (row: { printer_order_no: string; }) => row.printer_order_no ?? '---',
            sortable: true,
            sortField: 'printer_order_no',
            width: '8%',
            wrap: true
        },
        {
            name: 'Franchise',
            selector: (row: { franchise_name: string; }) => row.franchise_name,
            sortable: true,
            sortField: 'franchise_name',
        },
        {
            name: 'Sets Qty',
            selector: (row: { qty_sets: string; }) => row.qty_sets,
            sortable: true,
            sortField: 'qty_sets',
            width: '8%',
        },
        {
            name: 'Handbooks Qty',
            selector: (row: { qty_handbooks: string; }) => row.qty_handbooks,
            sortable: true,
            sortField: 'qty_handbooks',
            width: '8%',
        },
        // {
        //     name: 'Net Price',
        //     selector: (row: { net_price: string; }) => row.net_price,
        //     format: (row: { net_price: string; }) => <CurrencyFormat value={ row.net_price } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} />,
        //     sortable: true,
        //     sortField: 'net_price',
        // },
        // {
        //     name: 'Vat',
        //     selector: (row: { vat: string; }) => row.vat,
        //     format: (row: { vat: string; }) => <CurrencyFormat value={ row.vat } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} />,
        //     sortable: true,
        //     sortField: 'vat',
        // },
        {
            name: 'Total Amount',
            selector: (row: { total_price: string; }) => row.total_price,
            format: (row: { total_price: string, pcharge_total:string }) => <CurrencyFormat value={ (parseFloat(row.total_price) + parseFloat(row.pcharge_total)).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} />,
            sortable: true,
            sortField: 'total_price',
        },
        {
            name: 'Kashflow Order Account',
            selector: (row: { kashflow_order_account: string; }) => row.kashflow_order_account ?? '---',
            sortable: true,
            sortField: 'kashflow_order_account',
            width: '8%',
            wrap: true
        },
        {
            name: 'Order Date',
            selector: (row: { created_at: string; }) => row.created_at,
            cell: (row: { created_at: string; }) => (
                row.created_at ? moment(row.created_at).format('DD/MM/YYYY') : '---'
            ),
            // sortable: true,
            sortField: 'created_at',
            // wrap: true
        },
        {
            name: 'Dispatch Date',
            selector: (row: { dispatch_date: string; }) => row.dispatch_date,
            cell: (row: { dispatch_date: string; }) => (
                row.dispatch_date ? moment(row.dispatch_date).format('DD/MM/YYYY') : '---'
            ),
            // sortable: true,
            sortField: 'dispatch_date',
            omit: (props.category === 'inprogress')
        },
        {
            name: 'Status',
            selector: (row: { order_status: string; }) => row.order_status,
            cell: (row: { id: number, order_status: string, franchise_name: string; }) => (
                <a href='#' data-toggle="tooltip">
                    { row.order_status !== null ? (row.order_status === 'dispatched' ? <span className="badge badge-success">{ row.order_status.charAt(0).toUpperCase() + row.order_status.slice(1)}</span> : <span className="badge badge-danger">{ row.order_status.charAt(0).toUpperCase() + row.order_status.slice(1) } </span>) : '---'}
                </a>
            ),
            sortable: true,
            sortField: 'order_status',
            omit: (props.category === 'inprogress')
        },
        {
            name: 'Status',
            selector: (row: { order_status: string; }) => row.order_status,
            cell: (row: { id: number, order_status: string, franchise_name: string; }) => (
                <a href='#' onClick={ (e) => { canAccess?.['change-status'] ? handleUpdateStatus(row.id, row.order_status, row.franchise_name) : ''}} data-toggle="tooltip" title="Update order status">
                    { row.order_status !== null ? 
                        ((row.order_status === 'sent' || row.order_status === 'acknowledged') ? 
                            <span className="badge badge-success">{ (initOrderStatuses.find(obj => { return obj.value === row.order_status}))?.label }</span> 
                        :   (row.order_status === 'unpaid' ? 
                                <span className="badge badge-warning">{ row.order_status.charAt(0).toUpperCase() + row.order_status.slice(1) } </span> 
                            : <span className="badge badge-danger">{ row.order_status.charAt(0).toUpperCase() + row.order_status.slice(1) } </span>
                            )
                        ) 
                    : '---'}
                </a>
            ),
            sortable: true,
            sortField: 'order_status',
            omit: (props.category !== 'inprogress')
        },
        {  
            name: 'Action',
            cell: (row: { id: number, franchise_id:number, franchise_name:string, default_order: boolean, printer_order_no:number, order_status: string }) => (
                <>
                    {/* { canAccess?.payment && (row.order_status === 'unpaid' || row.order_status === 'failed') && 
                        <Link
                            to={`/orders/pay/${row.id}`}
                            role="button"
                            state={{ id: row.id }}
                            data-toggle="tooltip" title="Pay Amount"
                        >
                        <i className="fas fa-credit-card mr-2"/>
                        </Link>
                    } */}
                    { canAccess?.view && 
                        <Link
                            to={`/orders/view/${row.id}`}
                            role="button"
                            state={{ id: row.id }}
                            data-toggle="tooltip" title="View Order"
                        >
                        <i className="fas fa-eye mr-2"/>
                        </Link>
                    }
                    { canAccess?.['view-invoice'] && 
                        <Link
                            to={`/orders/view/invoice/${row.id}`}
                            role="button"
                            state={{ id: row.id }}
                            data-toggle="tooltip" title="View Invoice"
                        >
                        <i className="fas fa-receipt mr-2"/>
                        </Link>
                    }
                    { canAccess?.['download-printer-copy'] &&
                        <a href='#' onClick={ (e) => { downloadOrderInvoiceAPI(row.id, row.printer_order_no)} } data-toggle="tooltip" title="Download Printer Copy">
                            <i className="fas fa-download mr-2"></i>
                        </a>
                    }
                    { canAccess?.['make-default'] && (row.default_order !== true) &&
                        <a href='#' onClick={ (e) => { confirm(e, row.id, row.franchise_id, row.franchise_name)} } data-toggle="tooltip" title="Make Default Order">
                            <i className="fas fa-thumbtack mr-2"></i>
                        </a>
                    }
                </>
            ),
        },
    ];

    const conditionalRowStyles = [
        {
            when: row => row.default_order === true,
            style: {
                backgroundColor: 'rgb(228, 253, 254)',
                // color: 'white',
            },
        }
    ];

    const confirm = (e, id, franchise_id, franchise_name) => {
        confirmAlert({
            title: 'Make Default Order!',
            message: 'Are you sure to make this default order for franchise ' + franchise_name + '?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => makeDefaultOrderAPI(id, franchise_id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const makeDefaultOrderAPI = async (id, franchise_id) => {
        try {
            setLoading(true);
            const resp = await makeDefaultOrder({order_id: id, franchise_id: franchise_id});
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                getAllOrdersData(currentPage, perPage, sortField, sortDirection);
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
          }
    };

    const handlePageChange = page => {
        getAllOrdersData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllOrdersData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllOrdersData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        selectRef.current.setValue('');
        setSearch(initSearch);
    };
    
    const downloadOrderInvoiceAPI = async (id, printerId) => {
        try {
            const resp = await downloadOrderInvoice(id);
            printerId = printerId ? printerId : id + '-unpaid';
            fileDownload(resp, `FCL0000${printerId}.xlsx`)
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const handleUpdateStatus = async(order_id, order_status, franchise_name) => {
        handleShow();
        setUpdateStatusPayload({order_id: order_id, status: order_status, franchise_name:franchise_name})
    }

    const handleGetAllOrdersData = () => {
        getAllOrdersData(currentPage, perPage, sortField, sortDirection);
    }

    return (
        <>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>{ props.title }</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active">{ props.title }</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div style={{ marginBottom: 10 }} className="row">
                                
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">{ props.title }</h3>
                                    <div className=" text-right">
                                        { canAccess?.place &&
                                            <Link className="btn btn-primary" to="/orders/place" > + Place New Order </Link>
                                        }
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <div className="row mb-2">
                                        <div className="col-sm-2">
                                            <Select
                                                ref={selectRef}
                                                name="franchiseId"
                                                placeholder="Search By Franchise"
                                                options={franchises}
                                                getOptionValue={(option)=>`${option['id']}`}
                                                getOptionLabel={(option)=>`${option['name']}`}
                                                onChange={ (option) => { setSearch({ ...search, franchiseId: option.id });}}
                                                // onInputChange={ (option) => {onChangeFranchise(option)}}
                                                // value={search.franchiseId}
                                                styles={customStylesSelect}
                                            />
                                        </div>
                                        <div className="col-sm-2">
                                            <input type="text" name='invoiceNumber' className="form-control" value={search.invoiceNumber} onChange={(e) => setSearch({ ...search, invoiceNumber: e.target.value })} placeholder="Search By Invoice ID" />
                                        </div>
                                        <div className="col-sm-2">
                                            <input type="text" name='totalSets' className="form-control" value={search.totalSets} onChange={(e) => setSearch({ ...search, totalSets: e.target.value })} placeholder="Search By Sets" />
                                        </div>
                                        <div className="col-sm-2">
                                            <input type="text" name='totalHandbooks' className="form-control" value={search.totalHandbooks} onChange={(e) => setSearch({ ...search, totalHandbooks: e.target.value })} placeholder="Search By Handbooks" />
                                        </div>
                                        <div className="col-sm-2">
                                            <input type="text" name='net' className="form-control" value={search.net} onChange={(e) => setSearch({ ...search, net: e.target.value })} placeholder="Search By Net" />
                                        </div>
                                        <div className="col-sm-2">
                                            <input type="text" name='vat' className="form-control" value={search.vat} onChange={(e) => setSearch({ ...search, vat: e.target.value })} placeholder="Search By Vat" />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-sm-2">
                                            <input type="text" name='orderTotal' className="form-control" value={search.orderTotal} onChange={(e) => setSearch({ ...search, orderTotal: e.target.value })} placeholder="Search By Total" />
                                        </div>
                                        <div className="col-sm-2">
                                            <input type="text" name='transactionNumber' className="form-control" value={search.transactionNumber} onChange={(e) => setSearch({ ...search, transactionNumber: e.target.value })} placeholder="Search By Transaction ID" />
                                        </div>
                                        <div className="col-sm-2">
                                            <select
                                                className="form-control"
                                                name="orderStatus"
                                                onChange={(e) => setSearch({ ...search, orderStatus: [e.target.value] })}
                                                value={search.orderStatus}
                                            >
                                                <option value={initStatus}>Search By Status</option>
                                                {orderStatuses.map((obj) => (
                                                    <option key={obj.value} value={obj.value}>
                                                        {obj.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-sm-2'>
                                            <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                        </div>
                                    </div>
                                    
                                    <DataTable
                                        columns={columns}
                                        data={orders}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={currentPage}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                        progressPending={isLoading}
                                        sortServer
                                        onSort={handleSort}
                                        customStyles={customStyle}
                                        conditionalRowStyles={conditionalRowStyles}
                                        highlightOnHover
                                    />
                                </div>
                                {/* /.card-body */}
                            </div>
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
                <UpdateOrderStatusModal
                    show={show}
                    handleClose={handleClose}
                    getAllOrdersData={handleGetAllOrdersData}
                    payload={updateStatusPayload}
                />
            </section>
            {/* /.content */}
        </>
    )
};

export default ListOrders;