import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {authSlice} from '@app/store/reducers/auth';
import {uiSlice} from '@app/store/reducers/ui';
import { modulePermissionsSlice } from './reducers/modulePermissions';
import {createLogger} from 'redux-logger';

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { orderSlice } from './reducers/order';
import { tierSlice } from './reducers/tier';
import { subjectSlice } from './reducers/subject';
import { studentCountSlice } from './reducers/studentCount';
import { SettingPatternSlice } from './reducers/settingPattern';
import { StudentWorkSlice } from './reducers/studentWork';
import { PrintQueueSlice } from './reducers/printQueue';
import { PrintSummarySlice } from './reducers/printSummary';

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({ 
    auth: authSlice.reducer,
    ui: uiSlice.reducer,
    order: orderSlice.reducer,
    modulePermissions: modulePermissionsSlice.reducer,
    tier: tierSlice.reducer,
    subject: subjectSlice.reducer,
    studentCount: studentCountSlice.reducer,
    settingPattern: SettingPatternSlice.reducer,
    studentWork: StudentWorkSlice.reducer,
    printQueue: PrintQueueSlice.reducer,
    printSummary: PrintSummarySlice.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
  // middleware: (getDefaultMiddleware) => [
  //   // ...getDefaultMiddleware().concat(createLogger())
  // ]
});

export default store;
export const persistor = persistStore(store)
