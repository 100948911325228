import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { getPricingPlanById, getSubjectsWisePricesByPlanId } from '@app/services/pricingPlans';

const ViewPlan = () => {
    const planStatus = [{value:'active', label:'Active'}, {value:'inactive', label:'Inactive'}, {value:'on_hold', label:'On Hold'}];
    let obj: any = {}
    const [isLoading, setLoading] = useState(false);
    const [subjectWisePrices, setSubjectWisePrices] = useState([]);
    const [tiers, setPlanTiers] = useState([]);
    const [plan, setPlanData] = useState(obj);
    const navigate = useNavigate();
    let { id } = useParams();
    
    useEffect(() => {
        getPlanDetailsById(id);
        getSubjectsWisePricesByPlanIdData(id);
    }, []);

    const getPlanDetailsById = async (id) => {
        try {
            setLoading(true);
            const resp = await getPricingPlanById(id);
            if(resp.success === true){
                setLoading(false);
                setPlanData(resp.collections);
                setPlanTiers(resp?.collections?.tiers)
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
                navigate('/pricing-plans');
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getSubjectsWisePricesByPlanIdData = async (id) => {
        try {
            setLoading(true);
            const resp = await getSubjectsWisePricesByPlanId(id);
            if (resp?.success === true) {
                setLoading(false);
                setSubjectWisePrices(resp?.collections);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

  return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">View Plan</h3>
                            </div>
                            <div className="card-body">
                                <div className="row" style={{borderBottom: '1px solid #d1d1d1'}}>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Plan Name </label>
                                            <Form.Control plaintext readOnly value={plan.name} />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Start Date </label>
                                            <Form.Control plaintext readOnly value={plan?.start_date ? moment(plan.start_date).format('DD/MM/YYYY') : '---'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">End Date </label>
                                            <Form.Control plaintext readOnly value={plan?.end_date ? moment(plan.end_date).format('DD/MM/YYYY') : '---'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Is Default Plan? </label>
                                            <Form.Control plaintext readOnly value={plan.is_default === 'true' ? 'Yes' : 'No'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Status </label>
                                            <Form.Control plaintext readOnly value={plan?.status ? (planStatus.find(obj => { return obj.value === plan?.status}))?.label : '---'} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12 mt-3'>
                                        <h5>Tiers</h5>
                                    </div>
                                    <div className="col-md-6">
                                        <table className="table table-bordered" id="client_id_table">
                                            <tbody>
                                                <tr className='text-center'>
                                                    <th>Tier Name</th>
                                                    <th>Range From</th>
                                                    <th>Range To</th>
                                                    <th>Alert Threshold</th>
                                                </tr>
                                                { plan?.tiers && (plan?.tiers).map((tier) => (
                                                    <tr key={tier.id}>
                                                        <td>{ tier.name }</td>
                                                        <td>{ tier.from_count }</td>
                                                        <td>{ tier.to_count }</td>
                                                        <td>{ tier.alert_threshold }</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {(plan?.tiers)?.length == 0 && (
                                            <span style={{ color: "red", textAlign: "center" }}>There are no tiers to display</span>
                                        )}
                                    </div>
                                </div>
                                <div className="row" style={{borderTop: '1px solid #d1d1d1'}}>
                                    <div className='col-md-12 mt-3'>
                                        <h5>Pricing</h5>
                                    </div>
                                    <div className="col-md-6">
                                        {subjectWisePrices?.length > 0 && (
                                            <table className="table table-bordered" id="client_id_table">
                                                <tbody>
                                                    <tr className='text-center'>
                                                        <th>Subject</th>
                                                        { tiers.map((tier) => (
                                                            <th key={tier?.id}>{ tier?.name } (Range { tier?.from_count } - { tier?.to_count })</th>
                                                        ))}
                                                    </tr>
                                                    {subjectWisePrices?.length > 0  && subjectWisePrices.map((subject) => (
                                                        <tr key={subject.id}>
                                                            <td>{ subject.name }</td>
                                                            {(subject?.tiers).length > 0 && (subject?.tiers).map((tier) => (
                                                                <td key={tier.id}>£ { tier.price }</td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                        {subjectWisePrices?.length == 0 && (
                                            <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                {/* <Link className="btn btn-primary mr-2" to={`/franchise/edit/${id}`}>Edit</Link> */}
                                <Link className="btn btn-default" to='/pricing-plans'>Close</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default ViewPlan;