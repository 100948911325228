import { createSlice } from '@reduxjs/toolkit';

export interface StudentCountState {
	step: number;
	franchise: any;
	cart: [];
}

const initialState: StudentCountState = {
	step: 1,
	franchise: null,
	cart: [],
};

export const studentCountSlice = createSlice({
	name: 'studentCount',
	initialState,
	reducers: {
		loadCart: (state, { payload }) => {
			state.cart = payload;
		},
		clearCart: (state) => {
			state.step= 1;
			state.franchise= null;
			state.cart = [];
		},
		setFranchise: (state, {payload}) => {
			state.franchise = payload;
			state.cart = [];
		},
		setStep: (state, {payload}) => {
			state.step = payload;
		},
	}
});

export const { loadCart, clearCart, setFranchise, setStep} = studentCountSlice.actions;

export default studentCountSlice.reducer;