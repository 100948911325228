import { useEffect, useRef, useState } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { getAllActiveFranchise } from '@app/services/franchise';
import Select from "react-select";
import { getAllTransactionByPagination } from '@app/services/studentCount';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import UpdateTransactionStatusModal from './UpdateTransactionStatusModal';
import { setFranchise, setStep } from "@app/store/reducers/studentCount";

const ListStudentCountTransactions = () => {

    const selectRef = useRef(null);
    const [transactions, setTransactions] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({franchise: '', status: ''});
    const [franchises, setFranchises] = useState([]);
    const canAccess = useSelector((state: any) => state.auth.permissions?.['student-count']);
    const statuses = [{label:'Failed', value:'failed'}, {label:'Success', value:'success'}, {label:'Unpaid', value:'unpaid'}];
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [updateStatusPayload, setUpdateStatusPayload] = useState({transaction_id: '', franchise_name:''});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    useEffect(() => {
        getAllTransactionByPaginationData(1, perPage, sortField, sortDirection);
    },[search]);

    useEffect(() => {
        getAllActiveFranchiseData();
    },[]);

    const getAllTransactionByPaginationData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const usersResp = await getAllTransactionByPagination(page, perPage, sortField, sortDirection, search);
            if (usersResp.success === true) {
                setLoading(false);
                setTransactions(usersResp.collections.data);
                setTotalRows(usersResp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(usersResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllActiveFranchiseData = async () => {
        try {
            const resp = await getAllActiveFranchise();
            if (resp?.success === true) {
                setFranchises(resp?.collections.filter((franchise) => franchise.enabled_student_count == true));
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const columns = [
        {
            name: '#Id',
            selector: (row: { id: string; }) => `#${row.id}`,
            sortable: true,
            sortField: 'id',
            // width: '8%',
            wrap: true
        },
        {
            name: 'Franchise',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            sortField: 'franchise_name',
            wrap: true,
        },
        {
            name: 'Total Amount',
            selector: (row: { total_price: string; }) => row.total_price,
            format: (row: { total_price: string }) => <CurrencyFormat value={ row.total_price } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} />,
            sortable: true,
            sortField: 'total_price',
        },
        {
            name: 'Transaction Date',
            selector: (row: { created_at: string; }) => row.created_at,
            cell: (row: { created_at: string; }) => (
                row.created_at ? moment(row.created_at).format('DD/MM/YYYY') : '---'
            ),
            sortable: true,
            sortField: 'created_at',
        },
        {
            name: 'Kashflow Order Account',
            selector: (row: { kashflow_order_account: string; }) => row.kashflow_order_account ?? '---',
            sortable: true,
            sortField: 'kashflow_order_account',
            wrap: true
        },
        {
            name: 'Status',
            selector: (row: { status: string; }) => row.status,
            cell: (row: { id: number, status: string, name: string, total_price: string; }) => (
                <a href='#' onClick={ (e) => { handleUpdateStatus({transaction_id: row.id, franchise_name: row.name, status: row.status, total_price:row.total_price}) }} data-toggle="tooltip" title="Update Transaction status">
                    { row.status !== null ? 
                        ((row.status === 'success') ? 
                            <span className="badge badge-success">{ row.status.charAt(0).toUpperCase() + row.status.slice(1) }</span> 
                        :   (row.status === 'unpaid' ? 
                                <span className="badge badge-warning">{ row.status.charAt(0).toUpperCase() + row.status.slice(1) } </span> 
                            : <span className="badge badge-danger">{ row.status.charAt(0).toUpperCase() + row.status.slice(1) } </span>
                            )
                        ) 
                    : '---'}
                </a>
            ),
            sortable: true,
            sortField: 'status',
            omit: !(canAccess?.['update-transaction-status'])
        },
        {
            name: 'Status',
            selector: (row: { status: string; }) => row.status,
            cell: (row: { id: number, status: string, name: string, total_price: string; }) => (
                <>
                    { row.status !== null ? 
                        ((row.status === 'success') ? 
                            <span className="badge badge-success">{ row.status.charAt(0).toUpperCase() + row.status.slice(1) }</span> 
                        :   (row.status === 'unpaid' ? 
                                <span className="badge badge-warning">{ row.status.charAt(0).toUpperCase() + row.status.slice(1) } </span> 
                            : <span className="badge badge-danger">{ row.status.charAt(0).toUpperCase() + row.status.slice(1) } </span>
                            )
                        ) 
                    : '---'}
                </>
            ),
            sortable: true,
            sortField: 'status',
            omit: (canAccess?.['update-transaction-status'])
        },
        {  
            name: 'Action',
            cell: (row: { id: number, status: string, total_price: string, name: string, franchise_id: number }) => (
                <>
                    { canAccess?.['view-transaction'] && 
                        <Link
                            to={`/student-counts/view/${row.id}`}
                            role="button"
                            state={{ id: row.id }}
                            data-toggle="tooltip" title="View Transaction Details"
                        >
                        <i className="fas fa-eye mr-2"/>
                        </Link>
                    }
                    { (row.status === 'failed' || row.status === 'unpaid') && (parseFloat(row.total_price) > 0) && 
                        <Link
                            to={`/student-counts/pay/${row.id}`}
                            role="button"
                            state={{ id: row.id }}
                            data-toggle="tooltip" title="Pay Amount"
                        >
                        <i className="fas fa-credit-card mr-2"/>
                        </Link>
                    }
                    { (row.status === 'failed' || row.status === 'unpaid') && canAccess?.['edit-student-count'] && (parseFloat(row.total_price) > 0) && 
                        <a href='#' onClick={ (e) => { handleEditSubmitCount({id: row.franchise_id, name: row.name})} } data-toggle="tooltip" title="Edit Student Count">
                            <i className="fas fa-pen mr-2"/>
                        </a>
                    }
                </>
            ),
            // omit: !(canAccess?.['view-transaction'] || canAccess?.['delete-student-count'])
        },
    ];

    const handlePageChange = page => {
        getAllTransactionByPaginationData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllTransactionByPaginationData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllTransactionByPaginationData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        selectRef.current.setValue('');
        setSearch({franchise: '', status: ''});
    };

    const onChangeFranchise = (option) => {
        setSearch({ ...search, franchise: option?.id ? option?.id : '' });
    };

    const handleUpdateStatus = async(payload) => {
        if(payload.status === 'unpaid'){
            handleShow();
            setUpdateStatusPayload(payload)
        }
    }

    const handleGetTransactionsData = () => {
        getAllTransactionByPaginationData(currentPage, perPage, sortField, sortDirection);
    }

    const handleEditSubmitCount = (franchise) => {
        dispatch(setStep(3));
        dispatch(setFranchise(franchise));
        navigate('/submit-student-counts')
    }

    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Student Count Transactions</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active">Student Count Transactions</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Student Count Transactions</h3>
                                        <div className="col-lg-12 text-right">
                                            { canAccess?.['create-student-count'] &&
                                                <Link className="btn btn-primary" to="/submit-student-counts" > Submit Student Count </Link>
                                            }
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row mb-3">
                                            <div className="col-sm-3">
                                                <Select
                                                    ref={selectRef}
                                                    name="franchiseId"
                                                    placeholder="Search By Franchise"
                                                    options={franchises}
                                                    getOptionValue={(option)=>`${option['id']}`}
                                                    getOptionLabel={(option)=> option['name'] }
                                                    onChange={ (option) => {onChangeFranchise(option)}}
                                                    isClearable={true}
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <select
                                                    className="form-control"
                                                    name="orderStatus"
                                                    onChange={(e) => setSearch({ ...search, status: e.target.value })}
                                                    value={search.status}
                                                >
                                                    <option value=''>Search By Status</option>
                                                    {statuses.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-sm-3'>
                                                <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning mr-2">Reset Filter</Button>
                                            </div>
                                        </div>

                                        <DataTable
                                            columns={columns}
                                            data={transactions}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationDefaultPage={currentPage}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            paginationPerPage={perPage}
                                            paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                            progressPending={isLoading}
                                            sortServer
                                            onSort={handleSort}
                                            customStyles={customStyle}
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                    <UpdateTransactionStatusModal
                        show={show}
                        handleClose={handleClose}
                        getTransactionsData={handleGetTransactionsData}
                        payload={updateStatusPayload}
                    />
                </section>
                {/* /.content */}
            </div>
        </div>
    )
};

export default ListStudentCountTransactions;
