import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";


// Make sure to call loadStripe outside of a component’s render to avoid recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const StripeContainer = () => {

	const options = {
		// passing the client secret obtained from the server
		clientSecret: process.env.REACT_APP_STRIPE_SECRET_KEY,
	};

	return (
		<div className="App">
			<Elements stripe={stripePromise}> {/* options={options} */}
				<CheckoutForm />
			</Elements>
		</div>
	);
};

export default StripeContainer;