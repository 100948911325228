import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import {Form} from 'react-bootstrap';
import { useFormik } from 'formik';
import { Button } from '@app/components';
import { toast } from 'react-toastify';
import { getAllModules, getPermissionById, updatePermission } from '@app/services/permission';

const EditPermission = () => {

    let obj: any = {}
    const [modules, setModules] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [permissionData, setPermissionData] = useState(obj);
    const navigate = useNavigate();
    let { id } = useParams();

    useEffect(() => {
        getModulesData();
        getPermissionDetailsById(id);
    },[]);

    const getPermissionDetailsById = async (id) => {
        const resp = await getPermissionById(id);
        setPermissionData(resp.collections);
    };
    
    const getModulesData = async () => {
        try {
            const resp = await getAllModules();
            if (resp.success === true) {
                setModules(resp.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const putPermission = async (payload) => {
        try {
            setLoading(true);
            const resp = await updatePermission(id, payload);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                navigate('/permissions');
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error.response.message || 'Failed');
          }
    };

    const { handleChange, values, handleSubmit, touched, errors } = useFormik({
        initialValues: {
            name: permissionData?.name || '',
            module: permissionData?.module || '',
            // status: roleData?.status || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter permisssion name')
            .min(3, 'Permisssion name must be 3 characters or more')
            .max(30, 'Permisssion name must be 30 characters or less'),
            module: Yup.string().required('Please select module'),
            // status: Yup.string().required('Please select status'),
        }),
        onSubmit: (values) => {
            putPermission(values);
        },
        enableReinitialize: true,
    });

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Update Permission</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Module <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="module"
                                                    onChange={handleChange} 
                                                    value={values.module}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {modules.map((obj) => (
                                                        <option key={obj.id} value={obj.slug}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.module && errors.module) && (
                                                    <div className="text-danger">
                                                        {errors.module}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Permission Name <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter permission name"
                                                    name="name" 
                                                    onChange={handleChange} 
                                                    value={values.name}
                                                />
                                                {(touched.name && errors.name) && (
                                                    <div className="text-danger">
                                                        {errors.name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className='col-md-12'>
                                        <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                                        <Link className="btn btn-default" to='/permissions'>Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default EditPermission