import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import { useDispatch, useSelector } from "react-redux";
import SetPatternModal from './SetPatternModal';
import SelectModuleModal from './SelectModuleModal';
import { getStudAssignWorkById, processStudentWork } from '@app/services/student';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { resetSelectedModules, resetUpcomingModules, setModule, setModulesPerWk, setPattern, setSelModuleDetails, setSelectedModules, setSelectedSubject, setStep } from '@app/store/reducers/studentWork';
import { getLearningPathwayById } from '@app/services/learningPathway';
import moment from 'moment';
import { loadDays, loadEditDays } from '@app/store/reducers/settingPattern';
import UpcomingModuleModal from './UpcomingModuleModal';

const SetStudentWork = () => {

    let { id } = useParams();
    let obj: any = {}
    const dispatch = useDispatch();
    const studentWorkStore = useSelector((state: any) => state.studentWork);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [lastAssiModules, setLastAssiModules] = useState([]);
    const [studentDetails, setStudentDetails] = useState(obj);
    const [showSelectModule, setShowSelectModule] = useState(false);
    const [showUpcomingModule, setShowUpcomingModule] = useState(false);
    const [upcomingStreams, setUpcomingStreams] = useState([]);
    const [existingPattern, setExistingPattern] = useState(obj);
    const handleCloseSelectModule = () => setShowSelectModule(false);
    const handleCloseUpcomingModule = () => setShowUpcomingModule(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const weeks = []
    for (let i=1; i <= 10; i++) {
        weeks.push({value: i, name: i})
    }

    useEffect(() => {
        getStudAssignedWork()
        dispatch(resetSelectedModules())
        dispatch(resetUpcomingModules())
        dispatch(setPattern({}))
        dispatch(setStep(1))

        return () => {
            // Anything in here is fired on component unmount.
            setStudentDetails({})
            dispatch(resetSelectedModules())
            dispatch(resetUpcomingModules())
            dispatch(setModule({moduleNumber: 'all', module: []}))
            dispatch(setSelModuleDetails({moduleNumber: 'all', module: null}))
            dispatch(setPattern({}))
            dispatch(setStep(1))
            dispatch(setModulesPerWk(0))
            dispatch(loadDays({count: 0}))
            dispatch(setSelectedSubject({}))
        }
    },[]);

    const getLearningPathwayByIdAPI = async (id, module_num) => {
        try {
            setLoading(true);
            const resp = await getLearningPathwayById(id);
            if (resp?.success === true) {
                setLoading(false);
                dispatch(setModule({moduleNumber: module_num, module: resp?.collections}))
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Internal server error');
        }
    };

    const getStudAssignedWork = async () => {
        try {
            setLoading(true);
            const resp = await getStudAssignWorkById({student_id: id, subject_id: studentWorkStore.subject.id ?? 0});
            if (resp.success === true) {
                setLoading(false);
                if(resp?.collections?.status == 'inactive'){
                    toast.error('You cannot assign work to inactive student')
                    navigate('/students');
                }
                let stepFlag = false;
                setStudentDetails(resp?.collections)
                if(Object.keys(resp?.collections?.work).length > 0){
                    setLastAssiModules(resp?.collections?.work?.pathway)
                    dispatch(setStep(2))
                    let strands: any = {};
                    (resp?.collections?.work?.pathway).map((obj) => {
                        if(obj.latest_worksheet_number !== null){
                            switch(obj.module){
                                case 'module1' : strands.module1LearnigPath = obj.learning_pathway_id
                                                strands.module1 = obj.module_id
                                                strands.module1WSFrom = obj.latest_worksheet_number
                                                strands.module1WSTo = obj.to_worksheet_number
                                                strands.module1Name = obj.module_name
                                                strands.pathway1Name = obj.pathway_name
                                                getLearningPathwayByIdAPI(obj.learning_pathway_id, 1)
                                        break;
                                case 'module2' : strands.module2LearnigPath = obj.learning_pathway_id
                                                strands.module2 = obj.module_id
                                                strands.module2WSFrom = obj.latest_worksheet_number
                                                strands.module2WSTo = obj.to_worksheet_number
                                                strands.module2Name = obj.module_name
                                                strands.pathway2Name = obj.pathway_name
                                                getLearningPathwayByIdAPI(obj.learning_pathway_id, 2)
                                        break;
                                case 'module3' : strands.module3LearnigPath = obj.learning_pathway_id
                                                strands.module3 = obj.module_id
                                                strands.module3WSFrom = obj.latest_worksheet_number
                                                strands.module3WSTo = obj.to_worksheet_number
                                                strands.module3Name = obj.module_name
                                                strands.pathway3Name = obj.pathway_name
                                                getLearningPathwayByIdAPI(obj.learning_pathway_id, 3)
                                        break;
                                case 'module4' : strands.module4LearnigPath = obj.learning_pathway_id
                                                strands.module4 = obj.module_id
                                                strands.module4WSFrom = obj.latest_worksheet_number
                                                strands.module4WSTo = obj.to_worksheet_number
                                                strands.module4Name = obj.module_name
                                                strands.pathway4Name = obj.pathway_name
                                                getLearningPathwayByIdAPI(obj.learning_pathway_id, 4)
                                        break;
                            }
                        }else{
                            stepFlag = true;
                            dispatch(setStep(1))
                        }
                    })
                    dispatch(setSelectedModules(strands))               
                }
                if(Object.keys(resp?.collections?.pattern).length > 0){
                    setExistingPattern(resp?.collections?.pattern)
                    dispatch(setModulesPerWk(resp?.collections?.pattern?.modules_per_week))
                    dispatch(loadEditDays(resp?.collections?.pattern?.days))
                    dispatch(setPattern(resp?.collections?.pattern))
                    if(!stepFlag){
                        dispatch(setStep(3))
                    }
                }
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg)
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Internal server error');
        }
    };

    const processStudWork = async (payload) => {
        try {
            setLoading(true);
            const resp = await processStudentWork(payload);
            if(resp.success === true){
                setLoading(false);
                toast.success('Student work set successfully');
                navigate('/student/work-summary/'+id);
            }else{
                setLoading(false);
                toast.error(resp?.message);
                setUpcomingStreams(resp?.collections)
                setShowUpcomingModule(true);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Internal server error');
          }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            student_id: id,
            subject_id: studentWorkStore.subject.id,
            number_of_weeks: studentDetails?.work?.number_of_weeks || '',
            start_date: moment(studentDetails?.work?.end_date).add(1, 'days').format('YYYY-MM-DD') || '',
        },
        validationSchema: Yup.object({
            student_id: Yup.string().required('Something went wrong'),
            subject_id: Yup.string().required('Please select subject'),
            number_of_weeks: Yup.string().required('Please select number of weeks'),
            start_date: Yup.date().required('Please enter start date')
                .min('2020-01-01', 'Start date must be later than 01-01-2020'),
        }),
        onSubmit: (values) => {
            let payload = {
                student_id: values.student_id,
                subject_id: values.subject_id,
                start_date: values.start_date,
                number_of_weeks: values.number_of_weeks,
                pathway: [],
                upcoming: []
            }

            for (let index = 1; index <= parseInt(studentWorkStore?.pattern?.modules_per_week); index++) {
                payload?.pathway.push( {
                    module: "module"+index,
                    learning_pathway_id: studentWorkStore?.selectedModules?.['module'+index+'LearnigPath'],
                    module_id: studentWorkStore?.selectedModules?.['module'+index],
                    from_worksheet_number: studentWorkStore?.selectedModules?.['module'+index+'WSFrom'],
                    to_worksheet_number: studentWorkStore?.selectedModules?.['module'+index+'WSTo']
                })

                if(studentWorkStore?.upcomingModules?.['module'+index] != '' && studentWorkStore?.upcomingModules?.['module'+index] != undefined){
                    payload?.upcoming.push( {
                        module: "module"+index,
                        learning_pathway_id: studentWorkStore?.upcomingModules?.['module'+index+'LearnigPath'],
                        module_id: studentWorkStore?.upcomingModules?.['module'+index],
                        from_worksheet_number: studentWorkStore?.upcomingModules?.['module'+index+'WSFrom'],
                        to_worksheet_number: studentWorkStore?.upcomingModules?.['module'+index+'WSTo']
                    })
                }
            }

            processStudWork(payload);
        },
        enableReinitialize: true,
    });

    const resetSelectedSubject = () => {
	    dispatch(setSelectedSubject({}))
	}

    return (
    <div>
        {/* <section className="content">
            <div className="container-fluid"> */}
                <form className='form-horizontal' onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <span style={{fontSize: '1.5rem', fontWeight: 'bold'}}>Set Student Work</span>
                        </div>
                        <div className="col-md-8">
                            <div className="external-event" style={{backgroundColor: "#ffd316", position: 'relative', zIndex: 'auto', left: '0px', top: '0px', width: '18%', fontWeight: 400, cursor:'default'}}>Student Details</div>
                            <div className="card">
                                <div className="card-body" style={{backgroundColor: '#f9f1cc'}}>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            {/* <div className="form-group row">
                                                <label htmlFor="inputModule1" className="col-sm-5">Student Name:</label>
                                                <div className="col-sm-7">
                                                    <dd>{ studentDetails?.first_name + ' ' + studentDetails?.last_name} </dd>
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <dt className="col-sm-6">Student Name:</dt>
                                                <dd className="col-sm-6">{ studentDetails?.first_name + ' ' + studentDetails?.last_name} </dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            {/* <div className="form-group row">
                                                <label htmlFor="inputModule2" className="col-sm-5">Year Group:</label>
                                                <div className="col-sm-7">
                                                    <dd>{ studentDetails?.grade_name } </dd>
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <dt className="col-sm-6">Year Group:</dt>
                                                <dd className="col-sm-6">{ studentDetails?.grade_name ?? '---' } </dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            {/* <div className="form-group row">
                                                <label htmlFor="inputModule2" className="col-sm-5">Year Group:</label>
                                                <div className="col-sm-7">
                                                    <dd>{ studentDetails?.grade_name } </dd>
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <dt className="col-sm-6">Selected Subject:</dt>
                                                <dd className="col-sm-6">{ studentWorkStore?.subject?.name ?? '---' } </dd>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="external-event" style={{backgroundColor: studentWorkStore.step > 1 ? "#ffd316" : "#b5b3c5", position: 'relative', zIndex: 'auto', left: '0px', top: '0px', width: '10%', fontWeight: 400, cursor:'default'}}>STEP 1</div>
                            <div className="card">
                                <div className="card-body" style={{backgroundColor: studentWorkStore.step > 1 ? '#f9f1cc' : "#f4f3f6", opacity: studentWorkStore.step > 1 ? '' : '0.65'}}>
                                    <div className="form-group row">
                                        <div className="col-sm-12">
                                        { studentWorkStore.step > 1 ?
                                            <> Please verify the stream from learning pathway that has been set previously: <a href='#' onClick={(e) => {setShowSelectModule(true)}}>Verify Streams</a> </>
                                        : 
                                            <><i className="fas fa-exclamation-triangle"> </i> Please select the modules from learning pathway to proceed further: <a href='#' onClick={(e) => {setShowSelectModule(true)}}>Configure Streams</a></>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="external-event" style={{backgroundColor: studentWorkStore.step > 2 ? "#ffd316" : "#b5b3c5", position: 'relative', zIndex: 'auto', left: '0px', top: '0px', width: '10%', fontWeight: 400, cursor:'default'}}>STEP 2</div>
                            <div className="card">
                                <div className="card-body" style={{backgroundColor: studentWorkStore.step > 2 ? '#f9f1cc' : "#f4f3f6", opacity: studentWorkStore.step > 2 ? '' : '0.65'}}>
                                    <div className="form-group row">
                                        <div className="col-sm-12" style={{pointerEvents: studentWorkStore.step >= 2 ? 'all' : 'none'}}>
                                            { studentWorkStore.step > 2 ?
                                                <> Please verify the setting pattern that has been set previously: <a href='#' onClick={handleShow}>Verify Setting Pattern</a> </>
                                            : 
                                                <> <i className="fas fa-exclamation-triangle"></i> Please configure a setting pattern to proceed further: <a href='#' onClick={handleShow}>Configure Setting Pattern</a></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="external-event" style={{backgroundColor: (studentWorkStore.step == 3 && values.number_of_weeks != "" && values.start_date != "") ? "#ffd316" : "#b5b3c5", position: 'relative', zIndex: 'auto', left: '0px', top: '0px', width: '10%', fontWeight: 400, cursor:'default'}}>STEP 3</div>
                            <div className="card">
                                <div className="card-body" style={{backgroundColor: (studentWorkStore.step == 3 && values.number_of_weeks != "" && values.start_date != "") ? '#f9f1cc' : "#f4f3f6"}}>
                                    <div className="form-group row">
                                        <label htmlFor="inputModule1" className="col-sm-5 col-form-label">No. of weeks to set the work <span className="required">*</span></label>
                                        <div className="col-sm-6">
                                            <select
                                                className="form-control"
                                                name="number_of_weeks"
                                                // onChange={ (e) => {
                                                //     setFieldValue('number_of_weeks', e.target.value)
                                                // }}
                                                onChange={handleChange}
                                                value={values.number_of_weeks}
                                            >
                                                <option value="">Select number of weeks to set work</option>
                                                {weeks.map((obj) => (
                                                    <option key={obj.value} value={obj.value}>
                                                        {obj.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {(touched.number_of_weeks && errors.number_of_weeks) && (
                                                <div className="text-danger">
                                                    {errors.number_of_weeks}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputModule1" className="col-sm-5 col-form-label">Start Date <span className="required">*</span></label>
                                        <div className="col-sm-6">
                                            <Form.Control 
                                                type="date" 
                                                name="start_date" 
                                                id="start_date" 
                                                placeholder="Enter start date" 
                                                value={values.start_date}
                                                onChange={handleChange}
                                            />
                                            {(touched.start_date && errors.start_date) && (
                                                <div className="text-danger">
                                                    {errors.start_date}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="external-event" style={{backgroundColor: "#455cff", position: 'relative', zIndex: 'auto', left: '0px', top: '0px', width: '50%', color: '#fff', fontWeight: 400, cursor:'default'}}>Upcoming Worksheet(s)</div>
                            <div className="card">
                                <div className="card-body" style={{ backgroundColor: '#d4d9fb' }}>
                                    <div className="form-group row">
                                        <label htmlFor="inputModule1" className="col-sm-4">Stream 1:</label>
                                        <div className="col-sm-6">
                                            { lastAssiModules.length > 0 && lastAssiModules.find((obj) => obj.module === 'module1') != undefined && (
                                                <dd>{ lastAssiModules.length > 0 ? lastAssiModules.find((obj) => obj.module === 'module1')?.module_name +'-'+ ( (lastAssiModules.find((obj) => obj.module === 'module1'))?.latest_worksheet_number == null ? 'Finished' : (lastAssiModules.find((obj) => obj.module === 'module1'))?.latest_worksheet_number ) : '---'}</dd>
                                            )}
                                            { lastAssiModules.find((obj) => obj.module === 'module1') == undefined && (
                                                '---'
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputModule2" className="col-sm-4">Stream 2:</label>
                                        <div className="col-sm-6">
                                            { lastAssiModules.length > 0 && lastAssiModules.find((obj) => obj.module === 'module2') != undefined && (
                                                <dd>{ lastAssiModules.length > 0 ? lastAssiModules.find((obj) => obj.module === 'module2')?.module_name +'-'+ ( (lastAssiModules.find((obj) => obj.module === 'module2'))?.latest_worksheet_number == null ? 'Finished' : (lastAssiModules.find((obj) => obj.module === 'module2'))?.latest_worksheet_number ) : '---'}</dd>
                                            )}
                                            { lastAssiModules.find((obj) => obj.module === 'module2') == undefined && (
                                                '---'
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputModule3" className="col-sm-4">Stream 3:</label>
                                        <div className="col-sm-6">
                                            { lastAssiModules.length > 0 && lastAssiModules.find((obj) => obj.module === 'module3') != undefined && (
                                                <dd>{ lastAssiModules.length > 0 ? lastAssiModules.find((obj) => obj.module === 'module3')?.module_name +'-'+ ( (lastAssiModules.find((obj) => obj.module === 'module3'))?.latest_worksheet_number == null ? 'Finished' : (lastAssiModules.find((obj) => obj.module === 'module3'))?.latest_worksheet_number ) : '---'}</dd>
                                            )}
                                            { lastAssiModules.find((obj) => obj.module === 'module3') == undefined && (
                                                '---'
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputModule4" className="col-sm-4">Stream 4:</label>
                                        <div className="col-sm-6">
                                            { lastAssiModules.length > 0 && lastAssiModules.find((obj) => obj.module === 'module4') != undefined && (
                                                <dd>{ lastAssiModules.length > 0 ? lastAssiModules.find((obj) => obj.module === 'module4')?.module_name +'-'+ ( (lastAssiModules.find((obj) => obj.module === 'module4'))?.latest_worksheet_number == null ? 'Finished' : (lastAssiModules.find((obj) => obj.module === 'module4'))?.latest_worksheet_number ) : '---'}</dd>
                                            )}
                                            { lastAssiModules.find((obj) => obj.module === 'module4') == undefined && (
                                                '---'
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="external-event" style={{backgroundColor: "#b2cf02", position: 'relative', zIndex: 'auto', left: '0px', top: '0px', width: '50%', color: '#fff', fontWeight: 400, cursor:'default'}}>Selected Worksheet(s)</div>
                            <div className="card">
                                <div className="card-body" style={{backgroundColor: '#eaf0c8'}}>
                                    <div className="form-group row">
                                        <label htmlFor="inputModule1" className="col-sm-4">Stream 1:</label>
                                        <div className="col-sm-6">
                                            <dd>{studentWorkStore?.selectedModules?.module1Name ? studentWorkStore?.selectedModules?.module1Name +': '+ studentWorkStore?.selectedModules?.module1WSFrom +'-'+ studentWorkStore?.selectedModules?.module1WSTo : '---' }</dd>
                                            {/* <input type="text" className="form-control" id="inputModule1" placeholder="Module1" value={studentWorkStore?.selModule1?.module_name } disabled/> */}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputModule2" className="col-sm-4"> Stream 2:</label>
                                        <div className="col-sm-6">
                                            <dd>{studentWorkStore?.selectedModules?.module2Name ? studentWorkStore?.selectedModules?.module2Name +': '+ studentWorkStore?.selectedModules?.module2WSFrom +'-'+ studentWorkStore?.selectedModules?.module2WSTo : '---' }</dd>
                                            {/* <input type="text" className="form-control" id="inputModule2" placeholder="Module2" value={studentWorkStore?.selModule1?.module_name } disabled/> */}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputModule3" className="col-sm-4">Stream 3:</label>
                                        <div className="col-sm-6">
                                            <dd>{studentWorkStore?.selectedModules?.module3Name ? studentWorkStore?.selectedModules?.module3Name +': '+ studentWorkStore?.selectedModules?.module3WSFrom +'-'+ studentWorkStore?.selectedModules?.module3WSTo : '---' }</dd>
                                            {/* <input type="text" className="form-control" id="inputModule3" placeholder="Module3" /> */}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputModule4" className="col-sm-4">Stream 4:</label>
                                        <div className="col-sm-6">
                                            <dd>{studentWorkStore?.selectedModules?.module4Name ? studentWorkStore?.selectedModules?.module4Name +': '+ studentWorkStore?.selectedModules?.module4WSFrom +'-'+ studentWorkStore?.selectedModules?.module4WSTo : '---' }</dd>
                                            {/* <input type="text" className="form-control" id="inputModule4" placeholder="Module4" /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <Button type="submit" isLoading={isLoading} className="btn btn-warning mr-2" disabled={studentWorkStore.step != 3}>Process </Button>
                            <Button type="submit" onClick={resetSelectedSubject} className="btn btn-default mr-2">Back </Button>
                            <Link className="btn btn-default" to={`/student/work-summary/${id}`} role="button"> Work Summary </Link>
                        </div>
                    </div>
                </form>
            {/* </div>
        </section> */}
        <SetPatternModal
            show={show}
            handleClose={handleClose}
            studId={id}
            pattern={existingPattern}
        />
        <SelectModuleModal
            show={showSelectModule}
            handleClose={handleCloseSelectModule}
            studId={id}
            lastStreams={lastAssiModules}
        />
        <UpcomingModuleModal
            show={showUpcomingModule}
            handleClose={handleCloseUpcomingModule}
            streams={upcomingStreams}
            studId={id}
        />
    </div>
  )
}

export default SetStudentWork;