import React, { useState } from 'react'
import { Button } from '@app/components';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setShippingAddress } from '@app/store/reducers/order';
import { toast } from 'react-toastify';

const ShippingAddressModal = (props) => {

    const [selectedAddress, setSelectedAddress] = useState();
    const dispatch = useDispatch();

    const handleShippingAddress = (e) => {
        let selectedAddr = (props.addresses).find(obj => {
            return obj.id == e.target.value
        })
        setSelectedAddress(selectedAddr)
	}

    const handleSubmit = () => {
        if(selectedAddress){
            dispatch(setShippingAddress(selectedAddress));
            props.handleClose()
        }else{
            toast.error('Please select any shipping address.')
        }   
    }

    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Choose a shipping address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        {props.addresses && (props.addresses).map((obj) => (
                            <div className="form-check col-sm-12" key={obj?.id}>
                                <input className="form-check-input" 
                                    type="radio" name="shippingAddress" 
                                    onChange={ (e) => handleShippingAddress(e) }
                                    value={obj?.id}
                                />
                                <label className="form-check-label">{ obj?.address }</label><br/>
                                <label className="form-check-label">{ obj?.phone }</label><hr/>
                            </div>
                        )) }

                        {(props.addresses).length === 0 && (
                            <div className="text-danger"> There are no records to display </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" onClick={handleSubmit} className="btn btn-primary mr-2">Use this address </Button>
                    <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ShippingAddressModal;