import React, { useEffect, useRef, useState } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { getAllActiveFranchise } from '@app/services/franchise';
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import { getPrintHistoryWithPagination } from '@app/services/printReport';
import { setFranchisePrintSummary } from '@app/store/reducers/printSummary';

const ListPrintHistory = () => {

    const selectRef = useRef(null);
    const [printHistory, setPrintHistory] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({franchiseId: '', kashflowOrderAccount:'', month: '', year: (new Date().getFullYear()) + ''});
    const [franchises, setFranchises] = useState([]);
    const canAccess = useSelector((state: any) => state.auth.permissions?.['print-summary']);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const months = [{id: '01', name:'January'}, {id: '02', name:'February'}, {id: '03', name:'March'}, 
                    {id: '04', name:'April'}, {id: '05', name:'May'}, {id: '06', name:'June'},
                    {id: '07', name:'July'},{id: '08', name:'August'},{id: '09', name:'September'},
                    {id: '10', name:'October'}, {id: '11', name:'November'}, {id: '12', name:'December'}];
    const years = Array.from({ length: 5 }, (_, index) => new Date().getFullYear() - index );
    
    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    useEffect(() => {
        getAllByPaginationData(1, perPage, sortField, sortDirection);
    },[search]);

    useEffect(() => {
        getAllActiveFranchiseData();
    },[]);

    const getAllByPaginationData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const resp = await getPrintHistoryWithPagination(page, perPage, sortField, sortDirection, search);
            if (resp.success === true) {
                setLoading(false);
                setPrintHistory(resp.collections.data);
                setTotalRows(resp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal server error');
        }
    };

    const getAllActiveFranchiseData = async () => {
        try {
            const resp = await getAllActiveFranchise();
            if (resp?.success === true) {
                setFranchises(resp?.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error');
        }
    };

    const columns = [
        {
            name: 'Franchise',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            sortField: 'franchise_name',
            wrap: true,
        },
        {
            name: 'Kashflow Order Account',
            selector: (row: { kashflow_order_account: string; }) => row.kashflow_order_account ?? '---',
            sortable: true,
            sortField: 'kashflow_order_account',
            wrap: true
        },
        {
            name: 'Month',
            selector: (row: { month: any; }) => { return (months.find(obj => row.month === obj.id))?.name ?? '---' },
            sortable: true,
            sortField: 'month',
        },
        {
            name: 'Year',
            selector: (row: { year: string; }) => row.year ?? '---',
            sortable: true,
            sortField: 'year',
        },
        {
            name: 'Total Worksheet(s)',
            selector: (row: { worksheets: string; }) => row.worksheets ?? '---',
            sortable: true,
            sortField: 'worksheets',
            wrap: true,
        },
        {  
            name: 'Action',
            cell: (row) => (
              <>
                { canAccess?.['view'] && 
                   <>
                    <a href='#' onClick={ (e) => { viewSummaryDetails(e, row)} }>
                        <i className="fas fa-eye mr-2" title="View Summary Details"></i>
                    </a>
                    </>
                }
              </>
            ),
            omit: !(canAccess?.['view'])
        },
    ];

    const handlePageChange = page => {
        getAllByPaginationData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllByPaginationData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllByPaginationData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        selectRef.current.setValue('');
        setSearch({franchiseId: '', kashflowOrderAccount:'', month: '', year: (new Date().getFullYear()) + ''});
    };

    const onChangeFranchise = (option) => {
        setSearch({ ...search, franchiseId: option?.id ? option?.id : '' });
    };
    
    const viewSummaryDetails = (e, franchise) => {
        e.preventDefault();
        dispatch(setFranchisePrintSummary(franchise))
        navigate(`/print-summary/view/${franchise?.franchise_id}`);
    }

    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Print Summary</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item">Print Summary</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Print Summary</h3>
                                        <div className="col-lg-12 text-right">
                                           
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row mb-3">
                                            <div className="col-sm-3">
                                                <Select
                                                    ref={selectRef}
                                                    name="franchiseId"
                                                    placeholder="Search By Franchise"
                                                    options={franchises}
                                                    getOptionValue={(option)=>`${option['id']}`}
                                                    getOptionLabel={(option)=> option['name'] }
                                                    onChange={ (option) => {onChangeFranchise(option)}}
                                                    isClearable={true}
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    // onInputChange={ (option) => {onChangeFranchise(option)}}
                                                    // value={search.franchiseId}
                                                    // styles={customStylesSelect}
                                                />
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='kashflowOrderAccount' className="form-control" value={search.kashflowOrderAccount} onChange={(e) => setSearch({ ...search, kashflowOrderAccount: e.target.value })} placeholder="Kashflow Order Account" />
                                            </div>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="month"
                                                    onChange={(e) => setSearch({ ...search, month: e.target.value })} 
                                                    value={search.month}
                                                >
                                                    <option value="">All Months</option>
                                                    {months.map((obj) => (
                                                        <option key={obj.name} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="year"
                                                    onChange={(e) => setSearch({ ...search, year: e.target.value })} 
                                                    value={search.year}
                                                >
                                                    <option value="">All Years</option>
                                                    {years.map((year) => (
                                                        <option key={year} value={year}>
                                                            {year}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-sm-3'>
                                                <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning mr-2">Reset Filter</Button>
                                            </div>
                                        </div>

                                        <DataTable
                                            columns={columns}
                                            data={printHistory}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationDefaultPage={currentPage}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            paginationPerPage={perPage}
                                            paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                            progressPending={isLoading}
                                            sortServer
                                            onSort={handleSort}
                                            customStyles={customStyle}
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>

        </div>
    )
};

export default ListPrintHistory;
