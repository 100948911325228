import { createSlice } from '@reduxjs/toolkit';

export interface StudentWorkState {
	step: number;
	subject: {};
	modulePerWeek: number;
	pattern: any;
	module1: [];
	module2: [];
	module3: [];
	module4: [];
	selectedModules: any;
	upcomingModules: any;
	selModule1: any;
	selModule2: any;
	selModule3: any;
	selModule4: any;
	pageNumber: number;
}

const initialState: StudentWorkState = {
	step: 1,
	subject: {},
	modulePerWeek: 0,
	pattern: null,
	module1: [],
	module2: [],
	module3: [],
	module4: [],
	selectedModules: null,
	upcomingModules: null,
	selModule1: null,
	selModule2: null,
	selModule3: null,
	selModule4: null,
	pageNumber: 1,
};

export const StudentWorkSlice = createSlice({
	name: 'studentWork',
	initialState,
	reducers: {
		setStep: (state, {payload}) => {
			state.step = payload;
		},
		setSelectedSubject: (state, {payload}) => {
			state.subject = payload;
		},
		setModulesPerWk: (state, {payload}) => {
			state.modulePerWeek = payload;
		},
		setPattern: (state, {payload}) => {
			state.pattern = payload;
		},
		setSelectedModules: (state, {payload}) => {
			state.selectedModules = payload;
		},
		resetSelectedModules: (state) => {
			state.selectedModules = null;
		},
		setUpcomingModules: (state, {payload}) => {
			state.upcomingModules = payload;
		},
		resetUpcomingModules: (state) => {
			state.upcomingModules = null;
		},
		setModule: (state, {payload}) => {
			switch(payload.moduleNumber){
                case 1 : state.module1 = payload.module
                                break;
                case 2 : state.module2 = payload.module
                                break;
                case 3 : state.module3 = payload.module
                                break;
                case 4 : state.module4 = payload.module
                                break;
				case 'all' : state.module1 = payload.module
							state.module2 = payload.module
							state.module3 = payload.module
							state.module4 = payload.module
                        break;
            }
		},
		setSelModuleDetails: (state, {payload}) => {
			switch(payload.moduleNumber){
                case 1 : state.selModule1 = payload.module
                                break;
                case 2 : state.selModule2 = payload.module
                                break;
                case 3 : state.selModule3 = payload.module
                                break;
                case 4 : state.selModule4 = payload.module
                                break;
				case 'all' : state.selModule1 = payload.module
							state.selModule2 = payload.module
							state.selModule3 = payload.module
							state.selModule4 = payload.module
							break;
            }
		},
		setPageNum: (state, { payload }) => {
			state.pageNumber = payload;
			// StudentWorkSlice.caseReducers.setSynch(state, { payload: true, type: null });
		},
	}
});

export const { setStep, setSelectedSubject, setModulesPerWk, setPattern, setModule, setSelectedModules, resetSelectedModules, setSelModuleDetails, setUpcomingModules, resetUpcomingModules, setPageNum } = StudentWorkSlice.actions;

export default StudentWorkSlice.reducer;